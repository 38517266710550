import { Badge, Button, Col, Input, Modal, Row, Skeleton, Spin } from "antd";
import { LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import DataTableLayout from "components/layouts/DataTableLayout";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import axios from "axios";
import iconCCTV from "images/cctv.png";
import iconChart from "images/bar-chart.png";
import iconCon from "images/cctv-online.png";
import iconErr from "images/cctv-offline.png";
import styled from "styled-components";

const { REACT_APP_CCTV_MONOTORING, REACT_APP_CCTV_WS } = process.env;
// const connection = new W3CWebSocket(`ws://${REACT_APP_CCTV_WS}/ws/monitor`)

let lists = [];
let listSearch = [];
let timer = 0;

const CctvList = (props) => {
  const [search, setSearch] = useState(
    localStorage.getItem("search") ? localStorage.getItem("search") : ""
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isConnect, setIsConnect] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [isOnline, setIsOnline] = useState(0);
  const [isOffline, setIsOffline] = useState(0);
  const [isUpdateStatus, setIsUpdateStatus] = useState({
    status: false,
    id: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    window.addEventListener("online", () => {
      if (isDisconnected === true) {
        setIsDisconnected(false);
      }
    });
    window.addEventListener("offline", () => {
      if (isDisconnected === false) {
        setIsDisconnected(true);
      }
    });

    function connect() {
      const ws = new W3CWebSocket(`wss://${REACT_APP_CCTV_WS}/ws/monitor`);
      ws.onopen = function () {
        setIsConnect(true);
        // subscribe to some channels
        if (isDisconnected === false) {
          getList("onopen");
        }
        ws.send(
          JSON.stringify({
            //.... some message the I must send when I connect ....
          })
        );
      };

      ws.onmessage = function (e) {
        let data = JSON.parse(e.data);
        updateStatusCCTV(data);
      };

      ws.onclose = function (e) {
        setIsConnect(false);
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second.",
          e.reason
        );
        setTimeout(function () {
          connect();
          if (isDisconnected === false) {
            getList("onclose");
          }
        }, 5000);
      };

      ws.onerror = function (err) {
        setIsConnect(false);
        console.error(
          "Socket encountered error: ",
          err.message,
          "Closing socket"
        );
        ws.close();
      };
    }

    setDefaultCookies();
    if (!lists.length) {
      getList("lists");
    }

    connect();

    return () => {
      lists = [];
      listSearch = [];
      timer = 0;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisconnected]);

  const setDefaultCookies = () => {
    if (window.performance) {
      if (window.performance.navigation.type === 0) {
        localStorage.setItem("search", "");
      }
    }
  };

  const getList = (type) => {
    axios
      .get(`${REACT_APP_CCTV_MONOTORING}/cctv`, {
        headers: {
          "Accept-Language": "th",
        },
      })
      .then(function (response) {
        setIsModalVisible(false);
        onSetLoading();
        const { code, data } = response?.data;
        if (code === 200) {
          const dataMap =
            data &&
            data.map((item, key) => {
              item.order = key + 1;
              return item;
            });

          lists = dataMap || [];
          if (lists.length > 0) {
            setDataList(lists);
            if (search) {
              onSearch();
              countStatusCCTV(listSearch);
            } else {
              countStatusCCTV(dataMap);
            }
            setIsLoading(false);
          }
          if (data === null || data === undefined) {
            setDataList([]);
            setIsLoading(false);
          }
        } else {
          props.history.push("/");
        }
      })
      .catch(function (error) {
        onSetLoading();
        setIsModalVisible(true);
        console.log(error);
      });
  };

  const onSetLoading = () => {
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  };

  const sortByStatus = (a, b) => {
    return a.status - b.status;
  };

  const updateStatusCCTV = (data) => {
    const dataLists = lists.map((item, key) => {
      if (data.u === item.camera_name) {
        item.status = data.s === "false" ? false : true;
        return item;
      }
      return item;
    });
    dataLists
      .sort((a, b) => sortByStatus(a, b))
      .map((item, key) => {
        item.order = key + 1;
        return item;
      });
    lists = dataLists;

    const dataListSearch = listSearch
      .map((item) => {
        if (data.u === item.camera_name) {
          item.status = data.s === "false" ? false : true;
          return item;
        }
        return item;
      })
      .map((item, key) => {
        item.order = key + 1;
        return item;
      });

    dataListSearch
      .sort((a, b) => sortByStatus(a, b))
      .map((item, key) => {
        item.order = key + 1;
        return item;
      });
    listSearch = dataListSearch;
    setDataList(listSearch.length ? listSearch : lists);
    countStatusCCTV(listSearch.length ? listSearch : lists);
    setIsUpdateStatus({
      status: true,
      id: data.u,
    });
  };

  const onSearch = () => {
    const result = lists
      .filter((item) => {
        return (
          item.name.toLowerCase().search(search.toLowerCase()) !== -1 ||
          item.group.toLowerCase().search(search.toLowerCase()) !== -1
        );
      })
      .map((item, key) => {
        item.order = key + 1;
        return item;
      });

    listSearch = result;
    setDataList(listSearch);
    countStatusCCTV(listSearch);
    loseFocus();
  };

  const onSetKeyword = (e) => {
    clearInterval(timer);
    timer = setInterval(() => {
      if (document.getElementById("input")) {
        document.getElementById("input").blur();
      }
    }, 5000);

    let value = e.target.value.trimStart();
    localStorage.setItem("search", value);
    setSearch(value);

    const result = lists
      .filter((item) => {
        return (
          item.name.toLowerCase().search(value.toLowerCase()) !== -1 ||
          item.group.toLowerCase().search(value.toLowerCase()) !== -1
        );
      })
      .map((item, key) => {
        item.order = key + 1;
        return item;
      });
    listSearch = result;

    setDataList(listSearch);
    countStatusCCTV(listSearch);
  };

  const countStatusCCTV = (data) => {
    setIsOnline(data.filter((item) => item.status === true).length);
    setIsOffline(data.filter((item) => item.status === false).length);
  };

  const loseFocus = () => {
    if (document.getElementById("input")) {
      document.getElementById("input").blur();
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "order",
      key: "order",
      width: "1%",
    },
    {
      title: "ชื่อกล้อง",
      dataIndex: "name",
      key: "name",
      width: "10%",
    },
    {
      title: "กลุ่ม",
      dataIndex: "group",
      key: "group",
      width: "10%",
    },
    {
      title: "สถานะ",
      key: "status",
      dataIndex: "status",
      width: "5%",
      render: (status, record) => {
        return (
          <span className={`arrow-${record.camera_name}`}>
            <Badge
              // className={`${isUpdateStatus.status === true && isUpdateStatus.id === record.camera_name && 'blink-status'}`}
              clearclass={`${
                isUpdateStatus.status === true &&
                isUpdateStatus.id === record.camera_name &&
                clearclass(record)
              }`}
              status={status === true ? "success" : "error"}
              text={status === true ? "ทำงาน" : "ไม่ทำงาน"}
              style={{
                color: status === true ? "#00c48c" : "#ff0000",
              }}
            />
          </span>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: "1%",
      render: (record) => {
        return (
          <ChartImg
            src={iconChart}
            alt="Icon Chart"
            onClick={() =>
              props.history.push(`/cctv-monitoring/view/${record.camera_name}`)
            }
          />
        );
      },
    },
  ];

  const clearclass = (record) => {
    setTimeout(() => {
      let oldActiveElement = document.querySelector(
        `.arrow-${record.camera_name} .ant-badge`
      );
      if (oldActiveElement) {
        if (oldActiveElement.classList[0] === `blink-status`) {
          oldActiveElement.classList.remove(`blink-status`);
          setTimeout(() => {
            oldActiveElement.classList.add(`blink-status`);
            setTimeout(() => {
              oldActiveElement.classList.remove(`blink-status`);
              setIsUpdateStatus({
                status: false,
                id: "",
              });
            }, 5600);
          }, 100);
        } else {
          oldActiveElement.classList.add(`blink-status`);
          setTimeout(() => {
            oldActiveElement.classList.remove(`blink-status`);
            setIsUpdateStatus({
              status: false,
              id: "",
            });
          }, 5500);
        }
      }
    }, 500);
  };

  const onClickRefresh = () => {
    getList("refresh");
    setLoad(true);
  };

  return (
    <>
      <Row>
        <Card>
          <Wrap>
            <Container>
              <Row>
                <Col span={12} className="card-image">
                  <Image src={iconCCTV} alt="Icon Connent" />
                </Col>
                <Col span={12} className="card-text">
                  <TextNumber>{isOnline + isOffline}</TextNumber>
                  <TextName>กล้องทั้งหมด</TextName>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col span={12} className="card-image">
                  <Image src={iconCon} alt="Icon Connent" />
                </Col>
                <Col span={12} className="card-text">
                  <TextNumber>{isOnline ? isOnline : 0}</TextNumber>
                  <TextName>กล้องทำงาน</TextName>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col span={12} className="card-image">
                  <Image src={iconErr} alt="Icon Error" />
                </Col>
                <Col span={12} className="card-text">
                  <TextNumber>{isOffline ? isOffline : 0}</TextNumber>
                  <TextName>กล้องไม่ทำงาน</TextName>
                </Col>
              </Row>
            </Container>
          </Wrap>
        </Card>
      </Row>

      <Row justify={`${isConnect === false ? "space-between" : "end"}`}>
        {isConnect === false && (
          <Col style={{ marginTop: "6px" }}>
            <WarningOutlined style={{ color: "#ffc107" }} />
            <TextWarn>Connection lost</TextWarn>
          </Col>
        )}
        <Col>
          <Input
            id="input"
            style={{ marginBottom: "30px", width: "20vmax" }}
            onChange={onSetKeyword}
            placeholder="ค้นหาชื่อกล้อง, กลุ่ม"
            onPressEnter={() => onSearch()}
            value={search}
            autoComplete="on"
            allowClear
          ></Input>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          {isLoading === true ? (
            <Skeleton
              className="App-form"
              loading={isLoading}
              active
            ></Skeleton>
          ) : (
            <DataTableLayout
              dataSource={dataList}
              columns={columns}
              isloading={isLoading}
              showPagination={false}
            />
          )}
        </Col>
      </Row>
      <Modal
        className="lost-connect-model"
        title={false}
        visible={isModalVisible}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
        width="445px"
        footer={null}
      >
        <h2>Refresh Connection</h2>
        <p>Connection has been lost - please refresh to continue.</p>
        <Row>
          <Col span={12}>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />}
              spinning={load}
              style={{ color: "#bd1d2d" }}
            />
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button type="primary" onClick={onClickRefresh}>
              Refresh
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const Card = styled.div`
  background: #ffff;
  padding: 30px 0 30px 0;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 30px;
`;

const Wrap = styled.div`
  margin: 0 auto;
  display: flex;
`;

const Container = styled.div`
  padding: 0 10px 0 10px;
  width: 33.33%;
  background: #ffff;
  display: inline-block;
  border-right: solid rgba(0, 0, 0, 0.06) 1px;
  position: relative;

  &:last-child {
    border: 0px;
  }
`;

const TextNumber = styled.h1`
  text-align: left;
  font-size: clamp(18px, 1.5vw + 1.5vh, 32px);
  display: block;
  margin-bottom: auto;
`;

const TextName = styled.h2`
  text-align: left;
  text-transform: capitalize;
  opacity: 0.5;
  display: block;
  font-size: clamp(16px, 1vw, 20px);
`;

const Image = styled.img`
  width: 100%;
  max-width: 70px;
  height: auto;
`;

const ChartImg = styled.img`
  width: 16px;
  cursor: pointer;
`;

const TextWarn = styled.p`
  font-size: 14px;
  color: #6c757d;
  display: inline;
  margin-left: 5px;
`;

export default CctvList;
