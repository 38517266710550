import React from 'react'
import { connect } from 'react-redux'
import {
  Form,
  Upload,
  Modal,
  Input,
  Skeleton,
  message,
  DatePicker,
  Radio,
  Row,
  Col,
  Space,
  Button,
  Tooltip,
  TimePicker,
} from 'antd'
import moment from 'moment'
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import Footeractionlayout from 'components/layouts/Footeractionlayout'
import { createData, readData, updateData } from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'
import { resizeUpload } from 'actions/file'
import { slides } from 'schemas'
import iconErr from 'images/icon-error@3x.png'
import iconCorrect from 'images/correct@3x.png'

const { TextArea } = Input

const formRef = React.createRef()
const textValidName = (
  <span>ระบุเฉพาะ a-z, A-Z, 0-9, -, _ และ เว้นวรรค เท่านั้น</span>
)

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

function disabledDate(current) {
  return current && current <= moment().startOf('day')
}



class Formslidemenulayout extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
    isRemove: false,
    form: [],
    isDirty: true,
    tempData: {},
    isNameFill: true,
    isDetailFill: true,

    errorMsg: '',
    isCreated: false,
    isCreateError: false,
    isCancel: false,

    linkCheck: '',
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.readData(this.props.match.params.id).then((res) => {
        this.state.fileList.push(this.setImageToState(res?.data?.image_url))
        let tempData = {
          image_url: res?.data?.image_url,
          name: res?.data?.name,
          detail: res?.data?.detail,
          published_at: res?.data?.published_at,
          expired_at: res?.data?.expired_at,
          status: res?.data?.status,
        }
        this.setState({ tempData, linkCheck: res.data.link_url })
      })
    }
  }

  setImageToState(value) {
    return {
      uid: -1,
      name: 'Slideimage.png',
      status: 'done',
      url: value,
    }
  }

  clickCancle = () => {
    this.setState({ isCancel: true })
  }

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    })
  }

  handleCancel = () => this.setState({ previewVisible: false, isCancel: false })

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  handleChange = ({ fileList }) => this.setState({ fileList })

  handleSubmit = (value) => {
    const publish = moment(value.publish_date).format("DD/MM/YYYY") + " " + moment(value.publish_time).format("HH:mm")
    const publish_date = moment(publish, "DD/MM/YYYY HH:mm").unix();
    const expire = moment(value.expire_date).format("DD/MM/YYYY") + " " + moment(value.expire_time).format("HH:mm")
    const expire_date = moment(expire, "DD/MM/YYYY HH:mm").unix();

    const { isDirty } = this.state
    if (isDirty) {
      this.setState({ isDirty: false })
      const requestFrom = {
        name: { th: value.nameth, en: value.nameen },
        detail: { th: value.detailth, en: value.detailen },
        status: value.status,
        image_url: value.image_url.file.originFileObj.url,
        link_url: value.link_url,
        target: value.target,
        created_at: moment().unix(),
        published_at: publish_date,
        expired_at: expire_date,
      }

      message.loading('Save in progress..', 0)
      this.props.createData(requestFrom).then((res) => {
        message.destroy()
        if (res?.code === 200) {
          this.setState({ isDirty: true, isCreated: true }, () => {
            localStorage.setItem('page', 1)
            localStorage.setItem('search', '')
          })
        } else {
          this.setState({
            isDirty: true,
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    }
  }

  checkValidEng = (rule, value) => {
    if (value === ' ') {
      formRef.current.setFieldsValue({
        nameen: '',
      })
    } else {
      formRef.current.setFieldsValue({
        nameen: value,
      })
    }
    if (value === null || value === '') {
      return Promise.reject('Slide name is required !!')
    } else {
      if (!/^([A-Za-z0-9-_ ]{1,})?$/.test(value)) {
        return Promise.reject('Invalid data format !!')
      } else {
        return Promise.resolve()
      }
    }
  }

  handleUpdate = (value) => {
    const publish = moment(value.publish_date).format("DD/MM/YYYY") + " " + moment(value.publish_time).format("HH:mm")
    const publish_date = moment(publish, "DD/MM/YYYY HH:mm").unix();
    const expire = moment(value.expire_date).format("DD/MM/YYYY") + " " + moment(value.expire_time).format("HH:mm")
    const expire_date = moment(expire, "DD/MM/YYYY HH:mm").unix();

    const { isDirty } = this.state
    if (isDirty) {
      this.setState({ isDirty: false })
      const requestFrom = {
        name: { th: value.nameth, en: value.nameen },
        detail: { th: value.detailth, en: value.detailen },
        status: value.status,
        image_url: value.image_url.file
          ? value.image_url.file.originFileObj.url
          : value.image_url[0].url,
        link_url: value.link_url,
        target: value.target,
        created_at: moment().unix(),
        published_at: publish_date,
        expired_at: expire_date,
      }

      message.loading('Save in progress..', 0)
      this.props
        .updateData(requestFrom, this.props.match.params.id)
        .then((response) => {
          message.destroy()
          if (response?.code === 200) {
            this.setState({ isDirty: true, isCreated: true })
          } else {
            this.setState({
              isDirty: true,
              isCreateError: true,
              errorMsg: response?.message,
            })
          }
        })
    }
  }

  checkImg = (rule, value) => {
    if (this.props.match.params.id) {
      if (value[0]) {
        return Promise.resolve()
      }
    }
    if (
      (value && value.file.type === 'image/png') ||
      (value && value.file.type === 'image/jpeg')
    ) {
      if (this.props.match.params.id) {
        if (value && value.length) {
          return Promise.resolve()
        } else if (value.fileList && value.fileList.length !== 0) {
          return Promise.resolve()
        }
      } else {
        if (value.fileList && value.fileList.length !== 0) {
          return Promise.resolve()
        }
      }
      if (this.state.isRemove === true) {
        return this.setState({ isRemove: false })
      } else {
        return Promise.reject(
          <div style={{ marginTop: '-15px' }}>Please insert photo !!</div>
        )
      }
    } else {
      if (value && value.fileList.length !== 0) {
        value.file = []
        value.fileList = []
        this.setState({ fileList: [] })
        if (value.fileList && value.fileList.length === 0) {
          return Promise.reject(
            <div style={{ marginTop: '-15px' }}>
              Please insert file type .png, .jpeg only !
            </div>
          )
        }
      } else {
        if (this.state.isRemove === true) {
          return this.setState({ isRemove: false })
        } else {
          return Promise.reject(
            <div style={{ marginTop: '-15px' }}>Please insert photo !!</div>
          )
        }
      }
    }
  }

  onLinkChange = async (value) => {
    console.log('value', value)
    await this.setState({ linkCheck: value })
  }

  onRemoveImg = () => {
    this.setState({ isRemove: true })
  }

  render() {
    const { dataList, isLoading } = this.props
    const { previewVisible, previewImage, fileList } = this.state

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    )
    return (
      <>
        {isLoading === true ? (
          <Skeleton className="App-form" loading={isLoading} active></Skeleton>
        ) : (
          <Form
            ref={formRef}
            layout="vertical"
            className="App-form"
            onFinish={
              this.props.match.params.id ? this.handleUpdate : this.handleSubmit
            }
          >
            <Form.Item style={{ marginTop: '-20px' }}>
              <h1 className="topic">
                {this.props.match.params.id
                  ? 'แก้ไขข้อมูลรูปภาพสไลด์'
                  : 'เพิ่มข้อมูลรูปภาพสไลด์'}
              </h1>
            </Form.Item>
            <Form.Item
              className="form-slide-menu-size"
              label={
                <Row>
                  <Col>อัพโหลดรูปภาพ</Col>
                  <Col style={{ color: 'red' }}>&nbsp;*</Col>
                </Row>
              }
            >
              <p
                style={{
                  fontSize: '12px',
                  marginTop: '-20px',
                }}
              >
                (ขนาดภาพแนะนำ 1060x580 px)
              </p>
            </Form.Item>
            <Form.Item
              style={{ marginTop: '-35px' }}
              name="image_url"
              rules={[
                {
                  required: true,
                  validator: this.checkImg,
                },
              ]}
              initialValue={this.props.match.params.id ? fileList : ''}
            >
              <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={this.handlePreview}
                onRemove={this.onRemoveImg}
                onChange={this.handleChange}
                customRequest={this.props.addImageData}
                accept="image/png,image/jpeg"
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  label="ชื่อ"
                  name="nameth"
                  rules={[
                    { required: true, message: 'Slide name is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.name
                      ? dataList.name.th
                      : null
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label="Name"
                  name="nameen"
                  rules={[
                    {
                      required: true,
                      validator: this.checkValidEng,
                      transform: (value) => {
                        let text = ''
                        if (!/^([A-Za-z0-9-_ ]{1,})?$/.test(value)) {
                          text = value.substring(0, value.length - 1)
                        } else {
                          text = value
                        }
                        return text
                      },
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.name
                      ? dataList.name.en
                      : null
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={1}>
                <Tooltip
                  className="sil-toottip-custom-width"
                  placement="topRight"
                  title={textValidName}
                >
                  <ExclamationCircleOutlined
                    style={{ fontSize: '18px', marginTop: '38px' }}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  label="รายละเอียด"
                  name="detailth"
                  rules={[
                    { required: false, message: 'Photo detail is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.detail
                      ? dataList.detail.th
                      : ''
                  }
                >
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Detail"
                  name="detailen"
                  rules={[
                    { required: false, message: 'Photo detail is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.detail
                      ? dataList.detail.en
                      : ''
                  }
                >
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  label="ลิ้งค์ข้อมูลเพิ่มเติม"
                  name="link_url"
                  rules={[
                    { type: 'url', message: 'Website format is incorrect.' },
                    { required: false, message: 'Link is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.link_url
                      ? dataList.link_url
                      : ''
                  }
                >
                  <Input
                    autoComplete="off"
                    onChange={(e) => this.onLinkChange(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  label="ประเภทการเปิดลิ้งค์"
                  name="target"
                  rules={[
                    { required: false, message: 'Link target is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id
                      ? dataList.target === '_blank'
                        ? '_blank'
                        : '_self'
                      : '_blank'
                  }
                >
                  <Radio.Group
                    disabled={this.state.linkCheck !== '' ? false : true}
                  >
                    <Radio value={'_blank'}>เปิดแท็บใหม่</Radio>
                    <Radio value={'_self'}>เปิดแท็บเดิม</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[5, 15]}>
              <Col span={8}>
                <Form.Item
                  label="วันและเวลาที่ต้องการเผยแพร่"
                  name="publish_date"
                  rules={[
                    { required: true, message: 'Publish Date is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id
                      ? moment.unix(dataList.published_at)
                      : ''
                  }
                >
                  <DatePicker style={{ width: '100%' }} disabledDate={disabledDate} />
                </Form.Item>
              </Col>
              <Col span={4} style={{ marginTop: '31px' }}>
                <Form.Item
                  name="publish_time"
                  rules={[
                    { required: true, message: 'Publish Time is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id
                      ? moment.unix(dataList.published_at)
                      : ''
                  }
                >
                  <TimePicker
                    format={'HH:mm'}
                    style={{ width: '100%' }}
                    minuteStep={5}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[5, 15]}>
              <Col span={8}>
                <Form.Item
                  label="วันและเวลาที่สิ้นสุด"
                  name="expire_date"
                  rules={[
                    { required: true, message: 'Expire Date is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id
                      ? moment.unix(dataList.expired_at)
                      : ''
                  }
                >
                  <DatePicker style={{ width: '100%' }} disabledDate={disabledDate} />
                </Form.Item>
              </Col>
              <Col span={4} style={{ marginTop: '31px' }}>
                <Form.Item
                  name="expire_time"
                  rules={[
                    { required: true, message: 'Expire Time is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id
                      ? moment.unix(dataList.expired_at)
                      : ''
                  }
                >
                  <TimePicker
                    format={'HH:mm'}
                    style={{ width: '100%' }}
                    minuteStep={5}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  label="สถานะ"
                  name="status"
                  rules={[{ required: false }]}
                  initialValue={
                    this.props.match.params.id
                      ? (dataList.status === 'active') |
                        (dataList.status === 'draft') |
                        (dataList.status === 'expired')
                        ? 'active'
                        : 'inactive'
                      : 'active'
                  }
                >
                  <Radio.Group>
                    <Radio value={'active'}>ใช้งาน</Radio>
                    <Radio value={'inactive'}>ไม่ใช้งาน</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item shouldUpdate={true}>
              <Footeractionlayout
                onCancle={this.clickCancle}
              ></Footeractionlayout>
            </Form.Item>
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={this.handleCancel}
              width={800}
            >
              <img
                alt="example"
                style={{ width: '100%', marginTop: '20px' }}
                src={previewImage}
              />
            </Modal>
            <Modal
              visible={this.state.isCreated}
              footer={false}
              closable={false}
              centered={true}
              width={'420px'}
            >
              <div
                style={{
                  height: '220px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <img
                      alt="example"
                      src={iconCorrect}
                      width={48}
                      height={48}
                    />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    บันทึกข้อมูลสำเร็จแล้ว
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กดตกลงเพื่อออกจากหน้านี้
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.goBack()
                        }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.isCreateError}
              footer={false}
              closable={false}
              centered={true}
              width={'500px'}
            >
              <div
                style={{
                  height: '220px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <img alt="example" src={iconErr} width={48} height={48} />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    บันทึกข้อมูลไม่สำเร็จ
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    {this.state.errorMsg}
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.setState({ isCreateError: false })
                        }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.isCancel}
              footer={false}
              closable={false}
              centered={true}
              width={'500px'}
            >
              <div
                style={{
                  height: '180px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    ยืนยันการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    หากคุณต้องการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กรุณากด ยืนยัน
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        style={{ width: '170px' }}
                        onClick={() => this.handleCancel()}
                      >
                        ยกเลิก
                      </Button>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.goBack()
                        }}
                      >
                        ยืนยัน
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
          </Form>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(slides, state),
  dataList: getResource(slides, state),
})

const mapDispatchToProps = (dispatch) => ({
  createData: (data) => dispatch(createData(slides, data)),
  readData: (id) => dispatch(readData(slides, id)),
  updateData: (data, id) => dispatch(updateData(slides, data, id)),
  addImageData: (params) => dispatch(resizeUpload(params)),
})

const SlideListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Formslidemenulayout)

export default SlideListWithConnect
