import { Button, Col, Form, Input, Modal, Row, Space, message, Tooltip } from 'antd'
import React, { useState } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import SvgEmail from 'images/icon-email.svg'
import SvgEye from 'images/icon-eye.svg'
import SvgEyeLine from 'images/icon-eye-line.svg'
import SvgPassword from 'images/icon-password.svg'
import axios from 'axios'
import iconError from 'images/icon-error@3x.png'
import moment from 'moment'
import styled from 'styled-components'

const ForgetPassword = (props) => {
  const [messageErrorModal, setMessageErrorModal] = useState('')
  const [messageErrorEmail, setMessageErrorEmail] = useState('')
  const [messageErrorPass, setMessageErrorPass] = useState('')
  const [messageErrorCon, setMessageErrorCon] = useState('')
  const [openModelError, setOpenModalError] = useState(false)
  const [isLoading, setIsloading] = useState(false)
  const textValidName = (
    <span>ใช้อักขระ 8 ตัวขึ้นไป ที่มีทั้งตัวอักษรและตัวเลขผสมกัน</span>
  )
  const onFinish = (values) => {
    const requireVerify = {
      call_function: "check_contact_exist",
      contact: values?.email,
      service: "seeitlive",
      channel: "email",
      otp_code_length: 6,
      ref_code_length: 6,
      verify_max: 3,
      expire_time: 60
    }

    setIsloading(true)
    setMessageErrorCon('')
    setMessageErrorPass('')
    message.loading({ content: 'Loading...' })
    axios.post(`${process.env.REACT_APP_MSG}/otp/request`, requireVerify)
      .then(async (response) => {
        setIsloading(false)
        message.destroy()
        if (response?.data?.code === 200) {
          localStorage.setItem('time_count', moment().add(1, 'minutes'))
          localStorage.setItem('contact', response?.data?.data?.contact)
          localStorage.setItem('ref_code', response?.data?.data?.ref_code)
          localStorage.setItem('forgot_password', values?.password)
          localStorage.setItem('otp_type', 'forgot_password')
          props.history.push('/otp')
        } else {
          setOpenModalError(true)
          setMessageErrorModal(response?.data?.message)
        }
      }).catch((error) => {
        setIsloading(false)
        message.destroy()
        console.log('error', error?.response?.data?.errors)
        switch (error?.response?.data?.errors[0].subCode) {
          case 'EMAIL_NOT_EXIST':
            setMessageErrorModal('This email does not exist !!')
            setOpenModalError(true)
            break;
          default:
            setMessageErrorModal('พบข้อผิดพลาดในการทำงาน ')
            setOpenModalError(true)
            break;
        }
        throw error
      })
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo?.errorFields.length) {
      errorInfo.errorFields.map((err) => {
        if (err.name.toString() === 'password') {
          setMessageErrorPass(err.errors)
        } else if (err.name.toString() === 'confirm') {
          setMessageErrorCon(err.errors)
        } else {
          setMessageErrorCon('')
          setMessageErrorEmail(err.errors)
        }
        return err
      })
    }
  }

  const onFieldsChange = (e, key) => {
    switch (key) {
      case 'email':
        setMessageErrorEmail('')
        break;
      case 'password':
        setMessageErrorPass('')
        break;
      case 'confirm':
        setMessageErrorCon('')
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <LayoutFPassword>
        <Row align='center' justify='center'>
          <Col align='center' span='24'>
            <h1 className='title-password-forgot'>
              ลืมรหัสผ่าน
            </h1>
            <p className='sub-title'>รีเซ็ตรหัสผ่าน</p>
          </Col>
          <Col span='24'>
            <Form
              {...layout}
              name="basic"
              onFinish={!isLoading && onFinish}
              onFinishFailed={onFinishFailed}
              className="account-form"
            >
              <Form.Item
                className={messageErrorEmail ? 'inp-login inp-account msg-err' : 'inp-login inp-account'}
                name="email"
                validateStatus={messageErrorEmail ? 'error' : ''}
                help={messageErrorEmail}
                rules={[
                  {
                    required: true,
                    message: 'Email is required !!',
                  },
                  {
                    pattern: /^[\w-.]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Email format is incorrect !!'
                  }
                ]}
              >
                <Input
                  prefix={<img src={SvgEmail} width="16" height="16" alt="Email" />}
                  placeholder="อีเมล"
                  autoComplete="off"
                  name="email"
                  onChange={(e) => onFieldsChange(e, 'email')}
                />

              </Form.Item>
              <Tooltip title={textValidName}>
                <ExclamationCircleOutlined style={{ position: 'absolute', fontSize: '18px', marginTop: '20px', right: ' -28px', margin: ' 7px 0' }} />
              </Tooltip>
              <Form.Item
                className={messageErrorPass ? 'inp-login inp-account msg-err' : 'inp-login inp-account '}
                name="password"
                validateStatus={messageErrorPass ? 'error' : ''}
                help={messageErrorPass}
                rules={[
                  {
                    required: true,
                    message: 'New password is required !!',
                  }
                  , {
                    pattern: /^(?!.* )(?=.*?)(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                    message: 'New password is invalid !!',
                  },
                ]}
              >
                <Input.Password
                  prefix={<img src={SvgPassword} width="16" height="16" alt="password" />}
                  type="password"
                  autoComplete="new-password"
                  placeholder="รหัสผ่านใหม่"
                  iconRender={visible => (visible ?
                    <img src={SvgEye} width="16" height="16" alt="Show password" /> :
                    <img src={SvgEyeLine} width="16" height="16" alt="Hide password" />)
                  }
                  name="password"
                  onChange={(e) => onFieldsChange(e, 'password')}
                />

              </Form.Item>
              <Form.Item
                className={messageErrorCon ? 'inp-login inp-account msg-err' : 'inp-login inp-account '}
                name="confirm"
                validateStatus={messageErrorCon ? 'error' : ''}
                dependencies={['password']}
                help={messageErrorCon}
                rules={[
                  {
                    required: true,
                    message: 'Confirm new password is required !!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Password don’t match. Please try again.'));
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<img src={SvgPassword} width="16" height="16" alt="password" />}
                  type="password"
                  placeholder="ยืนยันรหัสผ่านใหม่"
                  iconRender={visible => (visible ?
                    <img src={SvgEye} width="16" height="16" alt="Show password" /> :
                    <img src={SvgEyeLine} width="16" height="16" alt="Hide password" />)
                  }
                  name="password"
                  onChange={(e) => onFieldsChange(e, 'confirm')}
                />
              </Form.Item>

              <Form.Item {...tailLayout} className="btn-account">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="account-form-button"
                >
                  ขอรหัส OTP
                </Button>
              </Form.Item>

            </Form>
          </Col>
        </Row>
      </LayoutFPassword>
      <Modal
        visible={openModelError}
        footer={false}
        closable={false}
        centered={true}
        width={'520px'}
        style={{ padding: '0' }}
      >
        <BoxModel height="315px">
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img
                alt="example"
                src={iconError}
                width={70}
                height={70}
              />
            </Col>
            <Col span={24}>
              <HeaderModel>
                {messageErrorModal || 'พบข้อผิดพลาดในการทำงาน กรุณาลองใหม่อีกครั้ง'}
              </HeaderModel>
            </Col>
            <Col span={24} >
            </Col>
            <Col span={24}>
              <TextModel marginTop="45px">
                <Space>
                  <Button onClick={() => setOpenModalError(false)}
                    type="primary" style={{ width: '170px' }}>
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
    </div>

  )
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
}

const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
}

const BoxModel = styled.div`
  min-height: ${(props) => (props.minHeight)};
  height: ${(props) => (props.height)};
  justify-content: center;
  align-items: center;
  display: flex;
`

const HeaderModel = styled.div`
  color: black;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  margin-top: 35px;
`

const TextModel = styled.div`
  color: black;
  font-size: 16px;
  font-weight: 300;
  text-align: center ;
  margin-top: ${(props) => (props.marginTop)};
`

const LayoutFPassword = styled.div`
  width: 300px;
  height: 100px;
  position: absolute;
  top: -200px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  .title-password-forgot{
    font-weight: 500;
    font-size: 35px;
    line-height: 52px;
    margin: 0;
  }
  .sub-title{
    color: black;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 35px;
  }
`

export default ForgetPassword
