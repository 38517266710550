import React from 'react'
// import { Button } from 'antd'
import ReactApexChart from 'react-apexcharts'
import ApexCharts from 'apexcharts'
import moment from 'moment'

const ApexChart = ({ data, dataPageviews, dateStart, dateEnd }) => {
  // const [selection, setSelection] = useState('one_month')
  // const [dataChart, setDataChart] = useState([])

  const series = [
    {
      name: 'ผู้เข้าใช้งาน',
      data: data,
      // type: 'area',
    },
    {
      name: 'จำนวนหน้าที่มีการเปิด',
      data: dataPageviews,
    }
  ]

  const options = {
    type: 'area',
    chart: {
      stacked: true,
      events: {
        selection: function (chart, e) {
          console.log(new Date(e.xaxis.min))
        },
      },
      zoom: {
        autoScaleYaxis: false,
      },
      toolbar: { show: false },
    },
    // colors: ['#1A73E8', '#50b432'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    fill: {
      // type: ['gradient', 'solid'],
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
    markers: {
      size: 5,
      hover: {
        size: 9,
      },
    },
    xaxis: {
      // tickAmount: 6,
      type: 'category',
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        maxHeight: 120,
        formatter: function (value, timestamp, index) {
          return moment(new Date(timestamp)).format('DD MMM ')
        },
        datetimeUTC: true,
      },
    },
    tooltip: {
      x: {
        show: true,
        formatter: function (value, timestamp, index) {
          return moment(new Date(value)).add(543, 'year').format('DD MMM YYYY ')
        },
      },
    },
  }

  const year_start = new Date(dateStart).getFullYear()
  const month_start = new Date(dateStart).getMonth() + 1
  const day_start = new Date(dateStart).getDate()

  const year_end = new Date(dateEnd).getFullYear()
  const month_end = new Date(dateEnd).getMonth() + 1
  const day_end = new Date(dateEnd).getDate()

  setTimeout(() => {
    ApexCharts.exec(
      'area-datetime',
      'zoomX',
      new Date(`${month_start}/${day_start}/${year_start}`).getTime(),
      new Date(`${month_end}/${day_end}/${year_end}`).getTime()
    )
  }, 500)

  // const updateData = (timeline) => {
  //   setSelection(timeline)

  //   const year_start = new Date(dateStart).getFullYear()
  //   const month_start = new Date(dateStart).getMonth() + 1
  //   const day_start = new Date(dateStart).getDate()

  //   const year_end = new Date(dateEnd).getFullYear()
  //   const month_end = new Date(dateEnd).getMonth() + 1
  //   const day_end = new Date(dateEnd).getDate()
  //   // console.log('timeline :>> ', timeline)
  //   switch (timeline) {
  //     case 'one_month':
  //       setTimeout(() => {
  //         ApexCharts.exec(
  //           'area-datetime',
  //           'zoomX',
  //           new Date(`${month_start}/01/${year_start}`).getTime(),
  //           new Date(`${month_start}/31/${year_end}`).getTime()
  //         )
  //       }, 500)
  //       break
  //     case 'one_year':
  //       setTimeout(() => {
  //         ApexCharts.exec(
  //           'area-datetime',
  //           'zoomX',
  //           new Date(`${month_start}/01/${year_start}`).getTime(),
  //           new Date(`${month_start}/01/${year_end + 1}`).getTime()
  //         )
  //       }, 500)
  //       break
  //     default:
  //   }
  // }

  return (
    <div id="chart">
      {/* <div className="toolbar">
        <Button
          id="one_month"
          onClick={() => updateData('one_month')}
          type={selection === 'one_month' ? 'primary' : ''}
        >
          Day
        </Button>
        &nbsp;
        <Button
          id="one_year"
          onClick={() => updateData('one_year')}
          type={selection === 'one_year' ? 'primary' : ''}
        >
          Month
        </Button>
      </div> */}

      <div id="chart-timeline">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={410}
        />
      </div>
    </div>
  )
}

export default ApexChart

// const domContainer = document.querySelector('#app')
