import React from 'react'

import ReactApexChart from 'react-apexcharts'

const ApexChart = ({ data }) => {
  const series = [
    {
      name: 'STOCK ABC',
      data: data,
    },
  ]
  const options = {
    chart: {
      width: '100%',
      type: 'area',
      height: 100,
      zoom: {
        enabled: false,
      },
      toolbar: { show: false },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },

    title: {
      text: '',
      align: 'left',
    },
    xaxis: {
      type: 'datetime',
      labels: { show: false },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      opposite: true,
      labels: { show: false },
    },

    legend: {
      horizontalAlign: 'left',
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
  }

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={100}
        width="100%"
      />
    </div>
  )
}

export default ApexChart
