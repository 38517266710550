import "./AccountStyle.less";

import { Button, Col, Form, Input, Row, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { setDefaultCookies, setToken } from "../../utils/common.js";

import CryptoJS from "crypto-js";
import SvgEmail from "images/icon-email.svg";
import SvgEye from "images/icon-eye.svg";
import SvgEyeLine from "images/icon-eye-line.svg";
import SvgLogo from "images/logo-sil.svg";
import SvgPassword from "images/icon-password.svg";
import { authContext } from "contexts/AuthContext";
import { userGroupContext } from "contexts/UserGroupContext";
import axios from "axios";
import styled from "styled-components";

function Login(props) {
  const { setAuthData } = useContext(authContext);
  const { setUserGroupData } = useContext(userGroupContext);
  const [messageError, setMessageError] = useState("");
  const [messageErrorEmail, setMessageErrorEmail] = useState("");
  const [messageErrorPass, setMessageErrorPass] = useState("");
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('isLogin') || localStorage.getItem('isLogin') === 'false') {
      localStorage.clear()
    }
    return () => { };
  }, []);

  const onFinish = (values) => {
    setIsloading(true);
    const requireLogin = {
      username: values.email,
      password: values.password,
    };

    const passwordEn = CryptoJS.AES.encrypt(
      values.password,
      "seeitlive-thailand-2020"
    ).toString();

    message.loading({ content: "Loading..." });
    axios
      .post(`${process.env.REACT_APP_AUTHEN}/login`, requireLogin)
      .then(async (response) => {
        setMessageErrorEmail("");
        setMessageErrorPass("");
        setIsloading(false);
        message.destroy();
        if (response?.data?.code === 200) {
          const user = response?.data.data;
          if (response?.data?.data?.verified) {
            localStorage.setItem("isLogin", true);
            localStorage.setItem(
              "user_name",
              `${user.first_name} ${user.last_name}`
            );
            localStorage.setItem("user_profile_image", user.image_profile);
            localStorage.setItem("tempData", passwordEn);
            await setToken(response?.data?.data);
            await setDefaultCookies();
            await setAuthData(response?.data?.data.access_token);
            await setUserGroupData(response?.data?.data?.user_type);
            if (sessionStorage.getItem('cctv')) {
              if (sessionStorage.getItem('cctv') === 'approve') {
                props.history.push('/cctv')
              } else {
                if (response?.data?.data?.user_type === 'admin' || response?.data?.data?.user_type === 'superadmin') {
                  await props.history.push('/approve-cctv')
                  await sessionStorage.clear()
                } else {
                  props.history.push('/cctv')
                }
              }
            }
          } else {
            localStorage.setItem("tempData", passwordEn);
            localStorage.setItem(
              "contact",
              response?.data?.data?.email
                ? response?.data?.data?.email
                : response?.data?.data?.username
            );
            await setToken(response?.data?.data);
            props.history.push("/verify");
          }
        } else {
          setMessageError(response?.data?.message);
        }
      })
      .catch((error) => {
        message.destroy();
        setIsloading(false);
        setMessageError("Username or password is incorrect.");
        throw error;
      });
  };

  const onFinishFailed = (errorInfo) => {
    if (errorInfo?.errorFields.length) {
      if (errorInfo?.values.email) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const checkMail = re.test(
          String(errorInfo?.values.email).toLowerCase()
        );
        if (!checkMail) {
          setMessageErrorEmail("Email is not a valid email !");
        }
      } else {
        setMessageErrorEmail("Please input your email !");
      }

      setMessageErrorPass(
        errorInfo?.values.password ? "" : "Please input your password !"
      );
      setMessageError(``);
    }
  };

  const onFieldsChange = (e, key) => {
    if (key === "email") {
      setMessageErrorEmail("");
    }

    if (key === "password") {
      setMessageErrorPass("");
    }

    setMessageError(``);
  };

  return (
    <LayoutLogin>
      <Row align="center" justify="center">
        <Col span="24" style={{ display: "contents" }}>
          <img
            src={SvgLogo}
            alt="Logo"
            style={{ marginBottom: "50px", marginTop: "-40px" }}
          />
        </Col>
        <Col span={24}>
          <Form
            {...layout}
            name="basic"
            onFinish={!isLoading && onFinish}
            onFinishFailed={onFinishFailed}
            className="account-form"
          >
            <Form.Item
              className={
                messageError
                  ? "inp-login inp-account msg-err"
                  : "inp-login inp-account "
              }
              name="email"
              validateStatus={messageError || messageErrorEmail ? "error" : ""}
              help={messageErrorEmail}
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                  type: "email",
                },
              ]}
            >
              <Input
                prefix={
                  <img src={SvgEmail} width="16" height="16" alt="Email" />
                }
                placeholder="อีเมล"
                autoComplete="off"
                name="email"
                onChange={(e) => onFieldsChange(e, "email")}
              />
            </Form.Item>
            <Form.Item
              className={
                messageError
                  ? "inp-login inp-account msg-err inp-pass"
                  : "inp-login inp-account inp-pass"
              }
              name="password"
              validateStatus={messageError || messageErrorPass ? "error" : ""}
              help={messageError || messageErrorPass}
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                prefix={
                  <img src={SvgPassword} width="16" height="16" alt="Email" />
                }
                type="password"
                placeholder="รหัสผ่าน"
                iconRender={(visible) =>
                  visible ? (
                    <img
                      src={SvgEye}
                      width="16"
                      height="16"
                      alt="Show password"
                    />
                  ) : (
                    <img
                      src={SvgEyeLine}
                      width="16"
                      height="16"
                      alt="Hide password"
                    />
                  )
                }
                name="password"
                onChange={(e) => onFieldsChange(e, "password")}
              />
            </Form.Item>
            <Form.Item {...tailLayout} className="btn-account">
              <Button
                type="primary"
                htmlType="submit"
                className="account-form-button"
              >
                เข้าสู่ระบบ
              </Button>
            </Form.Item>
          </Form>
          <ForgotBT align="center">
            <span
              onClick={() =>
                !isLoading && props.history.push("/forgot-password")
              }
            >
              ลืมรหัสผ่าน?
            </span>
          </ForgotBT>
        </Col>
      </Row>
    </LayoutLogin>
  );
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

const ForgotBT = styled(Col)`
  color: black;
  position: relative;
  top: -9px;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const LayoutLogin = styled.div`
  width: 300px;
  height: 100px;

  position: absolute;
  top: -200px;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
`;

export default Login;
