import {
  Badge,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  message,
} from "antd";
import { PlusOutlined, SearchOutlined, YoutubeFilled } from "@ant-design/icons";
import { deleteList, getList } from "actions/collection";
import { getCollectionLoading, getPagination, getStateResult } from "selectors";

import Actions from "components/action";
import DataTableLayout from "components/layouts/DataTableLayout";
import { DateTimeConvert } from "../../../utils/DateTimeFormat.js";
import { Link } from "react-router-dom";
import { MenuAuth } from "contexts/menuAuth";
import React from "react";
import { connect } from "react-redux";
import { getPageShowing } from "utils/common.js";
import iconDeleted from "images/correct@3x.png";
import iconErr from "images/icon-error@3x.png";
import { popupevent } from "schemas";
import { imageError } from '../../../utils/common'

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
}

class Slide extends React.Component {
  static contextType = MenuAuth;

  state = {
    page: 1,
    per_page: 15,
    search: "",

    visibleImg: false,
    onSwap: false,
    isSearch: false,
    list: [],
    image_link: "",
    isDeleteError: false,
    isDeleteSuccess: false,
    errorMsg: "",
    actionPermission: undefined,
  };

  componentDidMount() {
    getPageShowing();
    if (
      localStorage.getItem("search") &&
      localStorage.getItem("currSearch") === window.location.pathname
    ) {
      this.setState({ search: localStorage.getItem("search") });
    }
    let params = {
      page: localStorage.getItem("page")
        ? localStorage.getItem("search")
          ? this.state.page
          : localStorage.getItem("page")
        : this.state.page,
      per_page: localStorage.getItem("per_page")
        ? parseInt(localStorage.getItem("per_page"))
        : this.state.per_page,
      search:
        localStorage.getItem("search") &&
          localStorage.getItem("currSearch") === window.location.pathname
          ? String(localStorage.getItem("search"))
          : this.state.search,
    };
    this.props.getData(params).then((resopnse) => {
      this.setState({ list: resopnse?.data?.data_list });
    });
  }

  onSearch = (e) => {
    localStorage.setItem("page", 1);
    let params = {
      page: 1,
      per_page: localStorage.getItem("per_page")
        ? parseInt(localStorage.getItem("per_page"))
        : this.state.per_page,
      search: localStorage.getItem("search")
        ? localStorage.getItem("search")
        : this.state.search,
      sort_field: this.state.sort_field,
      sort_by: this.state.sort_by,
    };
    localStorage.setItem("search", this.state.search);
    localStorage.setItem("currSearch", window.location.pathname);
    this.props.getData(params).then((res) => {
      this.setState({ list: res.data.data_list });
    });
  };

  setKeyword = (e) => {
    const value = e.target.value;
    localStorage.setItem("search", value);
    this.setState({ search: value });
  };

  clickImg = (type, url) => {
    this.setState({ popup_type: type, image_link: url, visibleImg: true });
  };

  cancleImg = () => {
    this.setState({ popup_type: "", image_link: "", visibleImg: false });
  };

  deleteList = (delete_id) => {
    const { pagination, dataList } = this.props;
    localStorage.setItem(
      "page",
      dataList.length === 1 && parseInt(localStorage.getItem("page")) > 1
        ? parseInt(localStorage.getItem("page")) - 1
        : this.state.page
    );
    const params = {
      page:
        dataList.length === 1 && pagination.current_page > 1
          ? pagination.current_page - 1
          : this.state.page,
      search: this.state.search,
      per_page: this.state.per_page,
    };

    message.loading("Delete in progress..", 0);
    this.props.deleteData(delete_id, params).then((res) => {
      if (res?.code === 200) {
        message.destroy();
        this.setState({
          search: "",
          isDeleteSuccess: true,
        });
        let params = {
          page: localStorage.getItem("page")
            ? localStorage.getItem("search")
              ? this.state.page
              : localStorage.getItem("page")
            : this.state.page,
          per_page: localStorage.getItem("per_page")
            ? parseInt(localStorage.getItem("per_page"))
            : this.state.per_page,
          search: localStorage.getItem("search")
            ? String(localStorage.getItem("search"))
            : this.state.search,
          sort_field: this.state.sort_field,
          sort_by: this.state.sort_by,
        };
        this.props.getData(params).then((resopnse) => {
          this.setState({ list: resopnse?.data?.data_list });
        });
      } else {
        message.destroy();
        this.setState({
          isDeleteError: true,
          errorMsg: res?.message,
        });
      }
    });
  };

  handleTableChange = (dataRevice) => {
    this.setState({ list: dataRevice });
  };

  columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: "5%",
      render: (indexID) => {
        let index = this.state.list.findIndex((id) => id.id === indexID);
        const { pagination } = this.props;
        return (
          pagination.per_page * (pagination.current_page - 1) + (index + 1)
        );
      },
    },
    {
      title: "ตัวอย่างรูปภาพ",
      dataIndex: "id",
      key: "id",
      width: "15%",
      render: (index) => {
        let linkIndex = this.state.list.find((id) => id.id === index);
        const videoId = getId(linkIndex.video_link ? linkIndex.video_link : "");
        return linkIndex.image_link.portrait ||
          linkIndex.image_link.landscape ||
          linkIndex.image_link.square ? (
          <span
            style={{ height: "50px", cursor: "pointer" }}
            onClick={() =>
              this.clickImg(
                "image",
                linkIndex.image_link.portrait
                  ? linkIndex.image_link.portrait
                  : linkIndex.image_link.landscape
                    ? linkIndex.image_link.landscape
                    : linkIndex.image_link.square
              )
            }
          >
            <img
              onError={imageError}
              alt="ป๊อปอัพ"
              src={
                linkIndex.image_link.portrait
                  ? linkIndex.image_link.portrait.th
                  : linkIndex.image_link.landscape
                    ? linkIndex.image_link.landscape.th
                    : linkIndex.image_link.square.th
              }
              style={{ height: "50px" }}
            />
          </span>
        ) : (
          <span
            style={{ textAlign: "center", height: "50px", cursor: "pointer" }}
            onClick={() =>
              this.clickImg(
                "video",
                videoId
                  ? `//www.youtube.com/embed/${videoId}`
                  : linkIndex.video_link
              )
            }
          >
            <YoutubeFilled style={{ fontSize: "50px", color: "#bd1d2d" }} />
          </span>
        );
      },
    },
    {
      title: "ชื่อป๊อปอัพ",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (name) => {
        return name ? name.th : "-";
      },
    },
    {
      title: "ประเภทป๊อปอัพ",
      dataIndex: "popup_type",
      key: "popup_type",
      ellipsis: true,
      render: (value) => {
        return value && value === "video" ? "Video" : "Image";
      },
    },
    {
      title: "วันที่เผยแพร่",
      dataIndex: "start_datetime",
      key: "start_datetime",
      width: "20%",
      render: (value) => {
        const formatDay = DateTimeConvert(value);
        return formatDay;
      },
    },
    {
      title: "สถานะ",
      key: "status",
      dataIndex: "status",
      width: "10%",
      render: (status) => {
        return (
          <span>
            <Badge
              status={
                status === "active"
                  ? "success"
                  : status === "inactive"
                    ? "error"
                    : status === "draft"
                      ? "warning"
                      : "default"
              }
              text={
                status === "active"
                  ? "ใช้งาน"
                  : status === "inactive"
                    ? "ไม่ใช้งาน"
                    : status === "draft"
                      ? "รอเผยแพร่"
                      : "หมดอายุ"
              }
              style={{
                color:
                  status === "active"
                    ? "#00c48c"
                    : status === "inactive"
                      ? "#ff0000"
                      : status === "draft"
                        ? "#FF9B00"
                        : "#ABABAB",
              }}
            />
          </span>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: "7%",
      render: (record) => {
        return (
          <Actions
            record={record}
            path="/popup-events"
            onDelete={this.deleteList}
            history={this.props.history}
            permission={this.state.actionPermission}
          />
        );
      },
    },
  ];

  getActionPermission = () => {
    const author = this.context?.menuAuth?.find(
      (menu) => menu?.key_slug === "cms"
    );
    const menu = author?.sub_menus?.find(
      (sub) => sub?.key_slug === "popup-events"
    );

    !this.state.actionPermission &&
      this.setState({ actionPermission: menu?.role_permission });
  };

  render() {
    const { isLoading, pagination } = this.props;
    const { actionPermission } = this.state;
    return (
      <Form>
        <Row justify="space-between">
          <Col>
            <Input
              style={{ marginBottom: "30px", width: "20vmax" }}
              onChange={this.setKeyword}
              placeholder="ค้นหาชื่อป๊อปอัพ"
              onPressEnter={this.onSearch}
              suffix={
                <SearchOutlined
                  onClick={() => this.onSearch()}
                  style={{ cursor: "pointer" }}
                />
              }
              value={this.state.search}
              autoComplete="on"
            ></Input>
          </Col>
          {actionPermission && actionPermission?.can_create && (
            <Col>
              <Button type="primary">
                <Link to="popup-events/create">
                  <PlusOutlined /> เพิ่มข้อมูล
                </Link>
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            {isLoading === true ? (
              <Skeleton
                className="App-form"
                loading={isLoading}
                active
              ></Skeleton>
            ) : (
              <DataTableLayout
                dataSource={this.state.list || []}
                columns={this.columns}
                isloading={isLoading}
                pagePagination={pagination}
                getData={this.props.getData}
                onChange={this.handleTableChange}
                getPermission={this.getActionPermission}
              />
            )}
          </Col>
          <Modal
            className="modal-image"
            visible={this.state.visibleImg}
            footer={null}
            onCancel={this.cancleImg}
          >
            {this.state.popup_type === "image" ? (
              <img
                onError={imageError}
                alt="exampleTH"
                style={{ width: "100%", marginTop: "20px" }}
                src={this.state.image_link.th}
              />
            ) : this.state.image_link.split(/\//)[2] === "www.youtube.com" ? (
              // eslint-disable-next-line jsx-a11y/iframe-has-title
              <iframe
                width="450"
                height="345"
                style={{ border: 0, marginTop: "20px" }}
                src={this.state.image_link}
              ></iframe>
            ) : (
              <video
                controls
                style={{ width: "100%", marginTop: "20px" }}
                src={this.state.image_link}
              />
            )}
          </Modal>
          <Modal
            visible={this.state.isDeleteSuccess}
            footer={false}
            closable={false}
            centered={true}
            width={"500px"}
          >
            <div
              style={{
                height: "180px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img alt="example" src={iconDeleted} width={48} height={48} />
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  ลบข้อมูลเสร็จสิ้น
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "30px",
                  }}
                >
                  <Space>
                    <Button
                      type="primary"
                      style={{ width: "170px" }}
                      onClick={() => {
                        this.setState({ isDeleteSuccess: false });
                      }}
                    >
                      ตกลง
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Modal>
          <Modal
            visible={this.state.isDeleteError}
            footer={false}
            closable={false}
            centered={true}
            width={"500px"}
          >
            <div
              style={{
                height: "220px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img alt="example" src={iconErr} width={48} height={48} />
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  ลบข้อมูลไม่สำเร็จ
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  {this.state.errorMsg}
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "30px",
                  }}
                >
                  <Space>
                    <Button
                      type="primary"
                      style={{ width: "170px" }}
                      onClick={() => {
                        this.setState({ isDeleteError: false, errorMsg: "" });
                      }}
                    >
                      ตกลง
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Modal>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  pagination: getPagination(popupevent, state),
  isLoading: getCollectionLoading(popupevent, state),
  dataList: getStateResult(popupevent, state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: (params) => dispatch(getList(popupevent, params)),
  deleteData: (id) => dispatch(deleteList(popupevent, id)),
});

const SlideListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Slide);

export default SlideListWithConnect;
