import React from 'react'
import { connect } from 'react-redux'
import {
  Form,
  Upload,
  Modal,
  Input,
  Skeleton,
  message,
  DatePicker,
  Radio,
  Row,
  Col,
  Space,
  Button,
  Select,
  TimePicker,
  Tooltip,
} from 'antd'
import moment from 'moment'
import { PlusOutlined } from '@ant-design/icons'
import Footeractionlayout from 'components/layouts/Footeractionlayout'
import { createData, readData, updateData } from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'
import { resizeUpload } from 'actions/file'
import { popupevent } from 'schemas'
import iconErr from 'images/icon-error@3x.png'
import iconCorrect from 'images/correct@3x.png'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const text_url_info = (
  <span>ใช้งานได้เฉพาะลิงค์ youtube และลิงค์ .mp4 เท่านั้น</span>
)
const textValidName = (
  <span>ระบุเฉพาะ a-z, A-Z, 0-9, -, _ และ เว้นวรรค เท่านั้น</span>
)

const { TextArea } = Input
const { Option } = Select

const formRef = React.createRef()

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

function disabledDate(current) {
  return current < moment().startOf('day')
}

class Formslidemenulayout extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
    fileListEN: [],
    fileListLandTH: [],
    fileListLandEN: [],

    //temp
    fileListTemp: [],
    fileListENTemp: [],
    fileListLandTHTemp: [],
    fileListLandENTemp: [],
    image_TypeTemp: '',

    isRemove: false,
    form: [],
    isDirty: true,
    tempData: {},
    isNameFill: true,
    isDetailFill: true,
    image_type: '',
    errorMsg: '',
    isCreated: false,
    isCreateError: false,
    isCancel: false,

    linkCheck: '',
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.readData(this.props.match.params.id).then((res) => {
        if (res?.data?.image_type) {
          this.state.fileList.push(
            this.setImageToState(
              res?.data?.image_type === 'portrait' ||
                res?.data?.image_type === 'portrait&landscape'
                ? res?.data?.image_link.portrait.th
                : res?.data?.image_type === 'landscape'
                  ? res?.data?.image_link.landscape.th
                  : res?.data?.image_link.square.th
            )
          )
          this.state.fileListEN.push(
            this.setImageToState(
              res?.data?.image_type === 'portrait' ||
                res?.data?.image_type === 'portrait&landscape'
                ? res?.data?.image_link.portrait.en
                : res?.data?.image_type === 'landscape'
                  ? res?.data?.image_link.landscape.en
                  : res?.data?.image_link.square.en
            )
          )
          this.state.fileListLandTH.push(
            this.setImageToState(
              res?.data?.image_type === 'portrait&landscape' &&
              res?.data?.image_link.landscape.th
            )
          )
          this.state.fileListLandEN.push(
            this.setImageToState(
              res?.data?.image_type === 'portrait&landscape' &&
              res?.data?.image_link.landscape.en
            )
          )
        }
        let tempData = {
          fileListTemp: this.state.fileList,
          fileListENTemp: this.state.fileListEN,
          fileListLandTHTemp: this.state.fileListLandTH,
          fileListLandENTemp: this.state.fileListLandEN,
          image_TypeTemp: res?.data?.image_type,
          popup_TypeTemp: res?.data?.popup_type,
          video_LinkTemp: res?.data?.video_link,
        }
        this.setState({
          tempData,
          linkCheck: res.data.link_url,
          image_type: res.data.image_type,
          popup_type: res.data.popup_type,
        })
      })
    }
  }

  setImageToState(value) {
    return {
      uid: -1,
      name: 'Slideimage.png',
      status: 'done',
      url: value,
    }
  }

  clickCancle = () => {
    this.setState({ isCancel: true })
  }

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    })
  }

  handleCancel = () => this.setState({ previewVisible: false, isCancel: false })

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  handleChange = ({ fileList }) => this.setState({ fileList })
  handleChangeEn = ({ fileList }) => this.setState({ fileListEN: fileList })
  handleChangeLandEN = ({ fileList }) =>
    this.setState({ fileListLandEN: fileList })
  handleChangeLandTH = ({ fileList }) =>
    this.setState({ fileListLandTH: fileList })

  handleSubmit = (value) => {
    const publish =
      moment(value.publish_date).format('DD/MM/YYYY') +
      ' ' +
      moment(value.publish_time).format('HH:mm')
    const publish_date = moment(publish, 'DD/MM/YYYY HH:mm').unix()
    const expire =
      moment(value.expire_date).format('DD/MM/YYYY') +
      ' ' +
      moment(value.expire_time).format('HH:mm')
    const expire_date = moment(expire, 'DD/MM/YYYY HH:mm').unix()
    const { isDirty } = this.state

    if (isDirty) {
      this.setState({ isDirty: false })
      const requestFrom = {
        popup_type: value.popup_type,
        name: { th: value.nameth, en: value.nameen },
        detail: { th: value.detailth, en: value.detailen },
        image_type: value.image_type ? value.image_type : '',
        image_link: {
          portrait:
            value.image_type === 'portrait' ||
              value.image_type === 'portrait&landscape'
              ? {
                th:
                  value.image_type === 'portrait&landscape'
                    ? value.image_portraitTH.file.originFileObj.url
                    : value.image_urlTH.file.originFileObj.url,
                en:
                  value.image_type === 'portrait&landscape'
                    ? value.image_portraitEN.file.originFileObj.url
                    : value.image_urlEN.file.originFileObj.url,
              }
              : null,
          landscape:
            value.image_type === 'landscape' ||
              value.image_type === 'portrait&landscape'
              ? {
                th:
                  value.image_type === 'portrait&landscape'
                    ? value.image_landscapeTH.file.originFileObj.url
                    : value.image_urlTH.file.originFileObj.url,
                en:
                  value.image_type === 'portrait&landscape'
                    ? value.image_landscapeEN.file.originFileObj.url
                    : value.image_urlEN.file.originFileObj.url,
              }
              : null,
          square:
            value.image_type === 'square'
              ? {
                th: value.image_urlTH.file.originFileObj.url,
                en: value.image_urlEN.file.originFileObj.url,
              }
              : null,
        },
        video_link: value.video_link ? value.video_link : '',
        url_link: value.url_link,
        start_datetime: publish_date,
        end_datetime: expire_date,
        status: value.status,
      }
      message.loading('Save in progress..', 0)
      this.props.createData(requestFrom).then((res) => {
        message.destroy()
        if (res?.code === 201) {
          this.setState({ isDirty: true, isCreated: true }, () => {
            localStorage.setItem('page', 1)
            localStorage.setItem('search', '')
          })
        } else {
          this.setState({
            isDirty: true,
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    }
  }

  checkValidEng = (rule, value) => {
    if (value === ' ') {
      formRef.current.setFieldsValue({
        nameen: '',
      })
    } else {
      formRef.current.setFieldsValue({
        nameen: value,
      })
    }
    if (value === null || value === '') {
      return Promise.reject('Popup Event name is required !!')
    } else {
      if (!/^([A-Za-z0-9-_ ]{1,})?$/.test(value)) {
        return Promise.reject('Invalid data format !!')
      } else {
        return Promise.resolve()
      }
    }
  }

  handleUpdate = (value) => {
    const publish =
      moment(value.publish_date).format('DD/MM/YYYY') +
      ' ' +
      moment(value.publish_time).format('HH:mm')
    const publish_date = moment(publish, 'DD/MM/YYYY HH:mm').unix()
    const expire =
      moment(value.expire_date).format('DD/MM/YYYY') +
      ' ' +
      moment(value.expire_time).format('HH:mm')
    const expire_date = moment(expire, 'DD/MM/YYYY HH:mm').unix()
    const { isDirty } = this.state

    if (isDirty) {
      this.setState({ isDirty: false })
      const requestFrom = {
        popup_type: value.popup_type,
        name: { th: value.nameth, en: value.nameen },
        detail: { th: value.detailth, en: value.detailen },
        image_type: value.image_type ? value.image_type : '',
        image_link: {
          portrait:
            value.image_type === 'portrait' ||
              value.image_type === 'portrait&landscape'
              ? {
                th:
                  value.image_type === 'portrait&landscape'
                    ? value.image_portraitTH.file
                      ? value.image_portraitTH.file.originFileObj.url
                      : value.image_portraitTH[0].url
                    : value.image_urlTH.file
                      ? value.image_urlTH.file.originFileObj.url
                      : value.image_urlTH[0].url,
                en:
                  value.image_type === 'portrait&landscape'
                    ? value.image_portraitEN.file
                      ? value.image_portraitEN.file.originFileObj.url
                      : value.image_portraitEN[0].url
                    : value.image_urlEN.file
                      ? value.image_urlEN.file.originFileObj.url
                      : value.image_urlEN[0].url,
              }
              : null,
          landscape:
            value.image_type === 'landscape' ||
              value.image_type === 'portrait&landscape'
              ? {
                th:
                  value.image_type === 'portrait&landscape'
                    ? value.image_landscapeTH.file
                      ? value.image_landscapeTH.file.originFileObj.url
                      : value.image_landscapeTH[0].url
                    : value.image_urlTH.file
                      ? value.image_urlTH.file.originFileObj.url
                      : value.image_urlTH[0].url,
                en:
                  value.image_type === 'portrait&landscape'
                    ? value.image_landscapeEN.file
                      ? value.image_landscapeEN.file.originFileObj.url
                      : value.image_landscapeEN[0].url
                    : value.image_urlEN.file
                      ? value.image_urlEN.file.originFileObj.url
                      : value.image_urlEN[0].url,
              }
              : null,
          square:
            value.image_type === 'square'
              ? {
                th: value.image_urlTH.file
                  ? value.image_urlTH.file.originFileObj.url
                  : value.image_urlTH[0].url,
                en: value.image_urlEN.file
                  ? value.image_urlEN.file.originFileObj.url
                  : value.image_urlEN[0].url,
              }
              : null,
        },
        video_link: value.video_link ? value.video_link : '',
        url_link: value.url_link,
        start_datetime: publish_date,
        end_datetime: expire_date,
        status: value.status,
      }
      message.loading('Save in progress..', 0)
      this.props
        .updateData(requestFrom, this.props.match.params.id)
        .then((response) => {
          message.destroy()
          if (response?.code === 200) {
            this.setState({ isDirty: true, isCreated: true })
          } else {
            this.setState({
              isDirty: true,
              isCreateError: true,
              errorMsg: response?.message,
            })
          }
        })
    }
  }

  onSelectImageType = async (e) => {
    const { tempData } = this.state
    formRef.current.setFieldsValue({
      image_urlTH: null,
      image_urlEN: null,
      image_portraitTH: null,
      image_portraitEN: null,
      image_landscapeEN: null,
      image_landscapeTH: null,
    })
    formRef.current.setFieldsValue({
      image_urlTH: tempData.image_TypeTemp === e ? tempData.fileListTemp : '',
      image_urlEN: tempData.image_TypeTemp === e ? tempData.fileListENTemp : '',
      image_portraitTH:
        tempData.image_TypeTemp === e ? tempData.fileListTemp : '',
      image_portraitEN:
        tempData.image_TypeTemp === e ? tempData.fileListENTemp : '',
      image_landscapeEN:
        tempData.image_TypeTemp === e ? tempData.fileListLandENTemp : '',
      image_landscapeTH:
        tempData.image_TypeTemp === e ? tempData.fileListLandTHTemp : '',
    })
    this.setState({
      image_type: e,
      fileList: tempData.image_TypeTemp === e ? tempData.fileListTemp : [],
      fileListEN: tempData.image_TypeTemp === e ? tempData.fileListENTemp : [],
      fileListLandEN:
        tempData.image_TypeTemp === e ? tempData.fileListLandENTemp : [],
      fileListLandTH:
        tempData.image_TypeTemp === e ? tempData.fileListLandTHTemp : [],
    })
  }

  checkImg = (rule, value) => {
    if (this.props.match.params.id) {
      if (value[0]) {
        return Promise.resolve()
      }
    }
    if (
      (value && value.file.type === 'image/png') ||
      (value && value.file.type === 'image/jpeg')
    ) {
      if (this.props.match.params.id) {
        if (value && value.length) {
          return Promise.resolve()
        } else if (value.fileList && value.fileList.length !== 0) {
          return Promise.resolve()
        }
      } else {
        if (value.fileList && value.fileList.length !== 0) {
          return Promise.resolve()
        }
      }
      if (this.state.isRemove === true) {
        return this.setState({ isRemove: false })
      } else {
        return Promise.reject(
          <div
            style={{
              marginTop:
                this.state.image_type === 'portrait' ||
                  rule.file === 'image_portraitTH' ||
                  rule.file === 'image_portraitEN'
                  ? '-5px'
                  : '-15px',
            }}
          >
            Please insert photo !!
          </div>
        )
      }
    } else {
      if (value && value.fileList.length !== 0) {
        value.file = []
        value.fileList = []
        this.setState({ fileList: [] })
        if (value.fileList && value.fileList.length === 0) {
          return Promise.reject(
            <div
              style={{
                marginTop:
                  this.state.image_type === 'portrait' ||
                    rule.field === 'image_portraitTH' ||
                    rule.field === 'image_portraitEN'
                    ? '-5px'
                    : '-15px',
              }}
            >
              Please insert file type .png, .jpeg only !
            </div>
          )
        }
      } else {
        if (this.state.isRemove === true) {
          return this.setState({ isRemove: false })
        } else {
          return Promise.reject(
            <div
              style={{
                marginTop:
                  this.state.image_type === 'portrait' ||
                    rule.field === 'image_portraitTH' ||
                    rule.field === 'image_portraitEN'
                    ? '-5px'
                    : '-15px',
              }}
            >
              Please insert photo !!
            </div>
          )
        }
      }
    }
  }

  onLinkChange = async (value) => {
    await this.setState({ linkCheck: value })
  }

  onRemoveImg = () => {
    this.setState({ isRemove: true })
  }

  onTypeChange = (e) => {
    const { tempData } = this.state

    this.setState({
      popup_type: e,
      image_type:
        this.state.tempData.popup_TypeTemp === e
          ? this.state.tempData.image_TypeTemp
          : '',
      fileList:
        this.state.tempData.popup_TypeTemp === e && tempData.fileListTemp,
      fileListEN:
        this.state.tempData.popup_TypeTemp === e && tempData.fileListENTemp,
      fileListLandEN:
        this.state.tempData.popup_TypeTemp === e && tempData.fileListLandENTemp,
      fileListLandTH:
        this.state.tempData.popup_TypeTemp === e && tempData.fileListLandTHTemp,
    })

    formRef.current.setFieldsValue({
      image_type:
        tempData.popup_TypeTemp === e ? tempData.image_TypeTemp : undefined,
      video_link: tempData.popup_TypeTemp === e ? tempData.video_LinkTemp : '',
    })
  }

  render() {
    const { dataList, isLoading } = this.props
    const {
      previewVisible,
      previewImage,
      fileList,
      fileListEN,
      fileListLandEN,
      fileListLandTH,
    } = this.state

    const uploadButtonTH = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">อัพโหลด</div>
      </div>
    )

    const uploadButtonEN = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    )

    return (
      <>
        {isLoading === true ? (
          <Skeleton className="App-form" loading={isLoading} active></Skeleton>
        ) : (
          <Form
            ref={formRef}
            layout="vertical"
            className="App-form"
            onFinish={
              this.props.match.params.id ? this.handleUpdate : this.handleSubmit
            }
          >
            <Form.Item style={{ marginTop: '-20px' }}>
              <h1 className="topic">
                {this.props.match.params.id
                  ? 'แก้ไขข้อมูลป๊อปอัพ'
                  : 'เพิ่มข้อมูลป๊อปอัพ'}
              </h1>
            </Form.Item>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  label="ชื่อ"
                  name="nameth"
                  rules={[
                    {
                      required: true,
                      message: 'Popup Event name is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.name
                      ? dataList.name.th
                      : null
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label="Name"
                  name="nameen"
                  rules={[
                    {
                      required: true,
                      validator: this.checkValidEng,
                      transform: (value) => {
                        let text = ''
                        if (!/^([A-Za-z0-9-_ ]{1,})?$/.test(value)) {
                          text = value.substring(0, value.length - 1)
                        } else {
                          text = value
                        }
                        return text
                      },
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.name
                      ? dataList.name.en
                      : null
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={1}>
                <Tooltip
                  className="sil-toottip-custom-width"
                  placement="topRight"
                  title={textValidName}
                >
                  <ExclamationCircleOutlined
                    style={{ fontSize: '18px', marginTop: '38px' }}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  label="รายละเอียด"
                  name="detailth"
                  rules={[
                    {
                      required: false,
                      message: 'Popup Event detail is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.detail
                      ? dataList.detail.th
                      : ''
                  }
                >
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Detail"
                  name="detailen"
                  rules={[
                    {
                      required: false,
                      message: 'Popup Event detail is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.detail
                      ? dataList.detail.en
                      : ''
                  }
                >
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 15]}>
              <Col span={12}>
                <Form.Item
                  label="ประเภท"
                  name="popup_type"
                  rules={[
                    {
                      required: true,
                      message: 'Popup Event type is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.popup_type
                      ? dataList.popup_type
                      : undefined
                  }
                >
                  <Select
                    placeholder="เลือก"
                    onChange={(e) => this.onTypeChange(e)}
                  >
                    <Option value="image">รูปภาพ</Option>
                    <Option value="video">วิดีโอ</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {this.state.popup_type && this.state.popup_type === 'image' ? (
              <Row gutter={[0, 15]}>
                <Col span={12}>
                  <Form.Item
                    label="ประเภทของรูปภาพ"
                    name="image_type"
                    rules={[
                      { required: true, message: 'Image type is required !!' },
                    ]}
                    initialValue={
                      this.props.match.params.id && dataList.image_type
                        ? dataList.image_type
                        : undefined
                    }
                  >
                    <Select
                      placeholder="เลือก"
                      onChange={(e) => this.onSelectImageType(e)}
                    >
                      <Option value="portrait">Portrait</Option>
                      <Option value="landscape">Landscape</Option>
                      <Option value="square">Square</Option>
                      <Option value="portrait&landscape">
                        Portrait & Landscape
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            ) : this.state.popup_type && this.state.popup_type === 'video' ? (
              <Row gutter={[15, 15]}>
                <Col span={11}>
                  <Form.Item
                    label="ลิงค์วิดีโอ"
                    name="video_link"
                    rules={[
                      // { type: 'url', message: 'Video link format is incorrect.' },
                      { required: true, message: 'Video link is required !!' },
                    ]}
                    initialValue={
                      this.props.match.params.id && dataList.video_link
                        ? dataList.video_link
                        : undefined
                    }
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Tooltip placement="top" title={text_url_info}>
                    <ExclamationCircleOutlined
                      style={{ fontSize: '18px', marginTop: '38px' }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            ) : null}
            {this.state.image_type &&
              this.state.image_type !== 'portrait&landscape' ? (
              <>
                <Row gutter={[20, 15]}>
                  <Col span={12}>
                    <Form.Item
                      className="form-slide-menu-size"
                      label={
                        <Row>
                          <Col>อัพโหลดรูปภาพ</Col>
                          <Col style={{ color: 'red' }}>&nbsp;*</Col>
                        </Row>
                      }
                    >
                      <p
                        style={{
                          fontSize: '12px',
                          marginTop: '-20px',
                        }}
                      >
                        {this.state.image_type === 'square'
                          ? '(ขนาดภาพแนะนำ 800x800 px)'
                          : this.state.image_type === 'portrait'
                            ? '(ขนาดภาพแนะนำ 450x800 px)'
                            : '(ขนาดภาพแนะนำ 800x450 px)'}
                      </p>
                    </Form.Item>
                    <Form.Item
                      style={{ marginTop: '-35px' }}
                      name="image_urlTH"
                      rules={[
                        {
                          required: true,
                          validator: this.checkImg,
                        },
                      ]}
                      initialValue={this.props.match.params.id ? fileList : ''}
                    >
                      <Upload
                        className={
                          this.state.image_type === 'portrait'
                            ? 'portrait'
                            : this.state.image_type === 'landscape'
                              ? 'landscape'
                              : ''
                        }
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={this.handlePreview}
                        onRemove={this.onRemoveImg}
                        onChange={this.handleChange}
                        customRequest={this.props.addImageData}
                        accept="image/png,image/jpeg"
                      >
                        {fileList.length >= 1 ? null : uploadButtonTH}
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="form-slide-menu-size"
                      label={
                        <Row>
                          <Col>Upload picture</Col>
                          <Col style={{ color: 'red' }}>&nbsp;*</Col>
                        </Row>
                      }
                    >
                      <p
                        style={{
                          fontSize: '12px',
                          marginTop: '-20px',
                        }}
                      >
                        {this.state.image_type === 'square'
                          ? '(Recommend size 800x800 px)'
                          : this.state.image_type === 'portrait'
                            ? '(Recommend size 450x800 px)'
                            : '(Recommend size 800x450 px)'}
                      </p>
                    </Form.Item>
                    <Form.Item
                      style={{ marginTop: '-35px' }}
                      name="image_urlEN"
                      rules={[
                        {
                          required: true,
                          validator: this.checkImg,
                        },
                      ]}
                      initialValue={
                        this.props.match.params.id ? fileListEN : ''
                      }
                    >
                      <Upload
                        className={
                          this.state.image_type === 'portrait'
                            ? 'portrait'
                            : this.state.image_type === 'landscape'
                              ? 'landscape'
                              : ''
                        }
                        listType="picture-card"
                        fileList={fileListEN}
                        onPreview={this.handlePreview}
                        onRemove={this.onRemoveImg}
                        onChange={this.handleChangeEn}
                        customRequest={this.props.addImageData}
                        accept="image/png,image/jpeg"
                      >
                        {fileListEN.length >= 1 ? null : uploadButtonEN}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : (
              this.state.image_type !== '' && (
                <>
                  <Row gutter={[20, 15]}>
                    <Col span={4}>
                      <Form.Item
                        className="form-slide-menu-size"
                        label={
                          <Row>
                            <Col>อัพโหลดรูปภาพ (Portrait)</Col>
                            <Col style={{ color: 'red' }}>&nbsp;*</Col>
                          </Row>
                        }
                      >
                        <p
                          style={{
                            fontSize: '12px',
                            marginTop: '-20px',
                          }}
                        >
                          (ขนาดภาพแนะนำ 450x800 px)
                        </p>
                      </Form.Item>
                      <Form.Item
                        style={{ marginTop: '-35px' }}
                        name="image_portraitTH"
                        rules={[
                          {
                            required: true,
                            validator: this.checkImg,
                          },
                        ]}
                        initialValue={
                          this.props.match.params.id ? fileList : ''
                        }
                      >
                        <Upload
                          className={'portrait'}
                          listType="picture-card"
                          fileList={fileList}
                          onPreview={this.handlePreview}
                          onRemove={this.onRemoveImg}
                          onChange={this.handleChange}
                          customRequest={this.props.addImageData}
                          accept="image/png,image/jpeg"
                        >
                          {fileList.length >= 1 ? null : uploadButtonTH}
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        className="form-slide-menu-size"
                        label={
                          <Row>
                            <Col>อัพโหลดรูปภาพ (Landscape)</Col>
                            <Col style={{ color: 'red' }}>&nbsp;*</Col>
                          </Row>
                        }
                      >
                        <p
                          style={{
                            fontSize: '12px',
                            marginTop: '-20px',
                          }}
                        >
                          (ขนาดภาพแนะนำ 800x450 px)
                        </p>
                      </Form.Item>
                      <Form.Item
                        style={{ marginTop: '-35px' }}
                        name="image_landscapeTH"
                        rules={[
                          {
                            required: true,
                            validator: this.checkImg,
                          },
                        ]}
                        initialValue={
                          this.props.match.params.id ? fileListLandTH : ''
                        }
                      >
                        <Upload
                          className={'landscape'}
                          listType="picture-card"
                          fileList={fileListLandTH}
                          onPreview={this.handlePreview}
                          onRemove={this.onRemoveImg}
                          onChange={this.handleChangeLandTH}
                          customRequest={this.props.addImageData}
                          accept="image/png,image/jpeg"
                        >
                          {fileListLandTH.length >= 1 ? null : uploadButtonTH}
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        className="form-slide-menu-size"
                        label={
                          <Row>
                            <Col>Upload picture (Portrait)</Col>
                            <Col style={{ color: 'red' }}>&nbsp;*</Col>
                          </Row>
                        }
                      >
                        <p
                          style={{
                            fontSize: '12px',
                            marginTop: '-20px',
                          }}
                        >
                          (Recommend size 450x800 px)
                        </p>
                      </Form.Item>
                      <Form.Item
                        style={{ marginTop: '-35px' }}
                        name="image_portraitEN"
                        rules={[
                          {
                            required: true,
                            validator: this.checkImg,
                          },
                        ]}
                        initialValue={
                          this.props.match.params.id ? fileListEN : ''
                        }
                      >
                        <Upload
                          className={'portrait'}
                          listType="picture-card"
                          fileList={fileListEN}
                          onPreview={this.handlePreview}
                          onRemove={this.onRemoveImg}
                          onChange={this.handleChangeEn}
                          customRequest={this.props.addImageData}
                          accept="image/png,image/jpeg"
                        >
                          {fileListEN.length >= 1 ? null : uploadButtonEN}
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        className="form-slide-menu-size"
                        label={
                          <Row>
                            <Col>Upload picture (Landscape)</Col>
                            <Col style={{ color: 'red' }}>&nbsp;*</Col>
                          </Row>
                        }
                      >
                        <p
                          style={{
                            fontSize: '12px',
                            marginTop: '-20px',
                          }}
                        >
                          (Recommend size 800x450 px)
                        </p>
                      </Form.Item>
                      <Form.Item
                        style={{ marginTop: '-35px' }}
                        name="image_landscapeEN"
                        rules={[
                          {
                            required: true,
                            validator: this.checkImg,
                          },
                        ]}
                        initialValue={
                          this.props.match.params.id ? fileListLandEN : ''
                        }
                      >
                        <Upload
                          className={'landscape'}
                          listType="picture-card"
                          fileList={fileListLandEN}
                          onPreview={this.handlePreview}
                          onRemove={this.onRemoveImg}
                          onChange={this.handleChangeLandEN}
                          customRequest={this.props.addImageData}
                          accept="image/png,image/jpeg"
                        >
                          {fileListLandEN.length >= 1 ? null : uploadButtonEN}
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )
            )}
            {this.state.popup_type === 'image' && (
              <Row gutter={[0, 15]}>
                <Col span={12}>
                  <Form.Item
                    label="ลิ้งค์ข้อมูลเพิ่มเติม"
                    name="url_link"
                    rules={[
                      { type: 'url', message: 'Website format is incorrect.' },
                      { required: false, message: 'Link is required !!' },
                    ]}
                    initialValue={
                      this.props.match.params.id && dataList.url_link
                        ? dataList.url_link
                        : ''
                    }
                  >
                    <Input
                      autoComplete="off"
                      onChange={(e) => this.onLinkChange(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={[5, 15]}>
              <Col span={8}>
                <Form.Item
                  label="วันและเวลาที่ต้องการเผยแพร่"
                  name="publish_date"
                  rules={[
                    { required: true, message: 'Publish Date is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id
                      ? moment.unix(dataList.start_datetime)
                      : ''
                  }
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ marginTop: '31px' }}>
                <Form.Item
                  name="publish_time"
                  rules={[
                    { required: true, message: 'Publish Time is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id
                      ? moment.unix(dataList.start_datetime)
                      : ''
                  }
                >
                  <TimePicker format={'HH:mm'} style={{ width: '100%' }} minuteStep={5} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[5, 15]}>
              <Col span={8}>
                <Form.Item
                  label="วันและเวลาที่สิ้นสุด"
                  name="expire_date"
                  rules={[
                    { required: true, message: 'Expire Date is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id
                      ? moment.unix(dataList.end_datetime)
                      : ''
                  }
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ marginTop: '31px' }}>
                <Form.Item
                  name="expire_time"
                  rules={[
                    { required: true, message: 'Expire Time is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id
                      ? moment.unix(dataList.end_datetime)
                      : ''
                  }
                >
                  <TimePicker format={'HH:mm'} style={{ width: '100%' }} minuteStep={5} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 15]}>
              <Col span={12}>
                <Form.Item
                  label="สถานะ"
                  name="status"
                  rules={[{ required: false }]}
                  initialValue={
                    this.props.match.params.id
                      ? (dataList.status === 'active') |
                        (dataList.status === 'draft') |
                        (dataList.status === 'expired')
                        ? 'active'
                        : 'inactive'
                      : 'active'
                  }
                >
                  <Radio.Group>
                    <Radio value={'active'}>ใช้งาน</Radio>
                    <Radio value={'inactive'}>ไม่ใช้งาน</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item shouldUpdate={true}>
              <Footeractionlayout
                onCancle={this.clickCancle}
              ></Footeractionlayout>
            </Form.Item>
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={this.handleCancel}
              width={800}
            >
              <img
                alt="example"
                style={{ width: '100%', marginTop: '20px' }}
                src={previewImage}
              />
            </Modal>
            <Modal
              visible={this.state.isCreated}
              footer={false}
              closable={false}
              centered={true}
              width={'420px'}
            >
              <div
                style={{
                  height: '220px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <img
                      alt="example"
                      src={iconCorrect}
                      width={48}
                      height={48}
                    />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    บันทึกข้อมูลสำเร็จแล้ว
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กดตกลงเพื่อออกจากหน้านี้
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.goBack()
                        }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.isCreateError}
              footer={false}
              closable={false}
              centered={true}
              width={'500px'}
            >
              <div
                style={{
                  height: '220px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <img alt="example" src={iconErr} width={48} height={48} />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    บันทึกข้อมูลไม่สำเร็จ
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    {this.state.errorMsg}
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.setState({ isCreateError: false })
                        }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.isCancel}
              footer={false}
              closable={false}
              centered={true}
              width={'500px'}
            >
              <div
                style={{
                  height: '180px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    ยืนยันการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    หากคุณต้องการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กรุณากด ยืนยัน
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        style={{ width: '170px' }}
                        onClick={() => this.handleCancel()}
                      >
                        ยกเลิก
                      </Button>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.goBack()
                        }}
                      >
                        ยืนยัน
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
          </Form>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(popupevent, state),
  dataList: getResource(popupevent, state),
})

const mapDispatchToProps = (dispatch) => ({
  createData: (data) => dispatch(createData(popupevent, data)),
  readData: (id) => dispatch(readData(popupevent, id)),
  updateData: (data, id) => dispatch(updateData(popupevent, data, id)),
  addImageData: (params) => dispatch(resizeUpload(params)),
})

const SlideListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Formslidemenulayout)

export default SlideListWithConnect
