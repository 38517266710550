import { Col, Row } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'

const LoadingScene = (props) => {
  return (
    <Row>
      <Col
        span={24}
        style={{
          fontSize: '42px',
          color: '#bd1d2d',
          position: 'absolute',
          zIndex: props.onCctvError === true ? 99 : 0,
          top: 0,
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <LoadingOutlined />
      </Col>
    </Row>
  )
}

export default LoadingScene
