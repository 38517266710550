/* eslint-disable no-useless-escape */

import "../../../App.less";

import { Avatar, Col, Row, Skeleton, Button, Space, Modal, Input, Form, message } from "antd";
import React, { useEffect, useState, useRef, useContext } from "react";

import { UserOutlined } from "@ant-design/icons";
import Viewlayout from "components/layouts/Viewlayout";
import { connect } from "react-redux";
import { readData, updateData } from "actions/collection";
import { getResourceLoading } from "selectors";
import styled from "styled-components";
import { useHistory } from "react-router";
import { approval, revise } from "schemas";
import iconCorrect from "images/correct@3x.png";
import iconErr from 'images/icon-error@3x.png'
import { MenuAuth } from "contexts/menuAuth";

const { TextArea } = Input;

const url = `${process.env.REACT_APP_CCTV_APPROVAL}`;

const ApproveCctvView = (props) => {
  const history = useHistory();
  const formRef = useRef()
  const paramsId = props.match.params.id
  const [dataApproval, setDataApproval] = useState();
  const [loading, setLoading] = useState(true);
  const permissionAuthor = useContext(MenuAuth);
  const [actionPermission, setActionPermission] = useState(undefined);
  const [openModal, setOpenModal] = useState({
    openCancel: false,
    openConfirmReject: false,
    openConfirmApprove: false,
    openSuccess: false,
    openErr: false,
    text: '',
    textHeaderErr: '',
    textTitleErr: '',
  });

  useEffect(() => {
    const author = permissionAuthor?.menuAuth?.find((menu) => menu?.key_slug === "cms");
    const menu = author?.sub_menus?.find((sub) => sub?.key_slug === "approve-cctv");
    setActionPermission(menu?.role_permission);
  }, [permissionAuthor])

  useEffect(() => {
    setLoading(true);
    props.readData(paramsId).then((res) => {
      setLoading(false);
      if (res?.code === 200) {
        setDataApproval(res.data);
      } else {
        setDataApproval();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateApproval = (data, text) => {
    message.destroy()
    props.updateData(approval, data, paramsId)
      .then((res) => {
        if (res?.code === 200) {
          setOpenModal({ ...openModal, openConfirmApprove: false, openSuccess: true, text, openConfirmReject: false })
        } else {
          setOpenModal({ ...openModal, openErr: true, textHeaderErr: 'อนุมัติข้อมูลไม่สำเร็จ', textTitleErr: '' })
        }
      })
  }

  const updateRevise = () => {
    message.destroy()
    props.updateData(revise, '', dataApproval.id)
      .then((res) => {
        if (res?.code === 200) {
          history.push(`/cctv/edit/${dataApproval.cctv_id}`)
        } else if (res?.code === 404) {
          setOpenModal({ ...openModal, openErr: true, textHeaderErr: 'ไม่พบข้อมูลในระบบ', textTitleErr: 'เนื่องจากไม่มีรายการกล้อง CCTV นี้ กรุณาติดต่อแอดมิน' })
        } else {
          setOpenModal({ ...openModal, openErr: true, textHeaderErr: 'พบข้อผิดพลาดในการทำงาน', textTitleErr: 'เนื่องจากไม่สามารถดำเนินการกลับไปแก้ไขได้ กรุณาติดต่อแอดมิน' })
        }
      })
  }

  const onApprove = async () => {
    message.loading('Save in progress..', 0)
    await setLoading(true)
    const data = JSON.stringify({
      "cctv_id": dataApproval?.cctv_id,
      "status_approval": "approve",
      "reject_reason": ""
    });
    await updateApproval(data, 'อนุมัติข้อมูลสำเร็จแล้ว')
    await setLoading(false)
  }

  const onReject = async (value) => {
    message.loading('Save in progress..', 0)
    await setLoading(true)
    const data = JSON.stringify({
      "cctv_id": dataApproval?.cctv_id,
      "status_approval": "reject",
      "reject_reason": value.reason
    });
    await updateApproval(data, 'ส่งข้อมูลสำเร็จแล้ว')
    await setLoading(false)
  }

  const onClickCancelReject = () => {
    setOpenModal({ ...openModal, openConfirmReject: false })
    formRef.current.resetFields()
  }

  const onClickEditCctv = async () => {
    message.loading('Save in progress..', 0)
    await setLoading(true)
    if (dataApproval?.approval_by) {
      if (dataApproval.status_approval === 'reject') {
        await updateRevise()
      } else {
        await message.destroy()
        await history.push(`/cctv/edit/${dataApproval.cctv_id}`)
      }
    }
    await setLoading(false)
  }

  return (

    <div className="App-form">
      <Viewlayout
        viewData={dataApproval}
        isLoading={loading}
        permission={{ can_update: true }}
        textTitle="ดูข้อมูล CCTV"
      >
        <Skeleton className="App-form" loading={loading} active>
          {dataApproval && (
            <Row gutter={[30, 0]}>
              <Col span={24} style={{ marginTop: -20 }}>
                <TextImg>รูปภาพ</TextImg>
              </Col>
              <Col span={24}>
                {dataApproval?.cover_url ? (
                  <Avatar
                    size={80}
                    src={`${dataApproval?.cover_url}?w=160`}
                    icon={<UserOutlined />}
                  />
                ) : (
                  <Avatar size={80} icon={<UserOutlined />} />
                )}
              </Col>
              <Col className="sil-break-word" span={12}>
                <TextLabel>ชื่อโครงการ</TextLabel>
                {dataApproval?.name?.th || "-"}
              </Col>
              <Col className="sil-break-word" span={12}>
                <TextLabel>Project Name</TextLabel>
                {dataApproval?.name?.en || "-"}
              </Col>

              <Col className="sil-break-word" span={12}>
                <TextLabel>รายละเอียด</TextLabel>
                {dataApproval?.description?.th || "-"}
              </Col>
              <Col className="sil-break-word" span={12}>
                <TextLabel>Detail</TextLabel>
                {dataApproval?.description?.en || "-"}
              </Col>
              <Col className="sil-break-word" span={24}>
                <TextLabel>กลุ่มผู้ใช้งาน</TextLabel>
                {dataApproval?.user_groups || "-"}
              </Col>
              <Col className="sil-break-word" span={12}>
                <TextLabel>สถานะการอนุมัติ</TextLabel>
                {dataApproval?.status_approval === "approve" ?
                  <div style={{ color: "#00C428" }}>อนุมัติ</div>
                  : dataApproval?.status_approval === "pending_publish" ?
                    <div style={{ color: "#FFA800" }}>รออนุมัติ</div>
                    : dataApproval?.status_approval === "revise" ?
                      <div style={{ color: "#FF7F50" }}>ปรับปรุงใหม่</div>
                      : <div style={{ color: "#FF0000" }}>ไม่อนุมัติ</div>}
              </Col>
              {dataApproval?.reject_reason &&
                <Col className="sil-break-word" span={24}>
                  <TextLabel>สาเหตุ</TextLabel>
                  {dataApproval?.reject_reason || "-"}
                </Col>}
              {(dataApproval?.status_approval === "pending_publish" && actionPermission?.can_update) &&
                <Col
                  span={24}
                  style={{
                    fontSize: '18px',
                    textAlign: 'left',
                    marginTop: '30px',
                  }}
                >
                  <Space>
                    <Button
                      type="primary"
                      style={{ width: '170px' }}
                      onClick={() => setOpenModal({ ...openModal, openConfirmApprove: true })}
                    >
                      อนุมัติ
                    </Button>
                    <Button
                      style={{ width: '170px' }}
                      onClick={() => setOpenModal({ ...openModal, openConfirmReject: true })}
                    >
                      ไม่อนุมัติ
                    </Button>

                  </Space>
                </Col>}
            </Row>
          )}
          {(dataApproval?.status_approval === 'reject' || dataApproval?.status_approval === 'revise') &&
            <Col
              span={24}
              style={{
                fontSize: '18px',
                textAlign: 'left',
                marginTop: '30px',
              }}
            >
              <Button
                type="primary"
                style={{ width: '170px' }}
                onClick={onClickEditCctv}
              >
                กลับไปแก้ไขข้อมูล
              </Button>

            </Col>
          }
        </Skeleton>
      </Viewlayout>

      <Modal
        visible={openModal.openConfirmApprove}
        footer={false}
        closable={false}
        centered={true}
        width={'528px'}
      >
        <BoxModel height="300px">
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img
                alt="iconCorrect"
                src={iconCorrect}
                width={48}
                height={48}
              />
            </Col>
            <Col span={24}>
              <HeaderModel>
                กรุณายืนยันการอนุมัติ
              </HeaderModel>
            </Col>
            <Col span={24} >
              <TextModel>
                กด ‘ตกลง’ เพื่อยืนยันการอนุมัติข้อมูล
              </TextModel>
            </Col>
            <Col span={24}>
              <BoxButton>
                <Space>
                  <Button
                    style={{ width: "170px" }}
                    onClick={() =>
                      setOpenModal({ ...openModal, openConfirmApprove: false })
                    }
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "170px" }}
                    onClick={!loading && onApprove}
                  >
                    ตกลง
                  </Button>
                </Space>
              </BoxButton>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
      <Modal
        visible={openModal.openSuccess}
        footer={false}
        closable={false}
        centered={true}
        width={'528px'}
      >
        <BoxModel height="300px">
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img
                alt="iconCorrect"
                src={iconCorrect}
                width={48}
                height={48}
              />
            </Col>
            <Col span={24}>
              <HeaderModel>
                {openModal.text}
              </HeaderModel>
            </Col>
            <Col span={24} >
              <TextModel>
                กด ‘ตกลง’ เพื่อออกจากหน้านี้
              </TextModel>
            </Col>
            <Col span={24}>
              <BoxButton>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "170px" }}
                  onClick={() => { setOpenModal({ ...openModal, openSuccess: false }); history.push('/approve-cctv') }}
                >
                  ตกลง
                </Button>
              </BoxButton>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
      <Modal visible={openModal.openConfirmReject} footer={false} closable={false} centered={true} width={'740px'}>
        <BoxModel height="270px">
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col span={24}>
              <HeaderCancel>
                กรุณากรอกเหตุผลที่ไม่อนุมัติโครงการนี้
              </HeaderCancel>
            </Col>

            <Form onFinish={onReject} ref={formRef} >
              <Col span={24} style={{ padding: '15px', }}>
                <Form.Item
                  name="reason"
                  rules={[{
                    required: true,
                    message: ' Reason is required !!',
                  }]}>
                  <TextArea autoComplete="off" maxLength={1000} style={{ width: 550 }} autoSize={{ minRows: 5, maxRows: 5 }} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <BoxButton>
                  <Space>
                    <Button style={{ width: '170px' }} onClick={onClickCancelReject}>
                      ยกเลิก
                    </Button>
                    <Button type="primary" htmlType="submit" style={{ width: '170px' }} >
                      ยืนยัน
                    </Button>
                  </Space>
                </BoxButton>
              </Col>
            </Form>

          </Row>
        </BoxModel>
      </Modal>
      <Modal
        visible={openModal.openErr}
        footer={false}
        closable={false}
        centered={true}
        width={'528px'}
      >
        <BoxModel minHeight="300px">
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img alt="example" src={iconErr} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>
                {openModal.textHeaderErr}
              </HeaderModel>
            </Col>
            {openModal.textTitleErr &&
              <Col span={24} >
                <TextModel>
                  {openModal.textTitleErr}
                </TextModel>
              </Col>}
            <Col span={24}>
              <TextModel marginTop="40px">
                <Space>
                  <Button
                    onClick={() => setOpenModal({ ...openModal, openErr: false })}
                    type="primary" style={{ width: '170px' }} >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
    </div>

  );
};

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(approval, state),
});

const mapDispatchToProps = (dispatch) => ({
  readData: (id) => dispatch(readData(approval, id, url)),
  updateData: (schemas, data, id) => dispatch(updateData(schemas, data, id, url)),
});

const ApproveCctvViewWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveCctvView);

export default ApproveCctvViewWithConnect;
const TextLabel = styled.div`
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  color: black;
`;
const TextImg = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  color: black;
`;

const HeaderCancel = styled.div`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
`;
const BoxButton = styled.div`
  margin-top: 30px ;
  font-size: 18px;
  text-align: center;
`;

const BoxModel = styled.div`
  min-height: ${(props) => props.minHeight};
  height: ${(props) => props.height};
  justify-content: center;
  align-items: center;
  display: flex;
`;
const HeaderModel = styled.div`
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 8px;
  margin-top: 15px;
`;
const TextModel = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: ${(props) => props.marginTop};
`;

