import React from 'react'
import styled from 'styled-components'

import Iconpin from 'images/Icon-Location-Pin.png'
const Marker = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  top: -50px;
  /*positions our marker*/
  left: -50px;
  /*positions our marker*/
  display: block;
`

const Pin = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  top: 38px;
  left: 38px;
  background: rgba(5, 124, 255, 1);
  border: 2px solid #fff;
  border-radius: 50%;
  // z-index: 1000;
`
const Pin2 = styled.div`
  position: relative;
  top: 40px;
  left: 40px;
`

const PinEffect = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  display: block;
  background: rgba(5, 124, 255, 0.6);
  border-radius: 50%;
  opacity: 0;
  animation: pulsate 2400ms ease-out infinite;
  @keyframes pulsate {
    0% {
      transform: scale(0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(0.8);
      opacity: 0;
    }
  }
`

export const PulseMarker = () => {
  return (
    <Marker>
      <Pin />
      <PinEffect />
    </Marker>
  )
}

export const PulsePin = () => {
  return (
    <Marker>
      <Pin2><img alt="Pin" src={Iconpin} style={{ width: '23px', height: '23px' }} /></Pin2>
    </Marker>
  )
}

