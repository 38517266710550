import axios from 'axios'

import { uploadFile, uploadingFile, resetUploadingFile } from './actions'

export const upload = (data) => {
  return (dispatch) => {
    var bodyFormData = new FormData()
    bodyFormData.set('file', data.file)
    dispatch(resetUploadingFile())
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_MEDIAURL}`, bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          dispatch(uploadingFile())
          setTimeout(() => {
            dispatch(uploadFile(response.data))
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
          data.onError()
          throw error
        })
    })
  }
}

export const multiUpload = (data) => {
  return (dispatch) => {
    var bodyFormData = new FormData()
    bodyFormData.set('file', data.file)
    dispatch(resetUploadingFile())
    return new Promise(async (resolve, reject) => {
      await axios
        .post(`${process.env.REACT_APP_MEDIAURL}`, bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          dispatch(uploadingFile())
          setTimeout(() => {
            dispatch(uploadFile(response.data))
            // const result = { data: { link: response.data.URL}}
            // console.log(result);
            data.onSuccess(null, data.file)
            // return resolve(response.data.URL)
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
          data.onError()
          throw error
        })
    })
  }
}

export const singleUpload = (data, type) => {
  return (dispatch) => {
    var bodyFormData = new FormData()
    bodyFormData.set('file', data.file)
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_MEDIAURL}`, bodyFormData, {
          onUploadProgress: (e) => {
            data.onProgress({ percent: (e.loaded / e.total) * 100 })
          },
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          dispatch(uploadingFile())
          setTimeout(() => {
            dispatch(uploadFile(response.data, type))
            // console.log(response);
            const result = {
              name: 'asdfsadf',
              status: 'done',
              url: response.data.URL,
              thumbUrl: response.data.URL,
            }
            data.file.url = response.data.URL
            data.onSuccess(null, data.file)
            return resolve(result)
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
          data.onError(error)
          throw error
        })
    })
  }
}

export const resizeUpload = (data, type) => {
  return (dispatch) => {
    var bodyFormData = new FormData()
    if (data.file) {
      if (data.file.inpType) {
        bodyFormData.append('sampleFile', data.file, data.file.name)
      } else {
        bodyFormData.set('sampleFile', data.file)
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_MEDIAURL}`, bodyFormData, {
          onUploadProgress: (e) => {
            data.onProgress({ percent: (e.loaded / e.total) * 100 })
          },
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          dispatch(uploadingFile())
          setTimeout(() => {
            dispatch(uploadFile(response.data, type))
            const result = {
              name: 'asdfsadf',
              status: 'done',
              url: response.data.url,
              thumbUrl: response.data.url,
            }
            data.file.url = response.data.url
            data.onSuccess(null, data.file)
            return resolve(result)
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
          data.onError(error)
          throw error
        })
    })
  }
}

export const editorUpload = (data, type) => {
  return (dispatch) => {
    var bodyFormData = new FormData()

    bodyFormData.set('file', data.file)

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_MEDIAURL}`, bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          dispatch(uploadingFile())
          setTimeout(() => {
            dispatch(uploadFile(response.data, type))
            const result = { data: { link: response.data.URL } }
            return resolve(result)
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
          throw error
        })
    })
  }
}
