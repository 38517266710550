import {
  AreaChartOutlined,
  BlockOutlined,
  CarOutlined,
  CloudOutlined,
  CloudServerOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  EditOutlined,
  FileImageOutlined,
  InsertRowBelowOutlined,
  OneToOneOutlined,
  PartitionOutlined,
  ProfileOutlined,
  ReadOutlined,
  TagsOutlined,
  ToolOutlined,
  UserAddOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined,
  FileDoneOutlined
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import { MenuAuth } from "contexts/menuAuth";
import SvgLogo from "images/logo-sil.png";
import { setCookiesChangeScene } from "../../utils/common.js";
import { setMenuSelect } from "utils/setMenu";
import settings from "../../../src/config";
import styled from "styled-components";

// import Menus from "./menu";
const { Sider } = Layout;
const { SubMenu } = Menu;
let menuSelect = undefined;
function Siders(props) {
  const location = useLocation();
  const menus = useContext(MenuAuth);

  // const [collapsed, setCollapsed] = useState(false);
  const [activeMenu, setActiveMenu] = useState();
  const [activeSubMenu, setActiveSubMenu] = useState();

  const menuIcon = {
    dashboard: <DashboardOutlined />,
    ga: <AreaChartOutlined />,
    monitor: <VideoCameraOutlined />,
    cms: <InsertRowBelowOutlined />,
    cctv: <VideoCameraOutlined />,
    zm: <VideoCameraOutlined />,
    slides: <FileImageOutlined />,
    "popup-events": <BlockOutlined />,
    blog: <ReadOutlined />,
    opt: <ToolOutlined />,
    "contact-management": <ProfileOutlined />,
    "contact-partners": <PartitionOutlined />,
    "user-management": <UserOutlined />,
    user: <UserAddOutlined />,
    "user-group": <UsergroupAddOutlined />,
    "roles-permission-management": <EditOutlined />,
    "master-data": <DatabaseOutlined />,
    "cctv-groups": <VideoCameraOutlined />,
    tags: <TagsOutlined />,
    "tourism-types": <CarOutlined />,
    "partner-groups": <PartitionOutlined />,
    "blog-categories": <OneToOneOutlined />,
    "ftp-management": <CloudOutlined />,
    "master-server-ftp": <CloudServerOutlined />,
    "approve-cctv": <FileDoneOutlined />
  };

  useEffect(() => {
    menuSelect = setMenuSelect(menus.menuAuth || [], location?.pathname);

    props.setBreadcrumb(
      menuSelect?.breadcrumb[2]?.breadcrumbName === "Page error"
        ? menuSelect?.breadcrumb[2]
        : menuSelect?.breadcrumb
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, menus.menuAuth, props]);

  const selectMenu = async (val) => {
    const { props } = val.item;
    await setActiveMenu(val.key);
    await setActiveSubMenu(props.issubmenu ? props.openKeys[0] : "");
  };

  const onOpenSub = async (val) => {
    const sub_click = val[1];
    await setActiveSubMenu(sub_click);
  };

  useEffect(() => {
    if (menuSelect?.menuSelect === "profile") {
      setActiveMenu("");
      setActiveSubMenu("");
    } else {
      setActiveMenu(menuSelect?.menuSelect);
      setActiveSubMenu(menuSelect?.subMenuSelect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuSelect?.menuSelect, location.pathname]);

  return (
    <>
      <Sider
        // collapsible
        // collapsed={collapsed}
        // onCollapse={() => setCollapsed(!collapsed)}
        className="sider-menu"
      >
        <BoxLogo /*collapsed={collapsed}*/>
          <Link
            to="/"
            onClick={() => {
              setActiveMenu("dashboard");
              setActiveSubMenu("");
            }}
          >
            {
              /*collapsed ? (
              <TextLogo>See It LIVE</TextLogo>
            ) : (*/
              <img src={SvgLogo} width="95" alt="Logo" />
              /*)}*/
            }
          </Link>
        </BoxLogo>
        <Menu
          theme="dark"
          mode="inline"
          onSelect={selectMenu}
          onOpenChange={onOpenSub}
          selectedKeys={[activeMenu]} // select menu item by key
          openKeys={[activeSubMenu]} // open sub menu by key
          className="sil-menu-side"
        >
          {menus?.menuAuth?.map((menu) => {
            return menu?.sub_menus ? (
              <SubMenu
                key={menu.key_slug}
                icon={menuIcon[menu.icon]}
                title={menu.name}
              >
                {menu.sub_menus.map((sub) => {
                  return (
                    <Menu.Item
                      icon={menuIcon[sub.icon]}
                      key={sub.key_slug}
                      issubmenu={"issub"}
                    >
                      <Link
                        to={sub.path}
                        onClick={() => setCookiesChangeScene()}
                      >
                        {sub.name}
                      </Link>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            ) : null;
          })}
        </Menu>

        {props?.showVersion && (
          <BoxVersion /* collapsed={collapsed}*/>
            {settings.REACT_APP_VERSION
              ? "V. " + settings.REACT_APP_VERSION
              : ""}
          </BoxVersion>
        )}
      </Sider>
    </>
  );
}

const BoxVersion = styled.div`
  position: absolute;
  width: 100%;
  font-size: 14px;
  color: #fff;
  bottom: 20px;
  text-align: center;
`;

const BoxLogo = styled.div`
  height: 60px;
  margin: 10px 16px 50px 16px;
  color: white;
  //margin-bottom: ${(props) => (props.collapsed ? "100px" : "50px")};
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
`;

// const TextLogo = styled.div`
//   color: rgba(255, 255, 255, 0.65);
//   text-align: center;
//   font-weight: 600;
//   font-size: 25px;
// `;

export default Siders;
