import React from 'react'
import {
  Modal,
  Button,
  Form,
  Space,
  Row,
  Col,
  TimePicker,

  // Input,
} from 'antd'
import Warning from 'images/Warning.png'
import ErrorImage from 'images/icon-error@3x.png'
import SuccessImage from 'images/correct@3x.png'

export default function Index({
  showModal,
  isModalVisible,
  handleCancelModelSnapshot,
  handleOkModelSnapshot,
  handleCreateSnapshot,
  closeModal,
  modalSnapshotSuccess,
  modalSnapshotError,
  SnapshotError,
  SnapshotSuccess,
  createSnapshotTextStatus,
  snapshotForm,
}) {
  return (
    <div>
      <Modal
        visible={showModal}
        onCancel={handleCancelModelSnapshot}
        footer={false}
        centered
        bodyStyle={{
          width: '500px',
          height: '260px',
          textAlign: 'center',
          padding: '24px',
          paddingLeft: '40px',
        }}
      >
        <Form
          layout='vertical'
          name='time'
          onFinish={handleOkModelSnapshot}
          ref={snapshotForm}
        >
          <Row>
            <Col span={24}>
              <div
                style={{
                  fontSize: '18px',
                  marginBottom: '12px',
                  marginTop: '10px',
                  textAlign: 'left',
                }}
              >
                สร้าง Snapshot
              </div>
            </Col>
          </Row>
          <Row gutter={[10]} justify='space-around'>
            <Col span={6}>
              <Form.Item
                name='snapshotMorning'
                label='ช่วงเช้า'
                rules={[
                  {
                    required: true,
                    message: 'Snapshot time is required !!',
                  },
                ]}
              >
                <TimePicker format={'HH:mm'} style={{ width: '110%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name='snapshotAfternoon'
                label='ช่วงกลางวัน'
                rules={[
                  {
                    required: true,
                    message: 'Snapshot time is required !!',
                  },
                ]}
              >
                <TimePicker format={'HH:mm'} style={{ width: '110%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name='snapshotEvening'
                label='ช่วงเย็น'
                rules={[
                  {
                    required: true,
                    message: 'Snapshot time is required !!',
                  },
                ]}
              >
                <TimePicker format={'HH:mm'} style={{ width: '110%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Space style={{ gap: '15px', marginTop: '10px' }}>
              <Button
                type='primary'
                key='console'
                htmlType='submit'
                style={{
                  width: '170px',
                  height: '35px',
                }}
              >
                ตกลง
              </Button>

              <Button
                key='console'
                onClick={handleCancelModelSnapshot}
                style={{
                  width: '170px',
                  height: '35px',
                }}
              >
                ยกเลิก
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={closeModal}
        footer={false}
        closable={true}
        centered
        bodyStyle={{ padding: '24px 55px' }}
      >
        <div style={{ textAlign: 'center', marginTop: '15px', gap: '15px' }}>
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <img
              src={Warning}
              style={{
                width: '48px',
                height: '48px',
              }}
              alt='avatar'
            />
          </div>
          <div
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              textAlign: 'center',
              marginTop: '15px',
            }}
          >
            สร้าง Snapshot
          </div>
          <div
            style={{
              fontSize: '18px',
              textAlign: 'center',
            }}
          >
            เมื่อคุณสร้างใหม่ SnapShot ของวันเดียวกันจะหายไป คุณต้องการสร้างใหม่
            ใช่หรือไม่ ?
          </div>
          <Form.Item>
            <Space
              style={{ gap: '15px', marginTop: '10px', marginBottom: '-10px' }}
            >
              <Button
                type='primary'
                key='console'
                onClick={handleCreateSnapshot}
                style={{
                  width: '170px',
                  height: '35px',
                  marginTop: '15px',
                }}
              >
                ตกลง
              </Button>

              <Button
                key='console'
                onClick={closeModal}
                style={{
                  width: '170px',
                  height: '35px',
                  marginTop: '15px',
                }}
              >
                ยกเลิก
              </Button>
            </Space>
          </Form.Item>
        </div>
      </Modal>

      <Modal
        visible={modalSnapshotSuccess}
        footer={false}
        closable={false}
        centered
        bodyStyle={{ padding: '24px 55px' }}
      >
        <div style={{ textAlign: 'center', marginTop: '15px', gap: '15px' }}>
          <img
            src={SuccessImage}
            style={{
              width: '48px',
              height: '48px',
            }}
            alt='avatar'
          />
          <div
            style={{
              marginTop: '15px',
              fontSize: '24px',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            สร้าง SnapShot สำเร็จ
          </div>
          <div
            style={{
              fontSize: '18px',
              textAlign: 'center',
            }}
          >
            Snapshot ของคุณจะแสดงผลหลังจาก เวลา 00.00 น. ของวันที่ทำการสร้าง
          </div>
          <Space
            style={{ gap: '15px', marginTop: '10px', marginBottom: '10px' }}
          >
            <Button
              type='primary'
              key='console'
              onClick={SnapshotSuccess}
              style={{
                width: '170px',
                height: '35px',
                marginTop: '15px',
              }}
            >
              ตกลง
            </Button>
          </Space>
        </div>
      </Modal>

      <Modal
        visible={modalSnapshotError}
        onOk={modalSnapshotError}
        footer={false}
        closable={true}
        centered
        bodyStyle={{ padding: '24px 55px' }}
      >
        <div style={{ textAlign: 'center', marginTop: '15px', gap: '15px' }}>
          <img
            src={ErrorImage}
            alt='avatar'
            style={{ width: '48px', height: '48px' }}
          />
          <div
            style={{
              fontSize: '24px',
              textAlign: 'center',
              fontWeight: 'bold',
              marginTop: '15px',
            }}
          >
            สร้าง SnapShot ไม่สำเร็จ
          </div>
          <div
            style={{
              fontSize: '18px',
              textAlign: 'center',
            }}
          >
            {createSnapshotTextStatus
              ? createSnapshotTextStatus
              : 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง !'}
          </div>
          <Form.Item>
            <Space
              style={{
                gap: '15px',
                marginTop: '10px',
                marginBottom: '-10px',
              }}
            >
              <Button
                type='primary'
                key='console'
                onClick={SnapshotError}
                style={{
                  width: '170px',
                  height: '35px',
                  marginTop: '15px',
                }}
              >
                ตกลง
              </Button>
            </Space>
          </Form.Item>
        </div>
      </Modal>
    </div>
  )
}
