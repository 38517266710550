import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  Modal,
  Row,
  Space,
  message,
} from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { adminLogout } from "actions/actions";
import { authContext } from "contexts/AuthContext";
import { userGroupContext } from "contexts/UserGroupContext";
import { compose } from "redux";
import { connect } from "react-redux";
import iconExit from "images/exit.png";
import iconLogout from "images/logout.png";
import { logout } from "schemas";
import styled from "styled-components";

const { Header } = Layout;

function Headers(props) {
  const { breadcrumb } = props;
  // const [visible, setVisible] = useState(false)
  const [openLogout, setOpenLogout] = useState(false);
  // const [openDropdown, setOpenDropdown] = useState(false)
  const { setAuthData } = useContext(authContext);
  const { setUserGroupData } = useContext(userGroupContext);

  useEffect(() => {
    // if (visible === false && openLogout === true) {
    //   setOpenDropdown(true)
    // } else if (visible === false && openLogout === false) {
    //   setOpenDropdown(false)
    // } else {
    //   setOpenDropdown(true)
    // }
  }, [openLogout]);

  const handleMenuClick = (e) => {
    if (e === "logout") {
      setOpenLogout(true);
    } else {
      props.history.push("/profile");
    }
  };

  const handleLogout = () => {
    // setOpenDropdown(false)
    message.loading({ content: "Loading..." });
    props
      .adminLogout()
      .then((response) => {
        message.destroy();
        if (response.code === 200) {
          setAuthData(null);
          setUserGroupData(null)
          localStorage.clear();
          localStorage.setItem("isLogin", false);
          props.history.push("/login");
        } else {
          message.warning(response?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };

  const gotoPage = (val) => {
    if (val === "profile") {
      props.history.replace(`/${val}`);
    } else {
      props.history.push(`${val}`);
    }
  };

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;

    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : paths.length ? (
      <Link onClick={() => gotoPage(route.path)} to="#">
        {route.breadcrumbName}
      </Link>
    ) : (
      // <Link to={`/${paths}`}>{route.breadcrumbName}</Link>
      <span>{route.breadcrumbName}</span>
    );
  };

  return (
    <>
      <HeaderBox>
        <ContentHeader>
          <Row>
            <Col span={12} style={BreadcrumbStyle}>
              <BoxBreadcrumb>
                <Breadcrumb
                  className="sil-breadcrumb"
                  itemRender={itemRender}
                  routes={breadcrumb}
                />
              </BoxBreadcrumb>
            </Col>
            <Col span={12} style={DropdownStyle}>
              <Avatar
                size={28}
                icon={<UserOutlined />}
                src={localStorage.getItem("user_profile_image")}
                style={{ margin: " 0 5px 0 5px" }}
              />
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item
                      onClick={(e) => handleMenuClick("profile")}
                      key="profile"
                    >
                      <UserOutlined style={{ marginRight: "13px" }} />
                      ข้อมูลส่วนตัว
                    </Menu.Item>
                    <Menu.Item
                      onClick={(e) => handleMenuClick("logout")}
                      key="logout"
                    >
                      <IconImg src={iconExit} alt="Icon Exit" />
                      ออกจากระบบ
                    </Menu.Item>
                  </Menu>
                }
              // onVisibleChange={(flag) => setVisible(flag)}
              // visible={openDropdown}
              >
                <Link
                  to="/"
                  onClick={(e) => e.preventDefault()}
                  style={{ marginLeft: "5px" }}
                >
                  {localStorage.getItem("user_name")}
                  <DownOutlined style={{ marginLeft: "5px" }} />
                </Link>
              </Dropdown>
            </Col>
          </Row>
        </ContentHeader>
      </HeaderBox>

      <Modal
        className="modal-logout"
        visible={openLogout}
        footer={false}
        closable={false}
        centered={true}
        width={"528px"}
      >
        <BoxModel height="357px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="Icon Logout" src={iconLogout} width={70} height={54} />
            </Col>
            <Col span={24}>
              <HeaderModel>คุณต้องการออกจากระบบ</HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="11px">
                กด ‘ยืนยัน’ เพื่อออกจากระบบ
              </TextModel>
            </Col>
            <Col span={24}>
              <TextButton marginTop="49px">
                <Space>
                  <Button
                    style={{ width: "101px" }}
                    onClick={() => setOpenLogout(false)}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="primary"
                    style={{ width: "101px" }}
                    onClick={() => handleLogout()}
                  >
                    ยืนยัน
                  </Button>
                </Space>
              </TextButton>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
    </>
  );
}

const BreadcrumbStyle = {
  display: "flex",
  alignItems: "center",
  paddingLeft: "41px",
};

const DropdownStyle = {
  paddingRight: "40px",
  textAlign: "end",
};

const IconImg = styled.img`
  margin-right: 10px;
`;

const BoxModel = styled.div`
  height: ${(props) => props.height};
  justify-content: center;
  align-items: center;
  display: contents;
`;

const HeaderModel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 52px;
  text-align: center;
  margin-top: 15px;
  color: #000000;
`;

const TextModel = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: ${(props) => props.marginTop};
`;

const TextButton = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  margin-top: ${(props) => props.marginTop};
`;

const BoxBreadcrumb = styled.div`
  // border-bottom: 2px solid #e8e8e8;
`;

const ContentHeader = styled.div`
  // position: absolute;
  // right: 16px;
`;

const HeaderBox = styled(Header)`
  background: #fff !important;
  padding: 0;
`;
const mapStateToProps = (state) => ({
  // isLoading: getResourceLoading(logout, state),
});

const mapDispatchToProps = (dispatch) => ({
  adminLogout: () => dispatch(adminLogout(logout)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Headers);

// export default Headers
