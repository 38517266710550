import * as CONST from 'actions/collection'
import { fromJS } from 'immutable'

const initialState = fromJS({})

const resourceReducer = (state = initialState, { type, schema, payload }) => {
  switch (type) {
    case CONST.READ_DATA_REQUEST:
      return state.setIn([schema, 'isLoading'], true)

    case CONST.READ_DATA_FAILED:
      return state.setIn([schema, 'isLoading'], false)

    case CONST.READ_DATA_SUCCEED:
      return state
        .setIn([schema, 'data'], payload)
        .setIn([schema, 'isLoading'], false)

    case CONST.GET_LIST_SUCCEED:
      let reqList = payload.entities[schema]

      return state
        .setIn([schema, 'data'], reqList)
        .setIn([schema, 'isLoading'], false)

    default:
      return state
  }
}

export default resourceReducer
