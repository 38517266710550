import React, { useContext, useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { getToken } from "utils/common.js";
import { authContext } from "contexts/AuthContext";

// import cookie from 'js-cookie'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { auth } = useContext(authContext);
  const { loading } = auth;
  const history = useHistory()
  const isLogin = localStorage.getItem("isLogin") === 'true' || localStorage.getItem("isLogin") === true;
  const token = getToken("token");

  useEffect(() => {
    if (history.location.pathname === '/cctv') {
      if (history.location.search) {
        const cctvApprovalId = history.location.search?.split('=')[1]
        cctvApprovalId && sessionStorage.setItem("cctv", cctvApprovalId);
      } else {
        sessionStorage.setItem("cctv", 'approve');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <Route
        {...rest}
        render={() => {
          return <p></p>;
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        auth.data && isLogin && token ? <Component {...routeProps} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
