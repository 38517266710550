/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import { Row, Col, Form, Skeleton, message, Modal, Button, Space } from 'antd'
import { connect } from 'react-redux'
import Viewlayout from 'components/layouts/Viewlayout.js'
import Moment from 'moment'
import { DeleteOutlined } from '@ant-design/icons'
import { DateTimeConvert } from '../../../utils/DateTimeFormat.js'
import { readData, deleteData } from 'actions/collection'
import { popupevent } from 'schemas'
import { getResource, getResourceLoading } from 'selectors'
import iconDeleted from 'images/correct@3x.png'
import iconErr from 'images/icon-error@3x.png'
import { MenuAuth } from "contexts/menuAuth";
import { imageError } from '../../../utils/common'

let isYoutube = false

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  const checkUrl = url.split(/\//)
  isYoutube = checkUrl[2] === "www.youtube.com" || checkUrl[2] === "youtu.be" ? true : false
  return (match && match[2].length === 11)
    ? match[2]
    : null;
}

class Viewrayout extends React.Component {
  static contextType = MenuAuth;
  state = {
    visible: false,
    isCreateError: false,
    visibleDeleted: false,
    errorMsg: '',
    actionPermission: undefined,
  }
  componentDidMount() {
    this.props.readData(this.props.match.params.id)
  }

  componentDidUpdate() {
    if (this.context?.menuAuth !== this.state.actionPermission) {
      this.getPermission()
    }
  }

  getPermission() {
    const author = this.context?.menuAuth?.find((menu) => menu?.key_slug === "cms");
    const menu = author?.sub_menus?.find((sub) => sub?.key_slug === "popup-events");
    !this.state.actionPermission && this.setState({ actionPermission: menu?.role_permission });
  }

  showModaldelete = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    })
    this.deleteList()
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
      delete_id: '',
    })
  }

  deleteList = () => {
    this.setState({ visible: false }, () => {
      message.loading('Delete in progress..', 0)
      this.props.deleteData(this.props.match.params.id).then((res) => {
        if (res?.code === 200) {
          message.destroy()
          this.setState({
            visibleDeleted: true,
          })
        } else {
          message.destroy()
          this.setState({
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    })
  }

  gotoEdit = () => {
    this.props.history.push(`/popup-events/edit/${this.props.match.params.id}`)
  }

  render() {
    const { dataList, isLoading } = this.props
    const videoId = getId(dataList && dataList.video_link ? dataList.video_link : "");
    return (
      <Form className="App-form">
        <Viewlayout
          permission={this.state.actionPermission}
          viewData={dataList}
          isLoading={isLoading}
          textTitle="ข้อมูลป๊อปอัพ"
          iconEdit="edit"
          iconDelete="delete"
          textEdit="แก้ไขข้อมูล"
          textDelete="ลบข้อมูล"
          onDelete={this.showModaldelete}
          onEdit={this.gotoEdit}
        >
          {isLoading === true ? (
            <Skeleton
              className="App-form"
              loading={isLoading}
              active
            ></Skeleton>
          ) : (
            <Form.Item>
              <Row gutter={[100, 0]}>
                <Col className="sil-break-word" style={{ marginTop: '30px' }} span={12}>
                  <h1>ชื่อ</h1>
                  {dataList.name ? dataList.name.th : null}
                </Col>
                <Col className="sil-break-word" style={{ marginTop: '30px' }} span={12}>
                  <h1>Name</h1>
                  {dataList.name ? dataList.name.en : null}
                </Col>
              </Row>
              <Row gutter={[100, 0]}>
                <Col className="sil-break-word" style={{ marginTop: '30px' }} span={12}>
                  <h1>รายละเอียด</h1>
                  {dataList.detail && dataList.detail.th
                    ? dataList.detail.th
                    : '-'}
                </Col>
                <Col className="sil-break-word" style={{ marginTop: '30px' }} span={12}>
                  <h1>Detail</h1>
                  {dataList.detail && dataList.detail.en
                    ? dataList.detail.en
                    : '-'}
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>ประเภทป๊อปอัพ</h1>
                  {dataList && dataList.popup_type === 'image' ? 'รูปภาพ' : 'วิดีโอ'}
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>การแสดงผล</h1>
                  {dataList && dataList.image_type === "portrait" ? 'Portrait' : dataList.image_type === "landscape" ? 'Landscape' : dataList.image_type === "portrait&landscape" ? 'Portrait & Landscape' : 'Square'}
                </Col>
              </Row>
              {dataList.popup_type === 'image' ?
                <Row>
                  <Col style={{ marginTop: '30px' }}>
                    {dataList && dataList.image_link
                      ? <Row gutter={[100, 0]}>
                        <Col span={12}>
                          <h1>รูปภาพ</h1>
                          <Row>
                            <Col span={12}>
                              <img
                                onError={imageError}
                                alt="Image-TH"
                                style={{ width: dataList.image_type === "landscape" ? '300px' : '200px' }}
                                src={dataList.image_type === "portrait" || dataList.image_type === "portrait&landscape" ? dataList.image_link.portrait.th : dataList.image_type === "landscape" ? dataList.image_link.landscape.th : dataList.image_link.square.th}
                              />
                            </Col>
                            <Col span={12}>
                              {dataList.image_type === "portrait&landscape"
                                &&
                                <img
                                  onError={imageError}
                                  alt="ImageLand-TH"
                                  style={{ width: '300px' }}
                                  src={dataList.image_link.landscape.th}
                                />}
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <h1>Image</h1>
                          <Row>
                            <Col span={12}>
                              <img
                                onError={imageError}
                                alt="Image"
                                style={{ width: dataList.image_type === "landscape" ? '300px' : '200px' }}
                                src={dataList.image_type === "portrait" || dataList.image_type === "portrait&landscape" ? dataList.image_link.portrait.en : dataList.image_type === "landscape" ? dataList.image_link.landscape.en : dataList.image_link.square.en}
                              />
                            </Col>
                            <Col span={12}>
                              {dataList.image_type === "portrait&landscape"
                                && <img
                                  onError={imageError}
                                  alt="ImageLand-EN"
                                  style={{ width: '300px' }}
                                  src={dataList.image_link.landscape.en}
                                />}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      : "-"}
                  </Col>
                </Row>
                : <Row>
                  <Col style={{ marginTop: '30px' }}>
                    <h1>วิดีโอ</h1>
                    {isYoutube
                      // eslint-disable-next-line jsx-a11y/iframe-has-title
                      ? <iframe width="520" height="415"
                        style={{ border: '0px' }}
                        src={`//www.youtube.com/embed/${videoId}`}>
                      </iframe>
                      : <video
                        controls
                        style={{ width: '500px' }}
                        src={dataList && dataList.video_link ? dataList.video_link : ""}
                      />}
                  </Col>
                </Row>}
              {dataList.popup_type === 'image' &&
                <Row>
                  <Col className="sil-break-word" style={{ marginTop: '30px' }}>
                    <h1>ลิ้งค์ข้อมูลเพิ่มเติม</h1>
                    {dataList && dataList.url_link ? dataList.url_link : '-'}
                  </Col>
                </Row>}
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>วันที่สร้าง</h1>
                  {Moment.unix(dataList.created_at).format('DD MMMM YYYY')}
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>วันที่เผยแพร่</h1>
                  {DateTimeConvert(dataList.start_datetime)}
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>วันที่สิ้นสุด</h1>
                  {DateTimeConvert(dataList.end_datetime)}
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>สถานะ</h1>
                  {dataList.status === 'active' ? 'เผยแพร่' : dataList.status === 'inactive' ? 'ไม่เผยแพร่' : 'หมดอายุ'}
                </Col>
              </Row>
              <Modal
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
              >
                <Col className="modal-delete">
                  <DeleteOutlined style={{ fontSize: '40px' }} />
                </Col>
                <Col className="modal-delete">ต้องการลบข้อมูลใช่หรือไม่</Col>
              </Modal>
              <Modal
                visible={this.state.visibleDeleted}
                footer={false}
                closable={false}
                centered={true}
                width={'500px'}
              >
                <div
                  style={{
                    height: '180px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <img
                        alt="example"
                        src={iconDeleted}
                        width={48}
                        height={48}
                      />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      ลบข้อมูลเสร็จสิ้น
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          type="primary"
                          style={{ width: '170px' }}
                          onClick={() => this.props.history.push('/popup-events')}
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
              <Modal
                visible={this.state.isCreateError}
                footer={false}
                closable={false}
                centered={true}
                width={'500px'}
              >
                <div
                  style={{
                    height: '220px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <img alt="example" src={iconErr} width={48} height={48} />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      ลบข้อมูลไม่สำเร็จ
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      {this.state.errorMsg}
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          type="primary"
                          style={{ width: '170px' }}
                          onClick={() => {
                            this.setState({ isCreateError: false })
                          }}
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </Form.Item>
          )
          }
        </Viewlayout>
      </Form >
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(popupevent, state),
  dataList: getResource(popupevent, state),
})

const mapDispatchToProps = (dispatch) => ({
  readData: (id) => dispatch(readData(popupevent, id)),
  deleteData: (id) => dispatch(deleteData(popupevent, id)),
})

const SlideListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Viewrayout)

export default SlideListWithConnect
