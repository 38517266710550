import { Col, Dropdown, Menu, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";

import { Container } from "./Action.style";

export default function Action(props) {
  const { record, path, onDelete, history, permission, status } = props;
  const [deleteId, setDeleteId] = useState("");
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);

  const showDeleteModal = () => {
    setVisibleDeleteModal(true);
  };

  const handleOk = async (e) => {
    await setVisibleDeleteModal(false);

    if (onDelete) {
      await onDelete(deleteId);
    }

    await setDeleteId("");
  };

  const handleCancel = (e) => {
    setVisibleDeleteModal(false);
    setDeleteId("");
  };

  const onClickMenu = (e) => {
    switch (e.key) {
      case "view":
        return history.push(`${e.item.props.path}/view/${e.item.props.id}`);
      case "edit":
        return history.push(`${e.item.props.path}/edit/${e.item.props.id}`);
      case "delete":
        setDeleteId(e.item.props.id);
        showDeleteModal();
        break;
      default:
        break;
    }
  };

  const listMenu = (rec, path) => {
    return (
      <Menu key={rec.id} onClick={onClickMenu}>
        {permission?.can_read && (
          <Menu.Item key={"view"} id={rec.key_slug || rec.id} path={path}>
            <EyeOutlined />
            ดูข้อมูล
          </Menu.Item>
        )}
        {permission?.can_update && (
          <Menu.Item key={"edit"} id={rec.key_slug || rec.id} path={path}>
            <EditOutlined />
            แก้ไขข้อมูล
          </Menu.Item>
        )}
        {permission?.can_delete && (
          <Menu.Item key={"delete"} id={rec.id} path={path}>
            <DeleteOutlined />
            ลบข้อมูล
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <Container>
      <Dropdown trigger={["click"]} overlay={listMenu(record, path)}>
        <MoreOutlined style={{ fontSize: "22px" }} />
      </Dropdown>

      <Modal
        visible={visibleDeleteModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col className="modal-delete">
          <DeleteOutlined style={{ fontSize: "40px" }} />
        </Col>
        <Col className="modal-delete">
          ต้องการลบข้อมูลใช่หรือไม่
        </Col>
        {status === 'pending_publish' &&
          <Col className="modal-delete" style={{ marginTop: '0px' }}>
            เนื่องจากรายการ CCTV นี้อยู่ระหว่างการขออนุมัติ
          </Col>}
      </Modal>
    </Container>
  );
}
