import React, { createContext, useState, useEffect } from 'react'
// import Cookies from 'js-cookie'
export const authContext = createContext({})

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ loading: true, data: null })
  // let authData = Cookies.get('authData')
  let authData = localStorage.getItem('authData')

  const setAuthData = (data) => {
    setAuth({ data: data })
  }

  useEffect(() => {
    setAuth({
      loading: false,
      data: authData ? JSON.parse(authData) : null,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Cookies.set('authData', JSON.stringify(auth.data))
    localStorage.setItem('authData', JSON.stringify(auth.data))
  }, [auth.data])

  return (
    <authContext.Provider value={{ auth, setAuthData }}>
      {children}
    </authContext.Provider>
  )
}

export default AuthProvider
