// import lodash from 'lodash'
export const setMenuSelect = (list, path) => {
  const menuSelect = getMenuSelect(list, path);
  let menus = {};

  if (path.search("/profile") >= 0) {
    const paths = [];
    const names = [];
    const breadName = [
      "Home",
      "ข้อมูลส่วนตัว",
      "แก้ไขข้อมูล",
      "เปลี่ยนรหัสผ่าน",
    ];

    const lengthPath = path.split("/");
    if (lengthPath.length > 2) {
      lengthPath.pop();
    }

    for (let index = 0; index < lengthPath.length; index++) {
      paths.push(lengthPath[index] ? lengthPath[index] : "/dashboard");
      let nameBread = {
        path: index === 0 ? "/" : lengthPath[index],
        // path: paths.join('/'),
        breadcrumbName: breadName[index],
      };

      names.push(nameBread);
    }

    menus = {
      breadcrumb: names,
      menuSelect: "profile",
      subMenuSelect: "",
    };
  } else {
    menus = {
      breadcrumb: [
        { path: "/", breadcrumbName: path !== "/" ? "" : "Home" },
      ],
      menuSelect: "",
      subMenuSelect: "",
    };

    if (menuSelect.length === 1) {
      menus = getIsMenu(menuSelect[0], path);
    }
  }

  return menus;
};

const getMenuSelect = (list, path) => {
  const isSelect = [];
  const keys = [];
  let isMenuSelect = [];

  if (path !== "/") {
    list.map((menu) => {
      if (menu.sub_menus && menu.sub_menus.length) {
        menu.sub_menus.map((sub) => {
          // let check_path = path === sub.path
          let is_path = sub.path.search(path.split("/")[1]);
          if (is_path >= 0) {
            const check_key = keys.find((key) => key === menu.key_slug);
            if (!check_key) {
              keys.push(menu.key_slug);
              isSelect.push(menu);
            }
          }
          return sub;
        });
      } else {
        let is_path = menu.path.search(path);
        if (is_path >= 0) {
          const check_key = keys.find((key) => key === menu.key_slug);
          isSelect.push(menu);
          if (!check_key) {
            keys.push(menu.key_slug);
            isSelect.push(menu);
          }
        }
      }
      return menu;
    });
  }

  if (isSelect.length > 1) {
    // eslint-disable-next-line array-callback-return
    isSelect.map((menu) => {
      if (menu.sub_menus && menu.sub_menus.length) {
        // eslint-disable-next-line array-callback-return
        menu.sub_menus.map((sub) => {
          let check_path = "/" + path.split("/")[1] === sub.path;
          if (check_path) {
            isMenuSelect.push(menu);
          }
        });
      }
    });
  } else {
    isMenuSelect = isSelect;
  }
  return isMenuSelect;
};

const getIsMenu = (list, path) => {
  const breadcrumb = [
    {
      path: list.path,
      breadcrumbName: list.name,
      key: "",
    },
  ];

  let menu = {};
  list.sub_menus.length &&
    list.sub_menus.map((sub) => {
      if (sub.key_slug === path.split("/")[1]) {
        menu = sub;
      }
      return sub;
    });

  breadcrumb.push({
    path: menu.path,
    breadcrumbName: menu.name,
    key: menu.key_slug,
  });

  let dataBreadcrumb = setBreadcrumb(breadcrumb, path);

  let menuSelect = {
    breadcrumb: dataBreadcrumb,
    menuSelect: menu.key_slug,
    subMenuSelect: list.key_slug,
  };

  return menuSelect;
};

const setBreadcrumb = (list, path) => {
  const breadcrumbNames = {
    create: "เพิ่มข้อมูล",
    edit: "แก้ไขข้อมูล",
    view: "ดูข้อมูล",
  };

  const lengthPath = path.split("/");
  let lists = list;

  if (lists.length !== lengthPath.length) {
    if (
      lengthPath[lengthPath.length - 2] === "edit" ||
      lengthPath[lengthPath.length - 2] === "view"
    ) {
      lengthPath.pop();
    }

    const breadCrumbName = breadcrumbNames[lengthPath[lengthPath.length - 1]];

    if (breadCrumbName || lengthPath[lengthPath.length - 1] === "") {
      if (lengthPath[lengthPath.length - 1] !== "") {
        lists.push({
          path: lengthPath[lengthPath.length - 1],
          breadcrumbName: breadCrumbName,
          key: lengthPath[lengthPath.length - 1],
        });
      }
    } else {
      lists.push({
        path: lengthPath[lengthPath.length - 1],
        breadcrumbName: "Page error",
        key: lengthPath[lengthPath.length - 1],
      });
    }
  }

  return lists;
};
