import React from 'react'
import { connect } from 'react-redux'
import {
  Form,
  Input,
  message,
  Radio,
  Skeleton,
  Modal,
  Row,
  Col,
  Space,
  Button,
  Tooltip,
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import Footeractionlayout from 'components/layouts/Footeractionlayout'
import { createData, readData, updateData } from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'
import { resizeUpload } from 'actions/file'
import { partnergroups } from 'schemas'
import iconErr from 'images/icon-error@3x.png'
import iconCorrect from 'images/correct@3x.png'

const formRef = React.createRef()
const text = <span>ระบุเฉพาะ 0-9, a-z และ - เท่านั้น</span>
const textValidName = (
  <span>ระบุเฉพาะ a-z, A-Z, 0-9, -, _ และ เว้นวรรค เท่านั้น</span>
)

class Formlayout extends React.Component {
  state = {
    isDirty: false,
    tempData: {},

    errorMsg: '',
    isCreated: false,
    isCreateError: false,
    isCancel: false,
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.readData(this.props.match.params.id).then((res) => {
        let tempData = {
          name: res?.data?.name || '',
          key_slug: res?.data?.key_slug || '',
          status: res?.data?.status,
          id: res?.data?.id,
        }
        this.setState({ tempData })
      })
    }
  }

  handleCancel = () => this.setState({ isCancel: false })

  handleCancelForm = () => {
    this.setState({ isCancel: true })
  }

  handleSubmit = (value) => {
    if (this.state.isDirty === false) {
      this.setState({ isDirty: true })
      const requestFrom = {
        name: { th: value.nameth, en: value.nameen },
        key_slug: value.key,
        status: value.status,
      }
      message.loading('Save in progress..', 0)
      this.props.createData(requestFrom).then((res) => {
        message.destroy()
        if (res?.code === 200) {
          this.setState({ isDirty: true, isCreated: true }, () => {
            localStorage.setItem('page', 1)
            localStorage.setItem('search', '')
          })
        } else {
          this.setState({
            isDirty: false,
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    }
  }

  handleUpdate = (value) => {
    if (this.state.isDirty === false) {
      this.setState({ isDirty: true })
      const requestFrom = {
        name: { th: value.nameth, en: value.nameen },
        key_slug: value.key,
        status: value.status,
      }

      message.loading('Save in progress..', 0)
      this.props
        .updateData(requestFrom, this.state.tempData.id)
        .then((response) => {
          message.destroy()
          if (response?.code === 200) {
            this.setState({ isDirty: true, isCreated: true })
          } else {
            this.setState({
              isDirty: false,
              isCreateError: true,
              errorMsg: response?.message,
            })
          }
        })
    }
  }

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag)
    this.setState({ tags })
  }

  validatorKey = (rule, value, callback) => {
    formRef.current.setFieldsValue({
      key: value,
    })

    if (!value && !this.props.match.params.id)
      return callback('Key is required !!')

    const pattern = /^[a-z0-9-]*$/
    if (!pattern.test(value)) return callback('Invalid data format !!')
    return callback()
  }

  checkValidEng = (rule, value) => {
    if (value === ' ') {
      formRef.current.setFieldsValue({
        nameen: '',
      })
    } else {
      formRef.current.setFieldsValue({
        nameen: value,
      })
    }
    if (value === null || value === '') {
      return Promise.reject('Partner group name is required !!')
    } else {
      if (!/^([A-Za-z0-9-_ ]{1,})?$/.test(value)) {
        return Promise.reject('Invalid data format !!')
      } else {
        return Promise.resolve()
      }
    }
  }

  render() {
    const { dataList, isLoading } = this.props

    return isLoading === true ? (
      <Skeleton className="App-form" loading={isLoading} active></Skeleton>
    ) : (
        <Form
          ref={formRef}
          layout="vertical"
          className="App-form"
          onFinish={
            this.props.match.params.id ? this.handleUpdate : this.handleSubmit
          }
        >
          <Form.Item style={{ marginTop: '-20px' }}>
            <h1 className="topic">
              {this.props.match.params.id
                ? 'แก้ไขข้อมูลกลุ่มพาร์ทเนอร์'
                : 'เพิ่มข้อมูลกลุ่มพาร์ทเนอร์'}
            </h1>
          </Form.Item>
          <Row gutter={[20, 15]}>
            <Col span={12}>
              <Form.Item
                style={{ marginTop: '-15px' }}
                name="nameth"
                label="ชื่อ"
                rules={[
                  {
                    required: true,
                    message: 'Partner group name is required !!',
                  },
                ]}
                initialValue={
                  this.props.match.params.id && dataList.name
                    ? dataList.name.th
                    : ''
                }
              >
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                style={{ marginTop: '-15px' }}
                name="nameen"
                label="Name"
                rules={[
                  {
                    required: true,
                    validator: this.checkValidEng,
                    transform: (value) => {
                      let text = ''
                      if (!/^([A-Za-z0-9-_ ]{1,})?$/.test(value)) {
                        text = value.substring(0, value.length - 1)
                      } else {
                        text = value
                      }
                      return text
                    },
                  },
                ]}
                initialValue={
                  this.props.match.params.id && dataList.name
                    ? dataList.name.en
                    : ''
                }
              >
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
            <Col span={1}>
              <Tooltip
                className="sil-toottip-custom-width"
                placement="topRight"
                title={textValidName}
              >
                <ExclamationCircleOutlined
                  style={{ fontSize: '18px', marginTop: '20px' }}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={[20, 15]}>
            <Col span={12}>
              <Form.Item
                name="key"
                label="คีย์"
                rules={[
                  {
                    validator: this.validatorKey,
                    required: this.props.match.params.id ? false : true,
                    transform: (value) => {
                      let text = ''
                      if (!/^([A-Za-z0-9-]{1,})?$/.test(value)) {
                        text = value.substring(0, value.length - 1)
                      } else {
                        text = value
                      }
                      return text.toLowerCase()
                    },
                  },
                ]}
                initialValue={this.props.match.params.id ? dataList.key_slug : ''}
              >
                <Input
                  autoComplete="off"
                  disabled={this.props.match.params.id ? true : false}
                />
              </Form.Item>
            </Col>
            {!this.props.match.params.id && (
              <Col span={12}>
                <Tooltip placement="top" title={text}>
                  <ExclamationCircleOutlined
                    style={{ fontSize: '18px', marginTop: '38px' }}
                  />
                </Tooltip>
              </Col>
            )}
          </Row>
          <Row gutter={[20, 15]}>
            <Col span={12}>
              <Form.Item
                label="สถานะ"
                name="status"
                initialValue={
                  this.props.match.params.id
                    ? dataList.status === 'active'
                      ? 'active'
                      : 'inactive'
                    : 'active'
                }
              >
                <Radio.Group>
                  <Radio value={'active'}>ใช้งาน</Radio>
                  <Radio value={'inactive'}>ไม่ใช้งาน</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item shouldUpdate={true}>
            <Footeractionlayout
              onCancle={this.handleCancelForm}
            ></Footeractionlayout>
          </Form.Item>

          <Modal
            visible={this.state.isCreated}
            footer={false}
            closable={false}
            centered={true}
            width={'420px'}
          >
            <div
              style={{
                height: '220px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <img alt="example" src={iconCorrect} width={48} height={48} />
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginTop: '15px',
                  }}
                >
                  บันทึกข้อมูลสำเร็จแล้ว
              </Col>
                <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                  กดตกลงเพื่อออกจากหน้านี้
              </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: '18px',
                    textAlign: 'center',
                    marginTop: '30px',
                  }}
                >
                  <Space>
                    <Button
                      type="primary"
                      style={{ width: '170px' }}
                      onClick={() => {
                        this.props.history.goBack()
                      }}
                    >
                      ตกลง
                  </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Modal>
          <Modal
            visible={this.state.isCreateError}
            footer={false}
            closable={false}
            centered={true}
            width={'500px'}
          >
            <div
              style={{
                height: '220px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <img src={iconErr} width={48} height={48} alt="Icon err" />
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginTop: '15px',
                  }}
                >
                  บันทึกข้อมูลไม่สำเร็จ
              </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: '18px',
                    textAlign: 'center',
                    marginTop: '15px',
                  }}
                >
                  {this.state.errorMsg}
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: '18px',
                    textAlign: 'center',
                    marginTop: '30px',
                  }}
                >
                  <Space>
                    <Button
                      type="primary"
                      style={{ width: '170px' }}
                      onClick={() => {
                        this.setState({ isCreateError: false })
                      }}
                    >
                      ตกลง
                  </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Modal>
          <Modal
            visible={this.state.isCancel}
            footer={false}
            closable={false}
            centered={true}
            width={'420px'}
          >
            <div
              style={{
                height: '180px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Row>
                <Col
                  span={24}
                  style={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  ยืนยันการยกเลิก
              </Col>
                <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                  หากคุณต้องการยกเลิก
              </Col>
                <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                  กรุณากด ยืนยัน
              </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: '18px',
                    textAlign: 'center',
                    marginTop: '30px',
                  }}
                >
                  <Space>
                    <Button
                      style={{ width: '170px' }}
                      onClick={() => this.handleCancel()}
                    >
                      ยกเลิก
                  </Button>
                    <Button
                      type="primary"
                      style={{ width: '170px' }}
                      onClick={() => {
                        this.props.history.goBack()
                      }}
                    >
                      ยืนยัน
                  </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Modal>
        </Form>
      )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(partnergroups, state),
  dataList: getResource(partnergroups, state),
})

const mapDispatchToProps = (dispatch) => ({
  createData: (data) => dispatch(createData(partnergroups, data)),
  readData: (id) => dispatch(readData(partnergroups, id)),
  updateData: (data, id) => dispatch(updateData(partnergroups, data, id)),
  addImageData: (params) => dispatch(resizeUpload(params)),
})

const PartherGroupListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Formlayout)

export default PartherGroupListWithConnect
