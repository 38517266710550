/* eslint-disable jsx-a11y/anchor-is-valid */

import "../../../App.less";

import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  Upload,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { getList, updateData } from "actions/collection";

import { UserOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { getResourceLoading } from "selectors";
import iconCorrect from "images/correct@3x.png";
import iconErr from "images/icon-error@3x.png";
import { mapErrMsg } from "../../../utils/common";
import { resizeUpload } from "actions/file";
import styled from "styled-components";
import { useHistory } from "react-router";
import { userprofile } from "schemas";

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const url = `${process.env.REACT_APP_AUTHEN}`;

const ProfileForm = (props) => {
  const history = useHistory();
  const formRef = useRef();
  const [reqTel, setReqTel] = useState({ status: "success" });
  const [reqImg, setReqImg] = useState({ status: "success" });
  const [openModal, setOpenModal] = useState({
    openCancel: false,
    openErr: false,
    openSuccess: false,
  });
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [dataProfile, setDataProfile] = useState();
  const [fileList, setFileList] = useState([]);
  const [msgErr, setMsgErr] = useState([]);
  const [loading, setLoading] = useState(false);

  const labelPassword = (
    <BoxLabel display="flex">
      รหัสผ่าน
      <TextLabel paddingLeft="8px" paddingRight="8px">
        |
      </TextLabel>
      <a onClick={() => history.push(`/profile/edit/password/${props.match.params.id}`)}>
        <TextLabel color="#C4C4C4" textDecorationLine="underline">
          เปลี่ยน
      </TextLabel>
      </a>
    </BoxLabel>
  );

  const labelImg = (
    <BoxLabel display="flex" flexDirection="column">
      <div>รูปภาพโปรไฟล์</div>
      <TextLabel fontWeight="200" fontSize="12px">
        (ขนาดภาพแนะนำ 180x180 px | ขนาดไม่เกิน 2 mb)
      </TextLabel>
    </BoxLabel>
  );

  const blockSpecialCharecter = (rule, value) => {
    if (value) {
      const newValue = value.replace(/[^a-zA-Zก-๙]/g, "");
      formRef.current.setFieldsValue({ [rule.field]: newValue });
    }
    if (!value || !value.replace(/[^a-zA-Zก-๙]/g, "")) {
      return Promise.reject(`${rule.message}`);
    } else {
      return Promise.resolve();
    }
  };

  const mobileFormat = (e) => {
    const value = e.target.value.replace(/[^\d]/g, "");
    const isnum = /^\d+$/.test(value);
    if (isnum) {
      const frist = value.substring(0, 3);
      const middle = value.substring(3, 6);
      const last = value.substring(6, 10);
      if (value.length > 6) {
        formRef.current.setFieldsValue({
          mobile: `${frist} ${middle} ${last}`,
        });
      } else if (value.length > 3) {
        formRef.current.setFieldsValue({ mobile: `${frist} ${middle}` });
      } else if (value.length > 0) {
        formRef.current.setFieldsValue({ mobile: `${frist}` });
      }
    } else {
      formRef.current.setFieldsValue({ mobile: "" });
    }
    if (value && value.replace(/ +/g, "").length !== 10) {
      setReqTel({ status: "error", text: "Phone number is invalid  !!" });
    } else {
      setReqTel({ status: "success" });
    }
  };

  const onFinish = (value) => {
    setReqImg({ status: "success" });
    if (!loading && reqTel.status === "success") {
      message.loading("Save in progress..", 0);
      updateProfile(value);
    }
  };

  const onPreview = async (file) => {
    if (file.url) {
      file.url = `${file.url.split("?w=")[0]}?w=360`;
    } else if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    !isLt2M
      ? setReqImg({
        status: "error",
        text: (
          <BoxMargin marginTop="-6px">The image is too big size.</BoxMargin>
        ),
      })
      : setReqImg({ status: "success" });
    return isLt2M;
  };

  const onChangeImg = ({ fileList }) => {
    const isLt2M = fileList[0]?.size && fileList[0].size / 1024 / 1024 < 2;
    setFileList(isLt2M ? fileList : []);
  };

  const updateProfile = async (value) => {
    await setLoading(true);
    const data = JSON.stringify({
      first_name: value.first_name,
      last_name: value.last_name,
      mobile: value.mobile ? value.mobile.replace(/ +/g, "") : "",
      image_profile:
        typeof value.image_profile === "string"
          ? value.image_profile
          : value.image_profile.fileList.length === 1
            ? value.image_profile.fileList[0].originFileObj.url
            : "",
    });
    await props.updateData(data).then((res) => {
      message.destroy();
      if (res?.code === 200) {
        setOpenModal({ ...openModal, openSuccess: true, openConfirm: false });
        localStorage.setItem(
          "user_name",
          `${value.first_name} ${value.last_name}`
        );
        localStorage.setItem(
          "user_profile_image",
          typeof value.image_profile === "string"
            ? value.image_profile
            : value.image_profile.fileList.length === 1
              ? value.image_profile.fileList[0].originFileObj.url
              : ""
        );
      } else {
        message.destroy();
        const arrError = mapErrMsg(res.errors);
        setMsgErr(arrError);
        setOpenModal({ ...openModal, openErr: true });
      }
    });
    await setLoading(false);
  };

  useEffect(() => {
    props.readData().then((res) => {
      if (res?.code === 200) {
        setDataProfile(res.data);
        if (res.data.image_profile) {
          setFileList([
            {
              uid: -1,
              name: "Profileimage.png",
              status: "done",
              url: `${res.data.image_profile}?w=160`,
            },
          ]);
        } else {
          setFileList([]);
        }

        const mobile = res.data.mobile
          // eslint-disable-next-line no-useless-escape
          ? res.data.mobile.replace(/(\d{3})\-?(\d{3})\-?(\d{4})/, "$1 $2 $3")
          : "";
        formRef.current.setFieldsValue({ ...res.data, mobile });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Skeleton className="App-form" loading={!dataProfile} active>
        {dataProfile && (
          <Form
            layout="vertical"
            className="App-form"
            onFinish={onFinish}
            ref={formRef}
          >
            <Form.Item>
              <BoxMargin marginTop="-20px">
                <h1 className="topic">แก้ไขข้อมูลส่วนตัว</h1>
              </BoxMargin>
            </Form.Item>

            <Row gutter={[20, 0]}>
              <Col span={24}>
                <Form.Item
                  name="image_profile"
                  label={labelImg}
                  validateStatus={reqImg.status}
                  help={reqImg.text}
                >
                  <Upload
                    beforeUpload={beforeUpload}
                    className="upload-profile"
                    listType="picture-card"
                    style={{ borderRadius: "50%" }}
                    fileList={fileList}
                    onPreview={onPreview}
                    onChange={onChangeImg}
                    type="picture"
                    accept="image/png,image/jpeg"
                    customRequest={props.addImageData}
                  >
                    {fileList.length >= 1 ? null : (
                      <div style={{ borderRadius: "50%" }}>
                        <TextLabel color="#DCD6D6" fontSize="30px">
                          <UserOutlined />
                        </TextLabel>
                      </div>
                    )}
                  </Upload>
                </Form.Item>
              </Col>

              <Col span={12}>
                <BoxMargin marginTop="-10px">
                  <Form.Item
                    name="first_name"
                    label="ชื่อ"
                    rules={[
                      {
                        required: true,
                        message: "Firstname is required !!",
                        validator: blockSpecialCharecter,
                      },
                    ]}
                  >
                    <Input autoComplete="off" maxLength={255} />
                  </Form.Item>
                </BoxMargin>
              </Col>

              <Col span={12}>
                <BoxMargin marginTop="-10px">
                  <Form.Item
                    name="last_name"
                    label="นามสกุล"
                    rules={[
                      {
                        required: true,
                        message: "Lastname is required !!",
                        validator: blockSpecialCharecter,
                      },
                    ]}
                  >
                    <Input autoComplete="off" maxLength={255} />
                  </Form.Item>
                </BoxMargin>
              </Col>

              <Col span={12}>
                <Form.Item label="อีเมล">{dataProfile.email || "-"}</Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  onChange={mobileFormat}
                  name="mobile"
                  label="หมายเลขโทรศัพท์"
                  validateStatus={reqTel.status}
                  help={reqTel.text}
                >
                  <Input maxLength={12} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label={labelPassword}>
                  <div className="profile-password">00000000</div>
                </Form.Item>
              </Col>

              <Col span={12} />

              <Col span={12}>
                <Form.Item label="กลุ่มผู้ใช้งาน">
                  {dataProfile.user_group_data.name || "-"}
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="สิทธิ์การเข้าใช้งาน">
                  {dataProfile.role_data.name || "-"}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item shouldUpdate={true}>
              <Space>
                <Button
                  style={{ width: "12vmax" }}
                  htmlType="button"
                  onClick={() =>
                    setOpenModal({ ...openModal, openCancel: true })
                  }
                >
                  ยกเลิก
                </Button>
                <Button
                  style={{ width: "12vmax" }}
                  type="primary"
                  htmlType="submit"
                >
                  บันทึก
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}
      </Skeleton>

      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img
          alt="Preview profile"
          style={{ width: "100%", marginTop: "20px" }}
          src={`${previewImage}`}
        />
      </Modal>

      <Modal
        visible={openModal.openCancel}
        footer={false}
        closable={false}
        centered={true}
        width={"420px"}
      >
        <BoxModel height="180px">
          <Row>
            <Col span={24}>
              <HeaderCancel>ยืนยันการยกเลิก</HeaderCancel>
            </Col>
            <Col span={24}>
              <TextModel>หากคุณต้องการยกเลิก</TextModel>
            </Col>
            <Col span={24}>
              <TextModel>กรุณากด ยืนยัน</TextModel>
            </Col>
            <Col span={24}>
              <BoxButton>
                <Space>
                  <Button
                    style={{ width: "170px" }}
                    onClick={() =>
                      setOpenModal({ ...openModal, openCancel: false })
                    }
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "170px" }}
                    onClick={() => history.goBack()}
                  >
                    ยืนยัน
                  </Button>
                </Space>
              </BoxButton>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal
        visible={openModal.openErr}
        footer={false}
        closable={false}
        centered={true}
        width={"528px"}
      >
        <BoxModel minHeight="300px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="example" src={iconErr} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>แก้ไขข้อมูลไม่สำเร็จ</HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="15px">
                {msgErr.map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="40px">
                <Space>
                  <Button
                    onClick={() =>
                      setOpenModal({ ...openModal, openErr: false })
                    }
                    type="primary"
                    style={{ width: "170px" }}
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal
        visible={openModal.openSuccess}
        footer={false}
        closable={false}
        centered={true}
        width={"528px"}
      >
        <BoxModel height="300px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="example" src={iconCorrect} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>แก้ไขข้อมูลสำเร็จ</HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel>กด 'ตกลง' เพื่อออกจากหน้านี้</TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="40px">
                <Space>
                  <Button
                    onClick={() => {
                      setOpenModal({ ...openModal, openSuccess: false });
                      history.goBack();
                    }}
                    type="primary"
                    style={{ width: "170px" }}
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(userprofile, state),
});

const mapDispatchToProps = (dispatch) => ({
  readData: (id) => dispatch(getList(userprofile, id, url)),
  updateData: (data, id) => dispatch(updateData(userprofile, data, id, url)),
  addImageData: (params) => dispatch(resizeUpload(params)),
});

const ProfileFormConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileForm);

export default ProfileFormConnect;

const BoxMargin = styled.div`
  margin-top: ${(props) => props.marginTop};
`;

const HeaderCancel = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;
const BoxButton = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: 30px;
`;

const BoxModel = styled.div`
  min-height: ${(props) => props.minHeight};
  height: ${(props) => props.height};
  justify-content: center;
  align-items: center;
  display: flex;
`;
const HeaderModel = styled.div`
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 8px;
  margin-top: 15px;
`;
const TextModel = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: ${(props) => props.marginTop};
`;
const BoxLabel = styled.div`
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.flexDirection};
`;

const TextLabel = styled.div`
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
  color: ${(props) => props.color};
  text-decoration-line: ${(props) => props.textDecorationLine};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
`;
