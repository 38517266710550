import React from 'react'
import { Divider, Row, Col, Space, Button, Empty, Skeleton } from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  IssuesCloseOutlined,
  RetweetOutlined,
  PrinterOutlined,
  VideoCameraAddOutlined,
  LoadingOutlined,
  BlockOutlined,
} from '@ant-design/icons'

import ModalSnapshot from 'components/ModalSnapshot'
import ModalTimelapse from 'components/modalTimelapse'

const Viewlayout = (props) => {
  const {
    textTitle,
    textEdit,
    textDelete,
    textPrint,
    viewData,
    isLoading,
    permission,
    textLoadingSnapshot,
    loadingSnapshot,
    showModal,
    isModalVisible,
    showModalSnapShot,
    textSnapshot,
    handleOkModelSnapshot,
    handleCancelModelSnapshot,
    handleCreateSnapshot,
    closeModal,
    modalSnapshotSuccess,
    snapshotForm,
    modalSnapshotError,
    SnapshotError,
    SnapshotSuccess,
    textTimelapse,
    loadingTimelapse,
    showModalWarning,
    modalTime,
    loadingButtonTimelapse,
    showModalTimelapse,
    closeModalTime,
    handleOkModalTimelapse,
    handleCancelTimelapse,
    createTimelapse,
    modalSuccessTimelapse,
    modalFailedTimelapse,
    closeModalSuccess,
    closeModalFailed,
    createTimelapseTextStatus,
    createSnapshotTextStatus,
    createSnapshotStatus,
    timelapseForm,
  } = props

  const data =
    typeof viewData === 'object'
      ? Object.keys(viewData).length === 0
        ? undefined
        : viewData
      : viewData

  return (
    <>
      <Row style={{ marginTop: '-20px' }}>
        <Col span={10} style={{ width: '100%' }}>
          <h1 className='topic'> {textTitle} </h1>
        </Col>
        {data && (
          <Col className='topic-right'>
            <Space>
              {permission?.can_update && props.createSnapshot && (
                <h2>
                  {loadingSnapshot === true ? (
                    <LoadingOutlined />
                  ) : (
                    <BlockOutlined />
                  )}

                  <Button
                    type='link'
                    onClick={loadingSnapshot ? () => {} : showModalSnapShot}
                    disabled={loadingSnapshot ? true : false}
                  >
                    {loadingSnapshot === true
                      ? textLoadingSnapshot
                      : textSnapshot}
                  </Button>
                </h2>
              )}
              {permission?.can_update && props.iconPrint === 'print' && (
                <h2>
                  <PrinterOutlined />
                  <Button type='link' onClick={props.onClickPrint}>
                    {textPrint}
                  </Button>
                </h2>
              )}

              {permission?.can_update && textTimelapse && (
                <h2>
                  {loadingButtonTimelapse === true ? (
                    <LoadingOutlined />
                  ) : (
                    <VideoCameraAddOutlined />
                  )}
                  {/* ถ้า State loadingTimelapse เป็น true ให้เป็น กำลังสร้าง ถ้าไม่ ก็เป็น สร้าง Timelapse */}
                  <Button
                    type='link'
                    onClick={showModalTimelapse}
                    disabled={loadingButtonTimelapse ? true : false}
                  >
                    {loadingButtonTimelapse === true
                      ? loadingTimelapse
                      : textTimelapse}
                  </Button>
                </h2>
              )}
              {permission?.can_update && (
                <h2>
                  {props.iconEdit === 'reply' ? (
                    <RetweetOutlined />
                  ) : (
                    props.iconEdit === 'edit' && <EditOutlined />
                  )}
                  <Button type='link' onClick={props.onEdit}>
                    {textEdit}
                  </Button>
                </h2>
              )}
              {permission?.can_delete && (
                <h2>
                  {props.iconDelete === 'closeissue' ? (
                    <IssuesCloseOutlined />
                  ) : (
                    props.iconDelete === 'delete' && <DeleteOutlined />
                  )}
                  <Button type='link' onClick={props.onDelete}>
                    {textDelete}
                  </Button>
                </h2>
              )}
            </Space>
          </Col>
        )}
        <Divider style={{ marginTop: '5px' }} />
        {data ? (
          <>{props.children}</>
        ) : (
          <Skeleton className='App-form' loading={isLoading} active>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Empty />
            </div>
          </Skeleton>
        )}
      </Row>
      <ModalSnapshot
        snapshotForm={snapshotForm}
        showModal={showModal}
        isModalVisible={isModalVisible}
        handleCancelModelSnapshot={handleCancelModelSnapshot}
        handleOkModelSnapshot={handleOkModelSnapshot}
        handleCreateSnapshot={handleCreateSnapshot}
        closeModal={closeModal}
        modalSnapshotSuccess={modalSnapshotSuccess}
        modalSnapshotError={modalSnapshotError}
        SnapshotSuccess={SnapshotSuccess}
        SnapshotError={SnapshotError}
        loading={loadingSnapshot}
        createSnapshotTextStatus={createSnapshotTextStatus}
        createSnapshotStatus={createSnapshotStatus}
      />
      <ModalTimelapse
        timelapseForm={timelapseForm}
        createTimelapseTextStatus={createTimelapseTextStatus}
        showModalWarning={showModalWarning}
        viewData={viewData}
        closeModalTime={closeModalTime}
        modalTime={modalTime}
        handleOkModalTimelapse={handleOkModalTimelapse}
        handleCancelTimelapse={handleCancelTimelapse}
        createTimelapse={createTimelapse}
        modalSuccessTimelapse={modalSuccessTimelapse}
        modalFailedTimelapse={modalFailedTimelapse}
        closeModalSuccess={closeModalSuccess}
        closeModalFailed={closeModalFailed}
      />
    </>
  )
}
export default Viewlayout
