import React from 'react'
import { connect } from 'react-redux'
import {
  Form,
  Modal,
  Skeleton,
  message,
  Radio,
  Row,
  Col,
  Space,
  Button,
  Input,
  Tooltip,
} from 'antd'

import Footeractionlayout from 'components/layouts/Footeractionlayout'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { createData, readData, updateData } from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'
import { resizeUpload } from 'actions/file'
import { tourismtypes } from 'schemas'
import iconErr from 'images/icon-error@3x.png'
import iconCorrect from 'images/correct@3x.png'

const formRef = React.createRef()
const text = <span>ระบุเฉพาะ 0-9, a-z และ - เท่านั้น</span>
const textValidName = (
  <span>ระบุเฉพาะ a-z, A-Z, 0-9, -, _ และ เว้นวรรค เท่านั้น</span>
)

class Formslidemenulayout extends React.Component {
  state = {
    isDirty: true,
    errorMsg: '',
    isCreated: false,
    isCreateError: false,
    isCancel: false,
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.readData(this.props.match.params.id)
    }
  }

  clickCancle = () => {
    this.setState({ isCancel: true })
  }

  handleCancel = () => this.setState({ previewVisible: false, isCancel: false })

  checkValid = (rule, value) => {
    formRef.current.setFieldsValue({
      key_slug: value,
    })
    if (value === null || value === '') {
      return Promise.reject('Key is required !!')
    } else {
      if (!/^([a-z0-9-]{1,})?$/.test(value)) {
        return Promise.reject('Invalid data format !!')
      } else {
        return Promise.resolve()
      }
    }
  }

  handleSubmit = (value) => {
    const { isDirty } = this.state
    if (isDirty) {
      this.setState({ isDirty: false })
      const requestFrom = {
        key_slug: value.key_slug,
        name: {
          th: value.key_nameth,
          en: value.key_nameen,
        },
        status: value.status,
      }
      message.loading('Save in progress..', 0)
      this.props.createData(requestFrom).then((res) => {
        message.destroy()
        if (res?.code === 200) {
          this.setState({ isDirty: true, isCreated: true }, () => {
            localStorage.setItem('page', 1)
            localStorage.setItem('search', '')
          })
        } else {
          this.setState({
            isDirty: true,
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    }
  }

  handleUpdate = (value) => {
    const { dataList } = this.props
    const { isDirty } = this.state
    if (isDirty) {
      this.setState({ isDirty: false })
      const requestFrom = {
        key_slug: value.key_slug,
        name: {
          th: value.key_nameth,
          en: value.key_nameen,
        },
        status: value.status,
      }
      message.loading('Save in progress..', 0)
      this.props.updateData(requestFrom, dataList.id).then((response) => {
        message.destroy()
        if (response?.code === 200) {
          this.setState({ isDirty: true, isCreated: true })
        } else {
          this.setState({
            isDirty: true,
            isCreateError: true,
            errorMsg: response?.message,
          })
        }
      })
    }
  }

  checkValidEng = (rule, value) => {
    if (value === ' ') {
      formRef.current.setFieldsValue({
        key_nameen: '',
      })
    } else {
      formRef.current.setFieldsValue({
        key_nameen: value,
      })
    }
    if (value === null || value === '') {
      return Promise.reject('Tourism type name is required !!')
    } else {
      if (!/^([A-Za-z0-9-_ ]{1,})?$/.test(value)) {
        return Promise.reject('Invalid data format !!')
      } else {
        return Promise.resolve()
      }
    }
  }

  render() {
    const { dataList, isLoading } = this.props
    const { previewVisible, previewImage } = this.state
    return (
      <>
        {isLoading === true ? (
          <Skeleton className="App-form" loading={isLoading} active></Skeleton>
        ) : (
          <Form
            ref={formRef}
            layout="vertical"
            className="App-form"
            onFinish={
              this.props.match.params.id ? this.handleUpdate : this.handleSubmit
            }
          >
            <Form.Item style={{ marginTop: '-20px' }}>
              <h1 className="topic">
                {this.props.match.params.id
                  ? 'แก้ไขข้อมูลประเภทการท่องเที่ยว'
                  : 'เพิ่มข้อมูลประเภทการท่องเที่ยว'}
              </h1>
            </Form.Item>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  label="ชื่อ"
                  name="key_nameth"
                  rules={[
                    {
                      required: true,
                      message: 'Tourism type name is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.name
                      ? dataList.name.th
                      : null
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label="Name"
                  name="key_nameen"
                  rules={[
                    {
                      required: true,
                      validator: this.checkValidEng,
                      transform: (value) => {
                        let text = ''
                        if (!/^([A-Za-z0-9-_ ]{1,})?$/.test(value)) {
                          text = value.substring(0, value.length - 1)
                        } else {
                          text = value
                        }
                        return text
                      },
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.name
                      ? dataList.name.en
                      : null
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={1}>
                <Tooltip
                  className="sil-toottip-custom-width"
                  placement="topRight"
                  title={textValidName}
                >
                  <ExclamationCircleOutlined
                    style={{ fontSize: '18px', marginTop: '38px' }}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  label="คีย์"
                  name="key_slug"
                  rules={[
                    {
                      validator: this.checkValid,
                      required: this.props.match.params.id ? false : true,
                      transform: (value) => {
                        let text = ''
                        if (!/^([A-Za-z0-9-]{1,})?$/.test(value)) {
                          text = value.substring(0, value.length - 1)
                        } else {
                          text = value
                        }
                        return text.toLowerCase()
                      },
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id ? dataList.key_slug : ''
                  }
                >
                  <Input
                    autoComplete="off"
                    disabled={this.props.match.params.id ? true : false}
                  />
                </Form.Item>
              </Col>
              {!this.props.match.params.id && (
                <Col span={12}>
                  <Tooltip placement="top" title={text}>
                    <ExclamationCircleOutlined
                      style={{ fontSize: '18px', marginTop: '38px' }}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  label="สถานะ"
                  name="status"
                  rules={[{ required: false }]}
                  initialValue={
                    this.props.match.params.id
                      ? dataList.status === 'active'
                        ? 'active'
                        : 'inactive'
                      : 'active'
                  }
                >
                  <Radio.Group>
                    <Radio value={'active'}>ใช้งาน</Radio>
                    <Radio value={'inactive'}>ไม่ใช้งาน</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item shouldUpdate={true}>
              <Footeractionlayout
                onCancle={this.clickCancle}
              ></Footeractionlayout>
            </Form.Item>
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={this.handleCancel}
              width={800}
            >
              <img
                alt="example"
                style={{ width: '100%', marginTop: '20px' }}
                src={previewImage}
              />
            </Modal>
            <Modal
              visible={this.state.isCreated}
              footer={false}
              closable={false}
              centered={true}
              width={'420px'}
            >
              <div
                style={{
                  height: '220px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <img
                      alt="example"
                      src={iconCorrect}
                      width={48}
                      height={48}
                    />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    บันทึกข้อมูลสำเร็จแล้ว
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กดตกลงเพื่อออกจากหน้านี้
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.goBack()
                        }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.isCreateError}
              footer={false}
              closable={false}
              centered={true}
              width={'500px'}
            >
              <div
                style={{
                  height: '220px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <img alt="example" src={iconErr} width={48} height={48} />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    บันทึกข้อมูลไม่สำเร็จ
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    {this.state.errorMsg}
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.setState({ isCreateError: false })
                        }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.isCancel}
              footer={false}
              closable={false}
              centered={true}
              width={'500px'}
            >
              <div
                style={{
                  height: '180px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    ยืนยันการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    หากคุณต้องการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กรุณากด ยืนยัน
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        style={{ width: '170px' }}
                        onClick={() => this.handleCancel()}
                      >
                        ยกเลิก
                      </Button>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.goBack()
                        }}
                      >
                        ยืนยัน
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
          </Form>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(tourismtypes, state),
  dataList: getResource(tourismtypes, state),
})

const mapDispatchToProps = (dispatch) => ({
  createData: (data) => dispatch(createData(tourismtypes, data)),
  readData: (id) => dispatch(readData(tourismtypes, id)),
  updateData: (data, id) => dispatch(updateData(tourismtypes, data, id)),
  addImageData: (params) => dispatch(resizeUpload(params)),
})

const SlideListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Formslidemenulayout)

export default SlideListWithConnect
