import * as CONST from 'actions/collection'

export default (state = {}, action) => {
  switch (action.type) {
    case CONST.GET_LIST_FAILED:
    case CONST.CREATE_DATA_FAILED:
    case CONST.UPDATE_DATA_FAILED:
    case CONST.READ_DATA_FAILED:
    case CONST.DELETE_DATA_FAILED:
      return action

    case CONST.GET_LIST_REQUEST:
    case CONST.CREATE_DATA_REQUEST:
    case CONST.UPDATE_DATA_REQUEST:
    case CONST.READ_DATA_REQUEST:
    case CONST.DELETE_DATA_REQUEST:
      return {}
    default:
      return state
  }
}
