import React, { useState, useEffect, useContext } from 'react'
import {
  CloudOutlined,
  DatabaseOutlined,
  InsertRowBelowOutlined,
  ToolOutlined,
  UserOutlined,
  CheckOutlined,
  DashboardOutlined
} from '@ant-design/icons'
import Viewlayout from 'components/layouts/Viewlayout'
import {
  Row,
  Col,
  Modal,
  Skeleton,
  Space,
  Button,
  message
} from 'antd'

import { readData, deleteData } from 'actions/collection'
import { connect } from "react-redux";
import { getResourceLoading } from 'selectors'
import { roles } from 'schemas'
import { DeleteOutlined } from '@ant-design/icons'
import iconDeleted from 'images/correct@3x.png'
import iconErr from 'images/icon-error@3x.png'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { mapErrMsg } from '../../../utils/common'
import { MenuAuth } from "contexts/menuAuth";
import '../../../App.less'


const RoleAndPermissionView = (props) => {
  const history = useHistory();
  const pathName = history.location.pathname;
  const roleId = pathName.substring(pathName.lastIndexOf("/") + 1)
  const [openDelete, setOpenDelete] = useState(false)
  const [openDeleteSuccess, setOpenDeleteSuccess] = useState(false)
  const [openDeleteErr, setOpenDeleteErr] = useState({ open: false, errorMsg: '' })
  const [dataRoles, setDataRoles] = useState();
  const [msgErr, setMsgErr] = useState([])
  const permissionAuthor = useContext(MenuAuth);
  const [actionPermission, setActionPermission] = useState(undefined);

  const icon = {
    'dashboard': <DashboardOutlined />,
    'cms': <InsertRowBelowOutlined />,
    'opt': <ToolOutlined />,
    'user-management': <UserOutlined />,
    'master-data': <DatabaseOutlined />,
    "ftp-management": <CloudOutlined />
  }

  const getRoles = () => {
    props.readData(roleId).then((res) => {
      if (res.code === 200) {
        setDataRoles(res.data)
      } else {
        setDataRoles('')
      }
    })
  }

  const onDeleteRoles = () => {
    message.loading('Delete in progress..', 0)
    props.deleteData(roleId).then((res) => {
      message.destroy()
      setOpenDelete(false)
      if (res?.code === 200) {
        setOpenDeleteSuccess(true)
      } else {
        const arrError = mapErrMsg(res?.errors, 'สิทธิ์เข้าใช้งาน')
        setMsgErr(arrError.length > 0 ? arrError : [res?.status])
        setOpenDeleteErr({ open: true })
      }
    })
  }

  useEffect(() => {
    getRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const author = permissionAuthor?.menuAuth?.find((menu) => menu?.key_slug === "users-management");
    const menu = author?.sub_menus?.find((sub) => sub?.key_slug === "roles-and-permissions");
    setActionPermission(menu?.role_permission);
  }, [permissionAuthor])

  return (
    <>
      <div className="App-form">
        <Viewlayout
          viewData={dataRoles}
          isLoading={props.isLoading}
          permission={actionPermission}
          textTitle="ข้อมูลสิทธิ์การเข้าใช้งาน"
          iconEdit="edit"
          iconDelete="delete"
          textEdit="แก้ไขข้อมูล"
          textDelete="ลบข้อมูล"
          onEdit={() => history.push(`/roles-and-permissions/edit/${roleId}`)}
          onDelete={() => setOpenDelete(true)}
        >
          <Skeleton className="App-form" loading={props.isLoading} active>
            {dataRoles &&
              <>
                <div id="mainDiv">
                  <Row>
                    <Col className="sil-break-word" span={24}>
                      <h1>ชื่อสิทธิ์เข้าใช้งาน</h1>
                      {dataRoles.name || "-"}
                    </Col>
                  </Row>

                  <Row>
                    <Col className="sil-break-word" span={24}>
                      <TextLabel>กลุ่มผู้ใช้งาน</TextLabel>
                      {dataRoles.user_groups_data.name || "-"}
                    </Col>
                  </Row>

                  <Row>
                    <Col className="sil-break-word" span={24}>
                      <TextLabel>รายละเอียด</TextLabel>
                      {dataRoles.description || "-"}
                    </Col>
                  </Row>

                  <Row>
                    <Col className="sil-break-word" span={24}>
                      <TextLabel>สถานะสิทธิ์เข้าใช้งาน</TextLabel>
                      {dataRoles.status === "active" ? 'ใช้งาน' : 'ไม่ใช้งาน'}
                    </Col>
                  </Row>
                </div>
              </>}
          </Skeleton>
        </Viewlayout>

        {(dataRoles && !props.isLoading) &&
          <Row>
            <Col className="sil-break-word" span={24}>
              <TextLabel>สิทธิ์การใช้งานระบบ</TextLabel>
              <TableHeader>
                <Row justify="space-between" >
                  <Col span={12}>เมนูหลัก / เมนูย่อย</Col>
                  <Col span={12} style={{ textAlign: 'center' }}>การอนุญาตให้เข้าถึง</Col>
                </Row>
              </TableHeader>
              {
                dataRoles.roles_menus.map((item, index) => {
                  return (
                    <TableBody key={index}>
                      <Row justify="space-between" >
                        <Col span={12} >
                          <TextHeader1>
                            <Icon>
                              {icon[item.icon]}
                            </Icon>
                            {item.name}
                          </TextHeader1>
                        </Col>
                        <Col span={12}>
                          <TextHeader2>
                            <Roles width="62px">ดูข้อมูล</Roles>
                            <Roles width="62px">เพิ่มข้อมูล</Roles>
                            <Roles width="62px">แก้ไขข้อมูล</Roles>
                            <Roles width="62px">ลบข้อมูล</Roles>
                          </TextHeader2>
                        </Col>

                        {item.sub_menus.map((sub, indexSub) => {
                          const lastItem = item.sub_menus[item.sub_menus.length - 1].key_slug
                          return (
                            <React.Fragment key={indexSub}>
                              <Col span={12}>
                                <TextSubMenu borderBottom={sub.key_slug !== lastItem && '1px solid 	#E8E8E8'}>{sub.name}</TextSubMenu>
                              </Col>

                              <Col span={12}>
                                <BoxPermission borderBottom={sub.key_slug !== lastItem && '1px solid 	#E8E8E8'} >
                                  <Roles width="14px">{sub.roles_permission.can_read === true ? <CheckOutlined style={{ color: 'green' }} /> : <TextEmpty>-</TextEmpty>}</Roles>
                                  <Roles width="14px">{sub.roles_permission.can_create === true ? <CheckOutlined style={{ color: 'green' }} /> : <TextEmpty>-</TextEmpty>}</Roles>
                                  <Roles width="14px">{sub.roles_permission.can_update === true ? <CheckOutlined style={{ color: 'green' }} /> : <TextEmpty>-</TextEmpty>}</Roles>
                                  <Roles width="14px">{sub.roles_permission.can_delete === true ? <CheckOutlined style={{ color: 'green' }} /> : <TextEmpty>-</TextEmpty>}</Roles>
                                </BoxPermission>
                              </Col>
                            </React.Fragment>
                          )
                        })}
                      </Row>
                    </TableBody>
                  )
                })
              }
            </Col>
          </Row>
        }
      </div>

      <Modal visible={openDelete} onOk={onDeleteRoles} onCancel={() => setOpenDelete(false)}>
        <Col className="modal-delete">
          <DeleteOutlined style={{ fontSize: '40px' }} />
        </Col>
        <Col className="modal-delete">ต้องการลบข้อมูลใช่หรือไม่</Col>
      </Modal>

      <Modal
        visible={openDeleteSuccess}
        footer={false}
        closable={false}
        centered={true}
        width={'500px'}
      >
        <BoxModel height="180px">
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img
                alt="example"
                src={iconDeleted}
                width={48}
                height={48}
              />
            </Col>
            <Col span={24}>
              <HeaderModel>
                ลบข้อมูลเสร็จสิ้น
              </HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() => history.push('/role-and-permission')}
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal
        visible={openDeleteErr.open}
        footer={false}
        closable={false}
        centered={true}
        width={'500px'}
      >
        <BoxModel height="220px">
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img alt="example" src={iconErr} width={48} height={48} />
            </Col>
            <Col span={24} >
              <HeaderModel>
                ลบข้อมูลไม่สำเร็จ
              </HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="15px">
                {msgErr.map((item, index) => {
                  return <p key={index}>{item}</p>
                })}
              </TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() => setOpenDeleteErr({ open: false, errorMsg: '' })}
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(roles, state),
})

const mapDispatchToProps = (dispatch) => ({
  readData: (id) => dispatch(readData(roles, id, process.env.REACT_APP_AUTHOR)),
  deleteData: (id) => dispatch(deleteData(roles, id, process.env.REACT_APP_AUTHOR)),
})

const RoleViewWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleAndPermissionView)

export default RoleViewWithConnect


const TableHeader = styled.div`
border-bottom: 1px solid #DCD6D6;
 background-color: #FAFAFA;
 padding: 18px 50px;
`
const TableBody = styled.div`
border: 1px solid #DCD6D6;
 border-top: 0px;
padding: 16px;
padding-bottom: 5px;
`
const TextLabel = styled.div`
margin-top: 30px;
font-size: 16.38px;
font-weight: 500;
color:black;
`

const TextHeader1 = styled.div`
color: black;
 padding-right: 8px;
  display: flex;
`
const TextHeader2 = styled.div`
display: flex;
 justify-content: space-around;
`
const TextSubMenu = styled.div`
color: black;
font-weight: 200;
margin-left: 40px;
padding-left:10px ;
display: flex;
height: 30px;
align-items: center;
border-bottom: ${(props) => (props.borderBottom)};
`
const BoxPermission = styled.div`
display: flex ;
justify-content: space-around;
height: 30px;
align-items: center;
border-bottom: ${(props) => (props.borderBottom)};
`
const Icon = styled.div`
padding-right: 8px;
`

const BoxModel = styled.div`
height: ${(props) => (props.height)};
justify-content: center;
align-items: center;
display: flex;
`
const HeaderModel = styled.div`
 font-size: 24px;
font-weight: bold;
text-align: center;
 margin-top: 15px;
`
const TextModel = styled.div`
font-size: 18px;
text-align: center ;
margin-top: ${(props) => (props.marginTop)};
`

const Roles = styled.div`
width: ${(props) => (props.width)};
display: flex;
justify-content: center;
`
const TextEmpty = styled.div`
color: #DCD6D6;
`