import React from 'react'
import { httpClient } from 'HttpClient'
import { connect } from 'react-redux'
import {
  Form,
  Upload,
  Modal,
  Input,
  Skeleton,
  message,
  Radio,
  Row,
  Col,
  Space,
  Button,
  Select,
  Tooltip,
} from 'antd'

import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import Footeractionlayout from 'components/layouts/Footeractionlayout'
import { createData, readData, updateData } from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'
import { resizeUpload } from 'actions/file'
import { blogs } from 'schemas'
import iconErr from 'images/icon-error@3x.png'
import iconCorrect from 'images/correct@3x.png'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import FroalaEditor from 'react-froala-wysiwyg'
import axios from 'axios'

const text_keywords = (
  <div>
    <div>- ใช้ได้ทั้งภาษาไทย และภาษาอังกฤษ</div>
    <div>
      - ใช้เครื่องหมายคอมม่า (,) คั่นระหว่างคีย์เวิร์ดแต่ละคำ โดยไม่ต้องเว้นวรรค
    </div>
    <div>- ใส่คีย์เวิร์ดที่เกี่ยวข้องไม่เกิน 10 คีย์เวิร์ด</div>
  </div>
)

const text = <span>ระบุเฉพาะ 0-9, a-z และ - เท่านั้น</span>

const { TextArea } = Input

const { Option } = Select

const formRef = React.createRef()

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str
  return str.substring(0, index) + chr + str.substring(index + 1)
}

class Formslidemenulayout extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileListCover: [],
    fileListThumbnail: [],

    //temp
    fileListTemp: [],
    fileListENTemp: [],
    fileListLandTHTemp: [],
    fileListLandENTemp: [],
    image_TypeTemp: '',
    saveImage: [],
    unsaveImage: [],

    categoryOption: [],
    categoryType: [],

    isRemove: false,
    form: [],
    isDirty: true,
    tempData: {},
    isNameFill: true,
    isDetailFill: true,
    image_type: '',
    errorMsg: '',
    isCreated: false,
    isCreateError: false,
    isCancel: false,

    linkCheck: '',

    contentTH: '',
    contentEN: '',
  }

  componentDidMount() {
    this.getCatagoryList()
    if (this.props.match.params.id) {
      this.props.readData(this.props.match.params.id).then((res) => {
        this.state.fileListCover.push(this.setImageToState(res.data.cover))
        this.state.fileListThumbnail.push(
          this.setImageToState(res.data.thumbnail)
        )
        this.setState({
          contentTH: res.data.content?.th,
          contentEN: res.data.content?.en,
        })
      })
    }
  }

  setImageToState(value) {
    return {
      uid: -1,
      name: 'Slideimage.png',
      status: 'done',
      url: value,
    }
  }

  clickCancle = () => {
    this.setState({ isCancel: true })
  }

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    })
  }

  handleCancel = () => this.setState({ previewVisible: false, isCancel: false })

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  handleCoverChange = ({ fileList }) =>
    this.setState({ fileListCover: fileList })
  handleThumbnailChange = ({ fileList }) =>
    this.setState({ fileListThumbnail: fileList })

  handleSubmit = (value) => {
    const { isDirty, contentEN, contentTH } = this.state

    if (isDirty) {
      this.setState({ isDirty: false })
      const requestFrom = {
        key_slug: value.key_slug,
        title: {
          en: value.titleEN,
          th: value.titleTH,
        },
        content: {
          en: contentEN,
          th: contentTH,
        },
        description: {
          en: value.descriptionEN,
          th: value.descriptionTH,
        },
        status: value.status,
        cover: value.cover_url.file.originFileObj.url,
        thumbnail: value.Thumbnail_url.file.originFileObj.url,
        category: value.category,
        keywords: value.keywords,
        views: 0,
      }

      message.loading('Save in progress..', 0)
      this.props.createData(requestFrom).then((res) => {
        message.destroy()
        if (res?.code === 201) {
          this.setState({ isDirty: true, isCreated: true }, () => {
            localStorage.setItem('page', 1)
            localStorage.setItem('search', '')
            this.handleSaveFile()
          })
        } else {
          this.setState({
            isDirty: true,
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    }
  }

  handleSaveFile = () => {
    // eslint-disable-next-line array-callback-return
    this.state.saveImage.map((value, key) => {
      axios
        .post(`${process.env.REACT_APP_MEDIAURL_SAVE}`, { url: value })
        .then()
        .catch((error) => {
          throw error
        })
    })
  }

  handleUpdate = (value) => {
    const { isDirty, contentEN, contentTH } = this.state
    if (isDirty) {
      this.setState({ isDirty: false })
      const requestFrom = {
        key_slug: value.key_slug,
        title: {
          en: value.titleEN,
          th: value.titleTH,
        },
        content: {
          en: contentEN,
          th: contentTH,
        },
        description: {
          en: value.descriptionEN,
          th: value.descriptionTH,
        },
        status: value.status,
        cover: value.cover_url.file
          ? value.cover_url.file.originFileObj.url
          : value.cover_url[0].url,
        thumbnail: value.Thumbnail_url.file
          ? value.Thumbnail_url.file.originFileObj.url
          : value.Thumbnail_url[0].url,
        category: value.category,
        keywords: value.keywords,
        views: 0,
      }
      message.loading('Save in progress..', 0)
      this.props
        .updateData(requestFrom, this.props.match.params.id)
        .then((response) => {
          message.destroy()
          if (response?.code === 200) {
            this.setState({ isDirty: true, isCreated: true })
            this.handleSaveFile()
            this.handleUnSaveFile()
          } else {
            this.setState({
              isDirty: true,
              isCreateError: true,
              errorMsg: response?.message,
            })
          }
        })
    }
  }

  handleUnSaveFile = () => {
    if (this.state.unsaveImage.length > 0) {
      axios
        .post(`${process.env.REACT_APP_MEDIAURL_UNSAVE}`, {
          urls: this.state.unsaveImage,
        })
        .then((response) => { })
        .catch((error) => {
          throw error
        })
    }
  }

  getCatagoryList = () => {
    return httpClient
      .get(`${process.env.REACT_APP_BASEURL}/master/blog-categories`)
      .then((response) => {
        this.setState({ categoryType: response?.data.data })
        for (let i = 0; i < this.state.categoryType.length; i++) {
          this.state.categoryOption.push(
            <Option
              key={this.state.categoryType[i].key_slug}
              value={this.state.categoryType[i].key_slug}
            >
              {this.state.categoryType[i].name.th}
            </Option>
          )
        }

        if (!this.props.match.params.id) {
          formRef.current.setFieldsValue({
            category: undefined,
          })
        }
      })
      .catch((error) => {
        throw error
      })
  }

  validatorContentEN = (rule, value, callback) => {
    value = this.state.contentEN
    if (value === '') return callback('Content is required !!')
    else return callback()
  }

  validatorContentTH = (rule, value, callback) => {
    value = this.state.contentTH
    if (value === '') return callback('Content is required !!')
    else return callback()
  }

  checkImg = (rule, value) => {
    if (this.props.match.params.id) {
      if (value[0]) {
        return Promise.resolve()
      }
    }
    if (
      (value && value.file.type === 'image/png') ||
      (value && value.file.type === 'image/jpeg')
    ) {
      if (this.props.match.params.id) {
        if (value && value.length) {
          return Promise.resolve()
        } else if (value.fileList && value.fileList.length !== 0) {
          return Promise.resolve()
        }
      } else {
        if (value.fileList && value.fileList.length !== 0) {
          return Promise.resolve()
        }
      }
      if (this.state.isRemove === true) {
        return this.setState({ isRemove: false })
      } else {
        return Promise.reject(
          <div style={{ marginTop: '-5px' }}>Please insert photo !!</div>
        )
      }
    } else {
      if (value && value.fileList.length !== 0) {
        value.file = []
        value.fileList = []
        this.setState({ fileList: [] })
        if (value.fileList && value.fileList.length === 0) {
          return Promise.reject(
            <div style={{ marginTop: '-5px' }}>
              Please insert file type .png, .jpeg only !
            </div>
          )
        }
      } else {
        if (this.state.isRemove === true) {
          return this.setState({ isRemove: false })
        } else {
          return Promise.reject(
            <div style={{ marginTop: '-5px' }}>Please insert photo !!</div>
          )
        }
      }
    }
  }

  onLinkChange = async (value) => {
    await this.setState({ linkCheck: value })
  }

  onRemoveImg = () => {
    this.setState({ isRemove: true })
  }

  validatorKey = (rule, value, callback) => {
    formRef.current.setFieldsValue({
      key_slug: value,
    })

    if (!value && !this.props.match.params.id)
      return callback('Key is required !!')

    const pattern = /^[a-z0-9-]*$/
    if (!pattern.test(value)) return callback('Invalid data format !!')
    return callback()
  }

  validatorKeyWords = (rule, value, callback) => {
    let textValue = value

    if (textValue[0] === ' ') {
      textValue = setCharAt(textValue, 0, '')
    }

    if (
      textValue[value.length - 1] === ',' &&
      textValue[value.length - 2] === ' '
    ) {
      textValue = setCharAt(textValue, value.length - 2, ',')
      textValue = setCharAt(textValue, value.length - 1, '')
    }

    if (
      textValue[value.length - 1] === ' ' &&
      textValue[value.length - 2] === ','
    ) {
      textValue = setCharAt(textValue, value.length - 2, ',')
      textValue = setCharAt(textValue, value.length - 1, '')
    }

    if (
      textValue[value.length - 1] === ',' &&
      textValue[value.length - 2] === ','
    ) {
      textValue = setCharAt(textValue, value.length - 2, ',')
      textValue = setCharAt(textValue, value.length - 1, '')
    }

    formRef.current.setFieldsValue({
      keywords: textValue,
    })

    if (!textValue) return callback('Keywords is required !!')

    const pattern = /^[a-z0-9, ก-๏]*$/
    if (!pattern.test(textValue)) return callback('Invalid data format !!')
    return callback()
  }

  checkValidEng = (rule, value) => {
    if (value === ' ') {
      formRef.current.setFieldsValue({
        titleEN: '',
      })
    } else {
      formRef.current.setFieldsValue({
        titleEN: value,
      })
    }
    if (value === null || value === '') {
      return Promise.reject('Title is required !!')
    } else {
      if (
        !/^([A-Za-z0-9- `~!@#$%^&*()_|+\-=?;:'",.<>{}[\]/]{1,})?$/.test(value)
      ) {
        return Promise.reject('Invalid data format !!')
      } else {
        return Promise.resolve()
      }
    }
  }

  render() {
    const { dataList, isLoading } = this.props
    const {
      previewVisible,
      previewImage,
      fileListCover,
      fileListThumbnail,
    } = this.state

    const uploadCover = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">อัพโหลด</div>
      </div>
    )

    const uploadThumbnail = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">อัพโหลด</div>
      </div>
    )

    const config = {
      pastePlain: true,
      quickInsertEnabled: false,
      attribution: false,
      imageUploadMethod: 'POST',
      key: process.env.REACT_APP_FROALA_KEY,
      imageUploadParam: 'sampleFile',
      imageUploadURL: process.env.REACT_APP_MEDIAURL,
      videoUploadURL: process.env.REACT_APP_MEDIAURL,
      height: '250px',
      toolbarButtons: {
        moreText: {
          buttons: ['bold', 'italic', 'underline'],
        },
        moreParagraph: {
          buttons: ['alignLeft', 'alignCenter', 'alignRight'],
        },
        moreRich: {
          buttons: ['insertLink', 'insertImage', 'insertVideo'],
        },
      },
      requestWithCredentials: false,
      events: {
        'image.uploaded': (response) => {
          let dataRes = JSON.parse(response)
          this.state.saveImage.push(dataRes.link)
        },
        'image.removed': (img) => {
          if (this.props.match.params.id) {
            this.state.unsaveImage.push(img[0].src)
          } else {
            this.state.saveImage.splice(img[0].src.indexOf() + 1, 1)
          }
        },
      },
    }

    return (
      <>
        {isLoading === true ? (
          <Skeleton className="App-form" loading={isLoading} active></Skeleton>
        ) : (
          <Form
            ref={formRef}
            layout="vertical"
            className="App-form"
            onFinish={
              this.props.match.params.id ? this.handleUpdate : this.handleSubmit
            }
          >
            <Form.Item style={{ marginTop: '-20px' }}>
              <h1 className="topic">
                {this.props.match.params.id
                  ? 'แก้ไขข้อมูลบทความ'
                  : 'เพิ่มข้อมูลบทความ'}
              </h1>
            </Form.Item>
            <Row gutter={[15, 20]}>
              <Col span={12}>
                <Form.Item
                  name="category"
                  label="ประเภท"
                  rules={[
                    {
                      required: true,
                      message: 'Category is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id ? dataList.category : null
                  }
                >
                  <Select placeholder="เลือก">
                    {this.state.categoryOption}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15, 20]}>
              <Col span={12}>
                <Form.Item
                  name="titleTH"
                  label="หัวข้อ"
                  rules={[
                    {
                      required: true,
                      message: 'Title is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id ? dataList.title?.th : ''
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="titleEN"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      validator: this.checkValidEng,
                      transform: (value) => {
                        let text = ''
                        if (
                          !/^([A-Za-z0-9- `~!@#$%^&*()_|+\-=?;:'",.<>{}[\]/]{1,})?$/.test(
                            value
                          )
                        ) {
                          text = value.substring(0, value.length - 1)
                        } else {
                          text = value
                        }
                        return text
                      },
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id ? dataList.title?.en : ''
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15, 20]}>
              <Col span={11}>
                <Form.Item
                  name="key_slug"
                  label="คีย์"
                  rules={[
                    {
                      validator: this.validatorKey,
                      required: this.props.match.params.id ? false : true,
                      transform: (value) => {
                        let text = ''
                        if (!/^([A-Za-z0-9-]{1,})?$/.test(value)) {
                          text = value.substring(0, value.length - 1)
                        } else {
                          text = value
                        }
                        return text.toLowerCase()
                      },
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id ? dataList.key_slug : ''
                  }
                >
                  <Input
                    style={{ width: '103%' }}
                    autoComplete="off"
                    disabled={this.props.match.params.id ? true : false}
                  />
                </Form.Item>
              </Col>
              {!this.props.match.params.id && (
                <Col span={1}>
                  <Tooltip placement="top" title={text}>
                    <ExclamationCircleOutlined
                      style={{
                        fontSize: '18px',
                        marginTop: '38px',
                        marginLeft: '18px',
                      }}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
            <Row gutter={[15, 20]}>
              <Col span={12}>
                <Form.Item
                  name="descriptionTH"
                  label="รายละเอียด"
                  rules={[
                    {
                      required: true,
                      message: 'Description is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id ? dataList.description?.th : ''
                  }
                >
                  <TextArea
                    autoComplete="off"
                    autoSize={{ minRows: 5, maxRows: 5 }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="descriptionEN"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: 'Description is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id ? dataList.description?.en : ''
                  }
                >
                  <TextArea
                    autoComplete="off"
                    autoSize={{ minRows: 5, maxRows: 5 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15, 0]} justify="center">
              <Col span={12}>
                <Form.Item
                  className="form-slide-menu-size"
                  label={
                    <Row>
                      <Col>Cover</Col>
                      <Col style={{ color: 'red' }}>&nbsp;*</Col>
                    </Row>
                  }
                >
                  <p
                    style={{
                      fontSize: '12px',
                      marginTop: '-20px',
                    }}
                  >
                    (ขนาดภาพแนะนำ 1360x720 px)
                  </p>
                </Form.Item>
                <Form.Item

                  style={{ marginTop: '-35px' }}
                  name="cover_url"
                  rules={[
                    {
                      required: true,
                      validator: this.checkImg,
                    },
                  ]}
                  initialValue={this.props.match.params.id ? fileListCover : ''}
                >
                  <Upload
                    className={'blog-upload-cover'}
                    listType="picture-card"
                    fileList={fileListCover}
                    onPreview={this.handlePreview}
                    onRemove={this.onRemoveImg}
                    onChange={this.handleCoverChange}
                    customRequest={this.props.addImageData}
                    type="picture"
                    accept="image/png,image/jpeg"
                  >
                    {fileListCover.length >= 1 ? null : uploadCover}
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="form-slide-menu-size"
                  label={
                    <Row>
                      <Col>Thumbnail</Col>
                      <Col style={{ color: 'red' }}>&nbsp;*</Col>
                    </Row>
                  }
                >
                  <p
                    style={{
                      fontSize: '12px',
                      marginTop: '-20px',
                    }}
                  >
                    (ขนาดภาพแนะนำ 420x240 px)
                  </p>
                </Form.Item>
                <Form.Item
                  style={{ marginTop: '-35px' }}
                  name="Thumbnail_url"
                  rules={[
                    {
                      required: true,
                      validator: this.checkImg,
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id ? fileListThumbnail : ''
                  }
                >
                  <Upload
                    className={'blog-upload-thumbnail'}
                    listType="picture-card"
                    fileList={fileListThumbnail}
                    onPreview={this.handlePreview}
                    onRemove={this.onRemoveImg}
                    onChange={this.handleThumbnailChange}
                    customRequest={this.props.addImageData}
                    type="picture"
                    accept="image/png,image/jpeg"
                  >
                    {fileListThumbnail.length >= 1 ? null : uploadThumbnail}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15, 20]}>
              <Col span={12}>
                <Form.Item
                  name="contentTH"
                  label="คอนเทนท์"
                  rules={[
                    {
                      validator: this.validatorContentTH,
                      required: this.props.match.params.id ? false : true,
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id ? dataList.category : undefined
                  }
                >
                  <FroalaEditor
                    tag="textarea"
                    config={config}
                    model={this.props.match.params.id && this.state.contentTH}
                    onModelChange={(e) => this.setState({ contentTH: e })}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="contentEN"
                  label="Content"
                  rules={[
                    {
                      validator: this.validatorContentEN,
                      required: this.props.match.params.id ? false : true,
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id ? dataList.category : undefined
                  }
                >
                  <FroalaEditor
                    tag="textarea"
                    config={config}
                    model={this.props.match.params.id && this.state.contentEN}
                    onModelChange={(e) => this.setState({ contentEN: e })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15, 20]}>
              <Col span={11}>
                <Form.Item
                  name="keywords"
                  label="คีย์เวิร์ด"
                  rules={[
                    {
                      validator: this.validatorKeyWords,
                      required: true,
                      transform: (value) => {
                        let text = ''
                        if (!/^([a-z0-9, ก-๏]{1,})?$/.test(value)) {
                          text = value.substring(0, value.length - 1)
                        } else {
                          text = value
                        }
                        return text.toLowerCase()
                      },
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id ? dataList.keywords : ''
                  }
                >
                  <Input style={{ width: '103%' }} autoComplete="off" />
                </Form.Item>
              </Col>
              {!this.props.match.params.id && (
                <Col span={1}>
                  <Tooltip placement="top" title={text_keywords}>
                    <ExclamationCircleOutlined
                      style={{
                        fontSize: '18px',
                        marginTop: '38px',
                        marginLeft: '18px',
                      }}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
            {/* <Row gutter={[15, 20]}>
                <Col span={12}>
                  <Form.Item
                    name="views"
                    label="ยอดวิวเริ่มต้น"
                    rules={[
                      {
                        required: false,
                        message: 'Views is required !!'
                      },
                    ]}
                    initialValue={
                      this.props.match.params.id ? dataList.key_slug : ''
                    }
                  >
                    <InputNumber
                      style={{width: '100%'}}
                      defaultValue={0}
                      min={0}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
              </Row> */}
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Form.Item
                  label="สถานะ"
                  name="status"
                  initialValue={
                    this.props.match.params.id
                      ? dataList.status === 'active'
                        ? 'active'
                        : 'inactive'
                      : 'active'
                  }
                >
                  <Radio.Group>
                    <Radio value={'active'}>ใช้งาน</Radio>
                    <Radio value={'inactive'}>ไม่ใช้งาน</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Footeractionlayout
                onCancle={this.clickCancle}
              ></Footeractionlayout>
            </Form.Item>
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={this.handleCancel}
              width={800}
            >
              <img
                alt="example"
                style={{ width: '100%', marginTop: '20px' }}
                src={previewImage}
              />
            </Modal>
            <Modal
              visible={this.state.isCreated}
              footer={false}
              closable={false}
              centered={true}
              width={'420px'}
            >
              <div
                style={{
                  height: '220px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <img
                      alt="example"
                      src={iconCorrect}
                      width={48}
                      height={48}
                    />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    บันทึกข้อมูลสำเร็จแล้ว
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กดตกลงเพื่อออกจากหน้านี้
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.goBack()
                        }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.isCreateError}
              footer={false}
              closable={false}
              centered={true}
              width={'500px'}
            >
              <div
                style={{
                  height: '220px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <img alt="example" src={iconErr} width={48} height={48} />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    บันทึกข้อมูลไม่สำเร็จ
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    {this.state.errorMsg}
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.setState({ isCreateError: false })
                        }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.isCancel}
              footer={false}
              closable={false}
              centered={true}
              width={'500px'}
            >
              <div
                style={{
                  height: '180px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    ยืนยันการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    หากคุณต้องการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กรุณากด ยืนยัน
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        style={{ width: '170px' }}
                        onClick={() => this.handleCancel()}
                      >
                        ยกเลิก
                      </Button>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.goBack()
                        }}
                      >
                        ยืนยัน
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
          </Form>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(blogs, state),
  dataList: getResource(blogs, state),
})

const mapDispatchToProps = (dispatch) => ({
  createData: (data) => dispatch(createData(blogs, data)),
  readData: (id) => dispatch(readData(blogs, id)),
  updateData: (data, id) => dispatch(updateData(blogs, data, id)),
  addImageData: (params) => dispatch(resizeUpload(params)),
})

const SlideListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Formslidemenulayout)

export default SlideListWithConnect
