import React from 'react'

import ReactApexChart from 'react-apexcharts'

const ApexChart = ({ data }) => {
  const series = data.series
  const options = {
    chart: {
      width: 450,
      type: 'pie',
    },
    labels: data.labels,
    // colors: ['#1A73E8', '#50b432'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 240,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width={450}
      />
    </div>
  )
}

export default ApexChart
