import * as CONST from 'actions/collection'
import { fromJS } from 'immutable'

const initialState = fromJS({})

const collectionReducer = (
  state = initialState,
  { type, schema, payload, pagination }
) => {
  switch (type) {
    case CONST.GET_LIST_REQUEST:
      return state.setIn([schema, 'isLoading'], true)
    case CONST.GET_LIST_FAILED:
      return state.setIn([schema, 'isLoading'], false)

    case CONST.GET_LIST_SUCCEED:
      let reqList = payload.result
      return state
        .setIn([schema, 'data_list'], reqList)
        .setIn([schema, 'pagination'], pagination)
        .setIn([schema, 'isLoading'], false)

    default:
      return state
  }
}

export default collectionReducer
