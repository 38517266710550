// import Cookies from 'js-cookie'

import {
  Badge,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  message,
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { deleteList, getList, updateData } from "actions/collection";
import { getCollectionLoading, getPagination, getStateResult } from "selectors";

import Actions from "components/action";
import DataTableLayout from "components/layouts/DataTableLayout";
import { FullDateConvert } from "../../../utils/DateTimeFormat.js";
import { Link } from "react-router-dom";
import { MenuAuth } from "contexts/menuAuth";
import React from "react";
import { cctv, revise } from "schemas";
import { connect } from "react-redux";
import { getPageShowing } from "utils/common.js";
import { httpClient } from "HttpClient";
import iconDeletedError from "images/icon-error@3x.png";
import iconDeletedSuccess from "images/correct@3x.png";
import iconErr from 'images/icon-error@3x.png'
import styled from 'styled-components'

class Slide extends React.Component {
  static contextType = MenuAuth;

  state = {
    page: 1,
    per_page: 15,
    search: "",
    onShowing: false,
    tagsList: [],
    groupsType: [],
    isDeleteError: false,
    isDeleteSuccess: false,
    errorMsg: "",
    actionPermission: undefined,

    //Modal
    openModalError: false,
    textHeader: '',
    textTitle: '',
  };

  componentDidMount() {
    getPageShowing();
    this.getTags();
    this.getGroupsList();
    if (
      localStorage.getItem("search") &&
      localStorage.getItem("currSearch") === window.location.pathname
    ) {
      this.setState({ search: localStorage.getItem("search") });
    }
    let params = {
      page: localStorage.getItem("page")
        ? localStorage.getItem("page")
        : this.state.page,
      per_page: localStorage.getItem("per_page")
        ? parseInt(localStorage.getItem("per_page"))
        : this.state.per_page,
      search:
        localStorage.getItem("search") &&
          localStorage.getItem("currSearch") === window.location.pathname
          ? String(localStorage.getItem("search"))
          : this.state.search,
    };

    if (sessionStorage.getItem('cctv') && sessionStorage.getItem('cctv') !== 'approve') {
      const session = sessionStorage.getItem('cctv')
      this.props.reviseData(session).then((res) => {
        sessionStorage.clear()
        this.props.history.replace('/cctv')
        this.props.getData(params);
        if (res?.code === 200) {
          this.setState({ openModalError: false, textHeader: '', textTitle: '' })
        } else if (res?.code === 422) {
          this.setState({ textHeader: 'พบข้อผิดพลาดในการทำงาน', textTitle: 'เนื่องจากไม่สามารถดำเนินการกลับไปแก้ไขได้ กรุณาติดต่อแอดมิน', openModalError: true })
        } else {
          this.setState({ textHeader: 'ไม่พบข้อมูลในระบบ', textTitle: 'เนื่องจากไม่มีรายการกล้อง CCTV นี้ กรุณาติดต่อแอดมิน', openModalError: true })
        }
      })
    } else {
      this.props.getData(params).then(() => sessionStorage.clear())
    }

  }

  getTags = () => {
    return httpClient
      .get(`${process.env.REACT_APP_BASEURL}/master/tags`)
      .then((response) => {
        this.setState({ tagsList: response.data.data });
        return this.state.tagsList;
      })
      .catch((error) => {
        throw error;
      });
  };

  getGroupsList = () => {
    return httpClient
      .get(`${process.env.REACT_APP_BASEURL}/master/cctv-groups`)
      .then((response) => {
        this.setState({ groupsType: response.data.data });
        return this.state.groupsType;
      })
      .catch((error) => {
        throw error;
      });
  };

  onSearch = (e) => {
    localStorage.setItem("page", 1);
    let params = {
      page: 1,
      per_page: localStorage.getItem("per_page")
        ? parseInt(localStorage.getItem("per_page"))
        : this.state.per_page,
      search: localStorage.getItem("search")
        ? localStorage.getItem("search")
        : this.state.search,
    };
    localStorage.setItem("search", this.state.search);
    localStorage.setItem("currSearch", window.location.pathname);
    this.props.getData(params);
  };

  setKeyword = (e) => {
    const value = e.target.value;
    localStorage.setItem("search", value);
    this.setState({ search: value });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
    this.deleteList();
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      delete_id: "",
    });
  };

  deleteList = (delete_id) => {
    const { pagination, dataList } = this.props;
    localStorage.setItem(
      "page",
      dataList.length === 1 && parseInt(localStorage.getItem("page")) > 1
        ? parseInt(localStorage.getItem("page")) - 1
        : this.state.page
    );
    const params = {
      page:
        dataList.length === 1 && pagination.current_page > 1
          ? pagination.current_page - 1
          : this.state.page,
      search: this.state.search,
      per_page: this.state.per_page,
    };

    message.loading("Delete in progress..", 0);
    this.props.deleteData(delete_id, params).then((res) => {
      if (res?.code === 200) {
        message.destroy();
        this.setState({
          delete_id: "",
          search: "",
          isDeleteSuccess: true,
        });
      } else {
        message.destroy();
        this.setState({
          isDeleteError: true,
          errorMsg: res?.message,
        });
      }
    });
  };

  getGroupsNameFormSlug = (groups) => {
    return groups ? (groups === "อุทยานฯ" ? ", " + groups : groups) : "";
  };

  columns = [
    {
      title: "#",
      dataIndex: "order",
      key: "order",
      width: "5%",
    },
    {
      title: "ชื่อ",
      dataIndex: "name",
      key: "name",
      width: "15%",
      // ellipsis: true,
    },
    {
      title: "คีย์",
      dataIndex: "key_slug",
      key: "key_slug",
      width: "15%",
      render: (key) => {
        return key ? key : "-";
      },
    },
    {
      title: "ลิงค์",
      dataIndex: "camera_url",
      key: "camera_url",
      ellipsis: true,
    },
    {
      title: "กลุ่ม",
      dataIndex: "groups",
      key: "groups",
      width: "12%",
      render: (groups) => {
        return groups && groups.length ? groups.join(", ") : "-";
      },
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "created_at",
      key: "created_at",
      width: "12%",
      render: (value) => {
        const formatDay = FullDateConvert(value);
        return formatDay;
      },
    },
    {
      title: "สถานะ",
      key: "status",
      dataIndex: "status",
      width: "10%",
      render: (status) => {
        return (
          <span>
            <Badge
              status={status === "active" ? "success" : "error"}
              text={status === "active" ? "ใช้งาน" : "ไม่ใช้งาน"}
              style={{
                color: status === "active" ? "#00c48c" : "#ff0000",
              }}
            />
          </span>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: "7%",
      render: (record) => {
        const newPermition = {
          ...this.state.actionPermission,
          can_update: record.status_approval === 'draft' || record.status_approval === 'revise' ? this.state.actionPermission?.can_update : false
        }
        return (
          <Actions
            record={{ id: record.id }}
            path="/cctv"
            onDelete={this.deleteList}
            history={this.props.history}
            permission={newPermition}
          />
        );
      },
    },
  ];

  getActionPermission = () => {
    const author = this.context?.menuAuth?.find(
      (menu) => menu?.key_slug === "cms"
    );
    const menu = author?.sub_menus?.find((sub) => sub?.key_slug === "cctv");

    !this.state.actionPermission &&
      this.setState({ actionPermission: menu?.role_permission });
  };

  render() {
    const { dataList, isLoading, pagination } = this.props;
    const { actionPermission } = this.state;

    return (
      <Form>
        <Row justify="space-between">
          <Col>
            <Input
              style={{ marginBottom: "30px", width: "20vmax" }}
              onChange={this.setKeyword}
              placeholder="ค้นหาชื่อกล้อง, คีย์"
              onPressEnter={this.onSearch}
              suffix={
                <SearchOutlined
                  onClick={() => this.onSearch()}
                  style={{ cursor: "pointer" }}
                />
              }
              value={this.state.search}
              autoComplete="on"
            ></Input>
          </Col>
          {actionPermission && actionPermission?.can_create && (
            <Col>
              <Button type="primary">
                <Link to="/cctv/create">
                  <PlusOutlined /> เพิ่มข้อมูล
                </Link>
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            {isLoading === true ? (
              <Skeleton
                className="App-form"
                loading={isLoading}
                active
              ></Skeleton>
            ) : (
              <DataTableLayout
                dataSource={dataList}
                columns={this.columns}
                isloading={isLoading}
                pagePagination={pagination}
                getData={this.props.getData}
                getPermission={this.getActionPermission}
              />
            )}
          </Col>
        </Row>
        <Modal
          visible={this.state.isDeleteSuccess}
          footer={false}
          closable={false}
          centered={true}
          width={"500px"}
        >
          <div
            style={{
              height: "180px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <img
                  alt="example"
                  src={iconDeletedSuccess}
                  width={48}
                  height={48}
                />
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                ลบข้อมูลเสร็จสิ้น
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  marginTop: "30px",
                }}
              >
                <Space>
                  <Button
                    type="primary"
                    style={{ width: "170px" }}
                    onClick={() => {
                      this.setState({ isDeleteSuccess: false });
                    }}
                  >
                    ตกลง
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          visible={this.state.isDeleteError}
          footer={false}
          closable={false}
          centered={true}
          width={"500px"}
        >
          <div
            style={{
              height: "220px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <img
                  alt="example"
                  src={iconDeletedError}
                  width={48}
                  height={48}
                />
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                ลบข้อมูลไม่สำเร็จ
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                {this.state.errorMsg}
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  marginTop: "30px",
                }}
              >
                <Space>
                  <Button
                    type="primary"
                    style={{ width: "170px" }}
                    onClick={() => {
                      this.setState({ isDeleteError: false });
                    }}
                  >
                    ตกลง
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          visible={this.state.openModalError}
          footer={false}
          closable={false}
          centered={true}
          width={'528px'}
        >
          <BoxModel height="309px">
            <Row>
              <Col span={24} style={{ textAlign: 'center', marginTop: '14px' }}>
                <img alt="Icon Error" src={iconErr} width={58} height={58} />
              </Col>
              <Col span={24}>
                <HeaderModel>
                  {this.state.textHeader}
                </HeaderModel>
              </Col>
              <Col span={24}>
                <TextModel marginTop="11px">
                  {this.state.textTitle}
                </TextModel>
              </Col>
              <Col span={24}>
                <TextModel marginTop="52px">
                  <Space>
                    <Button
                      onClick={() => { this.setState({ openModalError: false, textHeader: '', textTitle: '' }) }}
                      type="primary"
                      style={{ width: '168px' }}
                    >
                      ตกลง
                    </Button>
                  </Space>
                </TextModel>
              </Col>
            </Row>
          </BoxModel>
        </Modal>
      </Form>
    );
  }
}

const BoxModel = styled.div`
  height: ${(props) => (props.height)};
  justify-content: center;
  align-items: center;
  display: flex;
`

const TextModel = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: ${(props) => (props.marginTop)};
`

const HeaderModel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 52px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
`

const mapStateToProps = (state) => ({
  pagination: getPagination(cctv, state),
  isLoading: getCollectionLoading(cctv, state),
  dataList: getStateResult(cctv, state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: (params) => dispatch(getList(cctv, params)),
  deleteData: (id) => dispatch(deleteList(cctv, id)),
  reviseData: (id) => dispatch(updateData(revise, null, id, process.env.REACT_APP_CCTV_APPROVAL)),
});

const SlideListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Slide);

export default SlideListWithConnect;
