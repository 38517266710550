import { getToken } from './utils/common'

export const setRequestHeadersInterceptor = (config) => {
  let token = getToken('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}
