import React from 'react'
import GoogleMapReact from 'google-map-react'
import { PulseMarker, PulsePin } from 'components/layouts/PulseMarker.js'
import { SearchOutlined } from '@ant-design/icons'
import {
  Input,
} from 'antd'

class googleMap extends React.Component {
  state = {
    latitude: 14.975880414117205,
    longitude: 102.10182666778564,
    mapLoading: true,
    searchBox: '',
    map: '',
    isSearch: false,
    selectLat: 0,
    selectLng: 0,
    zoom: 14,
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          mapLoading: false,
        })
      }, error => {
        this.setState({
          mapLoading: false,
        })
      })
    }
  }

  getLocation = async (place, status) => {
    if (status === 'OK') {
      const address = place[0].formatted_address
      await this.state.geoCoderService.geocode({ 'placeId': place[0]?.place_id, language: 'EN' }, (results, status) => {
        if (status === 'OK') {
        }
      });
      return address
    }
  }

  handleApiLoaded = (map, maps) => {

    const input = document.getElementById('pac-input');

    this.setState({
      searchBox: new maps.places.SearchBox(input),
      service: new maps.places.PlacesService(map),
      geoCoderService: new maps.Geocoder(),
      autoCompleteService: new maps.places.AutocompleteService(),
      mapLoading: false,
    })
    this.state.searchBox.addListener('places_changed', () => this.getSelectPos())
  }

  getSelectPos = () => {
    this.setState({ isSearch: true })
    var places = this.state.searchBox.getPlaces()
    this.state.geoCoderService.geocode({ 'placeId': places[0].place_id }, (results, status) => {
      if (status === 'OK') {
        this.setState({ mapSearch: places[0].formatted_address, selectLat: results[0].geometry.location.lat(), selectLng: results[0].geometry.location.lng(), zoom: 12 })
      }
    })
  }

  onSetMarkerInMap = async (e) => {
    const myLatlng = { lat: e.lat, lng: e.lng };
    const request = { latLng: myLatlng, region: 'EN', language: 'en' }
    await this.state.geoCoderService.geocode(request, (place, status) => {
      this.props.getMap(e.lat, e.lng, place && place.length ? place[0].formatted_address : '')
      this.setState({ isSearch: false })
    })
  }

  setKeyword = (e) => {
    const value = e.target.value
    this.setState({ mapSearch: value })
  }

  render() {
    if (this.props.setOwnlocataions) {
      const myLatlng = { lat: this.props.latitude, lng: this.props.longitude };
      const request = { latLng: myLatlng, region: 'EN', language: 'en' }
      this.state.geoCoderService.geocode(request, (place, status) => {
        this.props.getMap(this.props.latitude, this.props.longitude, place && place.length ? place[0].formatted_address : '')
        this.setState({ isSearch: false })
      })
    }
    return (
      <div style={{ height: '60vh', width: '100%' }} >
        <>
          <Input
            onChange={this.setKeyword}
            id="pac-input"
            type="text"
            placeholder={'ค้นหาสถานที่'}
            suffix={<SearchOutlined />}
            className={"inputCustom"}
            value={this.state.mapSearch}
          />
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLEMAP_API,
              libraries: ['places', 'geometry'],
              language: 'th'
            }}
            center={
              this.state.isSearch
                ? this.state.selectLat && this.state.selectLng
                  ? [this.state.selectLat, this.state.selectLng]
                  : [this.state.latitude, this.state.longitude]
                : this.props.latitude && this.props.longitude
                  ? [this.props.latitude, this.props.longitude]
                  : [this.state.latitude, this.state.longitude]
            }
            zoom={this.state.zoom}
            onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
            onClick={this.onSetMarkerInMap}
            options={{
              fullscreenControl: false,
              languageControl: 'en',
            }}
            yesIWantToUseGoogleMapApiInternals={true}
          >
            <PulseMarker
              lat={this.state.latitude}
              lng={this.state.longitude}
            />
            <PulsePin
              lat={this.props.latitude ? this.props.latitude : 0}
              lng={this.props.longitude ? this.props.longitude : 0}
            />
          </GoogleMapReact>
        </>
      </div >
    )
  }
}

export default googleMap
