import React, { createContext, useState, useEffect } from 'react'
// import Cookies from 'js-cookie'
export const userGroupContext = createContext({})

const UserGroupProvider = ({ children }) => {
  const [userGroup, setUserGroup] = useState({ loading: true, data: null })

  let userGroupData = localStorage.getItem('userGroupData')

  const setUserGroupData = (data) => {
    setUserGroup({ data: data })
  }

  useEffect(() => {
    setUserGroup({
      loading: false,
      data: userGroupData ? JSON.parse(userGroupData) : null,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    localStorage.setItem('userGroupData', JSON.stringify(userGroup.data))
  }, [userGroup.data])

  return (
    <userGroupContext.Provider value={{ userGroup, setUserGroupData }}>
      {children}
    </userGroupContext.Provider>
  )
}

export default UserGroupProvider
