import {
  Avatar,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Skeleton,
  Space,
  message
} from 'antd'
import { DeleteOutlined, UserOutlined } from '@ant-design/icons'
import React, { useEffect, useState, useContext } from 'react'
import { deleteData, readData } from 'actions/collection'
import Viewlayout from 'components/layouts/Viewlayout.js'
import { connect } from "react-redux";
import { getResourceLoading } from 'selectors'
import iconDeleted from 'images/correct@3x.png'
import iconErr from 'images/icon-error@3x.png'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { users } from 'schemas'
import { MenuAuth } from "contexts/menuAuth";

const UserManagementView = (props) => {
  const { isLoading } = props
  const [showModaldelete, setShowModaldelete] = useState(false)
  const history = useHistory();
  const [openDeleteSuccess, setOpenDeleteSuccess] = useState(false)
  const [openDeleteErr, setOpenDeleteErr] = useState({ open: false, errorMsg: '' })
  const [dataUsers, setDataUsers] = useState({})
  const permissionAuthor = useContext(MenuAuth);
  const [actionPermission, setActionPermission] = useState(undefined);

  useEffect(() => {
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const author = permissionAuthor?.menuAuth?.find((menu) => menu?.key_slug === "users-management");
    const menu = author?.sub_menus?.find((sub) => sub?.key_slug === "users");
    setActionPermission(menu?.role_permission);
  }, [permissionAuthor])

  const getUser = () => {
    props.readData(props.match.params.id).then((response) => {
      if (response?.code === 200) {
        setDataUsers(response?.data)
      } else {
        setDataUsers('')
      }
    })
  }

  const onDeleteUser = () => {
    setShowModaldelete(false)
    message.loading('Save in progress..', 0)

    props.deleteData(props.match.params.id).then((response) => {
      message.destroy()
      setShowModaldelete(false)
      if (response?.code === 200) {
        setOpenDeleteSuccess(true)
      } else {
        setOpenDeleteErr({ open: true, errorMsg: response.data.errors[0].cause })
      }
    })
  }

  const onCancelDelete = () => setShowModaldelete(false)

  const numberWithSpaces = (value, pattern) => {
    let i = 0, phone
    phone = value.split(' ').join('')
    return pattern.replace(/#/g, _ => phone[i++])
  }

  return (
    <>
      <Form className="App-form">
        <Viewlayout
          viewData={dataUsers}
          isLoading={isLoading}
          permission={actionPermission}
          textTitle="ดูข้อมูลผู้ใช้งาน"
          iconEdit="edit"
          iconDelete="delete"
          textEdit="แก้ไขข้อมูล"
          textDelete="ลบข้อมูล"
          onDelete={() => setShowModaldelete(true)}
          onEdit={() => history.push(`/users/edit/${props.match.params.id}`)}
        >
          <div style={{ width: '100%' }}>
            {isLoading === true ? (
              <Skeleton
                className="App-form"
                loading={isLoading}
                active
              ></Skeleton>
            ) : (
              <Form.Item>
                <Row>
                  <Col span={24}>
                    <TextLabel>รูปภาพโปรไฟล์</TextLabel>
                    {dataUsers.image_profile ?
                      <Avatar size={80}
                        src={`${dataUsers.image_profile}?w=160`}
                        icon={<UserOutlined />}
                      >
                      </Avatar>
                      :
                      <Avatar size={80} icon={<UserOutlined />} />
                    }
                  </Col>
                </Row>

                <Row gutter={[100, 0]}>
                  <Col className="sil-break-word" style={{ marginTop: '25px' }} span={12}>
                    <TextLabel>ชื่อ</TextLabel>
                    <TextDetail>{dataUsers.first_name ? dataUsers.first_name : '-'}</TextDetail>
                  </Col>
                  <Col className="sil-break-word" style={{ marginTop: '25px' }} span={12}>
                    <TextLabel>นามสกุล</TextLabel>
                    <TextDetail>{dataUsers.last_name ? dataUsers.last_name : '-'}</TextDetail>
                  </Col>
                </Row>

                <Row gutter={[100, 0]}>
                  <Col className="sil-break-word" style={{ marginTop: '25px' }} span={12}>
                    <TextLabel>อีเมล</TextLabel>
                    <TextDetail>{dataUsers.email ? dataUsers.email : '-'}</TextDetail>
                  </Col>
                  <Col className="sil-break-word" style={{ marginTop: '25px' }} span={12}>
                    <TextLabel>หมายเลขโทรศัพท์</TextLabel>
                    <TextDetail>
                      {dataUsers.mobile ?
                        numberWithSpaces(`${dataUsers.mobile}`, '### ### ####')
                        :
                        '-'
                      }
                    </TextDetail>
                  </Col>
                </Row>

                {/* <Row>
                  <Col style={{ marginTop: '25px' }} span={24}>
                    <TextLabel>รหัสผ่าน</TextLabel>
                    <TextDetail>
                      <div className="profile-password">12345678</div>
                    </TextDetail>
                  </Col>
                </Row> */}

                <Row gutter={[100, 0]}>
                  <Col className="sil-break-word" style={{ marginTop: '25px' }} span={12}>
                    <TextLabel>กลุ่มผู้ใช้งาน</TextLabel>
                    <TextDetail>{dataUsers.user_group_data ? dataUsers.user_group_data.name : '-'}</TextDetail>
                  </Col>
                  <Col className="sil-break-word" style={{ marginTop: '25px' }} span={12}>
                    <TextLabel>สิทธิ์เข้าใช้งาน</TextLabel>
                    <TextDetail>{dataUsers.role_data ? dataUsers.role_data.name : '-'}</TextDetail>
                  </Col>
                </Row>
                <Row gutter={[100, 0]}>
                  <Col className="sil-break-word" style={{ marginTop: '25px' }} span={12}>
                    <TextLabel>สถานะการใช้งาน</TextLabel>
                    <TextDetail style={{ color: dataUsers.status && dataUsers?.status === 'active' ? '#00c48c' : '#ff0000', }}>
                      {dataUsers.status ? dataUsers.status === 'active' ? 'ใช้งาน' : 'ไม่ใช้งาน' : '-'}
                    </TextDetail>
                  </Col>
                  <Col className="sil-break-word" style={{ marginTop: '25px' }} span={12}>
                    <TextLabel>สถานะการยืนยันตัวตน</TextLabel>
                    <TextDetail style={{ color: dataUsers.verified === true ? '#00c48c' : '#ff0000', }}>
                      {dataUsers.verified === true ? 'Verified' : 'Unverified'}
                    </TextDetail>
                  </Col>
                </Row>

              </Form.Item>
            )}
          </div>
        </Viewlayout>
      </Form>

      <Modal
        visible={showModaldelete}
        closable={false}
        onOk={onDeleteUser}
        onCancel={onCancelDelete}
      >
        <Col className="modal-delete">
          <DeleteOutlined style={{ fontSize: '40px' }} />
        </Col>
        <Col className="modal-delete">ต้องการลบข้อมูลใช่หรือไม่</Col>
      </Modal>

      <Modal
        visible={openDeleteSuccess}
        footer={false}
        closable={false}
        centered={true}
        width={'500px'}
      >
        <BoxModel height="180px">
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img
                alt="Icon Delete"
                src={iconDeleted}
                width={48}
                height={48}
              />
            </Col>
            <Col span={24}>
              <HeaderModel>
                ลบข้อมูลเสร็จสิ้น
              </HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() => history.goBack()}
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal
        visible={openDeleteErr.open}
        footer={false}
        closable={false}
        centered={true}
        width={'500px'}
      >
        <BoxModel height="220px">
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img alt="example" src={iconErr} width={48} height={48} />
            </Col>
            <Col span={24} >
              <HeaderModel>
                ลบข้อมูลไม่สำเร็จ
              </HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="15px">
                {openDeleteErr.errorMsg}
              </TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() => setOpenDeleteErr({ open: false, errorMsg: '' })}
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
    </>
  )
}

const TextLabel = styled.h1`
  font-size: 16px;
  line-height: 24px;  
  color: #000000;
`

const TextDetail = styled.h1`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`

const BoxModel = styled.div`
  height: ${(props) => (props.height)};
  justify-content: center;
  align-items: center;
  display: flex;
`

const HeaderModel = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
`

const TextModel = styled.div`
  font-size: 18px;
  text-align: center ;
  margin-top: ${(props) => (props.marginTop)};
`

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(users, state),
})

const mapDispatchToProps = (dispatch) => ({
  readData: (id) => dispatch(readData(users, id, process.env.REACT_APP_AUTHEN)),
  deleteData: (id) => dispatch(deleteData(users, id, process.env.REACT_APP_AUTHEN)),
})

const UserManagementViewWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementView)


export default UserManagementViewWithConnect
