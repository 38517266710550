import React from 'react'
import { httpClient } from 'HttpClient'
import { connect } from 'react-redux'
import {
  Form,
  Input,
  message,
  Radio,
  Upload,
  Skeleton,
  Modal,
  Row,
  Col,
  Space,
  Select,
  Button,
  Tooltip,
} from 'antd'
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import Footeractionlayout from 'components/layouts/Footeractionlayout'
import { createData, readData, updateData } from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'
import { resizeUpload } from 'actions/file'
import { partners } from 'schemas'
import iconErr from 'images/icon-error@3x.png'
import iconCorrect from 'images/correct@3x.png'

const { Option } = Select
const formRef = React.createRef()

const textValidName = (
  <span>ระบุเฉพาะ a-z, A-Z, 0-9, -, _ และ เว้นวรรค เท่านั้น</span>
)

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
const { TextArea } = Input
class Formlayout extends React.Component {
  state = {
    fileList: [],
    isDirty: false,
    isRemove: false,
    tempData: {},
    isNameFill: true,
    isDetailFill: true,

    OptionType: [],
    groupsType: [],
    isGroupChange: false,

    errorMsg: '',
    isCreated: false,
    isCreateError: false,
    isCancel: false,
  }

  getGroupsList = () => {
    return httpClient
      .get(`${process.env.REACT_APP_BASEURL}/master/partner-groups`)
      .then((response) => {
        this.setState({ groupsType: response?.data?.data })
        // eslint-disable-next-line array-callback-return
        this.state.groupsType.map((value, i) => {
          this.state.OptionType.push(
            <Option key={i + 1} value={value.key_slug}>
              {value.name.th}
            </Option>
          )
        })
        if (!this.props.match.params.id) {
          formRef.current.setFieldsValue({
            group: undefined,
          })
        }
      })
      .catch((error) => {
        throw error
      })
  }

  componentDidMount() {
    this.getGroupsList()

    if (this.props.match.params.id) {
      this.props.readData(this.props.match.params.id).then((res) => {
        if (res?.data?.image_url !== '') {
          this.state.fileList.push(this.setImageToState(res?.data?.image_url))
        }
        let tempData = {
          image_url: res?.data?.image_url,
          name: res?.data?.name,
          detail: res?.data?.detail,
          link_url: res?.data?.link_url,
          group: res?.data?.group,
          status: res?.data?.status,
        }
        this.setState({ tempData })
      })
    }
  }

  setImageToState(value) {
    return {
      uid: -1,
      name: 'Slideimage.png',
      status: 'done',
      url: value,
    }
  }

  handleGroupChange = () => {
    this.setState({ isGroupChange: true })
  }

  handleChange = ({ fileList }) => this.setState({ fileList })
  handleCancel = () => this.setState({ previewVisible: false, isCancel: false })

  handleCancelForm = () => {
    this.setState({ isCancel: true })
    // const { tempData } = this.state
    // this.setState(
    //   { fileList: this.props.match.params.id ? [this.setImageToState(tempData.image_url)] : '', isRemove: false },
    //   () => {
    //     formRef.current.setFieldsValue({
    //       image_url: this.props.match.params.id ? [this.setImageToState(tempData.image_url)] : '',
    //       nameth: this.props.match.params.id ? tempData.name : '',
    //       detailth: this.props.match.params.id ? tempData.detail : '',
    //       nameen: this.props.match.params.id ? tempData.name : '',
    //       detailen: this.props.match.params.id ? tempData.detail : '',
    //       link_url: this.props.match.params.id ? tempData.link_url : '',
    //       group: this.props.match.params.id ? tempData.group : '',
    //       status: this.props.match.params.id ? tempData.status : 'active',
    //     })
    //   }
    // )
  }

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  handleSubmit = (value) => {
    if (this.state.isDirty === false) {
      this.setState({ isDirty: true })
      const requestFrom = {
        name: { th: value.nameth, en: value.nameen },
        detail: { th: value.detailth, en: value.detailen },
        link_url: value.link_url,
        image_url: this.state.fileList.length
          ? value.image_url.file.originFileObj.url
          : '',
        group: value.group,
        status: value.status,
      }
      message.loading('Save in progress..', 0)
      this.props.createData(requestFrom).then((res) => {
        message.destroy()
        if (res?.code === 200) {
          this.setState({ isDirty: true, isCreated: true }, () => {
            localStorage.setItem('page', 1)
            localStorage.setItem('search', '')
          })
        } else {
          this.setState({
            isDirty: true,
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    }
  }

  handleUpdate = (value) => {

    if (this.state.isDirty === false) {
      this.setState({ isDirty: true })
      const requestFrom = {
        name: { th: value.nameth, en: value.nameen },
        detail: { th: value.detailth, en: value.detailen },
        link_url: value.link_url,
        image_url: this.state.fileList.length
          ? value.image_url.file
            ? value.image_url.file.originFileObj.url
            : value.image_url[0].url
          : '',
        group: value.group,
        status: value.status,
      }

      message.loading('Save in progress..', 0)
      this.props
        .updateData(requestFrom, this.props.match.params.id)
        .then((response) => {
          message.destroy()
          if (response?.code === 200) {
            this.setState({ isDirty: true, isCreated: true })
          } else {
            this.setState({
              isDirty: true,
              isCreateError: true,
              errorMsg: response?.message,
            })
          }
        })
    }
  }

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag)
    this.setState({ tags })
  }

  checkImg = (rule, value) => {
    if (this.props.match.params.id) {
      if (value[0]) {
        return Promise.resolve()
      }
    }
    if (
      (value && value.file.type === 'image/png') ||
      (value && value.file.type === 'image/jpeg')
    ) {
      if (value && value.fileList.length !== 0) {
        return Promise.resolve()
      } else {
        return Promise.resolve()
      }
    } else {
      if (value && value.fileList.length !== 0) {
        value.file = []
        value.fileList = []
        this.setState({ fileList: [] })
        if (value.fileList && value.fileList.length === 0) {
          return Promise.reject(
            <div style={{ marginTop: '-15px' }}>
              Please insert file type .png, .jpeg only !
            </div>
          )
        }
      } else {
        return Promise.resolve()
      }
    }
  }

  onRemoveImg = () => {
    this.setState({ isRemove: true, fileList: [] })
  }

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus())
  }

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value })
  }

  handleInputConfirm = () => {
    const { inputValue } = this.state
    let { tags } = this.state
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue]
    }
    this.setState({
      tags,
      inputVisible: false,
      inputValue: '',
    })
  }

  handleEditInputChange = (e) => {
    this.setState({ editInputValue: e.target.value })
  }

  handleEditInputConfirm = () => {
    this.setState(({ tags, editInputIndex, editInputValue }) => {
      const newTags = [...tags]
      newTags[editInputIndex] = editInputValue

      return {
        tags: newTags,
        editInputIndex: -1,
        editInputValue: '',
      }
    })
  }

  saveInputRef = (input) => {
    this.input = input
  }

  saveEditInputRef = (input) => {
    this.editInput = input
  }

  checkValidEng = (rule, value) => {
    if (value === ' ') {
      formRef.current.setFieldsValue({
        nameen: '',
      })
    } else {
      formRef.current.setFieldsValue({
        nameen: value,
      })
    }
    if (value === null || value === '') {
      return Promise.reject('Partner name is required !!')
    } else {
      if (!/^([A-Za-z0-9-_ ]{1,})?$/.test(value)) {
        return Promise.reject('Invalid data format !!')
      } else {
        return Promise.resolve()
      }
    }
  }

  render() {
    const { dataList, isLoading } = this.props
    const { previewVisible, previewImage, fileList } = this.state
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    )

    return isLoading === true ? (
      <Skeleton className="App-form" loading={isLoading} active></Skeleton>
    ) : (
      <Form
        ref={formRef}
        layout="vertical"
        className="App-form"
        onFinish={
          this.props.match.params.id ? this.handleUpdate : this.handleSubmit
        }
      >
        <Form.Item style={{ marginTop: '-20px' }}>
          <h1 className="topic">
            {this.props.match.params.id
              ? 'แก้ไขข้อมูลพาร์ทเนอร์'
              : 'เพิ่มข้อมูลพาร์ทเนอร์'}
          </h1>
        </Form.Item>
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Form.Item
              className="form-slide-menu-size"
              label={
                <Row>
                  <Col style={{ color: 'red' }}>{/**&nbsp;*/}</Col>
                  <Col>อัพโหลดรูปภาพ</Col>
                </Row>
              }
            >
              <p
                style={{
                  fontSize: '12px',
                  marginTop: '-20px' /*marginLeft: '10px' */,
                }}
              >
                (ขนาดภาพแนะนำ 300x300 px)
              </p>
            </Form.Item>
            <Form.Item
              style={{ marginTop: '-35px' }}
              name="image_url"
              rules={[
                {
                  required: false,
                  validator: this.checkImg,
                },
              ]}
              initialValue={this.props.match.params.id ? fileList : ''}
            >
              <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={this.handlePreview}
                onRemove={this.onRemoveImg}
                onChange={this.handleChange}
                customRequest={this.props.addImageData}
                accept="image/png,image/jpeg"
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 15]}>
          <Col span={12}>
            <Form.Item
              name="nameth"
              label="ชื่อ"
              rules={[
                { required: true, message: 'Partners name is required !!' },
              ]}
              initialValue={
                this.props.match.params.id && dataList.name
                  ? dataList.name.th
                  : ''
              }
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              name="nameen"
              label="Name"
              rules={[
                {
                  required: true,
                  validator: this.checkValidEng,
                  transform: (value) => {
                    let text = ''
                    if (!/^([A-Za-z0-9-_ ]{1,})?$/.test(value)) {
                      text = value.substring(0, value.length - 1)
                    } else {
                      text = value
                    }
                    return text
                  },
                },
              ]}
              initialValue={
                this.props.match.params.id && dataList.name
                  ? dataList.name.en
                  : ''
              }
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={1}>
            <Tooltip
              className="sil-toottip-custom-width"
              placement="topRight"
              title={textValidName}
            >
              <ExclamationCircleOutlined
                style={{ fontSize: '18px', marginTop: '38px' }}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={[20, 15]}>
          <Col span={12}>
            <Form.Item
              name="detailth"
              label="รายละเอียด"
              rules={[{ required: false }]}
              initialValue={
                this.props.match.params.id && dataList.detail
                  ? dataList.detail.th
                  : ''
              }
            >
              <TextArea
                autoSize={{ minRows: 4, maxRows: 4 }}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="detailen"
              label="Detail"
              rules={[{ required: false }]}
              initialValue={
                this.props.match.params.id && dataList.detail
                  ? dataList.detail.en
                  : ''
              }
            >
              <TextArea
                autoSize={{ minRows: 4, maxRows: 4 }}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 15]}>
          <Col span={12}>
            <Form.Item
              name="link_url"
              label="ลิงค์"
              rules={[
                {
                  type: 'url',
                  message: 'Website format is incorrect.',
                },
                {
                  required: true,
                  message: 'Website is required !!',
                },
              ]}
              initialValue={this.props.match.params.id ? dataList.link_url : ''}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 15]}>
          <Col span={12}>
            <Form.Item
              name="group"
              label="กลุ่ม (หน่วยงาน)"
              rules={[
                { required: true, message: 'Parthner group is required !!' },
              ]}
              initialValue={
                this.props.match.params.id && dataList.group
                  ? dataList.group.key_slug
                  : null
              }
            >
              <Select placeholder="เลือก" onChange={this.handleGroupChange}>
                {this.state.OptionType}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 15]}>
          <Col span={12}>
            <Form.Item
              label="สถานะ"
              name="status"
              initialValue={
                this.props.match.params.id
                  ? dataList.status === 'active'
                    ? 'active'
                    : 'inactive'
                  : 'active'
              }
            >
              <Radio.Group>
                <Radio value={'active'}>ใช้งาน</Radio>
                <Radio value={'inactive'}>ไม่ใช้งาน</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item shouldUpdate={true}>
          <Footeractionlayout
            onCancle={this.handleCancelForm}
          ></Footeractionlayout>
        </Form.Item>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img
            alt="example"
            style={{ width: '100%', marginTop: '20px' }}
            src={previewImage}
          />
        </Modal>
        <Modal
          visible={this.state.isCreated}
          footer={false}
          closable={false}
          centered={true}
          width={'420px'}
        >
          <div
            style={{
              height: '220px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <img alt="example" src={iconCorrect} width={48} height={48} />
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginTop: '15px',
                }}
              >
                บันทึกข้อมูลสำเร็จแล้ว
              </Col>
              <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                กดตกลงเพื่อออกจากหน้านี้
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '30px',
                }}
              >
                <Space>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() => {
                      this.props.history.goBack()
                    }}
                  >
                    ตกลง
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          visible={this.state.isCreateError}
          footer={false}
          closable={false}
          centered={true}
          width={'500px'}
        >
          <div
            style={{
              height: '220px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <img alt="example" src={iconErr} width={48} height={48} />
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginTop: '15px',
                }}
              >
                บันทึกข้อมูลไม่สำเร็จ
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '15px',
                }}
              >
                {this.state.errorMsg}
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '30px',
                }}
              >
                <Space>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() => {
                      this.setState({ isCreateError: false })
                    }}
                  >
                    ตกลง
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          visible={this.state.isCancel}
          footer={false}
          closable={false}
          centered={true}
          width={'420px'}
        >
          <div
            style={{
              height: '180px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Row>
              <Col
                span={24}
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                ยืนยันการยกเลิก
              </Col>
              <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                หากคุณต้องการยกเลิก
              </Col>
              <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                กรุณากด ยืนยัน
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '30px',
                }}
              >
                <Space>
                  <Button
                    style={{ width: '170px' }}
                    onClick={() => this.handleCancel()}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() => {
                      this.props.history.goBack()
                    }}
                  >
                    ยืนยัน
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
      </Form>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(partners, state),
  dataList: getResource(partners, state),
})

const mapDispatchToProps = (dispatch) => ({
  createData: (data) => dispatch(createData(partners, data)),
  readData: (id) => dispatch(readData(partners, id)),
  updateData: (data, id) => dispatch(updateData(partners, data, id)),
  addImageData: (params) => dispatch(resizeUpload(params)),
})

const PartnersListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Formlayout)

export default PartnersListWithConnect
