import { Col, Row, Table } from "antd";
import React, { useEffect } from "react";

const DatatableLayout = (props) => {
  useEffect(() => {
    if (props.getPermission) {
      props.getPermission();
    }
  }, [props]);

  const pagination = {
    defaultPageSize: props.pagePagination
      ? props.pagePagination.per_page
        ? props.pagePagination.per_page
        : 15
      : 15,
    pageSize: props.pagePagination
      ? props.pagePagination.per_page
        ? props.pagePagination.per_page
        : 0
      : 0,
    total: props.pagePagination
      ? props.pagePagination.total
        ? props.pagePagination.total
        : 0
      : 0,
    current: props.pagePagination
      ? props.pagePagination.current_page
        ? props.pagePagination.current_page
        : 1
      : 1,
    pageSizeOptions: ["15", "30", "50", "100"],
    showSizeChanger: true,
    hideOnSinglePage: false,
  };

  const mapdatalist = () => {
    let data = [...props.dataSource] || [];

    if (data.length > 0) {
      return data.map((value, index) => {
        value.key = `${index + 1}`;
        return value;
      });
    }
  };

  const { columns } = props;

  const handleTableChange = (pagination) => {
    let dataTableParam = props.parameter || {};
    localStorage.setItem("per_page", pagination.pageSize);
    localStorage.setItem("page", pagination.current);
    const params = {
      page: pagination.current,
      per_page: pagination.pageSize,
      search: props.pagePagination.search,
      sort_field: props.pagePagination.sort_field,
      sort_by: props.pagePagination.sort_by,
      ...dataTableParam,
    };

    if (props.onChange) {
      props.getData(params).then((resopnse) => {
        props.onChange(resopnse.data.data_list);
      });
    } else {
      props.getData(params);
    }

    // props.getData(params).then((resopnse) => {
    //   if (props.onChange) {
    //     props.onChange(resopnse.data.data_list)
    //   }
    // })
  };

  return (
    <Row style={{ marginBottom: 8 }}>
      <Col className="gutter-row" span={24}>
        <Table
          pagination={props.showPagination === false ? false : pagination}
          columns={columns}
          dataSource={mapdatalist()}
          loading={props.isloading}
          onChange={handleTableChange}
          scroll={props.scroll === true ? { y: 240 } : false}
        />
      </Col>
    </Row>
  );
};

export default DatatableLayout;
