/* eslint-disable no-useless-escape */

import "../../../App.less";

import { Avatar, Col, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";

import { UserOutlined } from "@ant-design/icons";
import Viewlayout from "components/layouts/Viewlayout";
import { connect } from "react-redux";
import { getList } from "actions/collection";
import { getResourceLoading } from "selectors";
import styled from "styled-components";
import { useHistory } from "react-router";
import { userprofile } from "schemas";

const url = `${process.env.REACT_APP_AUTHEN}`;

const Profile = (props) => {
  const history = useHistory();
  const [dataProfile, setDataProfile] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    props.readData().then((res) => {
      setLoading(false);
      if (res?.code === 200) {
        setDataProfile(res.data);
      } else {
        setDataProfile();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <div className="App-form">
      <Viewlayout
        viewData={dataProfile}
        isLoading={loading}
        permission={{ can_update: true }}
        textTitle="ข้อมูลส่วนตัว"
        iconEdit={dataProfile ? "edit" : ""}
        textEdit={dataProfile ? "แก้ไขข้อมูล" : ""}
        onEdit={() => history.push(`/profile/edit/${dataProfile?.id}`)}
      >
        <Skeleton className="App-form" loading={loading} active>
          {dataProfile && (
            <Row gutter={[30, 0]}>
              <Col span={24} style={{ marginTop: -20 }}>
                <TextImg>รูปภาพโปรไฟล์</TextImg>
              </Col>
              <Col span={24}>
                {dataProfile?.image_profile ? (
                  <Avatar
                    size={80}
                    src={`${dataProfile?.image_profile}?w=160`}
                    icon={<UserOutlined />}
                  />
                ) : (
                  <Avatar size={80} icon={<UserOutlined />} />
                )}
              </Col>
              <Col className="sil-break-word" span={12}>
                <TextLabel>ชื่อ</TextLabel>
                {dataProfile?.first_name || "-"}
              </Col>
              <Col className="sil-break-word" span={12}>
                <TextLabel>นามสกุล</TextLabel>
                {dataProfile?.last_name || "-"}
              </Col>

              <Col className="sil-break-word" span={12}>
                <TextLabel>อีเมล</TextLabel>
                {dataProfile?.email || "-"}
              </Col>
              <Col className="sil-break-word" span={12}>
                <TextLabel>หมายเลขโทรศัพท์</TextLabel>
                {dataProfile?.mobile
                  ? dataProfile?.mobile.replace(
                    /(\d{3})\-?(\d{3})\-?(\d{4})/,
                    "$1 $2 $3"
                  )
                  : "-"}
              </Col>
              <Col span={24}>
                <TextLabel>รหัสผ่าน</TextLabel>
                <div className="profile-password">000000000</div>
              </Col>
              <Col className="sil-break-word" span={12}>
                <TextLabel>กลุ่มผู้ใช้งาน</TextLabel>
                {dataProfile?.user_group_data?.name || "-"}
              </Col>
              <Col className="sil-break-word" span={12}>
                <TextLabel>สิทธิ์เข้าใช้งาน</TextLabel>
                {dataProfile?.role_data?.name || "-"}
              </Col>
              <Col className="sil-break-word" span={12}>
                <TextLabel>สถานะผู้ใช้งาน</TextLabel>
                {dataProfile?.status === "active" ? (
                  <div style={{ color: "#00C428" }}>ใช้งาน</div>
                ) : (
                  <div style={{ color: "#FF0000" }}>ไม่ใช้งาน</div>
                )}
              </Col>
            </Row>
          )}
        </Skeleton>
      </Viewlayout>
    </div>

  );
};

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(userprofile, state),
});

const mapDispatchToProps = (dispatch) => ({
  readData: (id) => dispatch(getList(userprofile, id, url)),
});

const ProfileWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);

export default ProfileWithConnect;
const TextLabel = styled.div`
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  color: black;
`;
const TextImg = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  color: black;
`;
