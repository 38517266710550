import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Skeleton,
  Space,
  Tag,
  message,
} from 'antd'
import { deleteData, readData } from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'

import DefaultImg from 'images/default-thumbnail.jpg'
import { DeleteOutlined } from '@ant-design/icons'
import React from 'react'
import Viewlayout from 'components/layouts/Viewlayout'
import { cctv } from 'schemas'
import { connect } from 'react-redux'
import { httpClient } from 'HttpClient'
import iconDeleted from 'images/correct@3x.png'
import iconErr from 'images/icon-error@3x.png'
import { MenuAuth } from 'contexts/menuAuth'
import { imageError } from '../../../utils/common'
import Axios from 'axios'
import moment from 'moment'
class View extends React.Component {
  static contextType = MenuAuth
  state = {
    snapshotForm: React.createRef(),
    timelapseForm: React.createRef(),
    tags: [],
    visible: false,
    inputVisible: false,
    inputValue: '',
    editInputIndex: -1,
    editInputValue: '',
    tagsList: [],
    groupsType: [],
    tourismTypesList: [],
    isCreateError: false,
    visibleDeleted: false,
    errorMsg: '',
    actionPermission: undefined,
    showModalWarning: false,
    modalTime: false,
    loadingButtonTimelapse: false,
    timestart: false,
    timestop: false,
    modalSuccessTimelapse: false,
    modalFailedTimelapse: false,
    showModal: false,
    isModalVisible: false,
    loadingSnapshot: false,
    snapshotMorning: '',
    snapshotAfternoon: '',
    snapshotEvening: '',
    modalSnapshotError: false,
    modalSnapshotSuccess: false,
    createTimelapseTextStatus: '',
    createSnapshotTextStatus: '',
    createTimelapseStatus: '',
    createSnapshotStatus: '',
  }

  componentDidMount() {
    this.props.readData(this.props.match.params.id).then((res) => {
      this.handleCheckProcessOnPageStart(res.data)
      this.getTags()
      this.gettourismTypes()
      this.getGroupsList()
    })
  }

  handleCheckProcessOnPageStart(data) {
    if (data.status_manual_snapshot === 'processing') {
      this.setState({ loadingSnapshot: true })
    } else if (data.status_manual_snapshot === 'complete') {
      this.setState({ modalSnapshotSuccess: true })
    } else {
      if (data.status_manual_snapshot === 'failed') {
        this.setState({ modalSnapshotError: true })
      } else {
        this.setState({
          modalSnapshotSuccess: false,
          modalSnapshotError: false,
        })
      }
    }

    if (data.status_manual_timelapse === 'processing') {
      this.setState({ loadingButtonTimelapse: true })
    } else if (data.status_manual_timelapse === 'complete') {
      this.setState({ modalSuccessTimelapse: true })
    } else {
      if (data.status_manual_timelapse === 'failed') {
        this.setState({ modalFailedTimelapse: true })
      } else {
        this.setState({
          modalSuccessTimelapse: false,
          modalFailedTimelapse: false,
        })
      }
    }
  }

  componentDidUpdate() {
    if (this.context?.menuAuth !== this.state.actionPermission) {
      this.getPermission()
    }
  }

  showModalTimelapse = () => {
    this.setState({
      modalTime: true,
    })

    if (this.state.timelapseForm.current) {
      this.state.timelapseForm.current.resetFields()
    }
  }

  createTimelapse = () => {
    const body = {
      uid: this.props?.dataList?.camera_name,
      capture_start: this.state?.timestart,
      capture_stop: this.state?.timestop,
    }
    message.loading('Loading...')
    this.setState({
      loadingButtonTimelapse: true,
      showModalWarning: false,
    })
    
    Axios.post(
      `${this.props?.dataList?.server_ftp?.protocol}://${this.props?.dataList?.server_ftp?.domain_name}/api/v1/timelapse`,
      body,
    ).then((res) => {
      message.destroy()
      if (res.data.code === 200) {
        message.success('Create Timelapse has pending.', 2.5)
      } else {
        message.error('Timelapse create request error.', 2.5)
        this.setState({
          createTimelapseTextStatus: res.data.error,
          loadingButtonTimelapse: false,
          modalFailedTimelapse: true,
          showModalWarning: false,
        })
      }
    })
  }
  successCreateTimelapse = () => {
    this.setState({
      loadingButtonTimelapse: false,
    })
  }

  closeModalTime = () => {
    this.setState({
      modalTime: false,
    })
  }
  handleOkModalTimelapse = (value) => {
    this.setState({
      showModalWarning: true,
      modalTime: false,
      timestart: moment(value.timestart._d).format('HH:mm'),
      timestop: moment(value.timestop._d).format('HH:mm'),
    })
  }
  handleCancelTimelapse = () => {
    this.setState({
      showModalWarning: false,
      modalTime: true,
    })
  }

  closeModalSuccess = () => {
    this.setState({
      modalSuccessTimelapse: false,
    })

    Axios.put(
      `${this.props?.dataList?.server_ftp?.protocol}://${this.props?.dataList?.server_ftp?.domain_name}/api/v1/timelapse/${this.props?.dataList?.camera_name}`,
    )
  }
  closeModalFailed = () => {
    this.setState({
      modalFailedTimelapse: false,
    })

    Axios.put(
      `${this.props?.dataList?.server_ftp?.protocol}://${this.props?.dataList?.server_ftp?.domain_name}/api/v1/timelapse/${this.props?.dataList?.camera_name}`,
    )
  }

  getPermission() {
    const author = this.context?.menuAuth?.find(
      (menu) => menu?.key_slug === 'cms',
    )
    const menu = author?.sub_menus?.find((sub) => sub?.key_slug === 'cctv')
    !this.state.actionPermission &&
      this.setState({ actionPermission: menu?.role_permission })
  }

  getTags = () => {
    return httpClient
      .get(`${process.env.REACT_APP_BASEURL}/master/tags`)
      .then((response) => {
        this.setState({ tagsList: response?.data?.data })
        return this.state.tagsList
      })
      .catch((error) => {
        throw error
      })
  }

  gettourismTypes = () => {
    return httpClient
      .get(`${process.env.REACT_APP_BASEURL}/master/tourism-types`)
      .then((response) => {
        this.setState({ tourismTypesList: response?.data?.data })
        return this.state.tourismTypesList
      })
      .catch((error) => {
        throw error
      })
  }

  getGroupsList = () => {
    return httpClient
      .get(`${process.env.REACT_APP_BASEURL}/master/cctv-groups`)
      .then((response) => {
        this.setState({ groupsType: response?.data?.data })
        return this.state.groupsType
      })
      .catch((error) => {
        throw error
      })
  }

  showModaldelete = () => {
    this.setState({
      visible: true,
    })
  }

  handleOkModelSnapshot = (value) => {
    this.setState({
      showModal: false,
      isModalVisible: true,
    })
    this.setState({
      snapshotMorning: moment(value.snapshotMorning._d).format('HH:mm'),
      snapshotAfternoon: moment(value.snapshotAfternoon._d).format('HH:mm'),
      snapshotEvening: moment(value.snapshotEvening._d).format('HH:mm'),
    })
  }

  handleCancelModelSnapshot = () => {
    this.setState({
      showModal: false,
      isModalVisible: false,
    })
  }

  showModalSnapShot = () => {
    this.setState({
      showModal: true,
    })
    if (this.state.snapshotForm.current) {
      this.state.snapshotForm.current.resetFields()
    }
  }

  SnapshotSuccess = () => {
    this.setState({
      modalSnapshotSuccess: false,
      isModalVisible: false,
    })

    Axios.put(
      `${this.props?.dataList?.server_ftp?.protocol}://${this.props?.dataList?.server_ftp?.domain_name}/api/v1/snapshot/${this.props?.dataList?.camera_name}`,
    )
  }

  SnapshotError = () => {
    this.setState({
      modalSnapshotError: false,
      isModalVisible: false,
    })

    Axios.put(
      `${this.props?.dataList?.server_ftp?.protocol}://${this.props?.dataList?.server_ftp?.domain_name}/api/v1/snapshot/${this.props?.dataList?.camera_name}`,
    )
  }

  closeModal = () => {
    this.setState({
      showModal: true,
      isModalVisible: false,
    })
  }

  handleCreateSnapshot = () => {
    this.setState({
      isModalVisible: false,
      loadingSnapshot: true,
    })
    const body = {
      uid: this.props?.dataList?.camera_name,
      mor: this.state?.snapshotMorning,
      aft: this.state?.snapshotAfternoon,
      eve: this.state?.snapshotEvening,
    }

    message.loading('loading...')

    Axios.post(
      `${this.props?.dataList?.server_ftp?.protocol}://${this.props?.dataList?.server_ftp?.domain_name}/api/v1/snapshot`,
      body,
    ).then((res) => {
      message.destroy()
      if (res.data.code === 200) {
        message.success('Create Snapshot has pending.', 2.5)
      } else {
        message.error('Snapshot create request error.', 2.5)
        this.setState({
          createSnapshotTextStatus: res.data.error,
          loadingSnapshot: false,
          isModalVisible: false,
          modalSnapshotError: true,
        })
      }
    })
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    })
    this.deleteList()
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
      delete_id: '',
    })
  }

  deleteList = () => {
    this.setState({ visible: false }, () => {
      message.loading('Delete in progress..', 0)
      this.props.deleteData(this.props.match.params.id).then((res) => {
        if (res?.code === 200) {
          message.destroy()
          this.setState({
            visibleDeleted: true,
          })
        } else {
          message.destroy()
          this.setState({
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    })
  }

  gotoEdit = () => {
    this.props.history.push(`/cctv/edit/${this.props.match.params.id}`)
  }

  getTagsNameFormSlug = (tag) => {
    const index = this.state.tagsList.find((tags) => tags.key_slug === tag)
    return index ? index.name.th : ''
  }

  getTourismTypesNameFormSlug = (tag) => {
    const index = this.state.tourismTypesList.find(
      (tags) => tags.key_slug === tag,
    )
    return index ? index.name.th : ''
  }

  getGroupsNameFormSlug = (groups) => {
    const index = this.state.groupsType.find(
      (group) => group.key_slug === groups,
    )
    return index
      ? index.key_slug === 'national-park'
        ? ', ' + index.name.th
        : index.name.th
      : ''
  }

  getLinkCCTV = () => {
    const { dataList } = this.props
    return httpClient
      .get(dataList.camera_url)
      .then((response) => {
        window.open(response?.data?.cctv)
      })
      .catch((error) => {
        throw error
      })
  }

  render() {
    const { dataList, isLoading } = this.props

    return (
      <Form className='App-form'>
        <Viewlayout
          permission={
            isLoading === false && {
              ...this.state.actionPermission,
              can_update:
                dataList.status_approval === 'pending_publish' ||
                dataList.status_approval === 'reject'
                  ? false
                  : this.state.actionPermission?.can_update,
            }
          }
          viewData={dataList}
          isLoading={isLoading}
          iconEdit='edit'
          iconDelete='delete'
          textTitle='ข้อมูล CCTV'
          textEdit='แก้ไขข้อมูล'
          textDelete='ลบข้อมูล'
          onEdit={this.gotoEdit}
          onDelete={this.showModaldelete}
          createSnapshot={true}
          textSnapshot='สร้าง Snapshot'
          textLoadingSnapshot='กำลังสร้าง Snapshot...'
          showModal={this.state.showModal}
          isModalVisible={this.state.isModalVisible}
          loadingSnapshot={this.state.loadingSnapshot}
          handleOkModelSnapshot={this.handleOkModelSnapshot}
          showModalSnapShot={this.showModalSnapShot}
          handleCancelModelSnapshot={this.handleCancelModelSnapshot}
          handleCreateSnapshot={this.handleCreateSnapshot}
          closeModal={this.closeModal}
          modalSnapshotSuccess={this.state.modalSnapshotSuccess}
          modalSnapshotError={this.state.modalSnapshotError}
          SnapshotSuccess={this.SnapshotSuccess}
          SnapshotError={this.SnapshotError}
          loadingTimelapse={'กำลังสร้าง Timelapse...'}
          textTimelapse={'สร้าง Timelapse'}
          showModalTimelapse={this.showModalTimelapse}
          createTimelapse={this.createTimelapse}
          closeModalTime={this.closeModalTime}
          handleOkModalTimelapse={this.handleOkModalTimelapse}
          handleCancelTimelapse={this.handleCancelTimelapse}
          loadingButtonTimelapse={this.state.loadingButtonTimelapse}
          modalTime={this.state.modalTime}
          showModalWarning={this.state.showModalWarning}
          modalSuccessTimelapse={this.state.modalSuccessTimelapse}
          modalFailedTimelapse={this.state.modalFailedTimelapse}
          closeModalSuccess={this.closeModalSuccess}
          closeModalFailed={this.closeModalFailed}
          createTimelapseTextStatus={this.state.createTimelapseTextStatus}
          createSnapshotTextStatus={this.state.createSnapshotTextStatus}
          createSnapshotStatus={this.state.createSnapshotStatus}
          snapshotForm={this.state.snapshotForm}
          timelapseForm={this.state.timelapseForm}
        >
          {isLoading === true ? (
            <Skeleton
              className='App-form'
              loading={isLoading}
              active
            ></Skeleton>
          ) : (
            <Form.Item>
              <Row>
                <Col>
                  <h1>รูปภาพ</h1>
                  <img
                    onError={imageError}
                    alt='รูปภาพ'
                    src={dataList ? dataList?.cover_url : DefaultImg}
                    height='250'
                  />
                </Col>
              </Row>
              <Row gutter={[100, 0]}>
                <Col
                  className='sil-break-word'
                  style={{ marginTop: '30px' }}
                  span={12}
                >
                  <h1>ชื่อ</h1>
                  {dataList.name ? dataList.name.th : ''}
                </Col>
                <Col
                  className='sil-break-word'
                  style={{ marginTop: '30px' }}
                  span={12}
                >
                  <h1>Name</h1>
                  {dataList.name ? dataList.name.en : ''}
                </Col>
              </Row>
              <Row gutter={[30, 0]}>
                <Col
                  className='sil-break-word'
                  style={{ marginTop: '30px' }}
                  span={12}
                >
                  <h1>คีย์</h1>
                  {dataList.key_slug ? dataList.key_slug : '-'}
                </Col>
              </Row>
              <Row gutter={[100, 0]}>
                <Col
                  className='sil-break-word'
                  style={{ marginTop: '30px', wordBreak: 'break-word' }}
                  span={12}
                >
                  <h1>รายละเอียด</h1>
                  {dataList.description && dataList.description.th
                    ? dataList.description.th
                    : '-'}
                </Col>
                <Col
                  className='sil-break-word'
                  style={{ marginTop: '30px', wordBreak: 'break-word' }}
                  span={12}
                >
                  <h1>Detail</h1>
                  {dataList.description && dataList.description.en
                    ? dataList.description.en
                    : '-'}
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>พิกัด</h1>
                  {dataList.location
                    ? dataList.location.coordinates[1] +
                      ', ' +
                      dataList.location.coordinates[0]
                    : ''}
                </Col>
              </Row>
              <Row gutter={[100, 0]}>
                <Col
                  className='sil-break-word'
                  style={{ marginTop: '30px' }}
                  span={12}
                >
                  <h1>ที่อยู่</h1>
                  {dataList.address ? dataList.address.th : ''}
                </Col>
                <Col
                  className='sil-break-word'
                  style={{ marginTop: '30px' }}
                  span={12}
                >
                  <h1>Address</h1>
                  {dataList.address ? dataList.address.en : ''}
                </Col>
              </Row>
              {dataList?.content_type === 'cctv' || !dataList?.content_type ? (
                <Row>
                  <Col style={{ marginTop: '30px' }}>
                    <h1>ลิงค์</h1>
                    {
                      <Button
                        style={{ marginLeft: '-15px' }}
                        type='link'
                        htmlType='type'
                        onClick={this.getLinkCCTV}
                      >
                        {dataList.camera_url}
                      </Button>
                    }
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col style={{ marginTop: '30px' }}>
                    <h1>วิดีโอ TimeLapse</h1>
                    {
                      <video
                        style={{ width: '500px' }}
                        src={dataList.camera_url}
                        type='video/mp4'
                        controls
                      />
                    }
                  </Col>
                </Row>
              )}
              {dataList.content_type === 'cctv' || !dataList.content_type ? (
                <Row>
                  <Col style={{ marginTop: '30px' }}>
                    <h1>รหัส</h1>
                    {dataList.camera_code}
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>กลุ่ม</h1>
                  {dataList.groups && dataList.groups.length !== 0
                    ? dataList.groups.map((group, index) => {
                        return this.getGroupsNameFormSlug(group)
                      })
                    : '-'}
                </Col>
              </Row>
              <Row gutter={[100, 30]}>
                <Col
                  className='sil-break-word'
                  style={{ marginTop: '30px' }}
                  span={12}
                >
                  <h1>เขต</h1>
                  {dataList.area ? dataList.area.th : ''}
                </Col>
                <Col
                  className='sil-break-word'
                  style={{ marginTop: '30px' }}
                  span={12}
                >
                  <h1>Area</h1>
                  {dataList.area ? dataList.area.en : ''}
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1>แท็ก</h1>
                  {dataList.tags && dataList.tags.length !== 0
                    ? dataList.tags.map((tag, index) => {
                        const isLongTag = tag.length > 30
                        const tagElem = (
                          <Tag className='edit-tag' key={tag} closable={false}>
                            <span>
                              {isLongTag
                                ? `${this.getTagsNameFormSlug(tag).slice(
                                    0,
                                    30,
                                  )}...`
                                : this.getTagsNameFormSlug(tag)}
                            </span>
                          </Tag>
                        )
                        return tagElem
                      })
                    : '-'}
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>ประเภทการท่องเที่ยว</h1>
                  {dataList.tourism_types && dataList.tourism_types.length !== 0
                    ? dataList.tourism_types.map((tag, index) => {
                        const isLongTag = tag.length > 30
                        const tagElem = (
                          <Tag className='edit-tag' key={tag} closable={false}>
                            <span>
                              {isLongTag
                                ? `${this.getTourismTypesNameFormSlug(
                                    tag,
                                  ).slice(0, 30)}...`
                                : this.getTourismTypesNameFormSlug(tag)}
                            </span>
                          </Tag>
                        )
                        return tagElem
                      })
                    : '-'}
                </Col>
              </Row>
              <Row gutter={[30, 0]}>
                <Col
                  className='sil-break-word'
                  style={{ marginTop: '30px' }}
                  span={12}
                >
                  <h1>คีย์เวิร์ด</h1>
                  {dataList.keywords ? dataList.keywords : '-'}
                </Col>
              </Row>
              <Row gutter={[30, 0]}>
                <Col
                  className='sil-break-word'
                  style={{ marginTop: '30px', whiteSpace: 'nowrap' }}
                  span={12}
                >
                  <h1>เซิร์ฟเวอร์ FTP</h1>
                  {dataList.server_ftp
                    ? `${dataList.server_ftp.name} | ${dataList.server_ftp.host} | ${dataList.server_ftp.username}`
                    : '-'}
                </Col>
              </Row>
              {dataList.content_type === 'cctv' || !dataList?.content_type ? (
                <Row>
                  <Col style={{ marginTop: '30px' }}>
                    <h1>ตั้งค่า Snapshot</h1>
                    <Row>
                      <Col style={{ width: '150px' }}>
                        <Row>
                          <Col span={24}>ช่วงเช้า</Col>
                          <Col span={24}>
                            {dataList.snapshot_config &&
                              dataList.snapshot_config.mor}
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ width: '150px' }}>
                        <Row>
                          <Col span={24}>ช่วงกลางวัน</Col>
                          <Col span={24}>
                            {dataList.snapshot_config &&
                              dataList.snapshot_config.aft}
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ width: '150px' }}>
                        <Row>
                          <Col span={24}>ช่วงเย็น</Col>
                          <Col span={24}>
                            {dataList.snapshot_config &&
                              dataList.snapshot_config.eve}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col style={{ marginTop: '30px' }}>
                    <h1>รูปภาพ Snapshot</h1>
                    <Row gutter={[20, 15]}>
                      <Col style={{ width: '200px' }}>
                        <Row>
                          <Col span={24}>ช่วงเช้า</Col>
                          <Col span={24}>
                            <img
                              onError={imageError}
                              alt='morImg'
                              src={
                                dataList.snapshot_images &&
                                dataList.snapshot_images.mor_link
                              }
                              width='100%'
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ width: '200px' }}>
                        <Row>
                          <Col span={24}>ช่วงกลางวัน</Col>
                          <Col span={24}>
                            <img
                              onError={imageError}
                              alt='aftImg'
                              src={
                                dataList.snapshot_images &&
                                dataList.snapshot_images.aft_link
                              }
                              width='100%'
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ width: '200px' }}>
                        <Row>
                          <Col span={24}>ช่วงเย็น</Col>
                          <Col span={24}>
                            <img
                              onError={imageError}
                              alt='eveImg'
                              src={
                                dataList.snapshot_images &&
                                dataList.snapshot_images.eve_link
                              }
                              width='100%'
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>ตั้งค่า TimeLapes</h1>
                  {dataList.timelapse_config
                    ? dataList.timelapse_config.name
                    : ''}
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>กลุ่มผู้ใช้งาน</h1>
                  {dataList.user_groups || '-'}
                </Col>
              </Row>
              <Row>
                <Col
                  style={{
                    marginTop: '30px',
                    color: dataList.status === 'active' ? '#00c48c' : '#ff0000',
                  }}
                >
                  <h1>สถานะกล้อง</h1>
                  {dataList.status === 'active' ? 'ใช้งาน' : 'ไม่ใช้งาน'}
                </Col>
              </Row>
              <Modal
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
              >
                <Col className='modal-delete'>
                  <DeleteOutlined style={{ fontSize: '40px' }} />
                </Col>
                <Col className='modal-delete'>ต้องการลบข้อมูลใช่หรือไม่</Col>
              </Modal>
              <Modal
                visible={this.state.visibleDeleted}
                footer={false}
                closable={false}
                centered={true}
                width={'500px'}
              >
                <div
                  style={{
                    height: '180px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <img
                        alt='example'
                        src={iconDeleted}
                        width={48}
                        height={48}
                      />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      ลบข้อมูลเสร็จสิ้น
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          type='primary'
                          style={{ width: '170px' }}
                          onClick={() => this.props.history.push('/cctv')}
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
              <Modal
                visible={this.state.isCreateError}
                footer={false}
                closable={false}
                centered={true}
                width={'500px'}
              >
                <div
                  style={{
                    height: '220px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <img alt='example' src={iconErr} width={48} height={48} />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      ลบข้อมูลไม่สำเร็จ
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      {this.state.errorMsg}
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          type='primary'
                          style={{ width: '170px' }}
                          onClick={() => {
                            this.setState({ isCreateError: false })
                          }}
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </Form.Item>
          )}
        </Viewlayout>
      </Form>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(cctv, state),
  dataList: getResource(cctv, state),
})

const mapDispatchToProps = (dispatch) => ({
  readData: (id) => dispatch(readData(cctv, id)),
  deleteData: (id) => dispatch(deleteData(cctv, id)),
})

const SlideListWithConnect = connect(mapStateToProps, mapDispatchToProps)(View)

export default SlideListWithConnect
