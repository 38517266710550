import React from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  message,
  Avatar,
} from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { createData, readData, updateData } from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'
import { connect } from 'react-redux'

import Footeractionlayout from 'components/layouts/Footeractionlayout'
import { cctv } from 'schemas'
import iconErr from 'images/icon-error@3x.png'
import iconSucc from 'images/correct@3x.png'
import { resizeUpload } from 'actions/file'
import styled from 'styled-components'

const { TextArea } = Input
const formRef = React.createRef()

class Formlayout extends React.Component {
  state = {
    errorMsg: '',
    isCreated: false,
    isCreateError: false,
    isCancel: false,
    isDirty: true,
    tempData: {},

    //Button
    isPending: false,
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.readData(this.props.match.params.id).then((data) => {
        this.getData()
      })
    } else {
      this.getData()
    }
  }

  getData = () => {
    if (this.props.match.params.id) {
      this.props.readData(this.props.match.params.id).then((res) => {
        if (res?.code === 200) {
          if (res?.data?.status_approval !== 'draft' && res?.data?.status_approval !== 'revise') {
            this.props.history.push('/404')
          } else {
            this.setState({ tempData: res?.data })
          }
        } else {
          this.setState({ tempData: {} })
        }
      })
    }
  }

  handleCancel = () => this.setState({ previewVisible: false, isCancel: false })

  handleUpdate = (value) => {
    const { isDirty } = this.state
    const data = {
      ...this.state.tempData,
      server_ftp_id: this.state.tempData.ServerFTPID,
      description: { th: value.descriptionth, en: value.descriptionen },
      status_approval: this.state.isPending === true ? 'pending_publish' : this.state.tempData.status_approval,
    }
    delete data.ServerFTPID
    if (isDirty) {
      this.setState({ isDirty: false })
      this.setState({ tempData: data })
      message.loading('Save in progress..', 0)
      this.props
        .updateData(this.state.tempData, this.props.match.params.id)
        .then((response) => {
          message.destroy()
          if (response?.code === 200) {
            this.setState({ isPending: false })
            this.setState({ isDirty: true, isCreated: true })
          } else {
            this.setState({
              isDirty: true,
              isCreateError: true,
              errorMsg: response?.message,
            })
          }
        })
    }
  }

  clickCancle = () => { this.setState({ isCancel: true }) }

  clickPending = () => { this.setState({ isPending: true }) }

  render() {
    const { dataList, isLoading } = this.props
    const {
      previewVisible,
      tempData,
    } = this.state

    return (
      <>
        {isLoading === true ? (
          <Skeleton className="App-form" loading={isLoading} active></Skeleton>
        ) : (
          <Form
            ref={formRef}
            layout="vertical"
            className="App-form"
            onFinish={this.handleUpdate}
          >
            <Form.Item style={{ marginTop: '-20px' }}>
              <h1 className="topic">
                แก้ไขข้อมูลกล้อง CCTV
              </h1>
            </Form.Item>
            <Row gutter={[20, 15]} style={{ marginBottom: '20px' }}>
              <Col span={24}>
                <TextLabel style={{ marginBottom: '10px' }}>รูปภาพ</TextLabel>
                {dataList.cover_url ?
                  <Avatar size={80}
                    src={`${dataList.cover_url}?w=160`}
                    icon={<UserOutlined />}
                    onClick={() => this.setState({ previewVisible: true })}
                  />
                  :
                  <Avatar
                    size={80}
                    icon={<UserOutlined />}
                    onClick={() => this.setState({ previewVisible: true })}
                  />
                }
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <TextLabel>ชื่อโครงการ</TextLabel>
                <TextLabel>
                  {this.props.match.params.id && dataList.name
                    ? dataList.name.th
                    : '-'}
                </TextLabel>
              </Col>
              <Col span={12}>
                <TextLabel>Project Name</TextLabel>
                <TextLabel>
                  {this.props.match.params.id && dataList.name
                    ? dataList.name.en
                    : '-'}
                </TextLabel>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  name="descriptionth"
                  label="รายละเอียด"
                  rules={[
                    {
                      required: true,
                      message: 'Detail is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.description
                      ? dataList.description.th
                      : ''
                  }
                >
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    autoComplete="off"
                    maxLength={2000}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="descriptionen"
                  label="Detail"
                  rules={[
                    {
                      required: true,
                      message: 'Detail is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.description
                      ? dataList.description.en
                      : ''
                  }
                >
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    autoComplete="off"
                    maxLength={2000}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={24}>
                <TextLabel>กลุ่มผู้ใช้งาน</TextLabel>
                <TextLabel>{this.props.match.params.id ? dataList.user_groups : '-'}</TextLabel>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={24}>
                <TextLabel>สถานะการอนุมัติ</TextLabel>
                <TextLabel style={{
                  color: dataList.status_approval === 'pending_publish' ?
                    '#FFA800' : dataList.status_approval === 'approve' ?
                      '#00C428' : dataList.status_approval === 'reject' ?
                        "#ff0000" : dataList.status_approval === 'revise' ?
                          '#FF7F50' : '#000000D9'
                }}>
                  {this.props.match.params.id ?
                    dataList.status_approval === 'pending_publish' ?
                      'รออนุมัติ' : dataList.status_approval === 'approve' ?
                        'อนุมัติ' : dataList.status_approval === 'reject' ?
                          'ไม่อนุมัติ' : dataList.status_approval === 'revise' ? 'ปรับปรุงใหม่' : 'แบบร่าง' : '-'}
                </TextLabel>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={24}>
                <TextLabel>สถานะกล้อง</TextLabel>
                <TextLabel style={{
                  color: dataList.status === "active" ? "#00c48c" : "#ff0000",
                }}>
                  {this.props.match.params.id ? dataList.status === 'active' ? 'ใช้งาน' : 'ไม่ใช้งาน' : '-'}
                </TextLabel>
              </Col>
            </Row>
            <Form.Item>
              <Space>
                <Footeractionlayout
                  isShowButtonApprove={
                    this.props.match.params.id ?
                      dataList.status_approval === 'draft' || dataList.status_approval === 'revise' ? true : false
                      : true
                  }
                  onPending={this.clickPending}
                  onCancle={this.clickCancle}
                ></Footeractionlayout>
              </Space >
            </Form.Item>

            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={this.handleCancel}
              width={800}
            >
              <img
                alt="example"
                style={{ width: '100%', marginTop: '20px' }}
                src={`${tempData?.cover_url}?w=360`}
              />
            </Modal>
            <Modal
              visible={this.state.isCreated}
              footer={false}
              closable={false}
              centered={true}
              width={'528px'}
            >
              <BoxModel height="309px">
                <Row>
                  <Col span={24} style={{ textAlign: 'center', marginTop: '14px' }}>
                    <img alt="Icon Success" src={iconSucc} width={58} height={58} />
                  </Col>
                  <Col span={24}>
                    <HeaderModel>
                      แก้ไขข้อมูลสำเร็จ
                    </HeaderModel>
                  </Col>
                  <Col span={24}>
                    <TextModel marginTop="11px">
                      กด ‘ตกลง’ เพื่อออกจากหน้านี
                    </TextModel>
                  </Col>
                  <Col span={24}>
                    <TextModel marginTop="52px">
                      <Space>
                        <Button
                          onClick={() => {
                            this.props.history.push('/cctv')
                          }}
                          type="primary"
                          style={{ width: '168px' }}
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </TextModel>
                  </Col>
                </Row>
              </BoxModel>
            </Modal>
            <Modal
              visible={this.state.isCreateError}
              footer={false}
              closable={false}
              centered={true}
              width={'528px'}
            >
              <BoxModel height="309px">
                <Row>
                  <Col span={24} style={{ textAlign: 'center', marginTop: '14px' }}>
                    <img alt="Icon Error" src={iconErr} width={58} height={58} />
                  </Col>
                  <Col span={24}>
                    <HeaderModel>
                      แก้ไขข้อมูลไม่สำเร็จ
                    </HeaderModel>
                  </Col>
                  <Col span={24}>
                    <TextModel marginTop="11px">
                      {this.state.errorMsg}
                    </TextModel>
                  </Col>
                  <Col span={24}>
                    <TextModel marginTop="52px">
                      <Space>
                        <Button
                          onClick={() => {
                            this.setState({ isCreateError: false })
                          }}
                          type="primary"
                          style={{ width: '168px' }}
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </TextModel>
                  </Col>
                </Row>
              </BoxModel>
            </Modal>
            <Modal
              visible={this.state.isCancel}
              footer={false}
              closable={false}
              centered={true}
              width={'420px'}
            >
              <div
                style={{
                  height: '180px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    ยืนยันการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    หากคุณต้องการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กรุณากด ยืนยัน
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        style={{ width: '170px' }}
                        onClick={() => this.handleCancel()}
                      >
                        ยกเลิก
                      </Button>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.push('/cctv')
                        }}
                      >
                        ยืนยัน
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
          </Form>
        )}
      </>
    )
  }
}

const TextLabel = styled.h1`
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,.85);
`

const BoxModel = styled.div`
  height: ${(props) => (props.height)};
  justify-content: center;
  align-items: center;
  display: flex;
`

const HeaderModel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 52px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
`

const TextModel = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: ${(props) => (props.marginTop)};
`

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(cctv, state),
  dataList: getResource(cctv, state),
})

const mapDispatchToProps = (dispatch) => ({
  createData: (data) => dispatch(createData(cctv, data)),
  readData: (id) => dispatch(readData(cctv, id)),
  updateData: (data, id) => dispatch(updateData(cctv, data, id)),
  addImageData: (params) => dispatch(resizeUpload(params)),
})

const SlideListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Formlayout)

export default SlideListWithConnect
