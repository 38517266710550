import './AccountStyle.less'

import { Button, Col, Modal, Row, Space, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { getToken, setDefaultCookies } from '../../utils/common'

import OtpInput from 'react-otp-input';
import { authContext } from 'contexts/AuthContext'
import axios from 'axios'
import iconCorrect from 'images/correct@3x.png'
import moment from 'moment'
import styled from 'styled-components'

let eventTime, currentTime, duration, interval, intervalId;

export default function Otp(props) {
  const { setAuthData } = useContext(authContext)

  const [otp, setOtp] = useState('')
  const [otpErr, setOtpErr] = useState(false)
  const [timeOutOtp, setTimeoutOtp] = useState(0)
  const [contact, setContact] = useState('')
  const [ref_code, setRefCode] = useState('')
  const [forgot_password, setForgotPassword] = useState('')
  const [openModel, setOpenModal] = useState(false)
  const [isLoading, setIsloading] = useState(false)
  const otpType = localStorage.getItem('otp_type') || 'forgot_password'

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    }
  }, [])

  useEffect(() => {
    countTimeOtp()
    setContact(localStorage.getItem('contact') ? localStorage.getItem('contact') : '')
    setRefCode(localStorage.getItem('ref_code') ? localStorage.getItem('ref_code') : '')
    setForgotPassword(localStorage.getItem('forgot_password') ? localStorage.getItem('forgot_password') : '')
  }, [])

  const countTimeOtp = () => {
    interval = 1000;
    eventTime = moment(localStorage.getItem('time_count'));
    currentTime = moment();
    duration = moment.duration(eventTime.diff(currentTime));
    intervalId = setInterval(function () {
      duration = moment.duration(duration - interval, 'milliseconds');
      if (duration.asSeconds() <= 0) {
        clearInterval(intervalId);
        setTimeoutOtp(-1)
      } else {
        setTimeoutOtp(duration.seconds() < 10 ? '0' + duration.seconds() : duration.seconds())
      }
    }, interval);
  }

  const blurInput = () => {
    let elmnt = document.getElementsByTagName("input")[5];
    if (elmnt) {
      elmnt.blur();
    }
  }

  const handleChange = (otp) => {
    if (otp.length === 6) {
      blurInput()
    }

    setOtp(otp)

    if (otp.length === 6) {
      message.loading({ content: 'Loading...' })
      const requireOtp = {
        contact: contact,
        channel: "email",
        service: "seeitlive",
        ref_code: ref_code,
        otp_code: otp
      }

      const requireOtpFP = {
        call_function: 'update-password',
        update_data: forgot_password,
        ...requireOtp
      }
      switch (otpType) {
        case 'verify':
          otpVerify()
          otpVerify(`${process.env.REACT_APP_MSG}/otp/verify`, requireOtp, {
            headers: {
              'Accept-Language': 'en',
              'Token': getToken('token'),
            }
          }, {
          })
          break;
        case 'forgot_password':
          otpVerify(`${process.env.REACT_APP_MSG}/otp/verify`, requireOtpFP)
          break;
        default:
          break;
      }
    }
  };

  const otpVerify = async (url, requireOtp, headers) => {
    axios.post(url, requireOtp, headers)
      .then(async (response) => {
        message.destroy()
        if (response?.data?.code === 200) {
          setOpenModal(true)
        } else {
          setOtpErr(true)
          setOtp('')
        }
      })
      .catch((error) => {
        message.destroy()
        console.log('error', error?.response?.data?.errors)

        if (error?.response?.data?.errors[0].subCode === 'MAX_VERIFY') {
          message.error('Number of max verification attempts has been reached')
        } else if (error?.response?.data?.errors[0].subCode === 'EXPIRED_VERIFY') {
          message.error('Verify otp expired')
        } else {
          // message.error('พบข้อผิดพลาดในการทำงาน')
        }

        blurInput()
        setOtpErr(true)
        setOtp('')
        throw error
      })

  }

  const onGetOtp = (values) => {
    clearInterval(intervalId);
    setIsloading(true)
    const requireVerify = {
      contact: contact,
      service: "seeitlive",
      channel: "email",
      otp_code_length: 6,
      ref_code_length: 6,
      verify_max: 3,
      expire_time: 60
    }

    message.loading({ content: 'Loading...' })
    axios.post(`${process.env.REACT_APP_MSG}/otp/request`, requireVerify)
      .then(async (response) => {
        message.destroy()
        setIsloading(false)
        if (response?.data?.code === 200) {
          localStorage.setItem('time_count', moment().add(61, 'seconds'))
          localStorage.setItem('ref_code', response?.data?.data?.ref_code)
          setRefCode(response?.data?.data?.ref_code)
          countTimeOtp()
          setOtpErr(false)
        } else {
          message.error(response?.data?.message)
        }
      })
      .catch((error) => {
        setIsloading(false)
        message.destroy()
        if (error?.response?.data?.errors[0].subCode === 'MAX_REQUEST') {
          message.error('Request more than 3 times within 5 minutes')
        }
        console.log('error', error?.response?.data?.errors)
        throw error
      })
  }

  const handleClickOk = async () => {
    switch (otpType) {
      case 'verify':
        setOpenModal(false);
        localStorage.setItem('isLogin', true)
        await setDefaultCookies()
        await setAuthData(getToken('token'))
        props.history.push('/');
        break;
      case 'forgot_password':
        localStorage.setItem('forgot_password', '')
        setOpenModal(false);
        props.history.push('/login');
        break;
      default:
        break;
    }

  }

  return (
    <div>
      <LayoutOtp>
        <Row align="center" justify="center">
          <Col span="24" className="text-align-center">
            <Title otpErr={otpErr}>
              {otpErr ? otpType === 'forgot_password' ? 'รหัส OTP ไม่ถูกต้อง' : 'รหัส OTP ยืนยันตัวตนไม่ถูกต้อง' : otpType === 'forgot_password' ? 'กรุณาระบุรหัส OTP' : 'กรุณาระบุรหัส OTP ยืนยันตัวตน'}
            </Title>
            <LabelSendTo>รหัสได้ถูกส่งไปยังอีเมล</LabelSendTo>
            <LabelMail>{contact}</LabelMail>
            <LabelRef>รหัสอ้างอิง ({ref_code})</LabelRef>
          </Col>
          <Col span={24}>
            <div id="otp-blok">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                hasErrored={otpErr}
                shouldAutoFocus={true}
                className="otp-input"
                separator={<span style={{ width: '20px' }}>  </span>}
                errorStyle={{ border: '1px solid #FF0000' }}
                isDisabled={!localStorage.getItem('contact') || isLoading ? true : false}
                isInputNum
              />
            </div>
            <div className="btn-account text-align-center">
              {timeOutOtp >= 0 ?
                <TimeCount>{timeOutOtp === 0 ? '' : `00:${timeOutOtp}`}</TimeCount> :
                <Button
                  type="link"
                  htmlType="submit"
                  className="otp-button"
                  onClick={() => { !isLoading && onGetOtp() }}
                  disabled={!localStorage.getItem('contact') || isLoading ? true : false}
                >
                  ส่งรหัส OTP อีกครั้ง
                </Button>
              }
            </div>
          </Col>
        </Row>
      </LayoutOtp >

      <Modal
        visible={openModel}
        footer={false}
        closable={false}
        centered={true}
        width={'420px'}
      >
        <BoxModel height="220px">
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img
                alt="example"
                src={iconCorrect}
                width={48}
                height={48}
              />
            </Col>
            <Col span={24}>
              <HeaderModel>
                {otpType === 'forgot_password' ? 'รีเซ็ตรหัสผ่านสำเร็จแล้ว' : 'ยืนยันตัวตนสำเร็จแล้ว'}
              </HeaderModel>
            </Col>
            <Col span={24} >
              <TextModel>
                {otpType === 'forgot_password' ? 'กด ‘ตกลง’ เพื่อกลับไปยังหน้าเข้าสู่ระบบ' : 'กด ‘ตกลง’ เพื่อไปยังหน้าหลัก '}
              </TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button onClick={() => {
                    handleClickOk()
                  }}
                    type="primary" style={{ width: '170px' }}>
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
    </div>
  )
}

const TimeCount = styled.h1`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  margin-top:20px;
  color:#000000;
`

const Title = styled.h1`
  font-size: 35px;
  margin-bottom:0px;
  color:${props => props.otpErr ? '#FF0000' : '#000000'};
`

const LabelSendTo = styled.h4`
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin-bottom:0px;
`

const LabelRef = styled.h4`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #818181;
  margin-bottom: 28px;
`

const LabelMail = styled.h4`
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 28px;
`

const LayoutOtp = styled.div`
  width: 460px;
  height: 100px;
  position: absolute;
  top: -200px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  display: flex;
  justify-content: center;
`

const BoxModel = styled.div`
  min-height: ${(props) => (props.minHeight)};
  height: ${(props) => (props.height)};
  justify-content: center;
  align-items: center;
  display: flex;
`

const HeaderModel = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
`

const TextModel = styled.div`
  font-size: 18px;
  text-align: center ;
  margin-top: ${(props) => (props.marginTop)};
`