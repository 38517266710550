import React, { useEffect, useState } from "react";

import ConntentApp from "./components/routes/routes";
// import Footer from "./components/footer";
import Header from "./components/header";
import { Layout } from "antd";
import Loading from "components/common/loading";
import { MenuAuth } from "contexts/menuAuth";
import Page500 from "components/page/page500";
import Sider from "./components/sidebar";
import { getToken } from "utils/common.js";
import { httpClient } from "HttpClient";
import isEqual from "lodash/isEqual";
import jwtDecode from "jwt-decode";
import settings from "../src/config";
import styled from "styled-components";

const { Content } = Layout;
const envName = settings.REACT_APP_ENV;

function App(props) {
  const [breadcrumb, setBreadcrumbs] = useState([]);
  const [nameUSer, setNameUser] = useState("");
  const [showVersion, setShowVersion] = useState(false);
  const [menuAuth, setMenuAuth] = useState(undefined);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const token = getToken("token");

  useEffect(() => {
    if (token && token !== "null") {
      let jwt = jwtDecode(token);
      setNameUser(`${jwt.first_name} ${jwt.last_name}`);
      getMenuAuthor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameUSer]);

  useEffect(() => {
    const env = process.env.NODE_ENV;
    if (env === "development" || envName === "development") {
      setShowVersion(true);
    }
  }, []);

  useEffect(() => {
    if (props.location.pathname === "/dashboardga") {
      //for route home profile
      props.history.replace("/")
    }
  });

  const setBreadcrumb = (val) => {
    const check_Val = !isEqual(JSON.stringify(breadcrumb), JSON.stringify(val));
    if (check_Val) setBreadcrumbs(val);
  };

  const getMenuAuthor = () => {
    const baseUrl = `${process.env.REACT_APP_AUTHOR}/me/permissions`;
    httpClient
      .get(baseUrl)
      .then((response) => {
        switch (response?.data?.code) {
          case 200:
            setMenuAuth(response?.data?.data);
            setIsLoadingData(true);
            break;

          default:
            setIsLoadingData(true);
            break;
        }
      })
      .catch((error) => {
        setIsLoadingData(true);
        throw error;
      });
  };

  return (
    <MenuAuth.Provider value={{ menuAuth, setMenuAuth }}>
      {!isLoadingData ? (
        <Loading />
      ) : !menuAuth ? (
        <Page500 />
      ) : (
        <Layout style={{ minHeight: "100vh", minWidth: "1440px" }}>
          <Sider
            {...props}
            setBreadcrumb={setBreadcrumb}
            showVersion={showVersion}
          />
          <Layout className="site-layout">
            <Header
              data={{ name: nameUSer }}
              breadcrumb={breadcrumb}
              {...props}
            ></Header>

            <Content style={{ margin: props.location.pathname === '/' ? "0px" : "0 16px" }}>
              <BoxContent padding={props.location.pathname === '/' ? "0px" : "24px 24px 0 24px"}>
                <ConntentApp />
              </BoxContent>
            </Content>
            {/* <Footer /> */}
          </Layout>
        </Layout>
      )}
    </MenuAuth.Provider>
  );
}

const BoxContent = styled.div`
  padding: ${props => props.padding};
  height: 100%;
  background: #f0f2f5 !important;
`;

export default App;
