import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import SvgEye from 'images/icon-eye.svg'
import SvgPassword from 'images/icon-password.svg'
import iconErr from 'images/icon-error@3x.png'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import SvgEyeLine from 'images/icon-eye-line.svg'
import { Form, Input, Button, Row, Col, Modal, Space, message, Tooltip } from 'antd'
import iconSucc from 'images/correct@3x.png'
import { useHistory } from 'react-router'
import axios from 'axios'

import './AccountStyle.less'

export default function SetPassword(props) {

  const [messagePasswordError, setMessagePasswordError] = useState('')
  const [messageConfirmPasswordError, setMessageConfirmPasswordError] = useState('')
  const [openModal, setOpenModal] = useState({ openSuccess: false, tokenError: false })
  const [isLoading, setIsloading] = useState(false)
  const [msg, setMsg] = useState(false)
  const [requiredCon, setRequiredCon] = useState(true)

  const history = useHistory()

  const textValidName = (
    <span>ใช้อักขระ 8 ตัวขึ้นไป ที่มีทั้งตัวอักษรและตัวเลขผสมกัน</span>
  )

  useEffect(() => {
    localStorage.clear()
    const activateToken = props.location.search.substring(7)
    const token = {
      activate_token: activateToken
    }
    axios.post(`${process.env.REACT_APP_AUTHEN}/activate/verify`, token)
      .then(async (response) => {
        setIsloading(false)
        if (response?.data?.code === 200) {
          setOpenModal({ ...openModal, openSuccess: false, tokenError: false })
        }
        else {
          setOpenModal({ ...openModal, openSuccess: false, tokenError: true })
        }
        message.destroy()
      }).catch((error) => {
        setOpenModal({ ...openModal, openSuccess: false, tokenError: true })
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onFinish = (value) => {
    const activateToken = props.location.search.substring(7)
    const requireConfirmPassword = {
      activate_token: activateToken,
      password: value.Password,
    }
    localStorage.clear()
    setIsloading(true)
    onSetDefaultErrMsg()
    message.loading({ content: 'Loading...' })
    axios
      .post(`${process.env.REACT_APP_AUTHEN}/me/activate`, requireConfirmPassword)
      .then(async (response) => {
        setIsloading(false)
        if (response?.data?.code === 200) {
          setOpenModal({ ...openModal, openSuccess: true, tokenError: false })
        } else {
          setOpenModal({ ...openModal, openSuccess: false, tokenError: true })
        }
        message.destroy()
      })
      .catch((error) => {
        setIsloading(false)
        message.destroy()
        setOpenModal({ ...openModal, openSuccess: false, tokenError: true })
      })
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo?.errorFields.length) {
      errorInfo.errorFields.map((err) => {
        if (err.name.toString() === 'Password') {
          setMessagePasswordError(err.errors)
        } else {
          if (!errorInfo.values.ConfirmPassword) {
            setMessageConfirmPasswordError(err.errors)
          }
          if (errorInfo.values.Password) {
            setMsg(false)
            setMessageConfirmPasswordError('')
            if (errorInfo.values.Password && errorInfo.values.ConfirmPassword) {
              setMsg(true)
              setMessageConfirmPasswordError(err.errors)
            } else {
              const re = /^(?!.* )(?=.*?)(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
              const checkPass = re.test(errorInfo?.values.Password)
              if (!checkPass || errorInfo?.values.Password.length < 8) {
                setRequiredCon(false)
                setMessageConfirmPasswordError('')
              }
              else {
                setMsg(false)
                setMessageConfirmPasswordError(err.errors)
              }
            }
          }
        }
        return err
      })
    }
  }


  const onSetDefaultErrMsg = () => {
    setMessagePasswordError('')
    setMessageConfirmPasswordError('')
  }

  const onChangePasswordError = () => {
    setMsg(false)
    setRequiredCon(true)
    setMessagePasswordError('')
  }

  const onChangeConfirmPasswordError = () => {
    setMsg(false)
    setRequiredCon(true)
    setMessageConfirmPasswordError('')
  }

  const goLogin = () => {
    history.push('/login');
    window.location.reload()
  }

  return (
    <>
      <LayoutSetPassword>
        <Row align="center" justify="center">
          <Col span="24" className="text-align-center">
            <Title>ตั้งค่ารหัสผ่าน</Title>
          </Col>
          <Col span={24}>
            <Form
              {...layout}
              name="basic"
              className="ConfrimPassword-form"
              onFinish={!isLoading && onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                className={messagePasswordError || msg === true ? 'inp-login inp-account msg-err password-msg-err' : 'inp-login inp-account'}
                name="Password"
                validateStatus={messagePasswordError ? 'error' : ''}
                help={messagePasswordError}
                rules={[
                  {
                    required: true,
                    message: 'Password is required !!',
                  }
                  , {
                    pattern: /^(?!.* )(?=.*?)(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                    message: 'Password is invalid !!',
                  },
                ]}
              >
                <Input.Password
                  prefix={<img src={SvgPassword} width="16" height="16" alt="password" />}
                  type="password"
                  autoComplete="off"
                  placeholder="รหัสผ่าน"
                  iconRender={visible => (visible ?
                    <img src={SvgEye} width="16" height="16" alt="Show password" /> :
                    <img src={SvgEyeLine} width="16" height="16" alt="Hide password" />)
                  }
                  name="Password"
                  className='inp-password-value'
                  onChange={onChangePasswordError}
                />
              </Form.Item>
              <TooltipPass >
                <Tooltip
                  className="sil-toottip-custom-width"
                  placement="top"
                  title={textValidName}
                >
                  <ExclamationCircleOutlined
                    style={{ fontSize: '18px', marginTop: '38px' }}
                  />
                </Tooltip>
              </TooltipPass>

              <Form.Item
                className={messageConfirmPasswordError ? 'inp-login inp-account msg-err ' : 'inp-login inp-account'}
                name="ConfirmPassword"
                validateStatus={messageConfirmPasswordError ? 'error' : ''}
                help={messageConfirmPasswordError}
                rules={[
                  {
                    required: requiredCon,
                    message: 'Confirm password is required !!',
                  }
                  ,
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('Password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Password don’t match. Please try again.'))
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<img src={SvgPassword} width="16" height="16" alt="password" />}
                  className='inp-ConfirmPassword-value'
                  type="password"
                  autoComplete="off"
                  placeholder="ยืนยันรหัสผ่าน"
                  iconRender={visible => (visible ?
                    <img src={SvgEye} width="16" height="16" alt="Show password" /> :
                    <img src={SvgEyeLine} width="16" height="16" alt="Hide password" />)
                  }
                  name="ConfirmPassword"
                  onChange={onChangeConfirmPasswordError}
                />
              </Form.Item>
              <Form.Item {...tailLayout} className="btn-confirm">
                <div>
                  <Button
                    type="primary"
                    htmlType="submit"
                  >
                    ยืนยัน
                </Button>
                </div>

              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Modal
          visible={openModal.openSuccess}
          footer={false}
          closable={false}
          centered={true}
          width={'528px'}
        >
          <BoxModel height="309px">
            <Row>
              <Col span={24} style={{ textAlign: 'center', marginTop: '14px' }}>
                <img alt="Icon Success" src={iconSucc} width={58} height={58} />
              </Col>
              <Col span={24}>
                <HeaderModel>
                  ตั้งค่ารหัสผ่านสำเร็จแล้ว
                </HeaderModel>
              </Col>
              <Col span={24}>
                <TextModel marginTop="11px">
                  กด ‘ตกลง’ เพื่อออกจากหน้านี้
                </TextModel>
              </Col>
              <Col span={24}>
                <TextModel marginTop="52px">
                  <Space>
                    <Button
                      onClick={goLogin}
                      type="primary"
                      style={{ width: '168px' }}
                    >
                      ตกลง
                    </Button>
                  </Space>
                </TextModel>
              </Col>
            </Row>
          </BoxModel>
        </Modal>

        <Modal
          visible={openModal.tokenError}
          footer={false}
          closable={false}
          centered={true}
          width={'528px'}
        >
          <BoxModel height="309px">
            <Row>
              <Col span={24} style={{ textAlign: 'center', marginTop: '14px' }}>
                <img alt="Icon Error" src={iconErr} width={58} height={58} />
              </Col>
              <Col span={24}>
                <HeaderModel>
                  ลิงค์หมดอายุ
                </HeaderModel>
              </Col>
              <Col span={24} >
                <TextModel style={{ marginRight: '10px' }}>
                  กรุณาติดต่อแอดมิน
                </TextModel>
              </Col>
              <Col span={24}>
                <TextModel marginTop="48px">
                  <Space>
                    <Button
                      onClick={goLogin}
                      type="primary"
                      style={{ width: '168px' }}
                    >
                      ตกลง
                    </Button>
                  </Space>
                </TextModel>
              </Col>
            </Row>
          </BoxModel>
        </Modal>

      </LayoutSetPassword>
    </>
  );
}

const Title = styled.h1`
   font-size: 35px;
  margin-bottom:45px;
`

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
}

const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
}

const TooltipPass = styled.div`
  left: 375px;
  position: absolute;
  bottom: 183px;
`

const TextModel = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: ${(props) => (props.marginTop)};
`

const HeaderModel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 52px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
`

const BoxModel = styled.div`
  height: ${(props) => (props.height)};
  justify-content: center;
  align-items: center;
  display: flex;
`

const LayoutSetPassword = styled.div`
  width: 300px;
  height: 100px;
  position: absolute;
  top: -200px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
`