import "../../../App.less";

import { Button, Col, Modal, Row, Skeleton, Space, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { deleteList, readData } from "actions/collection";

import { DeleteOutlined } from "@ant-design/icons";
import { MenuAuth } from "contexts/menuAuth";
import Viewlayout from "components/layouts/Viewlayout";
import { connect } from "react-redux";
import { getResourceLoading } from "selectors";
import iconDeleted from "images/correct@3x.png";
import iconWarn from 'images/exclamation.png'
import styled from "styled-components";
import { useHistory } from "react-router";
import { usergroup } from "schemas";
import { mapErrMsg } from '../../../utils/common'

const url = `${process.env.REACT_APP_AUTHEN}`;

const UserGroupView = (props) => {
  const [openDelete, setOpenDelete] = useState(false);
  const history = useHistory();
  const pathName = history.location.pathname;
  const keySlug = pathName.substring(pathName.lastIndexOf("/") + 1);
  const [dataGroup, setDataGroup] = useState();
  const [openDeleteSuccess, setOpenDeleteSuccess] = useState(false);
  const [openDeleteErr, setOpenDeleteErr] = useState({
    open: false,
    errorMsg: "",
  });
  const permissionAuthor = useContext(MenuAuth);
  const [actionPermission, setActionPermission] = useState(undefined);

  const getGroup = () => {
    props.readData(keySlug).then((res) => {
      if (res.code === 200) {
        setDataGroup(res.data);
      } else {
        setDataGroup("");
      }
    });
  };

  const onDeleteGroup = () => {
    setOpenDelete(false);
    message.loading("Save in progress..", 0);
    props.deleteList(keySlug).then((res) => {
      message.destroy();
      setOpenDelete(false);
      if (res?.code === 200) {
        setOpenDeleteSuccess(true);
      } else {
        const arrError = mapErrMsg(res?.errors, 'กลุ่มผู้ใช้งาน')
        setOpenDeleteErr({ open: true, errorMsg: arrError });
      }
    });
  };

  useEffect(() => {
    getGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const author = permissionAuthor?.menuAuth?.find(
      (menu) => menu?.key_slug === "users-management"
    );
    const menu = author?.sub_menus?.find(
      (sub) => sub?.key_slug === "user-groups"
    );
    setActionPermission(menu?.role_permission);
  }, [permissionAuthor]);

  return (
    <>
      <div className="App-form">
        <Viewlayout
          viewData={dataGroup}
          isLoading={props.isLoading}
          permission={actionPermission}
          textTitle="ดูข้อมูลกลุ่มผู้ใช้งาน"
          iconEdit="edit"
          iconDelete="delete"
          textEdit="แก้ไขข้อมูล"
          textDelete="ลบข้อมูล"
          onEdit={() => history.push(`/user-groups/edit/${keySlug}`)}
          onDelete={() => setOpenDelete(true)}
        >
          {dataGroup && (
            <Skeleton className="App-form" loading={!dataGroup} active>
              <Row gutter={[30, 0]}>
                <Col className="sil-break-word" span={12}>
                  <TextLabel>กลุ่มผู้ใช้งาน</TextLabel>
                  {dataGroup.name || "-"}
                </Col>
                <Col className="sil-break-word" span={12}>
                  <TextLabel>คีย์</TextLabel>
                  {dataGroup.key_slug || "-"}
                </Col>

                <Col className="sil-break-word" span={12}>
                  <TextLabel>รายละเอียด</TextLabel>
                  {dataGroup.description || "-"}
                </Col>
                <Col className="sil-break-word" span={12}>
                  <TextLabel>สถานะการใช้งาน</TextLabel>
                  {dataGroup.status === "active" ? (
                    <div style={{ color: "#00C428" }}>ใช้งาน</div>
                  ) : (
                    <div style={{ color: "#FF0000" }}>ไม่ใช้งาน</div>
                  )}
                </Col>
              </Row>
            </Skeleton>
          )}
        </Viewlayout>
      </div>

      <Modal
        visible={openDelete}
        closable={false}
        centered={true}
        onOk={onDeleteGroup}
        onCancel={() => setOpenDelete(false)}
      >
        <Col className="modal-delete">
          <DeleteOutlined style={{ fontSize: "40px" }} />
        </Col>
        <Col className="modal-delete">ต้องการลบข้อมูลใช่หรือไม่</Col>
      </Modal>
      <Modal
        visible={openDeleteSuccess}
        footer={false}
        closable={false}
        centered={true}
        width={"500px"}
      >
        <BoxModel height="180px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="example" src={iconDeleted} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>ลบข้อมูลเสร็จสิ้น</HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button
                    type="primary"
                    style={{ width: "170px" }}
                    onClick={() => history.goBack()}
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal
        visible={openDeleteErr.open}
        footer={false}
        closable={false}
        centered={true}
        width={"500px"}
      >
        <BoxModel height="220px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="example" src={iconWarn} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>ลบข้อมูลไม่สำเร็จ</HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="15px">{openDeleteErr.errorMsg}</TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button
                    type="primary"
                    style={{ width: "170px" }}
                    onClick={() =>
                      setOpenDeleteErr({ open: false, errorMsg: "" })
                    }
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(usergroup, state),
});

const mapDispatchToProps = (dispatch) => ({
  readData: (params) => dispatch(readData(usergroup, params, url)),
  deleteList: (id, params) => dispatch(deleteList(usergroup, id, params, url)),
});

const GroupViewWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserGroupView);

export default GroupViewWithConnect;

const TextLabel = styled.div`
  margin-top: 30px;
  font-size: 16.38px;
  font-weight: 500;
  color: black;
`;

const BoxModel = styled.div`
  height: ${(props) => props.height};
  justify-content: center;
  align-items: center;
  display: flex;
`;
const HeaderModel = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
`;
const TextModel = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: ${(props) => props.marginTop};
`;
