import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Tooltip,
  message,
} from 'antd'
import { createDataFTP, readDataFTP, updateDataFTP } from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import Footeractionlayout from 'components/layouts/Footeractionlayout'
import React from 'react'
import { connect } from 'react-redux'
import { ftppath } from 'schemas'
import { httpClient } from 'HttpClient'
import iconCorrect from 'images/correct@3x.png'
import iconErr from 'images/icon-error@3x.png'
import { resizeUpload } from 'actions/file'

const { Option } = Select

const formRef = React.createRef()

const text_keywords = (
  <div>
    <div>- กรอกเฉพาะตัวเลขและเครื่องหมาย โคลอน( : ) เท่านั้น</div>
  </div>
)

function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str
  return str.substring(0, index) + chr + str.substring(index + 1)
}

class Formlayout extends React.Component {
  state = {
    isDirty: false,
    tempData: {},

    errorMsg: '',
    isCreated: false,
    isCreateError: false,
    isCancel: false,

    serverID: [],
    serverIDOption: [],

    tempServerID: '',
    tempUID: '',

    isDataChanged: false,
    confirmUpdateModal: false,
    ratioSelection: '',

    ratio: '',
  }

  async componentDidMount() {
    this.getServerID()
    if (this.props.match.params.id) {
      await this.props
        .readDataFTP(
          this.props.match.params.id,
          this.props.location.search.split('=')[1]
        )
        .then((res) => {
          this.setState({
            tempServerID: res.data.server_ftp.username,
            tempUID: res.data.path.split('/')[1],
            ratioSelection:
              res.data.ratio === '16:9' || res.data.ratio === '4:3'
                ? res.data.ratio
                : 'custom',
            ratio: res.data.ratio,
          })
        })
    }
  }

  handleCancel = () => this.setState({ isCancel: false })

  handleCancelForm = () => {
    this.setState({ isCancel: true })
  }

  handleSubmit = (value) => {
    const ftp_id = this.state.serverID.find(
      (id) => id.name === value.server_ftp_id.split(' | ')[0]
    )
    if (this.state.isDirty === false) {
      this.setState({ isDirty: true })
      const requestFrom = {
        server_ftp_id: ftp_id.id,
        path: value.path,
        location_name: value.location_name,
        ratio: this.state.ratio,
      }

      message.loading('Save in progress..', 0)
      this.props
        .createDataFTP(requestFrom)
        .then((res) => {
          message.destroy()
          if (res?.code === 201) {
            this.setState({ isDirty: true, isCreated: true }, () => {
              localStorage.setItem('page', 1)
              localStorage.setItem('search', '')
            })
          } else {
            this.setState({
              isDirty: false,
              isCreateError: true,
              errorMsg: res?.message,
            })
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }

  handleUpdate = (value) => {
    const ftp_id = this.state.serverID.find(
      (id) => id.name === value.server_ftp_id.split(' | ')[0]
    )

    if (this.state.isDirty === false) {
      this.setState({ isDirty: true })
      const requestFrom = {
        server_ftp_id: ftp_id.id,
        path: value.path,
        location_name: value.location_name,
        ratio: this.state.ratio,
      }

      message.loading('Save in progress..', 0)
      this.props
        .updateDataFTP(requestFrom, this.props.match.params.id)
        .then((response) => {
          message.destroy()
          if (response?.code === 200) {
            this.setState({ isDirty: true, isCreated: true })
          } else {
            this.setState({
              isDirty: false,
              isCreateError: true,
              errorMsg: response?.message,
            })
          }
        })
    }
  }

  getServerID = () => {
    return httpClient
      .get(`${process.env.REACT_APP_FTP_BASEURL}/admin/ftp-server`)
      .then((response) => {
        this.setState({ serverID: response?.data.data.data_list })
        for (let i = 0; i < this.state.serverID.length; i++) {
          this.state.serverIDOption.push(
            <Option
              key={this.state.serverID[i].username}
              value={`${this.state.serverID[i].name} | ${this.state.serverID[i].host}:${this.state.serverID[i].ftp_port} | ${this.state.serverID[i].username}`}
            >
              {`${this.state.serverID[i].name} | ${this.state.serverID[i].host}:${this.state.serverID[i].ftp_port} | ${this.state.serverID[i].username}`}
            </Option>
          )
        }

        if (!this.props.match.params.id) {
          formRef.current.setFieldsValue({
            server_ftp_id: undefined,
          })
        }
      })
      .catch((error) => {
        throw error
      })
  }

  checkValidPassword = (rule, value) => {
    if (value === null || value === '') {
      return Promise.reject('Password is required !!')
    } else {
      if (
        formRef.current.getFieldsValue().password !==
        formRef.current.getFieldsValue().confirm_password
      ) {
        return Promise.reject('Password not match !!')
      } else {
        return Promise.resolve()
      }
    }
  }

  checkValidIP = (rule, value) => {
    let textValue = value

    if (
      textValue[value.length - 1] === '.' &&
      textValue[value.length - 2] === '.'
    ) {
      textValue = setCharAt(textValue, value.length - 2, '.')
      textValue = setCharAt(textValue, value.length - 1, '')
    }

    if (value === ' ') {
      formRef.current.setFieldsValue({
        host: '',
      })
    } else {
      formRef.current.setFieldsValue({
        host: textValue,
      })
    }

    if (value === null || value === '') {
      return Promise.reject('IP address is required !!')
    } else {
      if (!/^([0-9.]{1,})?$/.test(value)) {
        return Promise.reject('IP address Invalid data format !!')
      } else {
        return Promise.resolve()
      }
    }
  }

  checkValidPort = (rule, value) => {
    if (value === ' ') {
      formRef.current.setFieldsValue({
        port: '',
      })
    } else {
      formRef.current.setFieldsValue({
        port: value,
      })
    }

    if (value === null || value === '') {
      return Promise.reject('Port is required !!')
    } else {
      if (!/^([0-9]{1,})?$/.test(value)) {
        return Promise.reject('Invalid data format !!')
      } else {
        return Promise.resolve()
      }
    }
  }

  onUIDChange = (value) => {
    if (value !== this.state.tempUID) {
      this.setState({ isDataChanged: true })
    } else {
      this.setState({ isDataChanged: false })
    }
  }

  confirmUpdate = () => {
    this.setState({ confirmUpdateModal: true })
  }

  validRatio = (rule, value) => {
    let ratio = value.split(':')
    if (value) {
      if (ratio.length !== 2 || (ratio.length === 2 && !ratio[1])) {
        return Promise.reject('Invalid data format !!')
      } else {
        return Promise.resolve()
      }
    } else {
      return Promise.reject('Ratio is required !!')
    }
  }

  render() {
    const { dataList, isLoading } = this.props
    return isLoading === true ? (
      <Skeleton className="App-form" loading={isLoading} active></Skeleton>
    ) : (
      <Form
        ref={formRef}
        layout="vertical"
        className="App-form"
        onFinish={
          this.props.match.params.id
            ? this.state.isDataChanged
              ? this.confirmUpdate
              : this.handleUpdate
            : this.handleSubmit
        }
      >
        <Form.Item style={{ marginTop: '-20px' }}>
          <h1 className="topic">
            {this.props.match.params.id
              ? 'แก้ไขข้อมูลการตั้งค่าเส้นทาง FTP'
              : 'เพิ่มข้อมูลการตั้งค่าเส้นทาง FTP'}
          </h1>
        </Form.Item>
        <Row gutter={[20, 10]}>
          <Col span={12}>
            <Form.Item
              style={{ marginTop: '-5px' }}
              name="location_name"
              label="Location name"
              rules={[
                {
                  required: true,
                  message: 'Location name is required !!',
                },
              ]}
              initialValue={
                this.props.match.params.id && dataList.location_name
                  ? dataList.location_name
                  : ''
              }
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Form.Item
              style={{ marginTop: '0px' }}
              name="server_ftp_id"
              label="FTP server"
              rules={[
                {
                  required: true,
                  message: 'Server FTP ID is required !!',
                },
              ]}
              initialValue={
                this.props.match.params.id && dataList.server_ftp?.name
                  ? `${dataList.server_ftp.name} | ${dataList.server_ftp.host}:${dataList.server_ftp.ftp_port} | ${dataList.server_ftp.username}`
                  : null
              }
            >
              <Select showSearch placeholder="เลือก">
                {this.state.serverIDOption}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 10]}>
          <Col span={12}>
            <Form.Item
              style={{ marginTop: '-15px' }}
              name="path"
              label="Path (UID)"
              rules={[
                {
                  required: true,
                  message: 'Path (UID) is required!!',
                },
              ]}
              initialValue={
                this.props.match.params.id && dataList.path
                  ? dataList.path.split('/')[dataList.path.split.length]
                  : ''
              }
            >
              <Input
                autoComplete="off"
                onChange={(e) => this.onUIDChange(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Form.Item
              name="ratio"
              label="Ratio"
              rules={[
                {
                  required: true,
                  message: 'Server FTP ID is required !!',
                },
              ]}
              initialValue={
                (this.props.match.params.id &&
                  dataList.ratio &&
                  dataList.ratio === '4:3') ||
                  (this.props.match.params.id &&
                    dataList.ratio &&
                    dataList.ratio === '16:9')
                  ? dataList.ratio
                  : this.props.match.params.id
                    ? 'custom'
                    : undefined
              }
            >
              <Select
                placeholder="เลือก"
                onChange={(e) => this.setState({ ratioSelection: e, ratio: e })}
              >
                <Option value="4:3" key="4:3">
                  4:3
                </Option>
                <Option value="16:9" key="16:9">
                  16:9
                </Option>
                <Option value="custom" key="custom">
                  Custom
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {this.state.ratioSelection === 'custom' && (
          <Row gutter={[20, 20]}>
            <Col span={11}>
              <Form.Item
                style={{ marginTop: '-15px' }}
                name="ratio_custom"
                label=""
                rules={[
                  {
                    required: true,
                    validator: this.validRatio,
                    transform: (value) => {
                      let text = ''
                      if (!/^([0-9:]{1,})?$/.test(value)) {
                        text = value.substring(0, value.length - 1)
                        formRef.current.setFieldsValue({
                          ratio_custom: text,
                        })
                      } else {
                        text = value
                      }
                      return text
                    },
                  },
                ]}
                initialValue={
                  this.props.match.params.id && dataList.ratio
                    ? dataList.ratio
                    : undefined
                }
              >
                <Input
                  autoComplete="off"
                  onChange={(e) => this.setState({ ratio: e.target.value })}
                />
              </Form.Item>
            </Col>
            <Col span={1} style={{ padding: 0 }}>
              <Tooltip placement="top" title={text_keywords}>
                <ExclamationCircleOutlined
                  style={{
                    fontSize: '18px',
                    marginTop: '3px',
                    marginLeft: '15px',
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        )}
        <Form.Item shouldUpdate={true}>
          <Footeractionlayout
            onCancle={this.handleCancelForm}
          ></Footeractionlayout>
        </Form.Item>

        <Modal
          visible={this.state.isCreated}
          footer={false}
          closable={false}
          centered={true}
          width={'420px'}
        >
          <div
            style={{
              height: '220px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <img alt="example" src={iconCorrect} width={48} height={48} />
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginTop: '15px',
                }}
              >
                บันทึกข้อมูลสำเร็จแล้ว
              </Col>
              <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                กดตกลงเพื่อออกจากหน้านี้
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '30px',
                }}
              >
                <Space>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() => {
                      this.props.history.goBack()
                    }}
                  >
                    ตกลง
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          visible={this.state.isCreateError}
          footer={false}
          closable={false}
          centered={true}
          width={'500px'}
        >
          <div
            style={{
              height: '220px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <img src={iconErr} width={48} height={48} alt="Icon err" />
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginTop: '15px',
                }}
              >
                บันทึกข้อมูลไม่สำเร็จ
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '15px',
                }}
              >
                {this.state.errorMsg}
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '30px',
                }}
              >
                <Space>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() => {
                      this.setState({ isCreateError: false })
                    }}
                  >
                    ตกลง
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          visible={this.state.confirmUpdateModal}
          footer={false}
          closable={false}
          centered={true}
          width={'600px'}
        >
          <div
            style={{
              height: '180px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Row>
              <Col
                span={24}
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                ยืนยันการแก้ไข
              </Col>
              <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                หากทำการแก้ไข Path จะส่งผลให้ Path เก่าที่ถูกสร้างไว้จะถูกลบออก
              </Col>
              <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                หากต้องการแก้ไขกรุณากด ยืนยัน
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '30px',
                }}
              >
                <Space>
                  <Button
                    style={{ width: '170px' }}
                    onClick={() => this.setState({ confirmUpdateModal: false })}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() =>
                      this.setState(
                        { confirmUpdateModal: false },
                        this.handleUpdate(formRef.current.getFieldsValue())
                      )
                    }
                  >
                    ยืนยัน
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          visible={this.state.isCancel}
          footer={false}
          closable={false}
          centered={true}
          width={'420px'}
        >
          <div
            style={{
              height: '180px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Row>
              <Col
                span={24}
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                ยืนยันการยกเลิก
              </Col>
              <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                หากคุณต้องการยกเลิก
              </Col>
              <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                กรุณากด ยืนยัน
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '30px',
                }}
              >
                <Space>
                  <Button
                    style={{ width: '170px' }}
                    onClick={() => this.handleCancel()}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() => {
                      this.props.history.goBack()
                    }}
                  >
                    ยืนยัน
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
      </Form>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(ftppath, state),
  dataList: getResource(ftppath, state),
})

const mapDispatchToProps = (dispatch) => ({
  createDataFTP: (data) => dispatch(createDataFTP(ftppath, data)),
  readDataFTP: (id) => dispatch(readDataFTP(ftppath, id)),
  updateDataFTP: (data, id) => dispatch(updateDataFTP(ftppath, data, id)),
  addImageData: (params) => dispatch(resizeUpload(params)),
})

const PartherGroupListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Formlayout)

export default PartherGroupListWithConnect
