import {
  Col,
  Form,
  Modal,
  Row,
  Skeleton,
  Avatar,
} from 'antd'
import { deleteData, readData } from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'

import DefaultImg from 'images/default-thumbnail.jpg'
import { UserOutlined } from '@ant-design/icons'
import React from 'react'
import Viewlayout from 'components/layouts/Viewlayout'
import { cctv } from 'schemas'
import { connect } from 'react-redux'
import { httpClient } from 'HttpClient'
import { MenuAuth } from "contexts/menuAuth";
import { imageError } from '../../../utils/common'

class view extends React.Component {
  static contextType = MenuAuth;
  state = {
    groupsType: [],
    actionPermission: undefined,
    isCancel: false,
  }

  componentDidMount() {
    this.props.readData(this.props.match.params.id).then(() => {
      this.getGroupsList()
    })
  }

  componentDidUpdate() {
    if (this.context?.menuAuth !== this.state.actionPermission) {
      this.getPermission()
    }
  }

  getPermission() {
    const author = this.context?.menuAuth?.find((menu) => menu?.key_slug === "cms");
    const menu = author?.sub_menus?.find((sub) => sub?.key_slug === "cctv");
    !this.state.actionPermission && this.setState({ actionPermission: menu?.role_permission });
  }

  getGroupsList = () => {
    return httpClient
      .get(`${process.env.REACT_APP_BASEURL}/master/cctv-groups`)
      .then((response) => {
        this.setState({ groupsType: response?.data?.data })
        return this.state.groupsType
      })
      .catch((error) => {
        throw error
      })
  }

  gotoEdit = () => {
    this.props.history.push(`/cctv/edit/${this.props.match.params.id}`)
  }

  handleCancel = () => this.setState({ previewVisible: false, isCancel: false })

  render() {
    const { dataList, isLoading } = this.props
    const { previewVisible, } = this.state

    return (
      <Form className="App-form">
        <Viewlayout
          permission={
            isLoading === false &&
            {
              ...this.state.actionPermission,
              can_update: dataList.status_approval === 'draft' || dataList.status_approval === 'revise' ? this.state.actionPermission?.can_update : false
            }}
          viewData={dataList}
          isLoading={isLoading}
          iconEdit="edit"
          textTitle="ข้อมูล CCTV"
          textEdit="แก้ไขข้อมูล"
          onEdit={this.gotoEdit}
        >
          <div style={{ width: '100%' }}>
            {isLoading === true ? (
              <Skeleton
                className="App-form"
                loading={isLoading}
                active
              ></Skeleton>
            ) : (
              <Form.Item>
                <Row>
                  <Col>
                    <h1>รูปภาพ</h1>
                    <Avatar
                      size={80}
                      src={dataList ? dataList?.cover_url : DefaultImg}
                      icon={<UserOutlined />}
                      onClick={() => this.setState({ previewVisible: true })}
                      style={{ cursor: 'pointer' }}
                      onError={imageError}
                    />
                  </Col>
                </Row>
                <Row gutter={[100, 0]}>
                  <Col
                    className="sil-break-word"
                    style={{ marginTop: '30px' }}
                    span={12}
                  >
                    <h1>ชื่อโครงการ</h1>
                    {dataList.name ? dataList.name.th : ''}
                  </Col>
                  <Col
                    className="sil-break-word"
                    style={{ marginTop: '30px' }}
                    span={12}
                  >
                    <h1>Project Name</h1>
                    {dataList.name ? dataList.name.en : ''}
                  </Col>
                </Row>
                <Row gutter={[100, 0]}>
                  <Col
                    className="sil-break-word"
                    style={{ marginTop: '30px', wordBreak: 'break-word' }}
                    span={12}
                  >
                    <h1>รายละเอียด</h1>
                    {dataList.description && dataList.description.th
                      ? dataList.description.th
                      : '-'}
                  </Col>
                  <Col
                    className="sil-break-word"
                    style={{ marginTop: '30px', wordBreak: 'break-word' }}
                    span={12}
                  >
                    <h1>Detail</h1>
                    {dataList.description && dataList.description.en
                      ? dataList.description.en
                      : '-'}
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: '30px' }}>
                    <h1>กลุ่มผู้ใช้งาน</h1>
                    {dataList.user_groups ? dataList.user_groups : '-'}
                  </Col>
                </Row>
                <Row>
                  <Col style={{
                    marginTop: '30px',
                    color: dataList.status_approval === 'pending_publish' ?
                      '#FFA800' : dataList.status_approval === 'approve' ?
                        '#00C428' : dataList.status_approval === 'reject' ?
                          '#ff0000' : dataList.status_approval === 'revise' ?
                            '#FF7F50' : '#000000D9'
                  }}>
                    <h1>สถานะการอนุมัติ</h1>
                    {dataList.status_approval ?
                      dataList.status_approval === 'pending_publish' ?
                        'รออนุมัติ' : dataList.status_approval === 'approve' ?
                          'อนุมัติ' : dataList.status_approval === 'reject' ?
                            'ไม่อนุมัติ' : dataList.status_approval === 'revise' ?
                              'ปรับปรุงใหม่' : 'แบบร่าง' : '-'}
                  </Col>
                </Row>
                <Row>
                  <Col style={{
                    marginTop: '30px',
                    color: dataList.status === "active" ? "#00c48c" : "#ff0000",
                  }}>
                    <h1>สถานะกล้อง</h1>
                    {dataList.status ? dataList.status === 'active' ? 'ใช้งาน' : 'ไม่ใช้งาน' : '-'}
                  </Col>
                </Row>

                <Modal
                  visible={previewVisible}
                  footer={null}
                  onCancel={this.handleCancel}
                  width={800}
                >
                  <img
                    alt="example"
                    style={{ width: '100%', marginTop: '20px' }}
                    src={dataList ? `${dataList?.cover_url}?w=360` : DefaultImg}
                  />
                </Modal>
              </Form.Item>
            )}
          </div>
        </Viewlayout>
      </Form>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(cctv, state),
  dataList: getResource(cctv, state),
})

const mapDispatchToProps = (dispatch) => ({
  readData: (id) => dispatch(readData(cctv, id)),
  deleteData: (id) => dispatch(deleteData(cctv, id)),
})

const SlideListWithConnect = connect(mapStateToProps, mapDispatchToProps)(view)

export default SlideListWithConnect
