import * as schemas from "./schema";

import { httpClient } from "HttpClient";

export const GET_LIST_REQUEST = "GET_LIST_REQUEST";
export const GET_LIST_SUCCEED = "GET_LIST_SUCCEED";
export const GET_LIST_FAILED = "GET_LIST_FAILED";

export const CREATE_DATA_REQUEST = "CREATE_DATA_REQUEST";
export const CREATE_DATA_SUCCEED = "CREATE_DATA_SUCCEED";
export const CREATE_DATA_FAILED = "CREATE_DATA_FAILED";

export const UPDATE_DATA_REQUEST = "UPDATE_DATA_REQUEST";
export const UPDATE_DATA_SUCCEED = "UPDATE_DATA_SUCCEED";
export const UPDATE_DATA_FAILED = "UPDATE_DATA_FAILED";

export const READ_DATA_REQUEST = "READ_DATA_REQUEST";
export const READ_DATA_SUCCEED = "READ_DATA_SUCCEED";
export const READ_DATA_FAILED = "READ_DATA_FAILED";

export const DELETE_DATA_REQUEST = "DELETE_DATA_REQUEST";
export const DELETE_DATA_SUCCEED = "DELETE_DATA_SUCCEED";
export const DELETE_DATA_FAILED = "DELETE_DATA_FAILED";

const qs = require("qs");

const getListRequest = (schema) => ({
  type: GET_LIST_REQUEST,
  schema,
});

const getListSucceed = (schema, payload) => ({
  type: GET_LIST_SUCCEED,
  schema,
  payload: schemas.responseOfSchema(payload.data_list || [], schema),
  pagination: payload.pagination,
});

const getListFailed = (schema, error) => ({
  type: GET_LIST_FAILED,
  schema,
  error,
});

const createDataRequest = (schema) => ({
  type: CREATE_DATA_REQUEST,
  schema,
});

// const createDataSucceed = (schema, payload, code) => ({
//   type: CREATE_DATA_SUCCEED,
//   schema,
//   payload,
// })

const createDataFailed = (schema, payload) => ({
  type: CREATE_DATA_FAILED,
  schema,
  payload,
});

const updateDataRequest = (schema) => ({
  type: UPDATE_DATA_REQUEST,
  schema,
});

// const updateDataSucceed = (schema, payload) => ({
//   type: UPDATE_DATA_SUCCEED,
//   schema,
//   payload,
// })

const updateDataFailed = (schema, payload) => ({
  type: UPDATE_DATA_FAILED,
  schema,
  payload,
});

const deleteDataRequest = (schema) => ({
  type: DELETE_DATA_REQUEST,
  schema,
});

// const deleteDataSucceed = (schema, payload) => ({
//   type: DELETE_DATA_SUCCEED,
//   schema,
//   payload,
// })

const deleteDataFailed = (schema, payload) => ({
  type: DELETE_DATA_FAILED,
  schema,
  payload,
});

const readDataRequest = (schema) => ({
  type: READ_DATA_REQUEST,
  schema,
});

const readDataSucceed = (schema, payload) => ({
  type: READ_DATA_SUCCEED,
  schema,
  payload,
});

const readDataFailed = (schema, payload) => ({
  type: READ_DATA_FAILED,
  schema,
  payload,
});

export const getList = (schema, params, url) => (dispatch, getState) => {
  const baseUrl = url ? url : process.env.REACT_APP_BASEURL;
  dispatch(getListRequest(schema));

  return httpClient
    .get(`${baseUrl}/${schema}`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    })
    .then((response) => {
      switch (response?.data?.code) {
        case 200:
          setTimeout(() => {
            dispatch(getListSucceed(schema, response?.data?.data));
          }, 1000);
          return response?.data;
        default:
          dispatch(getListFailed(schema, response?.data));
          break;
      }
      return response?.data;
    })
    .catch((error) => {
      console.warn(error);
      throw error;
    });
};

export const deleteList = (schema, id, params, url) => (dispatch, getState) => {
  const baseUrl = url ? url : process.env.REACT_APP_BASEURL;
  dispatch(deleteDataRequest(schema));
  return httpClient
    .delete(`${baseUrl}/${schema}/${id}`)
    .then((response) => {
      switch (response?.data?.code) {
        case 200:
          dispatch(getList(schema, params, baseUrl));
          break;
        default:
          dispatch(deleteDataFailed(schema, response?.data));
          break;
      }
      return response?.data;
    })
    .catch((error) => {
      console.warn(error);
      throw error;
    });
};

export const getListFTP = (schema, params) => (dispatch, getState) => {
  const url = `${process.env.REACT_APP_BASEURL}/${schema}`;

  dispatch(getListRequest(schema));

  return httpClient
    .get(url, { params })
    .then((response) => {
      switch (response?.data?.code) {
        case 200:
          setTimeout(() => {
            dispatch(getListSucceed(schema, response?.data?.data));
          }, 1000);
          return response?.data;
        default:
          dispatch(getListFailed(schema, response?.data));
          break;
      }
      return response?.data;
    })
    .catch((error) => {
      console.warn(error);
      throw error;
    });
};

export const deleteListFTP = (schema, id, params) => (dispatch, getState) => {
  const url = `${process.env.REACT_APP_BASEURL}/${schema}/${id}`;

  dispatch(deleteDataRequest(schema));
  return httpClient
    .delete(url)
    .then((response) => {
      switch (response?.data?.code) {
        case 200:
          dispatch(getListFTP(schema, params));
          break;
        default:
          dispatch(deleteDataFailed(schema, response?.data));
          break;
      }
      return response?.data;
    })
    .catch((error) => {
      console.warn(error);
      throw error;
    });
};

export const deleteListMasterServerFTP =
  (schema, id, params) => (dispatch, getState) => {
    const url = `${process.env.REACT_APP_BASEURL}/${schema}/${id}`;

    dispatch(deleteDataRequest(schema));
    return httpClient
      .delete(url)
      .then((response) => {
        switch (response?.data?.code) {
          case 200:
            dispatch(getListFTP(schema, params));
            break;
          default:
            dispatch(deleteDataFailed(schema, response?.data));
            break;
        }
        return response?.data;
      })
      .catch((error) => {
        console.warn(error);
        throw error;
      });
  };

export const deleteData = (schema, id, url) => (dispatch, getState) => {
  const baseUrl = url ? url : process.env.REACT_APP_BASEURL;
  dispatch(deleteDataRequest(schema));
  return httpClient
    .delete(`${baseUrl}/${schema}/${id}`)
    .then((response) => {
      switch (response?.data?.code) {
        case 200:
          break;
        default:
          dispatch(deleteDataFailed(schema, response?.data));
          break;
      }
      return response?.data;
    })
    .catch((error) => {
      console.warn(error);
      throw error;
    });
};

export const deleteDataFTP = (schema, id) => (dispatch, getState) => {
  const url = `${process.env.REACT_APP_BASEURL}/${schema}/${id}`;

  dispatch(deleteDataRequest(schema));
  return httpClient
    .delete(url)
    .then((response) => {
      switch (response?.data?.code) {
        case 200:
          break;
        default:
          dispatch(deleteDataFailed(schema, response?.data));
          break;
      }
      return response?.data;
    })
    .catch((error) => {
      console.warn(error);
      throw error;
    });
};

export const readData = (schema, id, url) => {
  let path = id ? `/${schema}/${id}` : `/${schema}`;
  const baseUrl = url ? url : process.env.REACT_APP_BASEURL;
  return (dispatch) => {
    dispatch(readDataRequest(schema));
    return httpClient
      .get(`${baseUrl}${path}`)
      .then((response) => {
        switch (response?.data?.code) {
          case 200:
            setTimeout(() => {
              dispatch(readDataSucceed(schema, response?.data?.data));
            }, 1000);
            break;

          default:
            dispatch(readDataFailed(schema, response?.data));
            break;
        }
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const readDataFTP = (schema, id) => {
  let path = id
    ? `${process.env.REACT_APP_BASEURL}/${schema}/${id}`
    : `/${schema}`;
  return (dispatch) => {
    dispatch(readDataRequest(schema));
    return httpClient
      .get(path)
      .then((response) => {
        switch (response?.data?.code) {
          case 200:
            setTimeout(() => {
              dispatch(readDataSucceed(schema, response.data.data));
            }, 1000);
            break;

          default:
            dispatch(readDataFailed(schema, response.data));
            break;
        }
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const readDataMasterServerFTP = (schema, id) => {
  let path = id
    ? `${process.env.REACT_APP_BASEURL}/${schema}/${id}`
    : `/${schema}`;
  return (dispatch) => {
    dispatch(readDataRequest(schema));
    return httpClient
      .get(path)
      .then((response) => {
        switch (response?.data?.code) {
          case 200:
            setTimeout(() => {
              dispatch(readDataSucceed(schema, response.data.data));
            }, 1000);
            break;

          default:
            dispatch(readDataFailed(schema, response.data));
            break;
        }
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const createDataMasterServerFTP = (schema, data) => {
  return (dispatch) => {
    dispatch(createDataRequest(schema));
    return httpClient
      .post(`${process.env.REACT_APP_BASEURL}/${schema}`, data)
      .then((response) => {
        switch (response?.data?.code) {
          case 200:
            //dispatch(createDataSucceed(schema, response.data.data))
            break;

          default:
            dispatch(createDataFailed(schema, response.data));
            break;
        }
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateDataMasterServerFTP = (schema, data, id) => {
  return (dispatch) => {
    dispatch(updateDataRequest(schema));
    return httpClient
      .put(`${process.env.REACT_APP_BASEURL}/${schema}/${id}`, data)
      .then((response) => {
        switch (response?.data?.code) {
          case 200:
            break;
          default:
            dispatch(updateDataFailed(schema, response.data));
            break;
        }
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateData = (schema, data, id, url) => {
  const baseUrl = url ? url : process.env.REACT_APP_BASEURL;
  const params = id ? `/${id}` : "";
  return (dispatch) => {
    dispatch(updateDataRequest(schema));
    return httpClient
      .put(`${baseUrl}/${schema}${params}`, data)
      .then((response) => {
        switch (response?.data?.code) {
          case 200:
            break;
          default:
            dispatch(updateDataFailed(schema, response.data));
            break;
        }
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const createData = (schema, data, url) => {
  const baseUrl = url ? url : process.env.REACT_APP_BASEURL;

  return (dispatch) => {
    dispatch(createDataRequest(schema));
    return httpClient
      .post(`${baseUrl}/${schema}`, data)
      .then((response) => {
        switch (response?.data?.code) {
          case 200:
            //dispatch(createDataSucceed(schema, response.data.data))
            break;

          default:
            dispatch(createDataFailed(schema, response.data));
            break;
        }
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const createDataFTP = (schema, data) => {
  return (dispatch) => {
    dispatch(createDataRequest(schema));
    return httpClient
      .post(`${process.env.REACT_APP_BASEURL}/${schema}`, data)
      .then((response) => {
        switch (response?.data?.code) {
          case 200:
            //dispatch(createDataSucceed(schema, response.data.data))
            break;

          default:
            dispatch(createDataFailed(schema, response.data));
            break;
        }
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const createDataFTPtoZM = (schema, data) => {
  return (dispatch) => {
    dispatch(createDataRequest(schema));
    return httpClient
      .post(`${process.env.REACT_APP_BASEURL}/${schema}`, data)
      .then((response) => {
        switch (response?.data?.code) {
          case 200:
            //dispatch(createDataSucceed(schema, response.data.data))
            break;

          default:
            dispatch(createDataFailed(schema, response.data));
            break;
        }
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateDataFTP = (schema, data, id) => {
  return (dispatch) => {
    dispatch(updateDataRequest(schema));
    return httpClient
      .put(`${process.env.REACT_APP_BASEURL}/${schema}/${id}`, data)
      .then((response) => {
        switch (response?.data?.code) {
          case 200:
            break;
          default:
            dispatch(updateDataFailed(schema, response.data));
            break;
        }
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getData = (schema, params) => (dispatch, getState) => {
  const url = `/${schema}`;
  dispatch(readDataRequest(schema));
  return httpClient
    .get(url, { params })
    .then((response) => {
      switch (response?.data?.code) {
        case 200:
          setTimeout(() => {
            dispatch(readDataSucceed(schema, response?.data));
          }, 1000);
          return response?.data;
        default:
          dispatch(readDataFailed(schema, response?.data));
          break;
      }
      return response?.data;
    })
    .catch((error) => {
      console.warn(error);
      throw error;
    });
};

export const getMasterList = (schema, url) => (dispatch, getState) => {
  const baseUrl = url ? url : process.env.REACT_APP_BASEURL;
  dispatch(getListRequest(schema));
  return httpClient
    .get(`${baseUrl}/${schema}`)
    .then((response) => {
      switch (response?.data?.code) {
        case 200:
          return response?.data;
        default:
          break;
      }
      return response?.data;
    })
    .catch((error) => {
      console.warn(error);
      throw error;
    });
};
