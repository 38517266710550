import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  message,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { deleteList, getList } from "actions/collection";
import { getCollectionLoading, getPagination, getStateResult } from "selectors";

import Actions from "components/action";
import DataTableLayout from "components/layouts/DataTableLayout";
import { MenuAuth } from "contexts/menuAuth";
import { connect } from "react-redux";
import iconDeleted from "images/correct@3x.png";
import iconWarn from 'images/exclamation.png'
import styled from "styled-components";
import { usergroup } from "schemas";
import { mapErrMsg } from '../../../utils/common'

const url = `${process.env.REACT_APP_AUTHEN}`;

const UserGroup = (props) => {
  const history = useHistory();
  const [option, setOption] = useState();
  const [dataList, setDataList] = useState();
  const [openDeleteSuccess, setOpenDeleteSuccess] = useState(false);
  const [openDeleteErr, setOpenDeleteErr] = useState({
    open: false,
    errorMsg: "",
  });
  const [dataSearch, setDataSearch] = useState(localStorage.getItem("search"));
  const permissionAuthor = useContext(MenuAuth);
  const [actionPermission, setActionPermission] = useState(undefined);

  const pagination = {
    total: option?.total,
    per_page: option?.per_page,
    current_page: option?.current_page,
    sort_field: option?.sorts.key,
    sort_by: option?.sorts.value,
  };

  const getGroup = (page, perPage) => {
    const params = {
      page,
      per_page: perPage,
      filters: `name:like:${dataSearch}`,
    };
    setDataList(undefined);
    props.getData(params).then((res) => {
      if (res?.code === 200) {
        const list = res?.data ? res?.data.lists : [];
        const dataOption = res?.data ? res?.data.option : null;
        setOption(dataOption);
        const newList =
          list &&
          list.map((item, index) => {
            const order =
              dataOption.per_page * (dataOption.current_page - 1) + (index + 1);
            return { ...item, order: order };
          });
        setDataList(newList);
      } else {
        setDataList([]);
      }
    });
  };

  const onDeleteGroup = (keySlug) => {
    message.loading("Save in progress..", 0);
    props.deleteData(keySlug).then((res) => {
      message.destroy();
      if (res?.code === 200) {
        setOpenDeleteSuccess(true);
      } else {
        const arrError = mapErrMsg(res?.errors, 'กลุ่มผู้ใช้งาน')
        setOpenDeleteErr({ open: true, errorMsg: arrError });
      }
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "order",
      key: "order",
      width: "6%",
    },
    {
      title: "กลุ่มผู้ใช้งาน",
      dataIndex: "name",
      key: "name",
      // ellipsis: true,
      width: "30%",
    },
    {
      title: "คีย์",
      dataIndex: "key_slug",
      key: "key_slug",
      // ellipsis: true,
      width: "20%",
      render: (key_slug) => `${key_slug}`,
    },
    {
      title: "รายละเอียด",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      width: "30%",
      render: (description) => `${description || "-"}`,
    },
    {
      title: "สถานะ",
      key: "status",
      dataIndex: "status",
      width: "10%",
      render: (status) => {
        return (
          <div style={{ color: status === "active" ? "#00c48c" : "#ff0000" }}>
            {status === "active" ? "ใช้งาน" : "ไม่ใช้งาน"}
          </div>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: "7%",
      render: (record) => {
        return (
          <Actions
            record={{ id: record.key_slug, key_slug: record.key_slug }}
            path="/user-groups"
            onDelete={onDeleteGroup}
            history={history}
            permission={actionPermission}
          />
        );
      },
    },
  ];

  useEffect(() => {
    const author = permissionAuthor?.menuAuth?.find(
      (menu) => menu?.key_slug === "users-management"
    );
    const menu = author?.sub_menus?.find(
      (sub) => sub?.key_slug === "user-groups"
    );

    setActionPermission(menu?.role_permission);
  }, [permissionAuthor]);

  const onSearch = () => {
    localStorage.setItem("search", dataSearch);
    getGroup(1, localStorage.getItem("per_page"));
  };

  const onInputChange = (e) => {
    setDataSearch(e.target.value);
  };

  const onCloseDelete = () => {
    getGroup(localStorage.getItem("page"), localStorage.getItem("per_page"));
    setOpenDeleteSuccess(false);
  };

  useEffect(() => {
    getGroup(localStorage.getItem("page"), localStorage.getItem("per_page"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form>
        <Row justify="space-between">
          <Col>
            <Input
              id="input"
              style={{ marginBottom: "30px", width: "20vmax" }}
              onChange={onInputChange}
              placeholder="ค้นหากลุ่มผู้ใช้งาน"
              onPressEnter={onSearch}
              value={dataSearch}
              suffix={
                <SearchOutlined
                  onClick={() => onSearch("click")}
                  style={{ cursor: "pointer" }}
                />
              }
              autoComplete="on"
            />
          </Col>
          {actionPermission && actionPermission?.can_create && (
            <Col>
              <Button type="primary">
                <Link to="/user-groups/create">
                  <PlusOutlined /> เพิ่มข้อมูล
                </Link>
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <Skeleton className="App-form" loading={!dataList} active>
              <DataTableLayout
                dataSource={dataList}
                columns={columns}
                isloading={!dataList}
                pagePagination={pagination}
                getData={(param) => getGroup(param.page, param.per_page)}
              />
            </Skeleton>
          </Col>
        </Row>
      </Form>

      <Modal
        visible={openDeleteSuccess}
        footer={false}
        closable={false}
        centered={true}
        width={"500px"}
      >
        <BoxModel height="180px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="example" src={iconDeleted} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>ลบข้อมูลเสร็จสิ้น</HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button
                    type="primary"
                    style={{ width: "170px" }}
                    onClick={onCloseDelete}
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal
        visible={openDeleteErr.open}
        footer={false}
        closable={false}
        centered={true}
        width={"500px"}
      >
        <BoxModel height="220px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="example" src={iconWarn} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>ลบข้อมูลไม่สำเร็จ</HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="15px">{openDeleteErr.errorMsg}</TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button
                    type="primary"
                    style={{ width: "170px" }}
                    onClick={() =>
                      setOpenDeleteErr({ open: false, errorMsg: "" })
                    }
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  pagination: getPagination(usergroup, state),
  isLoading: getCollectionLoading(usergroup, state),
  dataList: getStateResult(usergroup, state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: (params) => dispatch(getList(usergroup, params, url)),
  deleteData: (id, params) => dispatch(deleteList(usergroup, id, params, url)),
});

const GroupListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserGroup);

export default GroupListWithConnect;

const BoxModel = styled.div`
  height: ${(props) => props.height};
  justify-content: center;
  align-items: center;
  display: flex;
`;
const HeaderModel = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
`;
const TextModel = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: ${(props) => props.marginTop};
`;
