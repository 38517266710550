import {
  Badge,
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Row,
  Skeleton,
  Space,
  message,
} from "antd";
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { deleteListFTP, getListFTP } from "actions/collection";
import { getCollectionLoading, getPagination, getStateResult } from "selectors";

import DataTableLayout from "components/layouts/DataTableLayout";
import { FullDateConvert } from "../../../utils/DateTimeFormat.js";
import { Link } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import { ftppath } from "schemas";
import { getPageShowing } from "utils/common.js";
import iconDeleted from "images/correct@3x.png";
import iconErr from "images/icon-error@3x.png";

class CategoryBlogs extends React.Component {
  state = {
    page: 1,
    per_page: 15,
    search: "",
    visible: false,
    isCreateError: false,
    visibleDeleted: false,
    errorMsg: "",
  };

  componentDidMount() {
    getPageShowing();
    if (
      localStorage.getItem("search") &&
      localStorage.getItem("currSearch") === window.location.pathname
    ) {
      this.setState({ search: localStorage.getItem("search") });
    }
    this.getData();
  }

  getData = () => {
    let params = {
      page: localStorage.getItem("page")
        ? localStorage.getItem("page")
        : this.state.page,
      per_page: localStorage.getItem("per_page")
        ? parseInt(localStorage.getItem("per_page"))
        : this.state.per_page,
      search:
        localStorage.getItem("search") &&
        localStorage.getItem("currSearch") === window.location.pathname
          ? String(localStorage.getItem("search"))
          : this.state.search,
    };
    this.props.getDataFTP(params).then((resopnse) => {
      this.setState({ list: resopnse?.data?.data_list });
    });
  };

  onSearch = (e) => {
    localStorage.setItem("page", 1);
    let params = {
      page: 1,
      per_page: localStorage.getItem("per_page")
        ? parseInt(localStorage.getItem("per_page"))
        : this.state.per_page,
      search: localStorage.getItem("search")
        ? localStorage.getItem("search")
        : this.state.search,
    };
    localStorage.setItem("search", this.state.search);
    localStorage.setItem("currSearch", window.location.pathname);
    this.props.getDataFTP(params).then((res) => {
      this.setState({ list: res.data.data_list });
    });
  };

  setKeyword = (e) => {
    const value = e.target.value;
    localStorage.setItem("search", value);
    this.setState({ search: value });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
    this.deleteList();
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      delete_id: "",
    });
  };

  deleteList = () => {
    const { pagination, dataList } = this.props;
    localStorage.setItem(
      "page",
      dataList.length === 1 && parseInt(localStorage.getItem("page")) > 1
        ? parseInt(localStorage.getItem("page")) - 1
        : this.state.page
    );
    const params = {
      page:
        dataList.length === 1 && pagination.current_page > 1
          ? pagination.current_page - 1
          : this.state.page,
      search: this.state.search,
      per_page: this.state.per_page,
    };

    this.setState({ visible: false }, () => {
      message.loading("Delete in progress..", 0);
      this.props.deleteDataFTP(this.state.delete_id, params).then((res) => {
        if (res?.code === 200) {
          message.destroy();
          this.setState({
            delete_id: "",
            search: "",
            visibleDeleted: true,
          });
        } else {
          message.destroy();
          this.setState({
            isCreateError: true,
            errorMsg: res?.message,
          });
        }
      });
    });
  };

  onClickMenu = (e) => {
    switch (e.key) {
      case "view":
        return this.props.history.push(`/ftp-path/view/${e.item.props.id}`);
      case "edit":
        return this.props.history.push(`/ftp-path/edit/${e.item.props.id}`);
      case "delete":
        return this.setState(
          {
            delete_id: e.item.props.id,
          },
          () => this.showModal()
        );
      default:
        break;
    }
  };

  handleTableChange = (dataRevice) => {
    this.setState({ list: dataRevice });
  };

  listMenu = (record) => {
    return (
      <Menu key={record.id} onClick={this.onClickMenu}>
        <Menu.Item
          key={"view"}
          id={record.id}
          host={record.server_ftp?.host}
          port={record.server_ftp?.service_port}
        >
          <EyeOutlined />
          ดูข้อมูล
        </Menu.Item>
        <Menu.Item
          key={"edit"}
          id={record.id}
          host={record.server_ftp?.host}
          port={record.server_ftp?.service_port}
        >
          <EditOutlined />
          แก้ไขข้อมูล
        </Menu.Item>
        <Menu.Item
          key={"delete"}
          id={record.id}
          host={record.server_ftp?.host}
          port={record.server_ftp?.service_port}
        >
          <DeleteOutlined />
          ลบข้อมูล
        </Menu.Item>
      </Menu>
    );
  };

  columns = [
    {
      title: "#",
      dataIndex: "order",
      key: "order",
      width: "5%",
    },
    {
      title: "Location name",
      dataIndex: "location_name",
      key: "location_name",
      ellipsis: true,
      width: "20%",
      render: (location) => {
        return location || "-";
      },
    },
    {
      title: "Path",
      dataIndex: "path",
      key: "path",
      ellipsis: true,
      width: "25%",
      render: (path) => {
        return path || "-";
      },
    },
    {
      title: "FTP Server / Port",
      dataIndex: "server_ftp",
      key: "server_ftp",
      ellipsis: true,
      width: "20%",
      render: (address) => {
        return `${address?.host} / ${address?.ftp_port}` || "-";
      },
    },
    {
      title: "FTP Username",
      dataIndex: "server_ftp",
      key: "server_ftp",
      ellipsis: true,
      width: "18%",
      render: (name) => {
        return name?.username || "-";
      },
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "created_at",
      key: "created_at",
      width: "15%",
      render: (value) => {
        const formatDay = FullDateConvert(value);
        return formatDay;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Link ZM</div>,
      dataIndex: "connected",
      key: "connected",
      width: "15%",
      render: (connected) => {
        return (
          <div style={{ textAlign: "center" }}>
            {connected ? <CheckOutlined /> : ""}
          </div>
        );
      },
    },
    {
      title: "สถานะ",
      key: "ftp_data_created",
      dataIndex: "ftp_data_created",
      width: "12%",
      render: (ftp_data_created) => {
        return (
          <span>
            <Badge
              status={ftp_data_created ? "success" : "error"}
              text={ftp_data_created ? "มีข้อมูล" : "ไม่มีข้อมูล"}
              style={{
                color: ftp_data_created ? "#00c48c" : "#ff0000",
              }}
            />
          </span>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: "8%",
      render: (record) => {
        return (
          <Dropdown trigger={["click"]} overlay={this.listMenu(record)}>
            <MoreOutlined style={{ fontSize: "22px" }} />
          </Dropdown>
        );
      },
    },
  ];

  render() {
    const { isLoading, pagination, dataList } = this.props;
    return (
      <Form>
        <Row justify="space-between">
          <Col>
            <Input
              style={{ marginBottom: "30px", width: "20vmax" }}
              onChange={this.setKeyword}
              placeholder="ค้นหา Location name ,Path"
              onPressEnter={this.onSearch}
              suffix={
                <SearchOutlined
                  onClick={() => this.onSearch()}
                  style={{ cursor: "pointer" }}
                />
              }
              value={this.state.search}
              autoComplete="on"
            ></Input>
          </Col>
          <Col>
            <Button type="primary">
              <Link to="/ftp-path/create">
                <PlusOutlined /> เพิ่มข้อมูล
              </Link>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {isLoading === true ? (
              <Skeleton
                className="App-form"
                loading={isLoading}
                active
              ></Skeleton>
            ) : (
              <DataTableLayout
                dataSource={dataList}
                columns={this.columns}
                isloading={isLoading}
                pagePagination={pagination}
                getData={this.props.getDataFTP}
                onChange={this.handleTableChange}
                getPermission={this.getActionPermission}
              />
            )}
          </Col>
          <Modal
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <Col className="modal-delete">
              <DeleteOutlined style={{ fontSize: "40px" }} />
            </Col>
            <Col className="modal-delete">ต้องการลบข้อมูลใช่หรือไม่</Col>
          </Modal>
          <Modal
            visible={this.state.visibleDeleted}
            footer={false}
            closable={false}
            centered={true}
            width={"500px"}
          >
            <div
              style={{
                height: "180px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img alt="example" src={iconDeleted} width={48} height={48} />
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  ลบข้อมูลเสร็จสิ้น
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "30px",
                  }}
                >
                  <Space>
                    <Button
                      type="primary"
                      style={{ width: "170px" }}
                      onClick={() => {
                        this.setState({ visibleDeleted: false });
                      }}
                    >
                      ตกลง
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Modal>
          <Modal
            visible={this.state.isCreateError}
            footer={false}
            closable={false}
            centered={true}
            width={"500px"}
          >
            <div
              style={{
                height: "220px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img alt="example" src={iconErr} width={48} height={48} />
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  ลบข้อมูลไม่สำเร็จ
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  {this.state.errorMsg}
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "30px",
                  }}
                >
                  <Space>
                    <Button
                      type="primary"
                      style={{ width: "170px" }}
                      onClick={() => {
                        this.setState({ isCreateError: false, errorMsg: "" });
                      }}
                    >
                      ตกลง
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Modal>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  pagination: getPagination(ftppath, state),
  isLoading: getCollectionLoading(ftppath, state),
  dataList: getStateResult(ftppath, state),
});

const mapDispatchToProps = (dispatch) => ({
  getDataFTP: (params) => dispatch(getListFTP(ftppath, params)),
  deleteDataFTP: (id, params) => dispatch(deleteListFTP(ftppath, id, params)),
});

const PartherGroupListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryBlogs);

export default PartherGroupListWithConnect;
