import {
  Badge,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  message,
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { deleteList, getList } from "actions/collection";
import { getCollectionLoading, getPagination, getStateResult } from "selectors";

import Actions from "components/action";
import DataTableLayout from "components/layouts/DataTableLayout";
import { FullDateConvert } from "../../../utils/DateTimeFormat.js";
import { Link } from "react-router-dom";
import { MenuAuth } from "contexts/menuAuth";
import React from "react";
import { connect } from "react-redux";
import { getPageShowing } from "utils/common.js";
import iconDeleted from "images/correct@3x.png";
import iconErr from "images/icon-error@3x.png";
import { tags } from "schemas";

class ContactPartners extends React.Component {
  static contextType = MenuAuth;

  state = {
    page: 1,
    per_page: 15,
    search: "",

    isDeleteError: false,
    isDeleteSuccess: false,
    errorMsg: "",
    actionPermission: undefined,
  };

  componentDidMount() {
    getPageShowing();
    if (
      localStorage.getItem("search") &&
      localStorage.getItem("currSearch") === window.location.pathname
    ) {
      this.setState({ search: localStorage.getItem("search") });
    }
    this.getData();
  }

  getData = () => {
    let params = {
      page: localStorage.getItem("page")
        ? localStorage.getItem("page")
        : this.state.page,
      per_page: localStorage.getItem("per_page")
        ? parseInt(localStorage.getItem("per_page"))
        : this.state.per_page,
      search:
        localStorage.getItem("search") &&
        localStorage.getItem("currSearch") === window.location.pathname
          ? String(localStorage.getItem("search"))
          : this.state.search,
    };
    this.props.getData(params).then((resopnse) => {
      this.setState({ list: resopnse.data.data_list });
    });
  };

  onSearch = (e) => {
    localStorage.setItem("page", 1);
    let params = {
      page: 1,
      per_page: localStorage.getItem("per_page")
        ? parseInt(localStorage.getItem("per_page"))
        : this.state.per_page,
      search: localStorage.getItem("search")
        ? localStorage.getItem("search")
        : this.state.search,
    };
    localStorage.setItem("search", this.state.search);
    localStorage.setItem("currSearch", window.location.pathname);
    this.props.getData(params).then((res) => {
      this.setState({ list: res.data.data_list });
    });
  };

  setKeyword = (e) => {
    const value = e.target.value;
    localStorage.setItem("search", value);
    this.setState({ search: value });
  };

  deleteList = (delete_id) => {
    const { pagination, dataList } = this.props;
    localStorage.setItem(
      "page",
      dataList.length === 1 && parseInt(localStorage.getItem("page")) > 1
        ? parseInt(localStorage.getItem("page")) - 1
        : this.state.page
    );
    const params = {
      page:
        dataList.length === 1 && pagination.current_page > 1
          ? pagination.current_page - 1
          : this.state.page,
      search: this.state.search,
      per_page: this.state.per_page,
    };

    message.loading("Delete in progress..", 0);
    this.props.deleteData(delete_id, params).then((res) => {
      if (res?.code === 200) {
        message.destroy();
        this.setState({
          search: "",
          isDeleteSuccess: true,
        });
      } else {
        message.destroy();
        this.setState({
          isDeleteError: true,
          errorMsg: res?.message,
        });
      }
    });
  };

  handleTableChange = (dataRevice) => {
    this.setState({ list: dataRevice });
  };

  columns = [
    {
      title: "#",
      dataIndex: "order",
      key: "order",
      width: "5%",
    },
    {
      title: "ชื่อ",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (name) => {
        return name ? name.th : "-";
      },
    },
    {
      title: "คีย์",
      dataIndex: "key_slug",
      key: "key_slug",
      ellipsis: true,
      render: (text) => {
        return text || "";
      },
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "created_at",
      key: "created_at",
      width: "12%",
      render: (value) => {
        const formatDay = FullDateConvert(value);
        return formatDay;
      },
    },
    {
      title: "สถานะ",
      key: "status",
      dataIndex: "status",
      width: "10%",
      render: (status) => {
        return (
          <span>
            <Badge
              status={status === "active" ? "success" : "error"}
              text={status === "active" ? "ใช้งาน" : "ไม่ใช้งาน"}
              style={{
                color: status === "active" ? "#00c48c" : "#ff0000",
              }}
            />
          </span>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: "7%",
      render: (record) => {
        const author = this.context?.menuAuth?.find(
          (menu) => menu?.key_slug === "master-data"
        );
        const menu = author?.sub_menus?.find((sub) => sub?.key_slug === "tags");

        !this.state.actionPermission &&
          this.setState({ actionPermission: menu?.role_permission });

        return (
          <Actions
            record={record}
            path="/tags"
            onDelete={this.deleteList}
            history={this.props.history}
            permission={this.state.actionPermission}
          />
        );
      },
    },
  ];

  getActionPermission = () => {
    const author = this.context?.menuAuth?.find(
      (menu) => menu?.key_slug === "master-data"
    );
    const menu = author?.sub_menus?.find((sub) => sub?.key_slug === "tags");

    !this.state.actionPermission &&
      this.setState({ actionPermission: menu?.role_permission });
  };

  render() {
    const { isLoading, pagination, dataList } = this.props;
    const { actionPermission } = this.state;
    return (
      <Form>
        <Row justify="space-between">
          <Col>
            <Input
              style={{ marginBottom: "30px", width: "20vmax" }}
              onChange={this.setKeyword}
              placeholder="ค้นหาชื่อ, คีย์"
              onPressEnter={this.onSearch}
              suffix={
                <SearchOutlined
                  onClick={() => this.onSearch()}
                  style={{ cursor: "pointer" }}
                />
              }
              value={this.state.search}
              autoComplete="on"
            ></Input>
          </Col>
          {actionPermission && actionPermission?.can_create && (
            <Col>
              <Button type="primary">
                <Link to="/tags/create">
                  <PlusOutlined /> เพิ่มข้อมูล
                </Link>
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            {isLoading === true ? (
              <Skeleton
                className="App-form"
                loading={isLoading}
                active
              ></Skeleton>
            ) : (
              <DataTableLayout
                dataSource={dataList}
                columns={this.columns}
                isloading={isLoading}
                pagePagination={pagination}
                getData={this.props.getData}
                onChange={this.handleTableChange}
                getPermission={this.getActionPermission}
              />
            )}
          </Col>

          <Modal
            visible={this.state.isDeleteSuccess}
            footer={false}
            closable={false}
            centered={true}
            width={"500px"}
          >
            <div
              style={{
                height: "180px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img alt="example" src={iconDeleted} width={48} height={48} />
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  ลบข้อมูลเสร็จสิ้น
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "30px",
                  }}
                >
                  <Space>
                    <Button
                      type="primary"
                      style={{ width: "170px" }}
                      onClick={() => {
                        this.setState({ isDeleteSuccess: false });
                      }}
                    >
                      ตกลง
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Modal>
          <Modal
            visible={this.state.isDeleteError}
            footer={false}
            closable={false}
            centered={true}
            width={"500px"}
          >
            <div
              style={{
                height: "220px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img alt="example" src={iconErr} width={48} height={48} />
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  ลบข้อมูลไม่สำเร็จ
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  {this.state.errorMsg}
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "30px",
                  }}
                >
                  <Space>
                    <Button
                      type="primary"
                      style={{ width: "170px" }}
                      onClick={() => {
                        this.setState({ isDeleteError: false, errorMsg: "" });
                      }}
                    >
                      ตกลง
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Modal>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  pagination: getPagination(tags, state),
  isLoading: getCollectionLoading(tags, state),
  dataList: getStateResult(tags, state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: (params) => dispatch(getList(tags, params)),
  deleteData: (id, params) => dispatch(deleteList(tags, id, params)),
});

const TagListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactPartners);

export default TagListWithConnect;
