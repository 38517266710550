import React from 'react'
import { Modal, Button, Form, Col, TimePicker, Row } from 'antd'
import styled from 'styled-components'
import WaringPng from 'images/Warning-01.png'
import ErrorImage from 'images/icon-error@3x.png'
import SuccessImage from 'images/correct@3x.png'

const Index = ({
  handleOkModalTimelapse,
  handleCancelTimelapse,
  modalSuccessTimelapse,
  modalFailedTimelapse,
  createTimelapse,
  showModalWarning,
  modalTime,
  closeModalTime,
  closeModalSuccess,
  closeModalFailed,
  createTimelapseTextStatus,
  timelapseForm,
}) => {
  return (
    <div>
      <Modal
        centered
        footer={false}
        visible={showModalWarning}
        onOk={handleOkModalTimelapse}
        onCancel={handleCancelTimelapse}
      >
        <div
          style={{
            textAlign: 'center',
            marginTop: '15px',
            padding: '24px 55px',
          }}
        >
          <img
            src={WaringPng}
            alt='avatar'
            style={{ width: '48px', height: '48px' }}
          />
        </div>
        <div
          style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}
        >
          สร้าง Timelapse
        </div>
        <div style={{ textAlign: 'center', fontSize: '18px' }}>
          เมื่อคุณสร้างใหม่ Timelapse ของวันเดียวกันจะหายไป
          คุณต้องการสร้างใหม่ใช่หรือไม่ ?{' '}
        </div>
        <FormItem style={{ paddingTop: '20px' }}>
          <Button
            style={{ width: 170 }}
            type='primary'
            key='console'
            onClick={createTimelapse}
          >
            ตกลง
          </Button>
          <Button
            style={{ width: 170 }}
            key='console'
            onClick={handleCancelTimelapse}
          >
            ยกเลิก
          </Button>
        </FormItem>
      </Modal>

      <Modal
        centered
        visible={modalTime}
        footer={false}
        onCancel={closeModalTime}
        bodyStyle={{
          padding: '24px',
        }}
      >
        <Form
          layout='vertical'
          onFinish={handleOkModalTimelapse}
          ref={timelapseForm}
        >
          <Row>
            <Col span={24} style={{ marginLeft: '15px' }}>
              <p style={{ fontSize: '18px' }}>สร้าง Timelapse</p>
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '35px',
                  marginLeft: '-115px',
                }}
              >
                <div>
                  <Form.Item
                    name='timestart'
                    label='ตั้งแต่'
                    rules={[
                      {
                        required: true,
                        message: 'Time is required !!',
                      },
                    ]}
                  >
                    <TimePicker format={'HH:mm'} />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name='timestop'
                    label='จนถึง'
                    rules={[
                      {
                        required: true,
                        message: 'Time is required !!',
                      },
                    ]}
                  >
                    <TimePicker format={'HH:mm'} />
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
          <FormItem style={{ marginTop: '10px' }}>
            <Button
              style={{ width: 170 }}
              type='primary'
              key='console'
              htmlType='submit'
            >
              ตกลง
            </Button>
            <Button
              style={{ width: 170 }}
              key='console'
              onClick={closeModalTime}
            >
              ยกเลิก
            </Button>
          </FormItem>
        </Form>
      </Modal>

      <Modal
        centered
        footer={false}
        visible={modalSuccessTimelapse}
        onCancel={closeModalSuccess}
      >
        <div
          style={{
            textAlign: 'center',
            marginTop: '15px',
            padding: '24px 55px',
          }}
        >
          <img
            src={SuccessImage}
            alt='avatar'
            style={{ width: '48px', height: '48px' }}
          />
        </div>
        <div
          style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}
        >
          สร้าง Timelapse สำเร็จ
        </div>
        <div
          style={{
            fontSize: '18px',
            textAlign: 'center',
          }}
        >
          Timelapse ของคุณจะแสดงผลหลังจาก เวลา 00.00 น. ของวันที่ทำการสร้าง
        </div>
        <FormItem style={{ paddingTop: '20px' }}>
          <Button
            style={{ width: 170 }}
            type='primary'
            key='console'
            onClick={closeModalSuccess}
          >
            ตกลง
          </Button>
        </FormItem>
      </Modal>

      <Modal
        centered
        footer={false}
        visible={modalFailedTimelapse}
        onCancel={closeModalFailed}
      >
        <div
          style={{
            textAlign: 'center',
            marginTop: '15px',
            padding: '24px 55px',
          }}
        >
          <img
            src={ErrorImage}
            alt='avatar'
            style={{ width: '48px', height: '48px' }}
          />
        </div>
        <div
          style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}
        >
          สร้าง Timelapse ไม่สำเร็จ
        </div>
        <div style={{ textAlign: 'center', fontSize: '18px' }}>
          {createTimelapseTextStatus
            ? createTimelapseTextStatus
            : 'เกิดข้อผิดพลาดระหว่างการทำงาน กรุณาลองใหม่อีกครั้ง !'}
        </div>
        <FormItem style={{ paddingTop: '20px' }}>
          <Button
            style={{ width: 170 }}
            type='primary'
            key='console'
            onClick={closeModalFailed}
          >
            ตกลง
          </Button>
        </FormItem>
      </Modal>
    </div>
  )
}

const FormItem = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
`

export default Index
