import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Row, Col, DatePicker, Space, Table, Badge, Spin } from 'antd'
import axios from 'axios'
import Chart from 'react-apexcharts'
import { useHistory } from 'react-router'
import { LoadingOutlined } from '@ant-design/icons'

const CctvHistory = () => {
  const history = useHistory()
  const pathName = history.location.pathname
  const cctvName = pathName.substring(pathName.lastIndexOf('/') + 1)
  const [dataDetail, setDataDetail] = useState()
  const dataTable =
    dataDetail &&
    dataDetail.raw_data.map((item, index) => ({ ...item, id: index + 1 }))
  const chartData =
    dataDetail &&
    dataDetail.chart.map((item) => ({
      x: `${moment.unix(new Date(item[0])).format('HH:mm')}`,
      y: item[1],
    }))

  const [loading, setLoading] = useState(true)
  const [checkTooltip, setCheckTooltip] = useState(false)
  if (chartData) {
    chartData.push({ x: '24:00', y: chartData[chartData.length - 1].y })
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'สถานะ',
      dataIndex: 'camera_alive',
      key: 'camera_alive',
      render: (status) => {
        return (
          <span>
            <Badge
              status={status === true ? 'success' : 'error'}
              text={status === true ? 'ทำงาน' : 'ไม่ทำงาน'}
              style={{
                color: status === true ? '#00c48c' : '#ff0000',
              }}
            />
          </span>
        )
      },
    },

    {
      title: 'วันที่',
      dataIndex: 'time_at',
      key: 'time_at',
      render: (date) => moment(date).local('th').format('LL'),
    },
    {
      title: 'เวลา',
      dataIndex: 'time_at',
      key: 'time_at',
      render: (time) => moment(time).format('HH:mm'),
    },
  ]

  const options = {
    chart: {
      events: {
        mouseMove: function (event, chartContext, config) {
          const points = chartData[config.dataPointIndex]
          if (points?.y) {
            setCheckTooltip(false)
          } else {
            setCheckTooltip(true)
          }
        },
      },
      id: 'area-datetime',
      type: 'area',
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    dataLabels: { enabled: false },
    title: {
      text: dataDetail && dataDetail.name,
      align: 'left',
      style: {
        fontSize: '24px',
        fontFamily: 'Kanit',
        fontWeight: 400,
      },
    },
    xaxis: { tickAmount: 24 },

    yaxis: {
      tickAmount: 3,
      min: 0,
      max: 3,
      offsetY: 'string',
      labels: {
        minWidth: 40,
        style: {
          fontSize: '14px',
          fontFamily: 'Kanit',
          fontWeight: 400,
        },
        formatter: (val) =>
          val === 1
            ? (val = 'ไม่ทำงาน')
            : val === 2
            ? (val = 'ทำงาน')
            : (val = ''),
      },
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      y: {
        formatter: (val) => (val === 2 ? 'ทำงาน' : 'ไม่ทำงาน'),
      },
      fixed: {
        enabled: checkTooltip,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
    },
  }

  const series = [
    {
      name: 'สถานะ',
      data: chartData,
    },
  ]

  const getCctvDetail = (date) => {
    axios
      .get(
        `${process.env.REACT_APP_CCTV_MONOTORING}/cctv/${cctvName}?date=${date}`,
      )
      .then(async (res) => {
        await setDataDetail(res.data.data)
        await setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const loseFocus = () => {
    setTimeout(() => {
      document.getElementById('dateChart').blur()
    }, 100)
  }

  const disabledDate = (current) => {
    return current > moment() || current < moment().subtract(90, 'day')
  }

  const onChange = (date) => {
    setLoading(true)
    getCctvDetail(moment(date).unix())
    loseFocus()
  }

  useEffect(() => {
    getCctvDetail(moment(new Date()).unix())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Row justify='space-between'>
        <h2>CCTV Monitoring Detail</h2>
        <Space direction='vertical' size={12}>
          <DatePicker
            id='dateChart'
            defaultValue={moment(new Date())}
            format={'DD/MM/YYYY'}
            onChange={onChange}
            disabledDate={disabledDate}
            allowClear={false}
          />
        </Space>
      </Row>
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />}
            spinning={loading}
            style={{ color: '#bd1d2d' }}
          >
            <div className='chart'>
              <Chart
                options={options}
                series={series}
                type='line'
                height={400}
                style={{ padding: '24px', backgroundColor: '#ffffff' }}
              />
            </div>
          </Spin>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={dataTable}
            pagination={false}
            rowKey='id'
          />
        </Col>
      </Row>
    </div>
  )
}

export default CctvHistory
