import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  message,
  Select
} from "antd";
import { useHistory } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { deleteList, getList } from "actions/collection";
import { getCollectionLoading, getPagination, getStateResult } from "selectors";

import Actions from "components/action";
import DataTableLayout from "components/layouts/DataTableLayout";
import { MenuAuth } from "contexts/menuAuth";
import { connect } from "react-redux";
import iconDeleted from "images/correct@3x.png";
import iconWarn from 'images/exclamation.png'
import styled from "styled-components";
import { approval } from "schemas";
import { mapErrMsg } from '../../../utils/common'
import { FullDateConvert } from "../../../utils/DateTimeFormat.js";
import moment from 'moment'

const { Option } = Select;
const url = `${process.env.REACT_APP_CCTV_APPROVAL}`;

const ApproveCctv = (props) => {
  const history = useHistory();
  const [option, setOption] = useState();
  const [dataList, setDataList] = useState();
  const [openDeleteSuccess, setOpenDeleteSuccess] = useState(false);
  const [openDeleteErr, setOpenDeleteErr] = useState({ open: false, errorMsg: "" });
  const [dataSearch, setDataSearch] = useState(localStorage.getItem("search"));
  const permissionAuthor = useContext(MenuAuth);
  const [actionPermission, setActionPermission] = useState(undefined);
  const [dataFilter, setDataFilter] = useState(localStorage.getItem("filter") || undefined)

  const pagination = {
    total: option?.total,
    per_page: option?.per_page,
    current_page: option?.current_page,
    sort_field: option?.sorts.key,
    sort_by: option?.sorts.value,
    filters: option?.sorts.value,
  };

  const getApproval = (page, perPage) => {
    const params = {
      page,
      per_page: perPage,
      search: [`name.th:${dataSearch}`, `name.en:${dataSearch}`, `name.zh:${dataSearch}`],
      filters: `status_approval:eq:${dataFilter}`,
      sorts: 'updated_at:desc'
    };
    if (!dataFilter) { delete params.filters }
    setDataList(undefined);
    props.getData(params).then((res) => {
      if (res?.code === 200) {
        const list = res?.data ? res?.data.lists : [];
        const dataOption = res?.data ? res?.data.option : null;
        const newList = list && list.map((item, index) => {
          const order = dataOption.per_page * (dataOption.current_page - 1) + (index + 1);
          return { ...item, order: order };
        });
        setOption(dataOption);
        setDataList(newList);
      } else {
        setDataList([]);
      }
    });
  };

  const onDeleteGroup = (keySlug) => {
    message.loading("Save in progress..", 0);
    props.deleteData(keySlug).then((res) => {
      message.destroy();
      if (res?.code === 200) {
        setOpenDeleteSuccess(true);
      } else {
        const arrError = mapErrMsg(res?.errors, 'กลุ่มผู้ใช้งาน')
        setOpenDeleteErr({ open: true, errorMsg: arrError });
      }
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "order",
      key: "order",
      width: "6%",
    },
    {
      title: "ชื่อโครงการ",
      dataIndex: "name",
      key: "name",
      // ellipsis: true,
      width: "30%",
      render: (name) => name.th
    },
    {
      title: "วันที่แก้ไขล่าสุด",
      dataIndex: "created_at",
      key: "created_at",
      width: "12%",
      render: (_, row) => FullDateConvert(moment(row.updated_at).unix())
    },
    {
      title: "สถานะการอนุมัติ",
      key: "status_approval",
      dataIndex: "status_approval",
      width: "10%",
      render: (status) => {
        return (
          <div style={{ color: status === 'approve' ? '#00c48c' : status === 'pending_publish' ? '#FFA800' : '#ff0000' }}>
            {status === 'approve' ? 'อนุมัติ' : status === 'pending_publish' ? 'รออนุมัติ' : 'ไม่อนุมัติ'}
          </div>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: "7%",
      render: (record) => {
        return (
          <Actions
            record={{ id: record.id, key_slug: record.id }}
            path="/approve-cctv"
            onDelete={onDeleteGroup}
            history={history}
            permission={{ ...actionPermission, can_update: false, can_create: false, can_delete: false }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    const author = permissionAuthor?.menuAuth?.find(
      (menu) => menu?.key_slug === "users-management"
    );
    const menu = author?.sub_menus?.find(
      (sub) => sub?.key_slug === "user-groups"
    );

    setActionPermission(menu?.role_permission);
  }, [permissionAuthor]);

  const onSearch = () => {
    localStorage.setItem("search", dataSearch);
    getApproval(1, localStorage.getItem("per_page"));
  };

  const onChangeFilter = (e) => {
    setDataFilter(e)
    localStorage.setItem("filter", e);
  }

  const onInputChange = (e) => {
    setDataSearch(e.target.value);
  };

  const onCloseDelete = () => {
    getApproval(localStorage.getItem("page"), localStorage.getItem("per_page"));
    setOpenDeleteSuccess(false);
  };

  useEffect(() => {
    getApproval(localStorage.getItem("page"), localStorage.getItem("per_page"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter]);

  return (
    <>
      <Form>
        <Row justify="space-between">
          <Col>
            <Input
              id="input"
              style={{ marginBottom: "30px", width: "20vmax" }}
              onChange={onInputChange}
              placeholder="ค้นหาชื่อโครงการ"
              onPressEnter={onSearch}
              value={dataSearch}
              suffix={
                <SearchOutlined
                  onClick={() => onSearch("click")}
                  style={{ cursor: "pointer" }}
                />
              }
              autoComplete="on"
            />
            <Select
              virtual={false}
              showSearch
              placeholder="สถานะการอนุมัติ"
              style={{ width: "200px", marginLeft: "10px" }}
              onChange={(e) => onChangeFilter(e)}
              value={dataFilter}
            >
              <Option value="">ทั้งหมด</Option>
              <Option value='pending_publish'>รออนุมัติ</Option>
              <Option value='approve'>อนุมัติ</Option>
              <Option value='reject'>ไม่อนุมัติ</Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Skeleton className="App-form" loading={!dataList} active>
              <DataTableLayout
                dataSource={dataList}
                columns={columns}
                isloading={!dataList}
                pagePagination={pagination}
                getData={(param) => getApproval(param.page, param.per_page)}
              />
            </Skeleton>
          </Col>
        </Row>
      </Form>

      <Modal
        visible={openDeleteSuccess}
        footer={false}
        closable={false}
        centered={true}
        width={"500px"}
      >
        <BoxModel height="180px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="example" src={iconDeleted} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>ลบข้อมูลเสร็จสิ้น</HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button
                    type="primary"
                    style={{ width: "170px" }}
                    onClick={onCloseDelete}
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal
        visible={openDeleteErr.open}
        footer={false}
        closable={false}
        centered={true}
        width={"500px"}
      >
        <BoxModel height="220px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="example" src={iconWarn} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>ลบข้อมูลไม่สำเร็จ</HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="15px">{openDeleteErr.errorMsg}</TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button
                    type="primary"
                    style={{ width: "170px" }}
                    onClick={() =>
                      setOpenDeleteErr({ open: false, errorMsg: "" })
                    }
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  pagination: getPagination(approval, state),
  isLoading: getCollectionLoading(approval, state),
  dataList: getStateResult(approval, state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: (params) => dispatch(getList(approval, params, url)),
  deleteData: (id, params) => dispatch(deleteList(approval, id, params, url)),
});

const ApproveCctvWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveCctv);

export default ApproveCctvWithConnect;

const BoxModel = styled.div`
  height: ${(props) => props.height};
  justify-content: center;
  align-items: center;
  display: flex;
`;
const HeaderModel = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
`;
const TextModel = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: ${(props) => props.marginTop};
`;
