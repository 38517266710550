import React, { useContext } from "react";

import Blog from "containers/ContentManagement/Blog";
import BlogCreate from "containers/ContentManagement/Blog/form.jsx";
import BlogEdit from "containers/ContentManagement/Blog/form.jsx";
import BlogView from "containers/ContentManagement/Blog/view.jsx";
import CCTVGroups from "containers/MasterData/CCTVGroups";
import CCTVGroupsCreate from "containers/MasterData/CCTVGroups/form.jsx";
import CCTVGroupsEdit from "containers/MasterData/CCTVGroups/form.jsx";
import CCTVGroupsView from "containers/MasterData/CCTVGroups/view.jsx";
import CategoryBlogs from "containers/MasterData/CategoryBlogs";
import CategoryBlogsCreate from "containers/MasterData/CategoryBlogs/form.jsx";
import CategoryBlogsEdit from "containers/MasterData/CategoryBlogs/form.jsx";
import CategoryBlogsView from "containers/MasterData/CategoryBlogs/view.jsx";
import Cctv from "containers/ContentManagement/Cctv";
import CctvCreate from "containers/ContentManagement/Cctv/form";
import CctvEdit from "containers/ContentManagement/Cctv/form";
import CctvHistory from "containers/Dashboard/Cctv/history";
import CctvList from "containers/Dashboard/Cctv";
import CctvView from "containers/ContentManagement/Cctv/view";
import ContateManagement from "containers/OperationTools/ContactManagement";
import ContateManagementView from "containers/OperationTools/ContactManagement/view";
import Dashboard from "containers/Dashboard/Ga";
import FTPPath from "containers/FTPManagement/FTPPath";
import FTPPathCreate from "containers/FTPManagement/FTPPath/form.jsx";
import FTPPathEdit from "containers/FTPManagement/FTPPath/form.jsx";
import FTPPathView from "containers/FTPManagement/FTPPath/view.jsx";
import HomePage from "components/page/homePage";
import MasterServerFTP from "containers/FTPManagement/MasterServerFTP";
import MasterServerFTPCreate from "containers/FTPManagement/MasterServerFTP/form.jsx";
import MasterServerFTPEdit from "containers/FTPManagement/MasterServerFTP/form.jsx";
import MasterServerFTPView from "containers/FTPManagement/MasterServerFTP/view.jsx";
import { MenuAuth } from "contexts/menuAuth";
import Page404 from "components/page/page404";
import PartnerGroups from "containers/MasterData/PartnerGroups";
import PartnerGroupsCreate from "containers/MasterData/PartnerGroups/form.jsx";
import PartnerGroupsEdit from "containers/MasterData/PartnerGroups/form.jsx";
import PartnerGroupsView from "containers/MasterData/PartnerGroups/view.jsx";
import PartnersManagement from "containers/OperationTools/ContactPartnersManagement";
import PartnersManagementCreate from "containers/OperationTools/ContactPartnersManagement/form";
import PartnersManagementEdit from "containers/OperationTools/ContactPartnersManagement/form";
import PartnersManagementView from "containers/OperationTools/ContactPartnersManagement/view";
import PopupEvents from "containers/ContentManagement/PopupEvent";
import PopupEventsCreate from "containers/ContentManagement/PopupEvent/form";
import PopupEventsEdit from "containers/ContentManagement/PopupEvent/form";
import PopupEventsView from "containers/ContentManagement/PopupEvent/view";
import PrivateRoute from "./PrivateRoute";
import Profile from "containers/UserManagement/Profile/index";
import ProfileEdit from "containers/UserManagement/Profile/form";
import ProfilePassword from "containers/UserManagement/Profile/password-form";
import RoleAndPermission from "containers/UserManagement/RoleAndPermission";
import RoleAndPermissionForm from "containers/UserManagement/RoleAndPermission/form";
import RoleAndPermissionView from "containers/UserManagement/RoleAndPermission/view";
import Slide from "containers/ContentManagement/Slide";
import SlideCreate from "containers/ContentManagement/Slide/form";
import SlideEdit from "containers/ContentManagement/Slide/form";
import SlideView from "containers/ContentManagement/Slide/view";
import { Switch } from "react-router-dom";
import Tags from "containers/MasterData/Tags";
import TagsCreate from "containers/MasterData/Tags/form.jsx";
import TagsEdit from "containers/MasterData/Tags/form.jsx";
import TagsView from "containers/MasterData/Tags/view.jsx";
import TourismTypes from "containers/MasterData/TourismTypes";
import TourismTypesCreate from "containers/MasterData/TourismTypes/form.jsx";
import TourismTypesEdit from "containers/MasterData/TourismTypes/form.jsx";
import TourismTypesView from "containers/MasterData/TourismTypes/view.jsx";
import UserGroups from "containers/UserManagement/UserGroups";
import UserGroupsForm from "containers/UserManagement/UserGroups/form";
import UserGroupsView from "containers/UserManagement/UserGroups/view";
import Users from "containers/UserManagement/Users";
import UsersForm from "containers/UserManagement/Users/form";
import UsersView from "containers/UserManagement/Users/view";
import ZmCreateManagement from "containers/ContentManagement/Zm/form.jsx";
import ZmEditManagement from "containers/ContentManagement/Zm/form.jsx";
import ZmManagement from "containers/ContentManagement/Zm";
import ZmViewManagement from "containers/ContentManagement/Zm/view";
import CctvGroup from "containers/ContentManagement/CctvGroup";
import CctvGroupEdit from "containers/ContentManagement/CctvGroup/form";
import CctvGroupView from "containers/ContentManagement/CctvGroup/view";
import { userGroupContext } from "contexts/UserGroupContext";
import ApproveCctv from "containers/ContentManagement/ApproveCctv/index.jsx"
import ApproveCctvView from "containers/ContentManagement/ApproveCctv/view.jsx"

// import Page503 from "components/page/page503";

const Routes = () => {
  const menus = useContext(MenuAuth);
  const { userGroup } = useContext(userGroupContext);

  const menuCctv = () => {
    const admin = {
      index: Cctv,
      create: CctvCreate,
      update: CctvEdit,
      read: CctvView,
    }
    const group = {
      index: CctvGroup,
      update: CctvGroupEdit,
      read: CctvGroupView,
    }
    return RegExp('depa').test(userGroup?.data?.toLowerCase()) === true ? group : admin
  }

  const menuLists = {
    ga: {
      index: Dashboard,
    },
    "approve-cctv": {
      index: ApproveCctv,
      read: ApproveCctvView,
    },
    cctv: menuCctv(),
    slides: {
      index: Slide,
      create: SlideCreate,
      update: SlideEdit,
      read: SlideView,
    },
    "contacts-feedbacks": {
      index: ContateManagement,
      read: ContateManagementView,
    },
    "partners-management": {
      index: PartnersManagement,
      create: PartnersManagementCreate,
      update: PartnersManagementEdit,
      read: PartnersManagementView,
    },
    zm: {
      index: ZmManagement,
      create: ZmCreateManagement,
      update: ZmEditManagement,
      read: ZmViewManagement,
    },
    "cctv-groups": {
      index: CCTVGroups,
      create: CCTVGroupsCreate,
      update: CCTVGroupsEdit,
      read: CCTVGroupsView,
    },
    tags: {
      index: Tags,
      create: TagsCreate,
      update: TagsEdit,
      read: TagsView,
    },
    "tourism-types": {
      index: TourismTypes,
      create: TourismTypesCreate,
      update: TourismTypesEdit,
      read: TourismTypesView,
    },
    "partner-groups": {
      index: PartnerGroups,
      create: PartnerGroupsCreate,
      update: PartnerGroupsEdit,
      read: PartnerGroupsView,
    },
    "popup-events": {
      index: PopupEvents,
      create: PopupEventsCreate,
      update: PopupEventsEdit,
      read: PopupEventsView,
    },
    blogs: {
      index: Blog,
      create: BlogCreate,
      update: BlogEdit,
      read: BlogView,
    },
    "blog-categories": {
      index: CategoryBlogs,
      create: CategoryBlogsCreate,
      update: CategoryBlogsEdit,
      read: CategoryBlogsView,
    },
    "master-server-ftp": {
      index: MasterServerFTP,
      create: MasterServerFTPCreate,
      update: MasterServerFTPEdit,
      read: MasterServerFTPView,
    },
    "ftp-path": {
      index: FTPPath,
      create: FTPPathCreate,
      update: FTPPathEdit,
      read: FTPPathView,
    },
    "cctv-monitoring": {
      index: CctvList,
      read: CctvHistory,
    },
    "roles-and-permissions": {
      index: RoleAndPermission,
      create: RoleAndPermissionForm,
      update: RoleAndPermissionForm,
      read: RoleAndPermissionView,
    },
    "user-groups": {
      index: UserGroups,
      create: UserGroupsForm,
      update: UserGroupsForm,
      read: UserGroupsView,
    },
    users: {
      index: Users,
      create: UsersForm,
      update: UsersForm,
      read: UsersView,
    },
  };

  return (
    <>
      <Switch>
        <PrivateRoute exact path="/" component={HomePage} />
        <PrivateRoute exact component={Profile} path="/profile" />
        <PrivateRoute exact component={ProfileEdit} path="/profile/edit/:id" />
        <PrivateRoute
          exact
          component={ProfilePassword}
          path="/profile/edit/password/:id"
        />

        {menus?.menuAuth?.map((menu) => {
          return menu?.sub_menus?.map((sub) => {
            return (
              menuLists[sub.key_slug]?.index && (
                <PrivateRoute
                  exact
                  component={menuLists[sub.key_slug]?.index}
                  path={sub?.path}
                />
              )
            );
          });
        })}

        {menus?.menuAuth?.map((menu) => {
          return menu?.sub_menus?.map((sub) => {
            return (
              sub?.role_permission?.can_create &&
              menuLists[sub.key_slug]?.create && (
                <PrivateRoute
                  exact
                  component={menuLists[sub.key_slug]?.create}
                  path={`${sub?.path}/create`}
                />
              )
            );
          });
        })}

        {menus?.menuAuth?.map((menu) => {
          return menu?.sub_menus?.map((sub) => {
            return (
              sub?.role_permission?.can_read &&
              menuLists[sub?.key_slug]?.read && (
                <PrivateRoute
                  exact
                  component={menuLists[sub?.key_slug]?.read}
                  path={`${sub?.path}/view/:id`}
                />
              )
            );
          });
        })}

        {menus?.menuAuth?.map((menu) => {
          return menu?.sub_menus?.map((sub) => {
            return (
              sub?.role_permission?.can_update &&
              menuLists[sub?.key_slug]?.update && (
                <PrivateRoute
                  exact
                  component={menuLists[sub?.key_slug]?.update}
                  path={`${sub?.path}/edit/:id`}
                />
              )
            );
          });
        })}

        {menus?.menuAuth && <PrivateRoute component={Page404} />}
      </Switch>
    </>
  );
};

export default Routes;
