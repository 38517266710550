import axios from 'axios'
import { setRequestHeadersInterceptor } from 'interceptors'

import { setToken, getToken } from './utils/common'

let token = getToken('token')

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  timeout: 10000,
  headers: {
    'Content-type': 'application/json',
    'Accept-Language': 'th',
    Authorization: `Bearer ${getToken('token')}`,
  },
})

instance.interceptors.request.use(setRequestHeadersInterceptor)

instance.interceptors.response.use(
  (response) => {
    const originalRequest = response.config
    if (response?.data?.code === 401) {  //  refresh token for api route old
      return getNewToken(originalRequest)
    } else {
      return response
    }
  },
  function (error) {
    console.log('request api failed :>> ', error?.response)
    if (error?.response?.status === 401) { //  refresh token for author 
      const originalRequest = error?.response?.config
      return getNewToken(originalRequest)
    } else {
      return error.response
    }
  }
)

const getNewToken = (originalRequest) => {
  return axios
    .post(`${process.env.REACT_APP_AUTHEN}/token/refresh`, { "refresh_token": getToken('refresh_token') }, {
      headers: {
        'Accept-Language': 'en',
      },
    })
    .then(
      async (res) => {
        if (res?.data?.code === 200) {
          // 1) put token to LocalStorage
          await setToken(res?.data?.data)
          token = res?.data?.data?.access_token
          // 2) Change Authorization header
          instance.defaults.headers.common['Authorization'] =
            'Bearer ' + token
          // 3) return originalRequest object with Axios.
          return await instance(originalRequest)
        } else {
          resetCookie()
          console.log('1 refresh token failed :>> ', res)
        }
      },
      function (error) {
        // Do something with request error
        console.log('2 request refresh token failed :>> ', error.response)
        resetCookie()
        return error?.response
      }
    )
}

const resetCookie = () => {
  localStorage.clear()
  window.location.href = '/login'
}

export const httpClient = instance
