import React from 'react'
import { Row, Col, Form, Skeleton, message, Modal, Button, Space } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { readData, deleteData } from 'actions/collection'
import { zm } from 'schemas'
import { getResource, getResourceLoading } from 'selectors'
import { connect } from 'react-redux'
import iconDeleted from 'images/correct@3x.png'
import iconErr from 'images/icon-error@3x.png'
import Viewlayout from 'components/layouts/Viewlayout.js'
import { MenuAuth } from "contexts/menuAuth";

class view extends React.Component {
  static contextType = MenuAuth;
  state = {
    visible: false,
    isCreateError: false,
    visibleDeleted: false,
    errorMsg: '',
    actionPermission: undefined,
  }
  componentDidMount() {
    this.props.readData(this.props.match.params.id)
  }

  componentDidUpdate() {
    if (this.context?.menuAuth !== this.state.actionPermission) {
      this.getPermission()
    }
  }

  getPermission() {
    const author = this.context?.menuAuth?.find((menu) => menu?.key_slug === "cms");
    const menu = author?.sub_menus?.find((sub) => sub?.key_slug === "zm");
    !this.state.actionPermission && this.setState({ actionPermission: menu?.role_permission });
  }

  showModaldelete = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    })
    this.deleteList()
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
      delete_id: '',
    })
  }

  gotoEdit = () => {
    this.props.history.push(`/zm/edit/${this.props.match.params.id}`)
  }

  deleteList = () => {
    this.setState({ visible: false }, () => {
      message.loading('Delete in progress..', 0)
      this.props.deleteData(this.props.match.params.id).then((res) => {
        if (res?.code === 200) {
          message.destroy()
          this.setState({
            visibleDeleted: true,
          })
        } else {
          message.destroy()
          this.setState({
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    })
  }

  render() {
    const { dataList, isLoading } = this.props

    return (
      <Form className="App-form">
        <Viewlayout
          permission={this.state.actionPermission}
          viewData={dataList}
          isLoading={isLoading}
          textTitle="ข้อมูลกล้อง ZM"
          iconEdit="edit"
          iconDelete="delete"
          textEdit="แก้ไขข้อมูล"
          textDelete="ลบข้อมูล"
          onDelete={this.showModaldelete}
          onEdit={this.gotoEdit}
        >
          {isLoading === true ? (
            <Skeleton
              className="App-form"
              loading={isLoading}
              active
            ></Skeleton>
          ) : (
            <Form.Item>
              <Row>
                <Col className="sil-break-word" style={{ marginTop: '30px' }} span={24}>
                  <h1>ไอดี</h1>
                  {dataList.Monitor ? dataList.Monitor.Monitor.Id : '-'}
                </Col>
              </Row>
              <Row>
                <Col className="sil-break-word" style={{ marginTop: '30px' }} span={24}>
                  <h1>รหัสกล้อง</h1>
                  {dataList.Monitor ? dataList.Monitor.Monitor.Name : '-'}
                </Col>
              </Row>
              <Row>
                <Col className="sil-break-word" style={{ marginTop: '30px' }} span={24}>
                  <h1>รายละเอียด</h1>
                  {dataList.Monitor && dataList.Monitor.Monitor.Notes
                    ? dataList.Monitor.Monitor.Notes
                    : '-'}
                </Col>
              </Row>
              <Row>
                <Col className="sil-break-word" style={{ marginTop: '30px' }} span={24}>
                  <h1>Type</h1>
                  {dataList.Monitor ? dataList.Monitor.Monitor.Type : '-'}
                </Col>
              </Row>
              <Row>
                <Col className="sil-break-word" style={{ marginTop: '30px' }} span={24}>
                  <h1>ลิงค์</h1>
                  {dataList.Monitor ? dataList.Monitor.Monitor.Path : '-'}
                </Col>
              </Row>
              <Row>
                <Col className="sil-break-word" style={{ marginTop: '30px' }} span={24}>
                  <h1>ขนาดวีดีโอ (Pixel)</h1>
                  {dataList.Monitor
                    ? `${dataList.Monitor.Monitor.Width} x ${dataList.Monitor.Monitor.Height}`
                    : '-'}
                </Col>
              </Row>
              <Modal
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
              >
                <Col className="modal-delete">
                  <DeleteOutlined style={{ fontSize: '40px' }} />
                </Col>
                <Col className="modal-delete">ต้องการลบข้อมูลใช่หรือไม่</Col>
              </Modal>
              <Modal
                visible={this.state.visibleDeleted}
                footer={false}
                closable={false}
                centered={true}
                width={'500px'}
              >
                <div
                  style={{
                    height: '180px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <img
                        alt="example"
                        src={iconDeleted}
                        width={48}
                        height={48}
                      />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      ลบข้อมูลเสร็จสิ้น
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          type="primary"
                          style={{ width: '170px' }}
                          onClick={() => this.props.history.push('/zm')}
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
              <Modal
                visible={this.state.isCreateError}
                footer={false}
                closable={false}
                centered={true}
                width={'500px'}
              >
                <div
                  style={{
                    height: '220px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <img alt="example" src={iconErr} width={48} height={48} />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      ลบข้อมูลไม่สำเร็จ
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      {this.state.errorMsg}
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          type="primary"
                          style={{ width: '170px' }}
                          onClick={() => {
                            this.setState({ isCreateError: false })
                          }}
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </Form.Item>
          )}
        </Viewlayout>
      </Form>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(zm, state),
  dataList: getResource(zm, state),
})

const mapDispatchToProps = (dispatch) => ({
  readData: (id) => dispatch(readData(zm, id)),
  deleteData: (id) => dispatch(deleteData(zm, id)),
})

const ViewtWithConnect = connect(mapStateToProps, mapDispatchToProps)(view)

export default ViewtWithConnect
