import { createSelector } from 'reselect'

export const getResource = (schema, state) => {
  return state.resource.getIn([schema, 'data'], {})
}

export const getCollection = (schema, state) =>
  state.collection.getIn([schema, 'data_list'], [])

export const getPagination = (schema, state) =>
  state.collection.getIn([schema, 'pagination'], {})

export const getCollectionLoading = (schema, state) =>
  state.collection.getIn([schema, 'isLoading'], false)

export const getResourceLoading = (schema, state) => {
  return state.resource.getIn([schema, 'isLoading'], {})
}

// Select filter from state

export const getStateResult = createSelector(
  getCollection,
  getResource,
  getPagination,
  (list, data, paginate) => {
    return list.map((id, index) => {
      const order = paginate.per_page * (paginate.current_page - 1) + (index + 1)
      return { ...data[id], order: order }
    })
  }
)

export const getListAccount = createSelector(
  getCollection,
  getResource,
  getPagination,
  (list, data, paginate) => {
    return list.map((id, index) => {
      const order = paginate.per_page * (paginate.current_page - 1) + (index + 1)
      const name = data[id].first_name + ' ' + data[id].last_name
      return { ...data[id], order: order, name }
    })
  }
)
