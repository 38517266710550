import React, { useState, useEffect } from 'react'
import { Space, Button } from 'antd'

const Footeractionlayout = (props) => {
  const [, forceUpdate] = useState()

  const { isFill, onCancle, size, submitText, isShowButtonApprove, onPending } = props

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({})
  }, [])

  return (
    <Space>
      <Button
        style={{ width: size ? size : '12vmax' }}
        htmlType="button"
        onClick={onCancle}
      >
        ยกเลิก
      </Button>
      <Button
        style={{ width: size ? size : '12vmax' }}
        type='primary'
        htmlType='submit'
        disabled={isFill}
      >
        {submitText ? submitText : 'บันทึก'}
      </Button>
      {isShowButtonApprove &&
        <Button
          style={{ width: size ? size : '12vmax' }}
          type='primary'
          htmlType='submit'
          disabled={isFill}
          onClick={onPending && onPending}
        >
          บันทึกและขออนุมัติ
        </Button>
      }
    </Space >
  )
}

export default Footeractionlayout
