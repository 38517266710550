import jwtDecode from 'jwt-decode'
import blank from '../images/default-thumbnail.jpg'


const Salt = 'see-it-live-th-2020'
let objCodec = require('object-encode')

export const getUserName = () => {
  const token = getToken('token')
  if (token && token !== 'null') {
    let jwt = jwtDecode(token)
    return `${jwt.first_name} ${jwt.last_name}`
  }
}

export const setCookiesChangeScene = () => {
  localStorage.setItem('page', '1')
  localStorage.setItem('search', '')
  localStorage.setItem('filter', '')
  localStorage.setItem('db_date_start', '')
  localStorage.setItem('db_date_end', '')
}

export const setDefaultCookies = () => {
  localStorage.setItem('page', '1')
  localStorage.setItem('per_page', '15')
  localStorage.setItem('search', '')
  localStorage.setItem('filter', '')
}

export const getPageShowing = () => {
  if (window.performance) {
    if (window.performance.navigation.type === 0) {
      setDefaultCookies()
    }
  }
}

export const setToken = (token) => {
  let object = {
    token: token?.access_token,
    refresh_token: token?.refresh_token || '',
  }
  let encodedString = objCodec.encode_object(object, 'base64', Salt)
  localStorage.setItem('_temp', encodedString || '')
}

export const getToken = (type = 'token') => {
  let temp = localStorage.getItem('_temp')
  let decodedObject = objCodec.decode_object(temp || '', 'base64', Salt)
  return type === 'token' ? decodedObject.token : decodedObject.refresh_token
}

export const NumberFormat = (n = 0) => {
  const value = n.toLocaleString(
    undefined, // leave undefined to use the browser's locale,
    // or use a string like 'en-US' to override it.
    { minimumFractionDigits: 0 }
  )
  return value
}


export const mapErrMsg = (err, text, type = '') => {
  let msg = []
  // eslint-disable-next-line array-callback-return
  err.map((item) => {
    switch (item.code) {
      case 'unique':
        if (item.subCode === 'Name') { msg.push(`ชื่อ${text}ซ้ำ กรุณากรอกใหม่อีกครั้ง`) }
        else if (item.subCode === 'KeySlug') { msg.push(`key_slug ซ้ำ กรุณากรอกใหม่อีกครั้ง`) }
        break
      case 'not_exist':
        if (item.subCode === 'UserGroups') { msg.push(`ไม่มีกลุ่มผู้ใช้งานนี้อยู่ในฐานข้อมูล`) }
        else if (item.subCode === 'KeySubMenu') { msg.push(`ไม่มีเมนูย่อยนี้อยู่ในฐานข้อมูล`) }
        break
      case 'required':
        if (item.code === 'required' && item.subCode === 'Name') { msg.push(`กรุณากรอกชื่อ${text}`) }
        else if (item.code === 'required' && item.subCode === 'UserGroups') { msg.push(`กรุณากรอกชื่อกลุ่มผู้ใช้งาน`) }
        break
      case 'oneof':
        if (item.subCode === 'Status') { msg.push(`สถานะ${text}ต้องเป็น ใช้งาน หรือ ไม่ใช้งาน เท่านั้น`) }
        break
      case 'in_used':
        if (type === 'edit') {
          msg.push(`ไม่สามารถเปลี่ยนสถานะเป็นไม่ใช้งานได้ เนื่องจากยังมีการใช้งานข้อมูลนี้อยู่`)
        } else {
          msg.push(`ไม่สามารถทำการลบข้อมูลได้ เนื่องจากยังมีการใช้งานข้อมูลนี้อยู่`)
        }
        break
      case 'CONDITION':
        if (item.subCode === "STILL_IN_USE") {
          msg.push(`ไม่สามารถทำการลบข้อมูลได้ เนื่องจากยังมีการใช้งานข้อมูลนี้อยู่`)
        }
        break
      default:
        msg.push('พบข้อผิดพลาดในการทำงาน กรุณาลองใหม่อีกครั้ง')
        break
    }
  })
  return msg
}

export const imageError = (ev) => {
  ev.target.src = blank
}


