import {
  Badge,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Row,
  Skeleton,
  Space,
  message,
} from "antd";
import {
  EyeOutlined,
  IssuesCloseOutlined,
  MoreOutlined,
  RetweetOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { deleteList, getList, updateData } from "actions/collection";
import { getCollectionLoading, getPagination, getStateResult } from "selectors";

import DataTableLayout from "components/layouts/DataTableLayout";
import { DateTimeConvert } from "../../../utils/DateTimeFormat.js";
import Footeractionlayout from "components/layouts/Footeractionlayout";
import { MenuAuth } from "contexts/menuAuth";
import React from "react";
import { connect } from "react-redux";
import { contacts } from "schemas";
import { getPageShowing } from "utils/common.js";

// import Cookies from 'js-cookie'
const { TextArea } = Input;

class ContactManagement extends React.Component {
  static contextType = MenuAuth;

  state = {
    visible: false,
    replyVisible: false,
    page: 1,
    per_page: 15,
    search: "",
    actionPermission: undefined,
  };

  componentDidMount() {
    getPageShowing();
    if (
      localStorage.getItem("search") &&
      localStorage.getItem("currSearch") === window.location.pathname
    ) {
      this.setState({ search: localStorage.getItem("search") });
    }
    let params = {
      page: localStorage.getItem("page")
        ? localStorage.getItem("page")
        : this.state.page,
      per_page: localStorage.getItem("per_page")
        ? parseInt(localStorage.getItem("per_page"))
        : this.state.per_page,
      search:
        localStorage.getItem("search") &&
          localStorage.getItem("currSearch") === window.location.pathname
          ? String(localStorage.getItem("search"))
          : this.state.search,
    };
    this.props.getData(params);
  }

  onSearch = (e) => {
    localStorage.setItem("page", 1);
    let params = {
      page: 1,
      per_page: localStorage.getItem("per_page")
        ? parseInt(localStorage.getItem("per_page"))
        : this.state.per_page,
      search: localStorage.getItem("search")
        ? localStorage.getItem("search")
        : this.state.search,
    };
    localStorage.setItem("search", this.state.search);
    localStorage.setItem("currSearch", window.location.pathname);
    this.props.getData(params);
  };

  setKeyword = (e) => {
    const value = e.target.value;
    localStorage.setItem("search", value);
    this.setState({ search: value });
  };

  showModaldelete = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
    this.deleteList();
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      closed_id: "",
    });
  };

  showModalreply = () => {
    this.setState({
      replyVisible: true,
    });
  };

  replayHandleOk = (reply) => {
    this.setState({
      replyVisible: false,
    });
    const { reply_id } = this.state;
    const { dataList } = this.props;
    const selectData = dataList.filter((id) => id.id === reply_id);
    this.handleUpdate(selectData, reply);
  };

  replayHandleCancel = (e) => {
    this.setState({
      replyVisible: false,
      reply_id: "",
    });
  };

  closedHandleOk = (reply) => {
    this.setState({
      visible: false,
    });
    const { closed_id } = this.state;
    const { dataList } = this.props;
    const selectData = dataList.filter((id) => id.id === closed_id);
    this.closedIssueUpdate(selectData);
  };

  closedIssueUpdate = (value) => {
    const { closed_id } = this.state;
    const requestFrom = {
      name: value[0].name,
      email: value[0].email,
      message: value[0].message,
      status: "closed",
      reply:
        value[0].reply === null ? null : { message: value[0].reply.message },
    };

    this.props.updateData(requestFrom, closed_id).then((response) => {
      this.setState({ isDirty: false, closed_id: "", visible: false }, () =>
        message.success(
          "Closed issue complete !!",
          2.5,
          this.props.history.push("/contacts-feedbacks"),
          this.componentDidMount()
        )
      );
    });
  };

  handleUpdate = (value, reply) => {
    const { reply_id } = this.state;
    const requestFrom = {
      name: value[0].name,
      email: value[0].email,
      message: value[0].message,
      status: "replied",
      reply: { message: reply.reply_text },
    };

    this.props.updateData(requestFrom, reply_id).then((response) => {
      this.setState({ isDirty: false, reply_id: "" }, () =>
        message.success(
          "Update complete !!",
          2.5,
          this.props.history.push("/contacts-feedbacks"),
          this.componentDidMount()
        )
      );
    });
  };

  onClickMenu = (e) => {
    switch (e.key) {
      case "view":
        return this.props.history.push(
          `/contacts-feedbacks/view/${e.item.props.id}`
        );
      case "reply":
        return (
          this.setState({ reply_id: e.item.props.id }), this.showModalreply()
        );
      case "closed issue":
        return (
          this.setState({ closed_id: e.item.props.id }), this.showModaldelete()
        );
      default:
        break;
    }
  };

  listMenu = (record) => {
    const { actionPermission } = this.state;

    const author = this.context?.menuAuth?.find(
      (menu) => menu?.key_slug === "opt"
    );
    const menu = author?.sub_menus?.find(
      (sub) => sub?.key_slug === "contacts-feedbacks"
    );

    !this.state.actionPermission &&
      this.setState({ actionPermission: menu?.role_permission });

    return record.status === "active" ? (
      <Menu key={record.id} onClick={this.onClickMenu}>
        {actionPermission?.can_read && (
          <Menu.Item key={"view"} id={record.id}>
            <EyeOutlined />
            ดูข้อมูล
          </Menu.Item>
        )}
        {actionPermission?.can_update && (
          <Menu.Item key={"reply"} id={record.id}>
            <RetweetOutlined />
            ตอบกลับ
          </Menu.Item>
        )}
        {actionPermission?.can_update && (
          <Menu.Item key={"closed issue"} id={record.id}>
            <IssuesCloseOutlined />
            ปิด/แก้ไขปัญหาแล้ว
          </Menu.Item>
        )}
      </Menu>
    ) : (
      <Menu key={record.id} onClick={this.onClickMenu}>
        {actionPermission?.can_read && (
          <Menu.Item key={"view"} id={record.id}>
            <EyeOutlined />
            ดูข้อมูล
          </Menu.Item>
        )}
      </Menu>
    );
  };

  columns = [
    {
      title: "#",
      dataIndex: "order",
      key: "order",
      width: "5%",
    },
    {
      title: "ชื่อผู้ส่ง",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: "15%",
    },
    {
      title: "อีเมล์",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      width: "15%",
    },
    {
      title: "ข้อความ",
      dataIndex: "message",
      key: "message",
      ellipsis: true,
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "created_at",
      key: "created_at",
      width: "16%",
      render: (value) => {
        const formatDay = DateTimeConvert(value);
        return formatDay;
      },
    },
    {
      title: "สถานะ",
      key: "status",
      dataIndex: "status",
      width: "12%",
      render: (status) => {
        return (
          <Space>
            <Badge
              color={
                status === "active"
                  ? "#00c48c"
                  : status === "inactive"
                    ? "#ff0000"
                    : status === "replied"
                      ? "#000000"
                      : status === "closed"
                        ? "#ABABAB"
                        : "#ff0000"
              }
            />
            <p
              style={{
                color:
                  status === "active"
                    ? "#00c48c"
                    : status === "inactive"
                      ? "#ff0000"
                      : status === "replied"
                        ? "#000000"
                        : status === "closed"
                          ? "#ABABAB"
                          : "#ff0000",
                marginTop: "13px",
                marginLeft: "-8px",
              }}
            >
              {status === "active"
                ? "ติดต่อเข้ามาใหม่"
                : status === "inactive"
                  ? "ไม่ใช้งาน"
                  : status === "replied"
                    ? "ตอบกลับแล้ว"
                    : status === "closed"
                      ? "ปิด/แก้ปัญหาแล้ว"
                      : "ยกเลิก"}
            </p>
          </Space>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: "7%",
      render: (record) => {
        return (
          <Dropdown trigger={["click"]} overlay={this.listMenu(record)}>
            <MoreOutlined style={{ fontSize: "22px" }} />
          </Dropdown>
        );
      },
    },
  ];

  render() {
    const { dataList, isLoading, pagination } = this.props;
    return (
      <Form>
        <Row justify="space-between">
          <Col>
            <Input
              style={{ marginBottom: "30px", width: "20vmax" }}
              onChange={this.setKeyword}
              placeholder="ค้นหาข้อความ"
              onPressEnter={this.onSearch}
              suffix={
                <SearchOutlined
                  onClick={() => this.onSearch()}
                  style={{ cursor: "pointer" }}
                />
              }
              value={this.state.search}
              autoComplete="on"
            ></Input>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {isLoading === true ? (
              <Skeleton
                className="App-form"
                loading={isLoading}
                active
              ></Skeleton>
            ) : (
              <DataTableLayout
                dataSource={dataList}
                columns={this.columns}
                isloading={isLoading}
                pagePagination={pagination}
                getData={this.props.getData}
              />
            )}
          </Col>

          <Modal
            visible={this.state.visible}
            onOk={this.closedHandleOk}
            onCancel={this.handleCancel}
          >
            <Col className="modal-delete">
              <IssuesCloseOutlined style={{ fontSize: "20px" }} />
            </Col>
            <Col className="modal-delete">
              ยืนยันการเปลี่ยนสถานะเป็น ปิด/แก้ไขปัญหาแล้ว
            </Col>
          </Modal>

          <Modal
            visible={this.state.replyVisible}
            onCancel={this.replayHandleCancel}
            footer={false}
          >
            <Form
              name="replyText"
              onFinish={this.replayHandleOk}
              layout="vertical"
            >
              <Form.Item
                name="reply_text"
                label="ข้อความตอบกลับ"
                rules={[
                  { required: true, message: "Please insert reply text." },
                ]}
              >
                <TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
              </Form.Item>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Footeractionlayout
                  size="13.8vmax"
                  submitText="ตอบกลับ"
                  onCancle={this.replayHandleCancel}
                />
              </span>
            </Form>
          </Modal>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  pagination: getPagination(contacts, state),
  isLoading: getCollectionLoading(contacts, state),
  dataList: getStateResult(contacts, state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: (params) => dispatch(getList(contacts, params)),
  deleteData: (id) => dispatch(deleteList(contacts, id)),
  updateData: (data, id) => dispatch(updateData(contacts, data, id)),
});

const ContactManagementListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactManagement);

export default ContactManagementListWithConnect;
