import React from 'react';
import { Row, Col, Form, Skeleton, message, Modal, Input } from 'antd'
import { connect } from 'react-redux'

import Viewlayout from 'components/layouts/Viewlayout.js'
import { IssuesCloseOutlined } from '@ant-design/icons'

import { DateTimeConvert } from '../../../utils/DateTimeFormat.js'
import { readData, deleteData, updateData } from 'actions/collection'
import { contacts } from 'schemas'
import { getResource, getResourceLoading } from 'selectors'
import Footeractionlayout from 'components/layouts/Footeractionlayout'
import { MenuAuth } from "contexts/menuAuth";

const { TextArea } = Input

class Viewrayout extends React.Component {
  static contextType = MenuAuth;
  state = {
    visible: false,
    replyVisible: false,
    status: true,
    actionPermission: undefined,
  }

  componentDidMount() {
    this.props.readData(this.props.match.params.id)
  }

  componentDidUpdate() {
    if (this.context?.menuAuth !== this.state.actionPermission) {
      this.getPermission()
    }
  }

  getPermission() {
    const author = this.context?.menuAuth?.find((menu) => menu?.key_slug === "opt");
    const menu = author?.sub_menus?.find((sub) => sub?.key_slug === "contacts-feedbacks");
    !this.state.actionPermission &&
      this.setState({ actionPermission: menu?.role_permission });
  }

  showModalreply = () => {
    this.setState({
      replyVisible: true,
    })
  }

  replayHandleOk = (reply) => {
    this.setState({ replyVisible: false })
    this.handleUpdate(reply)
  }

  handleUpdate = (reply) => {
    const { dataList } = this.props
    const requestFrom = {
      name: dataList.name,
      email: dataList.email,
      message: dataList.message,
      status: 'replied',
      reply: { message: reply.reply_text }
    }

    message.loading('Save in progress..', 2.5).then(() => {
      this.props
        .updateData(requestFrom, this.props.match.params.id)
        .then((response) => {
          this.setState({ isDirty: false, replyVisible: false }, () =>
            message.success(
              'Update complete !!',
              2.5,
              this.props.history.push('/contacts-feedbacks'),
              this.componentDidMount(),
            )
          )
        })
    })
  }

  replayHandleCancel = (e) => {
    this.setState({
      replyVisible: false,
    })
  }

  showModaldelete = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    })
    this.closedIssue()
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    })
  }

  closedIssue = () => {
    const { dataList } = this.props
    const requestFrom = {
      name: dataList.name,
      email: dataList.email,
      message: dataList.message,
      status: 'closed',
      reply: dataList.reply === null ? null : { message: dataList.reply.message }
    }

    message.loading('Closed issue in progress..', 2.5).then(() => {
      this.props
        .updateData(requestFrom, this.props.match.params.id)
        .then((response) => {
          this.setState({ isDirty: false, replyVisible: false }, () =>
            message.success(
              'Closed issue complete !!',
              2.5,
              this.props.history.push('/contacts-feedbacks'),
              this.componentDidMount(),
            )
          )
        })
    })
  }

  gotoEdit = () => {
    return (
      (this.setState({ reply_id: this.props.match.params.id }), this.showModalreply())
    )
  }

  render() {
    const { dataList, isLoading } = this.props

    return (
      <Form className="App-form" >
        < Viewlayout
          permission={this.state.actionPermission}
          viewData={dataList}
          isLoading={isLoading}
          textTitle='ข้อมูล Contact'
          iconEdit={dataList.status === 'active' ? 'reply' : ''}
          iconDelete={dataList.status === 'active' ? 'closeissue' : ''}
          textEdit={dataList.status === 'active' ? 'ตอบกลับ' : ''}
          textDelete={dataList.status === 'active' ? 'ปิด/แก้ไขปัญหาแล้ว' : ''}
          onDelete={this.showModaldelete} onEdit={this.showModalreply}>
          {isLoading === true ?
            <Skeleton className="App-form" loading={isLoading} active></Skeleton> :
            <Form.Item>
              <Row>
                <Col className="sil-break-word" >
                  <h1>ชื่อผู้ส่ง</h1>
                  {dataList.name}
                </Col>
              </Row>
              <Row>
                <Col className="sil-break-word" style={{ marginTop: '30px' }}>
                  <h1>อีเมล์</h1>
                  {dataList.email}
                </Col>
              </Row>
              <Row>
                <Col className="sil-break-word" style={{ marginTop: '30px' }}>
                  <h1>ข้อความ</h1>
                  {dataList.message}
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>วันที่สร้าง</h1>
                  {DateTimeConvert(dataList.created_at)}
                </Col>
              </Row>
              {dataList.status !== 'replied' ?
                '' :
                <>
                  <Row>
                    <Col className="sil-break-word" style={{ marginTop: '30px' }}>
                      <h1>ข้อความตอบกลับ</h1>
                      {dataList.reply && dataList.reply !== null ? dataList.reply.message : ' - '}
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ marginTop: '30px' }}>
                      <h1>วันเวลาตอบกลับ</h1>
                      {dataList.reply && dataList.reply !== null ? DateTimeConvert(dataList.reply.updated_at) : ' - '}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="sil-break-word" style={{ marginTop: '30px' }}>
                      <h1>ชื่อผู้ตอบกลับ</h1>
                      {dataList.reply && dataList.reply !== null ? dataList.reply.admin_name : ' - '}
                    </Col>
                  </Row>
                </>}
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>สถานะ</h1>
                  {dataList.status === 'active'
                    ? 'ติดต่อเข้ามาใหม่'
                    : dataList.status === 'inactive'
                      ? 'ไม่ใช้งาน'
                      : dataList.status === 'replied'
                        ? 'ตอบกลับแล้ว'
                        : dataList.status === 'closed'
                          ? 'ปิด/แก้ปัญหาแล้ว'
                          : 'ยกเลิก'}
                </Col>
              </Row>
            </Form.Item>
          }
        </Viewlayout>
        <Modal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Col className="modal-delete">
            <IssuesCloseOutlined style={{ fontSize: '20px' }} />
          </Col>
          <Col className="modal-delete">ยืนยันการเปลี่ยนสถานะเป็น ปิด/แก้ไขปัญหาแล้ว</Col>
        </Modal>

        <Modal
          visible={this.state.replyVisible}
          onCancel={this.replayHandleCancel}
          onOk={this.replayHandleOk}
          footer={false}
        >
          <Form name='replyText' onFinish={this.replayHandleOk} layout="vertical">
            <Form.Item name="reply_text" label="ข้อความตอบกลับ" rules={[{ required: true, message: 'Please insert reply text.' }]}>
              <TextArea
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>
            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Footeractionlayout
                size='13.8vmax'
                submitText='ตอบกลับ'
                onCancle={this.replayHandleCancel}
              />
            </span>
          </Form>
        </Modal>
      </Form >
    )
  }
}


const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(contacts, state),
  dataList: getResource(contacts, state),
})

const mapDispatchToProps = (dispatch) => ({
  readData: (id) => dispatch(readData(contacts, id)),
  deleteData: (id) => dispatch(deleteData(contacts, id)),
  updateData: (data, id) => dispatch(updateData(contacts, data, id)),
})

const SlideListWithConnect = connect(mapStateToProps, mapDispatchToProps)(Viewrayout)

export default SlideListWithConnect