import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Tooltip,
  message,
} from 'antd'
import {
  createDataMasterServerFTP,
  readDataMasterServerFTP,
  updateDataMasterServerFTP,
} from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'

import CryptoJS from 'crypto-js'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import Footeractionlayout from 'components/layouts/Footeractionlayout'
import React from 'react'
import { connect } from 'react-redux'
import { ftpserver } from 'schemas'
import iconCorrect from 'images/correct@3x.png'
import iconErr from 'images/icon-error@3x.png'

const { Option } = Select
const textValidPass = (
  <div style={{ width: '370px' }}>
    <ul style={{ marginTop: '15px' }}>
      <li>ควรมีความยาวอย่างน้อย 6 ตัวอักษรหรือมากกว่านั้น</li>
      <li>ประกอบด้วยตัวอักษร (a-z, A-Z, 0-9) และห้ามเว้นวรรค</li>
    </ul>
  </div>
)

const formRef = React.createRef()

function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str
  return str.substring(0, index) + chr + str.substring(index + 1)
}

class Formlayout extends React.Component {
  state = {
    isDirty: false,
    tempData: {},
    errorMsg: '',
    isCreated: false,
    isCreateError: false,
    isCancel: false,
    tempPassword: '',
    tempHost: '',
    tempPort: '',
    dePassword: '',
    isChanged: false,
    confirmUpdateModal: false,
    protocolType: false,
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props
        .readDataMasterServerFTP(this.props.match.params.id)
        .then((res) => {
          this.setState({
            tempPassword: res.data?.password,
            tempHost: res.data?.host,
            tempPort: res.data?.port,
            protocolType:
              res.data?.protocol === 'http' || res.data?.protocol === 'https'
                ? true
                : false,
          })
        })
    }
  }

  handleCancel = () => this.setState({ isCancel: false })

  handleCancelForm = () => {
    this.setState({ isCancel: true })
  }

  handleSubmit = (value) => {
    const passwordEn = CryptoJS.AES.encrypt(
      value.password,
      'seeitlive-thailand-2020'
    ).toString()

    if (this.state.isDirty === false) {
      this.setState({ isDirty: true })

      const requestFrom = {
        name: value.name,
        username: value.username,
        password: passwordEn,
        host: value.host,
        ftp_port: value.ftp_port,
        service_port: value.service_port,
        stream_port: value.stream_port,
        protocol: value.protocol,
        domain_name:
          value.protocol === 'http' || value.protocol === 'https'
            ? value.domain_name
            : '',
        timelapse_port: value.timelapse_port,
      }

      message.loading('Save in progress..', 0)
      this.props.createDataMasterServerFTP(requestFrom).then((res) => {
        message.destroy()
        if (res?.code === 201) {
          this.setState({ isDirty: true, isCreated: true }, () => {
            localStorage.setItem('page', 1)
            localStorage.setItem('search', '')
          })
        } else {
          this.setState({
            isDirty: false,
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    }
  }

  handleUpdate = (value) => {
    const passwordEn = CryptoJS.AES.encrypt(
      value.password,
      'seeitlive-thailand-2020'
    ).toString()
    if (this.state.isDirty === false) {
      this.setState({ isDirty: true })
      const requestFrom = {
        name: value.name,
        username: value.username,
        password: value.password ? passwordEn : this.state.tempPassword,
        host: value.host,
        ftp_port: value.ftp_port,
        service_port: value.service_port,
        stream_port: value.stream_port,
        protocol: value.protocol,
        domain_name:
          value.protocol === 'http' || value.protocol === 'https'
            ? value.domain_name
            : '',
        timelapse_port: value.timelapse_port,
      }

      message.loading('Save in progress..', 0)
      this.props
        .updateDataMasterServerFTP(requestFrom, this.props.match.params.id)
        .then((response) => {
          message.destroy()
          if (response?.code === 200) {
            this.setState({ isDirty: true, isCreated: true })
          } else {
            this.setState({
              isDirty: false,
              isCreateError: true,
              errorMsg: response?.message,
            })
          }
        })
    }
  }

  checkIsPassword(str) {
    return /^(?!.* )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/.test(str)
  }

  checkValidPassword = (rule, value) => {
    if (this.props.match.params.id && value === '') {
      return Promise.resolve()
    }

    if (value === null || value === '') {
      return Promise.reject('Please input your password !!')
    } else {
      if (this.checkIsPassword(value)) {
        return Promise.resolve()
      } else {
        return Promise.reject('Invalid data format !!')
      }
    }
  }

  checkValidPasswordConfirm = (rule, value) => {
    if (this.props.match.params.id && value === '') {
      return Promise.resolve()
    }

    if (value === null || value === '') {
      return Promise.reject('Please input your password !!')
    } else {
      if (!this.checkIsPassword(value)) {
        return Promise.reject('Invalid data format !!')
      } else {
        if (
          formRef.current.getFieldsValue().password !==
          formRef.current.getFieldsValue().confirm_password
        ) {
          return Promise.reject('Password not match !!')
        } else {
          return Promise.resolve()
        }
      }
    }
  }

  checkValidIP = (rule, value) => {
    let textValue = value

    if (
      textValue[value.length - 1] === '.' &&
      textValue[value.length - 2] === '.'
    ) {
      textValue = setCharAt(textValue, value.length - 2, '.')
      textValue = setCharAt(textValue, value.length - 1, '')
    }

    if (value === ' ') {
      formRef.current.setFieldsValue({
        host: '',
      })
    } else {
      formRef.current.setFieldsValue({
        host: textValue,
      })
    }

    if (value === null || value === '') {
      return Promise.reject('IP address is required !!')
    } else {
      if (!/^([0-9.a-zA-Z_:/-]{1,})?$/.test(value)) {
        return Promise.reject('IP address Invalid data format !!')
      } else {
        return Promise.resolve()
      }
    }
  }

  onPasswordChange = (value) => {
    if (this.props.dataList.total_used !== 0) {
      if (
        value ||
        formRef.current.getFieldValue().host !== this.state.tempHost ||
        formRef.current.getFieldValue().port !== this.state.tempPort
      ) {
        this.setState({ isChanged: true })
      } else {
        this.setState({ isChanged: false })
      }
    }
  }

  onHostChange = async (value) => {
    if (this.props.dataList.total_used !== 0) {
      if (
        formRef.current.getFieldValue().password ||
        value !== this.state.tempHost ||
        formRef.current.getFieldValue().port !== this.state.tempPort
      ) {
        await this.setState({ isChanged: true })
      } else {
        await this.setState({ isChanged: false })
      }
    }
  }

  onPortChange = (value) => {
    if (this.props.dataList.total_used !== 0) {
      if (
        formRef.current.getFieldValue().password ||
        formRef.current.getFieldValue().host !== this.state.tempHost ||
        this.state.tempPort !== value
      ) {
        this.setState({ isChanged: true })
      } else {
        this.setState({ isChanged: false })
      }
    }
  }

  handleConfirmUpdate = () => {
    this.setState({ confirmUpdateModal: true })
  }

  onSelected = (e) => {
    if (e === 'http' || e === 'https') {
      this.setState({ protocolType: true })
    } else {
      formRef.current.setFieldsValue({ domain_name: '' })
      this.setState({ protocolType: false })
    }
  }

  checkUrl = (rule, value) => {
    let urlreg1 = /^(?!https?)?www\.([A-z]+)\.([A-z]{2,})/
    let check_point = false
    let check_www = false
    let check_position_point = value.length - value.lastIndexOf(".")
    let check_position_after_point = value.indexOf("..")

    let arr = value.split('');
    let counts = 0;

    for (let i = 0; i < arr.length; i++) {
      let num = arr[i];
      if (num === '.') {
        counts = counts + 1;
      }
    }

    if (value.indexOf(".") > 1 && value.includes('.') && check_position_point > 2 && check_position_after_point < 0 && counts >= 2) {
      check_point = true
    }
    if (value.split('www').length === 1) {
      check_www = true
    }

    if (urlreg1.test(value) || (check_point && check_www)) {
      return Promise.resolve()
    } else {
      if (!value) {
        return Promise.reject('Domain name is required !')
      } else {
        return Promise.reject('Domain name format is incorrect.')
      }
    }
  }

  render() {
    const { dataList, isLoading } = this.props
    return isLoading === true ? (
      <Skeleton className="App-form" loading={isLoading} active></Skeleton>
    ) : (
      <Form
        ref={formRef}
        layout="vertical"
        className="App-form"
        onFinish={
          this.props.match.params.id
            ? this.state.isChanged
              ? this.handleConfirmUpdate
              : this.handleUpdate
            : this.handleSubmit
        }
      >
        <Form.Item style={{ marginTop: '-20px' }}>
          <h1 className="topic">
            {this.props.match.params.id
              ? 'แก้ไขข้อมูลมาสเตอร์เซิร์ฟเวอร์ FTP'
              : 'เพิ่มข้อมูลมาสเตอร์เซิร์ฟเวอร์ FTP'}
          </h1>
        </Form.Item>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'Name is required !!',
                },
              ]}
              initialValue={
                this.props.match.params.id && dataList.name ? dataList.name : ''
              }
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Form.Item
              style={{ marginTop: '-15px' }}
              name="host"
              label="Host (IP address)"
              rules={[
                {
                  required: true,
                  validator: this.checkValidIP,
                  transform: (value) => {
                    let text = ''
                    if (!/^([0-9.a-zA-Z_:/-]{1,})?$/.test(value)) {
                      text = value.substring(0, value.length - 1)
                    } else {
                      text = value
                    }
                    return text
                  },
                },
              ]}
              initialValue={
                this.props.match.params.id && dataList.host ? dataList.host : ''
              }
            >
              <Input
                autoComplete="off"
                onChange={(value) => this.onHostChange(value.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 5]}>
          <Col span={3}>
            <Form.Item
              style={{ marginTop: '-15px' }}
              name="ftp_port"
              label="FTP Port"
              rules={[
                {
                  required: true,
                  // validator: this.checkValidIP,
                  message: 'FTP port is required !!',
                  transform: (value) => {
                    let text = ''
                    if (!/^([0-9]{1,})?$/.test(value)) {
                      text = value.substring(0, value.length - 1)
                      formRef.current.setFieldsValue({ ftp_port: text })
                    } else {
                      text = value
                    }
                    return text
                  },
                },
              ]}
              initialValue={
                this.props.match.params.id && dataList.ftp_port
                  ? dataList.ftp_port
                  : ''
              }
            >
              <Input
                autoComplete="off"
                onChange={(value) => this.onPortChange(value.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              style={{ marginTop: '-15px' }}
              name="service_port"
              label="Service Port"
              rules={[
                {
                  required: true,
                  // validator: this.checkValidIP,
                  message: 'Service port is required !!',
                  transform: (value) => {
                    let text = ''
                    if (!/^([0-9]{1,})?$/.test(value)) {
                      text = value.substring(0, value.length - 1)
                      formRef.current.setFieldsValue({
                        service_port: text,
                      })
                    } else {
                      text = value
                    }
                    return text
                  },
                },
              ]}
              initialValue={
                this.props.match.params.id && dataList.service_port
                  ? dataList.service_port
                  : ''
              }
            >
              <Input
                autoComplete="off"
                onChange={(value) => this.onPortChange(value.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              style={{ marginTop: '-15px' }}
              name="stream_port"
              label="Stream Port"
              rules={[
                {
                  required: true,
                  message: 'Stream port is required !!',
                  transform: (value) => {
                    let text = ''
                    if (!/^([0-9]{1,})?$/.test(value)) {
                      text = value.substring(0, value.length - 1)
                      formRef.current.setFieldsValue({
                        stream_port: text,
                      })
                    } else {
                      text = value
                    }
                    return text
                  },
                },
              ]}
              initialValue={
                this.props.match.params.id && dataList.stream_port
                  ? dataList.stream_port
                  : ''
              }
            >
              <Input
                autoComplete="off"
                onChange={(value) => this.onPortChange(value.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              style={{ marginTop: '-15px' }}
              name="timelapse_port"
              label="Timelapse Port"
              rules={[
                {
                  required: true,
                  message: 'Timelapse port is required !!',
                  transform: (value) => {
                    let text = ''
                    if (!/^([0-9]{1,})?$/.test(value)) {
                      text = value.substring(0, value.length - 1)
                      formRef.current.setFieldsValue({
                        timelapse_port: text,
                      })
                    } else {
                      text = value
                    }
                    return text
                  },
                },
              ]}
              initialValue={
                this.props.match.params.id && dataList.timelapse_port
                  ? dataList.timelapse_port
                  : ''
              }
            >
              <Input
                autoComplete="off"
                onChange={(value) => this.onPortChange(value.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={3}>
            <Form.Item
              name="protocol"
              label="Protocol"
              rules={[
                {
                  required: false,
                },
              ]}
              initialValue={
                this.props.match.params.id && dataList.protocol
                  ? dataList.protocol
                  : ''
              }
            >
              <Select
                placeholder="ไม่เลือก"
                onChange={(e) => this.onSelected(e)}
              >
                <Option value="">ไม่เลือก</Option>
                <Option value="https">https</Option>
                <Option value="http">http</Option>
              </Select>
            </Form.Item>
          </Col>
          {this.state.protocolType && (
            <Col span={9}>
              <Form.Item
                name="domain_name"
                label="Domain name"
                rules={[
                  // {
                  //   required: true,
                  //   message: 'Domain name is required !!',
                  // },
                  {
                    validator: this.checkUrl,
                    // message: 'Domain name format is incorrect.',
                  },
                ]}
                initialValue={
                  this.props.match.params.id && dataList.domain_name
                    ? dataList.domain_name
                    : ''
                }
              >
                <Input />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Form.Item
              name="username"
              label="Username"
              rules={[
                {
                  required: true,
                  message: 'Username is required !!',
                },
              ]}
              initialValue={
                this.props.match.params.id && dataList.username
                  ? dataList.username
                  : ''
              }
            >
              <Input
                autoComplete="off"
                disabled={this.props.match.params.id ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={11}>
            <Form.Item
              style={{ marginTop: '-15px' }}
              name="password"
              label="Password"
              rules={[
                {
                  required: this.props.match.params.id ? false : true,
                  validator: this.checkValidPassword,
                },
              ]}
              initialValue=""
            >
              <Input.Password
                autoComplete="off"
                onChange={(value) => this.onPasswordChange(value.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={1}>
            <Tooltip
              className="sil-toottip-custom-width"
              placement="topRight"
              title={textValidPass}
              style={{ width: '150px' }}
            >
              <ExclamationCircleOutlined
                style={{ fontSize: '18px', marginTop: '22px' }}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={11}>
            <Form.Item
              style={{ marginTop: '-15px' }}
              name="confirm_password"
              label="Confirm Password"
              rules={[
                {
                  required: this.props.match.params.id ? false : true,
                  validator: this.checkValidPasswordConfirm,
                },
              ]}
              initialValue=""
              dependencies={['password']}
            >
              <Input.Password autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={1}>
            <Tooltip
              className="sil-toottip-custom-width"
              placement="topRight"
              title={textValidPass}
              style={{ width: '100px' }}
            >
              <ExclamationCircleOutlined
                style={{ fontSize: '18px', marginTop: '22px' }}
              />
            </Tooltip>
          </Col>
        </Row>
        <Form.Item shouldUpdate={true}>
          <Footeractionlayout
            onCancle={this.handleCancelForm}
          ></Footeractionlayout>
        </Form.Item>

        <Modal
          visible={this.state.isCreated}
          footer={false}
          closable={false}
          centered={true}
          width={'420px'}
        >
          <div
            style={{
              height: '220px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <img alt="example" src={iconCorrect} width={48} height={48} />
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginTop: '15px',
                }}
              >
                บันทึกข้อมูลสำเร็จแล้ว
              </Col>
              <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                กดตกลงเพื่อออกจากหน้านี้
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '30px',
                }}
              >
                <Space>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() => {
                      this.props.history.goBack()
                    }}
                  >
                    ตกลง
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          visible={this.state.isCreateError}
          footer={false}
          closable={false}
          centered={true}
          width={'500px'}
        >
          <div
            style={{
              height: '220px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <img src={iconErr} width={48} height={48} alt="Icon err" />
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginTop: '15px',
                }}
              >
                บันทึกข้อมูลไม่สำเร็จ
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '15px',
                }}
              >
                {this.state.errorMsg}
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '30px',
                }}
              >
                <Space>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() => {
                      this.setState({ isCreateError: false })
                    }}
                  >
                    ตกลง
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          visible={this.state.isCancel}
          footer={false}
          closable={false}
          centered={true}
          width={'420px'}
        >
          <div
            style={{
              height: '180px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Row>
              <Col
                span={24}
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                ยืนยันการยกเลิก
              </Col>
              <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                หากคุณต้องการยกเลิก
              </Col>
              <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                กรุณากด ยืนยัน
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '30px',
                }}
              >
                <Space>
                  <Button
                    style={{ width: '170px' }}
                    onClick={() => this.handleCancel()}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() => {
                      this.props.history.goBack()
                    }}
                  >
                    ยืนยัน
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          visible={this.state.confirmUpdateModal}
          footer={false}
          closable={false}
          centered={true}
          width={'700px'}
        >
          <div
            style={{
              height: '180px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Row>
              <Col
                span={24}
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                ยืนยันการแก้ไข
              </Col>
              <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                {`หากทำการแก้ไขอาจส่งผลกระทบกับ FTP path ที่ใช้งาน Username นี้ทั้งหมด ${dataList?.total_used} ข้อมูล`}
              </Col>
              <Col span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                หากต้องการแก้ไขกรุณากด ยืนยัน
              </Col>
              <Col
                span={24}
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  marginTop: '30px',
                }}
              >
                <Space>
                  <Button
                    style={{ width: '170px' }}
                    onClick={() => this.setState({ confirmUpdateModal: false })}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="primary"
                    style={{ width: '170px' }}
                    onClick={() =>
                      this.setState(
                        { confirmUpdateModal: false },
                        this.handleUpdate(formRef.current.getFieldsValue())
                      )
                    }
                  >
                    ยืนยัน
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
      </Form>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(ftpserver, state),
  dataList: getResource(ftpserver, state),
})

const mapDispatchToProps = (dispatch) => ({
  createDataMasterServerFTP: (data) =>
    dispatch(createDataMasterServerFTP(ftpserver, data)),
  readDataMasterServerFTP: (id) =>
    dispatch(readDataMasterServerFTP(ftpserver, id)),
  updateDataMasterServerFTP: (data, id) =>
    dispatch(updateDataMasterServerFTP(ftpserver, data, id)),
})

const PartherGroupListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Formlayout)

export default PartherGroupListWithConnect
