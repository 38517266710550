import * as CONST from 'actions/collection'
import { fromJS } from 'immutable'

const initialState = fromJS({})

const resourceReducer = (state = initialState, { type, schema, payload }) => {
  switch (type) {
    case CONST.GET_LIST_SUCCEED:
      let reqList = payload.entities[schema]

      return state.setIn([schema, 'data'], reqList)

    default:
      return state
  }
}

export default resourceReducer
