import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  message,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { deleteList, getList } from "actions/collection";
import { getCollectionLoading, getPagination, getStateResult } from "selectors";

import Actions from "components/action";
import DataTableLayout from "components/layouts/DataTableLayout";
import { MenuAuth } from "contexts/menuAuth";
import { connect } from "react-redux";
import iconDeleted from "images/correct@3x.png";
import iconErr from "images/icon-error@3x.png";
import { mapErrMsg } from "../../../utils/common";
import moment from "moment";
import { roles } from "schemas";
import styled from "styled-components";

const RoleAndPermission = (props) => {
  const history = useHistory();
  const [dataList, setDataList] = useState();
  const [dataSearch, setDataSearch] = useState(localStorage.getItem("search"));
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteSuccess, setOpenDeleteSuccess] = useState(false);
  const [openDeleteErr, setOpenDeleteErr] = useState({
    open: false,
    errorMsg: "",
  });
  const [option, setOption] = useState();
  const [msgErr, setMsgErr] = useState([]);
  const permissionAuthor = useContext(MenuAuth);
  const [actionPermission, setActionPermission] = useState(undefined);

  const pagination = {
    total: option?.total,
    per_page: option?.per_page,
    current_page: option?.current_page,
    sort_field: option?.sorts.key,
    sort_by: option?.sorts.value,
  };

  const columns = [
    {
      title: "#",
      dataIndex: "order",
      key: "order",
      width: "6%",
    },
    {
      title: "ชื่อสิทธิ์เข้าใช้งาน",
      dataIndex: "name",
      key: "name",
      // ellipsis: true,
      width: "30%",
    },
    {
      title: "กลุ่มผู้ใช้งาน",
      dataIndex: "user_groups_data",
      key: "user_groups_data",
      // ellipsis: true,
      width: "20%",
      render: (group) => `${group?.name || "-"}`,
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "created_at",
      key: "created_at",
      // ellipsis: true,
      width: "15%",
      render: (date) => moment(date).local("th").format("LL"),
    },
    {
      title: "สถานะ",
      key: "status",
      dataIndex: "status",
      width: "10%",
      render: (status) => {
        return (
          <div style={{ color: status === "active" ? "#00c48c" : "#ff0000" }}>
            {status === "active" ? "ใช้งาน" : "ไม่ใช้งาน"}
          </div>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: "7%",
      render: (record) => {
        return (
          <Actions
            record={record}
            path="/roles-and-permissions"
            onDelete={onDeleteRoles}
            history={history}
            permission={actionPermission}
          />
        );
      },
    },
  ];

  const getList = (page, perPage) => {
    setDataList(undefined);
    const params = {
      page,
      per_page: perPage,
      filters: `name:like:${dataSearch}`,
    };
    props.getData(params).then((resopnse) => {
      if (resopnse?.code === 200) {
        const list = resopnse?.data ? resopnse?.data.lists : [];
        const dataOption = resopnse?.data ? resopnse?.data.option : null;
        setOption(dataOption);
        const newList =
          list &&
          list.map((item, index) => {
            const order =
              dataOption.per_page * (dataOption.current_page - 1) + (index + 1);
            return { ...item, order: order };
          });
        setDataList(newList);
      } else {
        setDataList([]);
      }
    });
  };

  const onDeleteRoles = (rowId) => {
    message.loading("Delete in progress..", 0);
    props.deleteData(rowId).then((res) => {
      message.destroy();
      setOpenDelete(false);
      if (res?.code === 200) {
        setOpenDeleteSuccess(true);
      } else {
        const arrError = mapErrMsg(res?.errors, "สิทธิ์เข้าใช้งาน");
        setMsgErr(arrError.length > 0 ? arrError : [res?.status]);
        setOpenDeleteErr({ open: true });
      }
    });
  };

  const onSearch = () => {
    localStorage.setItem("search", dataSearch);
    getList(1, localStorage.getItem("per_page"));
  };

  const onInputChange = (e) => {
    setDataSearch(e.target.value);
  };

  const onCloseDelete = () => {
    getList(localStorage.getItem("page"), localStorage.getItem("per_page"));
    setOpenDeleteSuccess(false);
  };

  useEffect(() => {
    getList(localStorage.getItem("page"), localStorage.getItem("per_page"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const author = permissionAuthor?.menuAuth?.find(
      (menu) => menu?.key_slug === "users-management"
    );
    const menu = author?.sub_menus?.find(
      (sub) => sub?.key_slug === "roles-and-permissions"
    );

    setActionPermission(menu?.role_permission);
  }, [permissionAuthor]);

  return (
    <>
      <Form>
        <Row justify="space-between">
          <Col>
            <Input
              id="input"
              style={{ marginBottom: "30px", width: "20vmax" }}
              onChange={onInputChange}
              placeholder="ค้นหาชื่อสิทธิ์เข้าใช้งาน"
              onPressEnter={onSearch}
              value={dataSearch}
              suffix={
                <SearchOutlined
                  onClick={() => onSearch("click")}
                  style={{ cursor: "pointer" }}
                />
              }
              autoComplete="on"
              // allowClear
            />
          </Col>
          {actionPermission && actionPermission?.can_create && (
            <Col>
              <Button type="primary">
                <Link to="/roles-and-permissions/create">
                  <PlusOutlined /> เพิ่มข้อมูล
                </Link>
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <Skeleton
              className="App-form"
              loading={dataList === undefined}
              active
            >
              <DataTableLayout
                dataSource={dataList}
                columns={columns}
                isloading={dataList === undefined}
                pagePagination={pagination}
                getData={(param) => getList(param.page, param.per_page)}
              />
            </Skeleton>
          </Col>
        </Row>
      </Form>

      <Modal
        visible={openDelete}
        onOk={onDeleteRoles}
        onCancel={() => setOpenDelete(false)}
      >
        <Col className="modal-delete">
          <DeleteOutlined style={{ fontSize: "40px" }} />
        </Col>
        <Col className="modal-delete">ต้องการลบข้อมูลใช่หรือไม่</Col>
      </Modal>

      <Modal
        visible={openDeleteSuccess}
        footer={false}
        closable={false}
        centered={true}
        width={"500px"}
      >
        <BoxModel height="180px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="example" src={iconDeleted} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>ลบข้อมูลเสร็จสิ้น</HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button
                    type="primary"
                    style={{ width: "170px" }}
                    onClick={onCloseDelete}
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal
        visible={openDeleteErr.open}
        footer={false}
        closable={false}
        centered={true}
        width={"500px"}
      >
        <BoxModel height="220px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="example" src={iconErr} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>ลบข้อมูลไม่สำเร็จ</HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="15px">
                {msgErr.map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button
                    type="primary"
                    style={{ width: "170px" }}
                    onClick={() =>
                      setOpenDeleteErr({ open: false, errorMsg: "" })
                    }
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  pagination: getPagination(roles, state),
  isLoading: getCollectionLoading(roles, state),
  dataList: getStateResult(roles, state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: (params) =>
    dispatch(getList(roles, params, process.env.REACT_APP_AUTHOR)),
  deleteData: (id, params) =>
    dispatch(deleteList(roles, id, params, process.env.REACT_APP_AUTHOR)),
});

const RoleListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleAndPermission);

export default RoleListWithConnect;

const BoxModel = styled.div`
  height: ${(props) => props.height};
  justify-content: center;
  align-items: center;
  display: flex;
`;
const HeaderModel = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
`;
const TextModel = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: ${(props) => props.marginTop};
`;
