import React from "react";
import imageBG from "images/home-page.png";
import styled from "styled-components";

const HomePage = () => {
  return (
    <>
      <BodyHomePage src={imageBG}>
        <BoxText>
          <TextTitle>Hi, {localStorage.getItem('user_name') ? localStorage.getItem('user_name') : ''}</TextTitle>
          <TextHeader>Welcome!</TextHeader>
        </BoxText>
      </BodyHomePage>
    </>
  )
}

export default HomePage;

const BoxText = styled.div`
  margin: 85px 0 0 82px;
  position: absolute;
`

const BodyHomePage = styled.div`
  background-image:${props => props.src && `url(${props.src})`};
  background-repeat:no-repeat;
  background-size:contain;
  background-position:right;
  width: 100%;
  height: 100%;
`

const TextTitle = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 0;
`

const TextHeader = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 130px;
  color: #000000;
`
