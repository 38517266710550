import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  message,
} from 'antd'
import {
  deleteListMasterServerFTP,
  readDataMasterServerFTP,
} from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'

import CryptoJS from 'crypto-js'
import { DeleteOutlined } from '@ant-design/icons'
import { EyeOutlined } from '@ant-design/icons'
import React from 'react'
import Viewlayout from 'components/layouts/Viewlayout'
import { connect } from 'react-redux'
import { ftpserver } from 'schemas'
import iconDeleted from 'images/correct@3x.png'
import iconErr from 'images/icon-error@3x.png'
import { MenuAuth } from "contexts/menuAuth";

const reqPassFormRef = React.createRef()

class view extends React.Component {
  static contextType = MenuAuth;
  state = {
    visible: false,
    isCreateError: false,
    visibleDeleted: false,
    errorMsg: '',
    passwordDe: '',
    viewPasswordModal: false,
    getPassword: '',
    showingPassword: false,
    getDePassword: '',
    actionPermission: undefined
  }

  componentDidMount() {
    this.props.readData(this.props.match.params.id).then((res) => {
      const bytes = CryptoJS.AES.decrypt(
        res.data.password,
        'seeitlive-thailand-2020'
      )
      const passwordDe = bytes.toString(CryptoJS.enc.Utf8)
      const passwordConvert = []

      for (let i = 0; i < passwordDe.length; i++) {
        if (i === 0 || i === passwordDe.length - 1) {
          passwordConvert.push(passwordDe[i])
        } else {
          passwordConvert.push('*')
        }
      }

      this.setState({
        passwordDe: passwordConvert,
        getDePassword: res.data.password,
      })
    })
  }

  componentDidUpdate() {
    if (this.context?.menuAuth !== this.state.actionPermission) {
      this.getPermission()
    }
  }

  getPermission() {
    const author = this.context?.menuAuth?.find((menu) => menu?.key_slug === "master-data");
    const menu = author?.sub_menus?.find((sub) => sub?.key_slug === "master-server-ftp");
    !this.state.actionPermission && this.setState({ actionPermission: menu?.role_permission });
  }


  showModaldelete = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    })
    this.deleteList()
  }

  handleCancel = (e) => {
    this.setState({
      page: 1,
      search: this.state.search,
      per_page: localStorage.getItem('per_page')
        ? parseInt(localStorage.getItem('per_page'))
        : this.state.per_page,
      sort_field: 'sort',
      sort_by: 'asc',
      visible: false,
    })
  }

  deleteList = () => {
    this.setState({ visible: false }, () => {
      this.props.deleteData(this.props.dataList.id).then(() => {
        message.loading('Delete in progress..', 2).then(() => {
          message.success('Delete complete !!', 0.5)
          this.setState({ delete_id: '' })
          this.props.history.push('/master-server-ftp')
        })
      })
    })
  }

  deleteList = () => {
    const { dataList } = this.props
    this.setState({ visible: false }, () => {
      message.loading('Delete in progress..', 0)
      this.props.deleteData(dataList.id).then((res) => {
        if (res?.code === 200) {
          message.destroy()
          this.setState({
            visibleDeleted: true,
          })
        } else {
          message.destroy()
          this.setState({
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    })
  }

  gotoEdit = () => {
    this.props.history.push(
      `/master-server-ftp/edit/${this.props.match.params.id}`
    )
  }

  getPassword = async (e) => {
    await this.setState({ getPassword: e })
  }

  readingPassword = () => {
    reqPassFormRef.current.setFieldsValue({
      req_pass: '',
    })
    this.setState({ viewPasswordModal: false })
    const bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('tempData'),
      'seeitlive-thailand-2020'
    )
    const passwordDe = bytes.toString(CryptoJS.enc.Utf8)

    if (this.state.getPassword === passwordDe) {
      this.setState({ showingPassword: true })
      message.success('Success !!', 1.5)
    } else {
      this.setState({ showingPassword: false })
      message.error('Wrong password !!', 1.5)
    }
  }

  render() {
    const { dataList, isLoading } = this.props
    const bytes = CryptoJS.AES.decrypt(
      this.state.getDePassword,
      'seeitlive-thailand-2020'
    )

    const passwordDecode = bytes.toString(CryptoJS.enc.Utf8)

    return (
      <Form className="App-form">
        <Viewlayout
          permission={this.state.actionPermission}
          viewData={dataList}
          isLoading={isLoading}
          textTitle="ข้อมูลมาสเตอร์เซิร์ฟเวอร์ FTP"
          iconEdit="edit"
          iconDelete="delete"
          textEdit="แก้ไขข้อมูล"
          textDelete="ลบข้อมูล"
          onEdit={this.gotoEdit}
          onDelete={this.showModaldelete}
        >
          {isLoading === true ? (
            <Skeleton
              className="App-form"
              loading={isLoading}
              active
            ></Skeleton>
          ) : (
            <div>
              <Row gutter={[100, 0]}>
                <Col
                  className="sil-break-word"
                  style={{ width: '250px' }}
                  span={24}
                >
                  <h1>Name</h1>
                  {dataList.name ? dataList.name : '-'}
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '30px', width: '250px' }} span={12}>
                  <h1>Host (IP address)</h1>
                  {dataList.host || '-'}
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col style={{ marginTop: '30px', width: '250px' }} span={5}>
                  <h1>FTP Port</h1>
                  {dataList.ftp_port || '-'}
                </Col>
                <Col style={{ marginTop: '30px', width: '250px' }} span={5}>
                  <h1>Service Port</h1>
                  {dataList.service_port || '-'}
                </Col>
                <Col style={{ marginTop: '30px', width: '250px' }} span={5}>
                  <h1>Stream Port</h1>
                  {dataList.stream_port || '-'}
                </Col>
                <Col style={{ marginTop: '30px', width: '250px' }} span={5}>
                  <h1>Timelapse Port</h1>
                  {dataList.timelapse_port || '-'}
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col
                  className="sil-break-word"
                  style={{ marginTop: '30px', width: '250px' }}
                  span={5}
                >
                  <h1>Protocol</h1>
                  {dataList.protocol ? dataList.protocol : '-'}
                </Col>
                {dataList.protocol === 'http' ||
                  dataList.protocol === 'https' ? (
                  <Col
                    className="sil-break-word"
                    style={{ marginTop: '30px', width: '250px' }}
                    span={19}
                  >
                    <h1>Domain name</h1>
                    {dataList.domain_name ? dataList.domain_name : '-'}
                  </Col>
                ) : null}
              </Row>
              <Row gutter={[100, 0]}>
                <Col
                  className="sil-break-word"
                  style={{ marginTop: '30px', width: '250px' }}
                  span={24}
                >
                  <h1>Username</h1>
                  {dataList.username ? dataList.username : '-'}
                </Col>
              </Row>
              <Row gutter={[100, 0]}>
                <Col
                  className="sil-break-word"
                  style={{ marginTop: '30px', width: '250px' }}
                  span={24}
                >
                  <h1>Password</h1>
                  <Space size="middle">
                    <div>
                      {dataList.password && !this.state.showingPassword
                        ? this.state.passwordDe
                        : passwordDecode}{' '}
                    </div>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.setState({ viewPasswordModal: true })}
                    >
                      {!this.state.showingPassword ? <EyeOutlined /> : null}
                    </div>
                  </Space>
                </Col>
              </Row>
              <Modal
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
              >
                <Col className="modal-delete">
                  <DeleteOutlined style={{ fontSize: '40px' }} />
                </Col>
                <Col className="modal-delete">ต้องการลบข้อมูลใช่หรือไม่</Col>
              </Modal>
              <Modal
                visible={this.state.visibleDeleted}
                footer={false}
                closable={false}
                centered={true}
                width={'500px'}
              >
                <div
                  style={{
                    height: '180px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <img
                        alt="example"
                        src={iconDeleted}
                        width={48}
                        height={48}
                      />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      ลบข้อมูลเสร็จสิ้น
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          type="primary"
                          style={{ width: '170px' }}
                          onClick={() =>
                            this.props.history.push('/master-server-ftp')
                          }
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
              <Modal
                visible={this.state.isCreateError}
                footer={false}
                closable={false}
                centered={true}
                width={'500px'}
              >
                <div
                  style={{
                    height: '220px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <img alt="example" src={iconErr} width={48} height={48} />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      ลบข้อมูลไม่สำเร็จ
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      {this.state.errorMsg}
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          type="primary"
                          style={{ width: '170px' }}
                          onClick={() => {
                            this.setState({ isCreateError: false })
                          }}
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
              <Modal
                visible={this.state.viewPasswordModal}
                footer={false}
                closable={false}
                centered={true}
                width={'700px'}
              >
                <div
                  style={{
                    height: '180px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row justify="center">
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      ยืนยันการขอดู Password
                    </Col>
                    <Col
                      span={24}
                      style={{ fontSize: '18px', textAlign: 'center' }}
                    >
                      กรุณากรอก Password ของคุณ
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      <Space size="large" align="center">
                        Password :
                        <Form ref={reqPassFormRef}>
                          <Form.Item
                            name="req_pass"
                            style={{ marginBottom: 0 }}
                          >
                            <Input.Password
                              style={{ width: '300px' }}
                              onChange={(e) => this.getPassword(e.target.value)}
                            />
                          </Form.Item>
                        </Form>
                      </Space>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          style={{ width: '170px' }}
                          onClick={() =>
                            this.setState({ viewPasswordModal: false })
                          }
                        >
                          ยกเลิก
                        </Button>
                        <Button
                          type="primary"
                          style={{ width: '170px' }}
                          onClick={() => this.readingPassword()}
                        >
                          ยืนยัน
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </div>
          )}
        </Viewlayout>
      </Form>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(ftpserver, state),
  dataList: getResource(ftpserver, state),
})

const mapDispatchToProps = (dispatch) => ({
  readData: (id) => dispatch(readDataMasterServerFTP(ftpserver, id)),
  deleteData: (id) => dispatch(deleteListMasterServerFTP(ftpserver, id)),
})

const PartherGroupWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(view)

export default PartherGroupWithConnect
