import { schema, normalize } from 'normalizr'

// export const instructions = new schema.Entity('instructions')
// export const list = new schema.Array(instructions)

export const responseOfSchema = (response, key) => {
  const dataSchema = new schema.Entity(key)

  const list = new schema.Array(dataSchema)

  return normalize(response, list)
}
