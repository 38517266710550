import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  message,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { PlusOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { deleteList, getList, getMasterList } from "actions/collection";
import { getCollectionLoading, getPagination, getStateResult } from "selectors";

import Actions from "components/action";
import Blank from "images/default-profile.png";
import DataTableLayout from "components/layouts/DataTableLayout";
import { MenuAuth } from "contexts/menuAuth";
import { connect } from "react-redux";
import iconDeleted from "images/correct@3x.png";
import iconErr from "images/icon-error@3x.png";
import moment from "moment";
import styled from "styled-components";
import { users } from "schemas";

const { Option } = Select;

const UserManagement = (props) => {
  const history = useHistory();
  const [dataList, setDataList] = useState();
  const [dataSearch, setDataSearch] = useState(localStorage.getItem("search"));
  const [dataFilter, setDataFilter] = useState(
    localStorage.getItem("filter") || undefined
  );

  const [openDeleteSuccess, setOpenDeleteSuccess] = useState(false);
  const [openDeleteErr, setOpenDeleteErr] = useState({
    open: false,
    errorMsg: "",
  });
  const [option, setOption] = useState();
  const [userGroup, setUserGroup] = useState([]);
  const permissionAuthor = useContext(MenuAuth);
  const [actionPermission, setActionPermission] = useState(undefined);

  const pagination = {
    total: option?.total,
    per_page: option?.per_page,
    current_page: option?.current_page,
    sort_field: option?.sorts.key,
    sort_by: option?.sorts.value,
    filters: option?.sorts.value,
  };

  const columns = [
    {
      title: "#",
      dataIndex: "order",
      key: "order",
      width: "6%",
    },
    {
      title: "ชื่อ - นามสกุล",
      dataIndex: "fullname",
      key: "fullname",
      ellipsis: true,
      width: "30%",
      render: (text, record) => {
        return (
          <>
            {!record.image_profile ? (
              <Avatar
                size={24}
                style={{ marginRight: "10px" }}
                icon={<UserOutlined />}
                onError={imageError}
              />
            ) : (
              <Avatar
                size={24}
                style={{ marginRight: "10px" }}
                src={`${record.image_profile}?w=48`}
                icon={<UserOutlined />}
              />
            )}
            {record.fullname}
          </>
        );
      },
    },
    {
      title: "กลุ่มผู้ใช้งาน",
      dataIndex: "user_group_data",
      key: "user_group_data",
      // ellipsis: true,
      width: "20%",
      render: (user_group_data) => `${user_group_data.name || "-"}`,
    },
    {
      title: "สิทธิ์เข้าใช้งาน",
      dataIndex: "role_data",
      key: "role_data",
      // ellipsis: true,
      width: "15%",
      render: (role_data) => `${role_data.name || "-"}`,
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "created_at",
      key: "created_at",
      // ellipsis: true,
      width: "15%",
      render: (date) => moment(date).local("th").format("LL"),
    },
    {
      title: "สถานะ",
      key: "status",
      dataIndex: "status",
      width: "10%",
      render: (status) => {
        return (
          <div style={{ color: status === "active" ? "#00c48c" : "#ff0000" }}>
            {status === "active" ? "ใช้งาน" : "ไม่ใช้งาน"}
          </div>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: "7%",
      render: (record) => {
        return (
          <Actions
            record={record}
            path="/users"
            onDelete={onDeleteRoles}
            history={history}
            permission={actionPermission}
          />
        );
      },
    },
  ];

  useEffect(() => {
    const author = permissionAuthor?.menuAuth?.find(
      (menu) => menu?.key_slug === "users-management"
    );
    const menu = author?.sub_menus?.find((sub) => sub?.key_slug === "users");

    setActionPermission(menu?.role_permission);
  }, [permissionAuthor]);

  const imageError = (ev) => {
    // console.log(`ev`, ev)
    ev.target.src = Blank;
  };

  const getUserGroup = () => {
    props
      .getMasData("all/user-groups", process.env.REACT_APP_AUTHEN)
      .then((res) => {
        if (res?.code === 200) {
          setUserGroup(res.data);
        }
      });
  };

  const getList = (page, perPage, group = "") => {
    let userGroup = dataFilter
      ? { filters: `user_group:eq:${group ? group : dataFilter}` }
      : "";
    let nameSearch = !dataSearch
      ? ""
      : {
        search: [`first_name:${dataSearch}`, `last_name:${dataSearch}`],
      };
    setDataList(undefined);

    let params = {
      page,
      per_page: perPage,
    };

    params = userGroup ? Object.assign(params, userGroup) : params;
    params = dataSearch ? Object.assign(params, nameSearch) : params;
    props.getData(params).then((resopnse) => {
      if (resopnse?.code === 200) {
        const list = resopnse?.data ? resopnse?.data.lists : [];
        const dataOption = resopnse?.data ? resopnse?.data.option : null;
        setOption(dataOption);
        const newList =
          list &&
          list.map((item, index) => {
            const order =
              dataOption.per_page * (dataOption.current_page - 1) + (index + 1);
            const fullname = item.first_name + " " + item.last_name;
            return { ...item, order: order, fullname: fullname };
          });
        setDataList(newList);
      } else {
        setDataList([]);
      }
    });
  };

  const onDeleteRoles = (rowId) => {
    message.loading("Save in progress..", 0);
    props.deleteData(rowId).then((resopnse) => {
      if (resopnse?.code === 200) {
        message.destroy();
        if (resopnse?.code === 200) {
          setOpenDeleteSuccess(true);
        } else {
          setOpenDeleteErr({
            open: true,
            errorMsg: resopnse.data.errors[0].cause,
          });
        }
      }
    });
  };

  const onSearch = () => {
    localStorage.setItem("search", dataSearch);
    getList(1, localStorage.getItem("per_page"));
  };

  const onInputChange = (e) => {
    let value = e.target.value.trimStart();
    setDataSearch(value);
  };

  const onSelectChange = (e) => {
    setDataFilter(e);
    localStorage.setItem("filter", e);
  };

  const onCloseDelete = () => {
    getList(localStorage.getItem("page"), localStorage.getItem("per_page"));
    setOpenDeleteSuccess(false);
  };

  useEffect(() => {
    if (!userGroup.length) {
      getUserGroup();
    }
    getList(localStorage.getItem("page"), localStorage.getItem("per_page"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter]);

  return (
    <>
      <Form>
        <Row justify="space-between">
          <Col>
            <Input
              id="input"
              style={{ marginBottom: "30px", width: "294px" }}
              onChange={onInputChange}
              placeholder="ค้นหาชื่อ - นามสกุล"
              onPressEnter={onSearch}
              value={dataSearch}
              suffix={
                <SearchOutlined
                  onClick={() => onSearch("click")}
                  style={{ cursor: "pointer" }}
                />
              }
              autoComplete="on"
            />
            <Select
              virtual={false}
              showSearch
              placeholder="กลุ่มผู้ใช้งาน"
              style={{ width: "200px", marginLeft: "10px" }}
              onChange={(e) => onSelectChange(e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={dataFilter}
            >
              <Option value="">ทั้งหมด</Option>
              {userGroup.length &&
                userGroup.map((item, index) => {
                  return (
                    <Option value={item.key_slug} key={item.key_slug}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
          {actionPermission && actionPermission?.can_create && (
            <Col>
              <Button type="primary">
                <Link to="/users/create">
                  <PlusOutlined /> เพิ่มข้อมูล
                </Link>
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <Skeleton className="App-form" loading={!dataList} active>
              <DataTableLayout
                dataSource={dataList}
                columns={columns}
                isloading={!dataList}
                pagePagination={pagination}
                getData={(param) => getList(param.page, param.per_page)}
              />
            </Skeleton>
          </Col>
        </Row>
      </Form>

      <Modal
        visible={openDeleteSuccess}
        footer={false}
        closable={false}
        centered={true}
        width={"500px"}
      >
        <BoxModel height="180px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="Icon Delete" src={iconDeleted} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>ลบข้อมูลเสร็จสิ้น</HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button
                    type="primary"
                    style={{ width: "170px" }}
                    onClick={onCloseDelete}
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal
        visible={openDeleteErr.open}
        footer={false}
        closable={false}
        centered={true}
        width={"500px"}
      >
        <BoxModel height="220px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="Icon Error" src={iconErr} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>ลบข้อมูลไม่สำเร็จ</HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="15px">{openDeleteErr.errorMsg}</TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button
                    type="primary"
                    style={{ width: "170px" }}
                    onClick={() =>
                      setOpenDeleteErr({ open: false, errorMsg: "" })
                    }
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
    </>
  );
};

const BoxModel = styled.div`
  height: ${(props) => props.height};
  justify-content: center;
  align-items: center;
  display: flex;
`;
const HeaderModel = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
`;
const TextModel = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: ${(props) => props.marginTop};
`;

const mapStateToProps = (state) => ({
  pagination: getPagination(users, state),
  isLoading: getCollectionLoading(users, state),
  dataList: getStateResult(users, state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: (params) =>
    dispatch(getList(users, params, process.env.REACT_APP_AUTHEN)),
  deleteData: (id, params) =>
    dispatch(deleteList(users, id, params, process.env.REACT_APP_AUTHEN)),
  getMasData: (schema, url) => dispatch(getMasterList(schema, url)),
});

const UserManagementWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagement);

export default UserManagementWithConnect;
