import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import ReactCountryFlag from "react-country-flag"
import moment from 'moment'
import { Row, Col, Skeleton, DatePicker, Space, message, BackTop } from 'antd'
import { ArrowUpOutlined } from '@ant-design/icons'
import LineChart from 'components/chart/line'
import PieChart from 'components/chart/pie'
import LineBasicChart from 'components/chart/lineBasic'
import Styled from 'styled-components'
import DataTableLayout from "components/layouts/DataTableLayout";
import DefaultImg from 'images/default-thumbnail.jpg'

import { dashboard } from 'schemas'
import { getResource, getResourceLoading } from 'selectors'
import { getData } from 'actions/collection'

import { NumberFormat } from 'utils/common'

const { RangePicker } = DatePicker

class Dashboard extends Component {
  state = {
    dataChartLine: '',
    dataPageviews: '',
    dataChartPie: '',
    dataChartCard: '',
    dateStart: localStorage.getItem('db_date_start')
      ? localStorage.getItem('db_date_start')
      : moment().subtract(7, 'days'),
    dateEnd: localStorage.getItem('db_date_end')
      ? localStorage.getItem('db_date_end')
      : moment().subtract(1, 'days'),
    loading: false,
    monthLength: 1,
    dataLocation: '',
    option: '',
    pagination: {
      total: 0,
      per_page: 15,
      current_page: 1,
    },
  }

  columns = [
    {
      title: "#",
      dataIndex: "order",
      key: "order",
      width: "6%",
    },
    {
      title: 'ประเทศ',
      dataIndex: 'country',
      key: 'country',
      width: "54%",
      render: (_, record) => {
        return (
          <>
            {record.country !== '(not set)' ?
              <ReactCountryFlag
                countryCode={`${record.country_code}`}
                style={{
                  marginRight: '10px',
                  fontSize: '18px',
                  lineHeight: '14px',
                  border: '1px solid rgb(215, 210, 203)',
                  height: '0px !important'
                }}
                svg
                aria-label={`${record.country}`}
              />
              :
              <img
                style={{
                  width: '20px',
                  height: '16px',
                  marginRight: '10px',
                  lineHeight: '14px',
                }}
                src={DefaultImg} alt="Default flag" />
            }
            <span style={{ color: 'rgb(0, 143, 251)' }}>{record.country}</span>
          </>
        )
      }
    },
    {
      title: 'ผู้ใช้',
      dataIndex: 'total_users',
      key: 'total_users',
      width: "10%",
      align: 'right',
      render: (total_users) => total_users.toLocaleString('en')
    },
    {
      title: '% ผู้ใช้',
      dataIndex: 'percent_users',
      key: 'percent_users',
      width: "20%",
      render: (percent_users) => {
        return (
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: `${percent_users}%`, paddingTop: '14px', backgroundColor: 'rgb(0, 143, 251)', marginRight: '10px' }} />
            {percent_users.toFixed(2)}%
          </div>
        )
      }
    }
  ]

  style = {
    height: 40,
    width: 40,
    lineHeight: '45px',
    borderRadius: 50,
    backgroundColor: '#E0E0E0',
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
    boxShadow: '0 3px 6px -4px #0000001f', '0 6px 16px #00000014': '0 9px 28px 8px #0000000d',
    transition: 'color .3s'
  }

  componentDidMount() {
    this.getData()
    this.getDataLocations(localStorage.getItem("page"), localStorage.getItem("per_page"))
  }

  getDataLocations = (page, perPage) => {
    this.setState({ dataLocation: undefined })
    let params = {
      page: page,
      per_page: perPage,
      date_start: moment(this.state.dateStart).format('YYYY-MM-DD'),
      date_end: moment(this.state.dateEnd).format('YYYY-MM-DD'),
      filter: 'date', //date month year
    }
    this.props.getData(`${dashboard}/location-users`, params).then((response) => {
      this.setState({ loading: false })
      if (response?.code === 200) {
        const list = response?.data ? response?.data?.locations : [];
        const dataOption = response?.data ? response?.data.option : null;
        this.setState({
          pagination: {
            total: dataOption?.total,
            per_page: dataOption?.per_page,
            current_page: dataOption?.current_page,
          }
        })
        const newList =
          list &&
          list.map((item, index) => {
            const order =
              dataOption.per_page * (dataOption.current_page - 1) + (index + 1);
            return { ...item, order: order };
          })
        this.setState({ dataLocation: newList })
      } else {
        this.setState({ dataLocation: [] })
      }
    })
  }

  getData = () => {
    let params = {
      date_start: moment(this.state.dateStart).format('YYYY-MM-DD'),
      date_end: moment(this.state.dateEnd).format('YYYY-MM-DD'),
      filter: 'date', //date month year
    }
    // console.log('params :>> ', params)
    this.props.getData(dashboard, params).then((response) => this.setStateData(response))
  }

  setStateData = (response) => {
    const dataAllCard = []
    this.setState({ loading: false })
    if (response?.code === 200) {

      // eslint-disable-next-line array-callback-return
      response.data.graph.map((item) => {
        const dataCard = []
        if (
          item.name === 'Users' ||
          item.name === 'ผู้ใช้' ||
          item.key_slug === 'user'
        ) {
          const dataChart = []
          // eslint-disable-next-line array-callback-return
          item.charts.map((item) => {
            const year = item.date.substr(0, 4)
            const month = item.date.substr(4, 2)
            const day = item.date.substr(6, 2)
            let dataDate = [
              new Date(`${month}/${day}/${year}`).getTime(),
              item.value.toFixed(0),
            ]
            dataChart.push(dataDate)
          })
          this.setState({ dataChartLine: dataChart })
        }

        if (
          item.name === 'pageviews' ||
          item.name === 'จำนวนหน้าที่มีการเปิด' ||
          item.key_slug === 'pageviews'
        ) {
          const dataPageviews = []
          // eslint-disable-next-line array-callback-return
          item.charts.map((item) => {
            const year = item.date.substr(0, 4)
            const month = item.date.substr(4, 2)
            const day = item.date.substr(6, 2)
            let dataDate = [
              new Date(`${month}/${day}/${year}`).getTime(),
              item.value.toFixed(0),
            ]
            dataPageviews.push(dataDate)
          })
          this.setState({ dataPageviews: dataPageviews })
        }

        // eslint-disable-next-line array-callback-return
        item.charts.map((val, index) => {
          let cardData = [index, val.value]
          dataCard.push(cardData)
        })

        let newData = {
          name: item.name,
          total: item.total,
          chart: dataCard,
        }
        dataAllCard.push(newData)
      })
      this.setState({ dataChartCard: dataAllCard })
      const series = []
      const labels = []
      // eslint-disable-next-line array-callback-return
      response.data.pie.map((item) => {
        series.push(item.Percent)
        labels.push(`${item.name} (${item.Value})`)
      })

      const dataPie = {
        series: series,
        labels: labels,
      }

      this.setState({ dataChartPie: dataPie })
    }
  }

  onChange = (dates) => {
    const month_start = moment(dates[0]).format('M')
    const month_end = moment(dates[1]).format('M')

    if (month_end - month_start <= 2 && month_end - month_start >= 0) {
      const monthLength = month_end - month_start
      localStorage.setItem('db_date_start', moment(dates[0]))
      localStorage.setItem('db_date_end', moment(dates[1]))
      this.setState({
        monthLength, dateStart: moment(dates[0]),
        dateEnd: moment(dates[1])
      },
        () => {
          this.getData()
          this.getDataLocations(localStorage.getItem("page"), localStorage.getItem("per_page"))
        }
      )
    } else {
      message.warning('สามารถเลือกดูได้ตามช่วงเวลาสูงสุดไม่เกิน 2 เดือน')
    }
  }

  disabledDate = (current) => {
    return current > moment().subtract(1, 'days').endOf('day')
  }

  render() {

    return (
      <div>
        <div style={{ width: '50%', height: '50%', backgroundColor: 'blue' }} />
        <h2>Overview </h2>
        <Row gutter={[24, 16]}>
          <Col span={24} className="flex justify-end">
            <Space direction="vertical" size={12}>
              <RangePicker
                showToday={false}
                allowClear={false}
                defaultValue={[
                  moment(this.state.dateStart),
                  moment(this.state.dateEnd),
                ]}
                ranges={{
                  // Today: [moment(), moment().subtract(1, 'days')],
                  'This Month': [
                    moment().startOf('month'),
                    moment().subtract(1, 'days'),
                  ],
                }}
                onChange={this.onChange}
                disabledDate={this.disabledDate}
              />
            </Space>
          </Col>
        </Row>
        <Skeleton loading={this.props.isLoading} active>
          <Row gutter={[24, 16]}>
            <Col span={24}>
              {this.state.dataChartLine && (
                <LineChart
                  data={this.state.dataChartLine}
                  dataPageviews={this.state.dataPageviews}
                  dateStart={this.state.dateStart}
                  dateEnd={this.state.dateEnd}
                  monthLength={this.state.monthLength}
                ></LineChart>
              )}
            </Col>
            <Col span={12} style={{ margin: '20px 0 0 27px' }}>
              <Row gutter={[16, 16]}>
                {this.state.dataChartCard &&
                  this.state.dataChartCard.map((item, index) => {
                    return (
                      <Col span={8} key={index}>
                        <CardNum>
                          <span style={{ fontSize: '16px' }}>
                            <p className="text-ellipsis-line-1">{item.name}</p>
                          </span>
                          <span style={{ fontSize: '18px' }}>
                            {NumberFormat(item.total)}
                          </span>
                          <span style={{ marginTop: '-25px' }}>
                            <LineBasicChart data={item.chart}></LineBasicChart>
                          </span>
                        </CardNum>
                      </Col>
                    )
                  })}
              </Row>
            </Col>
            <Col span={12} className="flex justify-end" style={{ marginLeft: '-27px' }}>
              {this.state.dataChartPie && (
                <PieChart data={this.state.dataChartPie}></PieChart>
              )}
            </Col>
          </Row>
          <Row gutter={[24, 16]}>
            <Col span={24}>
              <Skeleton className="App-form" loading={!this.state.dataLocation} active>
                <DataTableLayout
                  dataSource={this.state.dataLocation}
                  columns={this.columns}
                  isloading={!this.state.dataLocation}
                  pagePagination={this.state.pagination}
                  getData={(param) => this.getDataLocations(param.page, param.per_page)}
                />
              </Skeleton>
            </Col>
          </Row>
        </Skeleton>
        <BackTop className='sil-back-top'>
          <div style={this.style}><ArrowUpOutlined style={{ fontSize: '21px' }} /></div>
        </BackTop>
      </div>
    )
  }
}

const CardNum = Styled.div`
  display: flex;
  flex-flow: column;
  border-right: 1px solid;
  height: 120px;
`

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(dashboard, state),
  response: getResource(dashboard, state),
})

const mapDispatchToProps = (dispatch) => ({
  getData: (schema, params) => dispatch(getData(schema, params)),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboard)
