// import axios from 'axios'
import { httpClient } from 'HttpClient'

export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'

export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOADING_FILE = 'UPLOADING_FILE'
export const RESET_UPLOADING_FILE = 'RESET_UPLOADING_FILE'

export const logout = (schema) => ({
  type: USER_LOGGED_OUT,
  schema,
})

export const adminLogout = (schema, id) => {
  return (dispatch) => {
    dispatch(logout(schema))
    return httpClient
      .post(`${process.env.REACT_APP_AUTHEN}${schema}`)
      .then((response) => {
        return response?.data ? response?.data : response
      })
      .catch((error) => {
        return error
      })
  }
}

export const resetUploadingFile = () => ({
  type: RESET_UPLOADING_FILE,
})

export const uploadFile = (payload) => ({
  type: UPLOAD_FILE,
  payload,
})

export const uploadingFile = () => ({
  type: UPLOADING_FILE,
})
