import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Upload,
  message
} from 'antd'
import React, { createRef, useEffect, useState } from 'react'
import { activate, roles, users } from 'schemas'
import { createData, getList, getMasterList, readData, updateData } from 'actions/collection'

import Blank from 'images/default-profile.png'
import Footeractionlayout from 'components/layouts/Footeractionlayout'
import { connect } from "react-redux";
import { getResourceLoading } from 'selectors'
import iconErr from 'images/icon-error@3x.png'
import iconSucc from 'images/correct@3x.png'
import iconUpload from 'images/user-upload.png'
import iconWarn from 'images/exclamation.png'
import moment from 'moment'
import { resizeUpload } from 'actions/file'
import styled from 'styled-components'
import { useHistory } from 'react-router'

const { Option } = Select
const formRef = createRef()

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const mapErrMsg = (err, text) => {
  let msg = []
  // eslint-disable-next-line array-callback-return
  err.map((item) => {
    switch (item.code) {
      case 'unique':
        if (item.subCode === 'Email') { msg.push(`${text} already exists !!`) }
        break
      case 'CONDITION':
        if (item.subCode === 'ACTIVATE_EXPIRED_AT') { msg.push(`Activation links that have been sent can still be used  !!`) }
        break
      default:
        msg.push('พบข้อผิดพลาดในการทำงาน กรุณาลองใหม่อีกครั้ง')
        break
    }
  })
  return msg
}

const UserManagementForm = (props) => {
  const { isLoading } = props

  const history = useHistory()

  const [fileList, setFileList] = useState([])
  // const [isRemove, setIsRemove] = useState(false)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  // const [previewTitle, setPreviewTitle] = useState('')
  const [userGroup, setUserGroup] = useState([])
  const [isSelected, setIsSelected] = useState(false)
  const [role, setRole] = useState([])
  const [idRole, setIdRole] = useState()
  const [isCancel, setIsCancel] = useState(false)
  const [openModal, setOpenModal] = useState({ openErr: false, openSuccess: false })
  const [msgErr, setMsgErr] = useState([])
  const [isVerified, setIsVerified] = useState()
  const [dataUser, setDataUser] = useState({})
  const [isClick, setIsClick] = useState(false)

  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [messageErrorEmail, setMessageErrorEmail] = useState('')
  const [reqTel, setReqTel] = useState({ status: 'success' })
  const [reqImg, setReqImg] = useState({ status: 'success' })
  const [openModalLink, setOpenModalLink] = useState(false)

  const [activateExp, setActivateExp] = useState({ status: false, date: '', time: '' })

  useEffect(() => {
    getUserGroup()
    if (props.match.params.id) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = () => {
    props.readData(props.match.params.id, process.env.REACT_APP_AUTHEN).then((res) => {
      if (res?.code === 200) {
        let activateTime = moment.unix(res?.data?.activate_expired_at).format('LT')
        let date = new window.Date(moment.unix(res?.data?.activate_expired_at))
        const options = { year: 'numeric', month: 'long', day: '2-digit' }

        let checkExp = onCheckExpire(res?.data?.activate_expired_at)
        setActivateExp({
          status: checkExp,
          date: date.toLocaleDateString('th', options),
          time: activateTime,
        })

        let numberMobile
        if (res?.data?.image_profile !== '') {
          if (/http/.test(res?.data?.image_profile) === true) {
            fileList.push(setImageToState(res?.data?.image_profile))
          } else {
            fileList.push(setImageToState(Blank))
          }
        }
        if (res?.data?.mobile !== '' && res?.data?.mobile.length === 10) {
          numberMobile = numberWithSpaces(`${res?.data?.mobile}`, '### ### ####')
        } else {
          numberMobile = res?.data?.mobile
        }

        let tempData = {
          first_name: res?.data?.first_name,
          last_name: res?.data?.last_name,
          mobile: numberMobile,
          email: res?.data?.email,
          user_group: res?.data?.user_group,
          role: res?.data?.role,
          status: res?.data?.status,
          image_profile: res?.data?.image_profile
        }
        setDataUser(tempData)
        setIsVerified(res?.data?.verified)
        getAllRole(res?.data?.user_group, res?.data?.role)
        setIdRole(res?.data?.role)
      } else {
        setDataUser({})
        setIsVerified('')
        setIdRole('')
        setActivateExp({})
      }
    })
  }

  const onCheckExpire = (value) => {
    const dateFormat = "YYYY-MM-DD HH:mm:ss"
    let pastDate = moment.unix(value)
    let todayDate = moment()
    let dDiff = pastDate.diff(todayDate, dateFormat)

    if (dDiff >= 0) {
      //not yet expired
      return true
    } else {
      //expire
      return false
    }
  }

  const getAllRole = (data, id) => {
    const params = {
      filters: `user_groups:eq:${data}`
    }
    props.getData(params).then((response) => {
      if (response?.code === 200) {
        setRole(response?.data)

        let checkId = response?.data.find((value) => {
          return value.id === id
        })
        if (checkId === undefined || null) {
          setIdRole(undefined)
        } else {
          setIdRole(checkId.id)
        }
      } else {
        setRole([])
      }
    })
  }

  const setImageToState = (value) => {
    return {
      uid: -1,
      name: 'Profileimage.png',
      status: 'done',
      url: `${value}?w=360`,
    }
  }

  const clickCancle = () => setIsCancel(true)

  const handleCancel = () => {
    setPreviewVisible(false)
    setIsCancel(false)
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
    // setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }

  const uploadAll = async (name, e) => {
    if (name === 'upload') {
      await beforeUpload(e)
    } else {
      await onChangeImg(e)
    }
  }

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setReqImg({
        status: 'error',
        text: <div style={{ marginTop: '-6px' }}>
          The image is too big size.
        </div>
      })
    } else {
      setReqImg({ status: 'success' })
    }
    return isLt2M
  }

  const onChangeImg = ({ fileList }) => {
    if (reqImg.status === 'success') {
      setLoading(true)
      setFileList(reqImg.status === 'success' ? fileList : []);
      setTimeout(() => { setLoading(false) }, 2000);
    }
  };

  const getUserGroup = () => {
    props.getMasData('all/user-groups', process.env.REACT_APP_AUTHEN).then((res) => {
      if (res?.code === 200) {
        setUserGroup(res?.data)
      } else {
        setUserGroup([])
      }
    })
  }

  const handleSubmit = (value) => {
    if (!loading && reqTel.status === 'success') {
      setReqImg({ status: 'success' })
      setIsClick(true)
      message.loading('Save in progress..', 0)
      const data = JSON.stringify({
        "first_name": value.first_name.trim(),
        "last_name": value.last_name.trim(),
        "email": value.email.trim(),
        "mobile": value.mobile ? value.mobile.trim().split(' ').join('') : value.mobile,
        "user_group": value.user_group,
        "role": value.role,
        "status": value.status,
        "verified": value.verified,
        "image_profile": fileList.length
          ? value.cover_url.file.originFileObj.url
          : '',
      })

      props.createData(data).then((response) => {
        setMessageErrorEmail('')
        message.destroy()
        if (response?.code === 201) {
          if (response?.data) {
            setOpenModal({ ...openModal, openSuccess: true, openConfirm: false })
          }
        } else {
          const arrError = mapErrMsg(response?.errors, 'Email')
          setMsgErr(arrError)
          setOpenModal({ ...openModal, openErr: true })
        }
      })
    }
  }

  const handleUpdate = async (value) => {
    if (reqTel.status === 'success') {
      await setLoading(true)
      setIsClick(true)
      const data = JSON.stringify({
        "first_name": value.first_name.trim(),
        "last_name": value.last_name.trim(),
        "email": value.email.trim(),
        "mobile": value.mobile ? value.mobile.trim().split(' ').join('') : value.mobile,
        "user_group": value.user_group,
        "role": value.role,
        "status": value.status,
        "image_profile": fileList.length ?
          value.cover_url.file
            ? value.cover_url.file.originFileObj.url
            : value.cover_url[0].url.split('?w=360').join('')
          : '',
      })

      props
        .updateData(data, props.match.params.id)
        .then((response) => {
          message.destroy()
          if (response?.code === 200) {
            setOpenModal({ ...openModal, openSuccess: true, openConfirm: false })
          } else {
            message.destroy()
            const arrError = mapErrMsg(response?.errors, 'Email')
            setMsgErr(arrError)
            setOpenModal({ ...openModal, openErr: true })
          }
        })
      await setLoading(false)
    }
  }

  const blockSpecialCharecter = (rule, value) => {
    if (value) {
      if (rule.field) {
        const newValue = value.replace(/[^a-zA-Z0-9-ก-๙ ]/g, "")
        formRef.current.setFieldsValue({ [rule.field]: newValue, })
      }
    } else {
      if (rule.field === 'first_name') {
        return Promise.reject('Firstname is required !!')
      } else {
        return Promise.reject('Lastname is required !!')
      }
    }

    if (!value
      || (rule.field && !value.replace(/[^a-zA-Z0-9ก-๙ ]/g, ""))) {
      return Promise.reject(`${rule.message}`)
    } else {
      return Promise.resolve()
    }
  }

  const formatToName = (e) => {
    const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (e.type === 'paste') {
      const regexPas = new RegExp("^[a-zA-Z0-9ก-๙]")
      const arrString = e.clipboardData.getData('text/plain').split('')
      const isvalid = arrString.every((item) => regexPas.test(item) === false)
      if (isvalid) {
        e.preventDefault();
        return false;
      }
    } else {
      const regex = new RegExp("^[a-zA-Z0-9ก-๙ ]")
      if (!regex.test(key)) {
        e.preventDefault();
        return false;
      }
    }
  }

  const numberWithSpaces = (value, pattern) => {
    let i = 0, phone
    phone = value.toString()
    return pattern.replace(/#/g, _ => phone[i++])
  }

  const onSelected = (e) => {
    setIsSelected(true)
    formRef.current.setFieldsValue({ role: undefined })
    getAllRole(e)
  }

  const onRemoveImg = (value) => {
    // setIsRemove(true)
    setFileList([])
  }

  const uploadButton = (
    <img src={iconUpload} width="28px" height="26px" alt="Icon Upload" />
  )

  const onFinishFailed = (errorInfo) => {
    if (errorInfo?.errorFields.length) {
      if (errorInfo?.values.email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const checkMail = re.test(String(errorInfo?.values.email).toLowerCase());
        if (!checkMail) {
          setMessageErrorEmail('Email format is incorrect !!')
        }
      } else {
        setMessageErrorEmail('Email is required !!')
      }

      setMessageError(``)
    }
  };

  const mobileFormat = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    const isnum = /^\d+$/.test(value);

    if (isnum) {
      const frist = value.substring(0, 3)
      const middle = value.substring(3, 6)
      const last = value.substring(6, 10)
      if (value.length > 6) { formRef.current.setFieldsValue({ mobile: `${frist} ${middle} ${last}` }) }
      else if (value.length > 3) { formRef.current.setFieldsValue({ mobile: `${frist} ${middle}` }) }
      else if (value.length > 0) { formRef.current.setFieldsValue({ mobile: `${frist}` }) }
    } else {
      formRef.current.setFieldsValue({ mobile: '' })
    }

    if (value && value.replace(/ +/g, "").length !== 10) { setReqTel({ status: 'error', text: 'Phone number is invalid  !!' }) }
    else { setReqTel({ status: 'success' }) }
  }

  const onFieldsChange = (e, key) => {
    if (key === 'email') {
      setMessageErrorEmail('')
    }

    setMessageError(``)
  }

  const onActivate = () => {
    message.loading('Resend Link in progress..', 0)
    const data = JSON.stringify({
      "user_id": props.match.params.id
    })

    props.activateResend(data).then((response) => {
      message.destroy()
      if (response?.code === 200) {
        getData()
        message.success(response?.status)
      } else {
        const arrError = mapErrMsg(response?.errors)
        setMsgErr(arrError)
        setOpenModalLink(true)
      }
    })
  }

  const labelVerified = (
    <>
      {isVerified === true ?
        'สถานะการยืนยันตัวตน'
        :
        <>
          สถานะการยืนยันตัวตน
          <Divider className="verified-divider" type="vertical" />
          <ResendLink onClick={() => activateExp.status === true ? setOpenModalLink(true) : onActivate()}>Resend Link</ResendLink>
        </>
      }
    </>
  )

  return (
    <>
      {isLoading === true ? (
        <Skeleton className="App-form" loading={isLoading} active></Skeleton>
      ) : (
        <Form
          ref={formRef}
          layout="vertical"
          className="App-form"
          onFinish={
            props.match.params.id ? handleUpdate : handleSubmit
          }
          onFinishFailed={onFinishFailed}
        >
          <Form.Item style={{ marginTop: '-20px' }}>
            <h1 className="topic">
              {props.match.params.id
                ? 'แก้ไขข้อมูลผู้ใช้งาน'
                : 'เพิ่มข้อมูลผู้ใช้งาน'}
            </h1>
          </Form.Item>

          <Row gutter={[20, 15]}>
            <Col span={12}>
              <Form.Item
                className="form-slide-menu-size"
                label={
                  <Row>
                    <Col>
                      <TitleImage>
                        รูปภาพโปรไฟล์
                      </TitleImage>
                    </Col>
                  </Row>
                }
              >
                <TextImage>
                  (ขนาดภาพแนะนำ 180x180 px | ขนาดไม่เกิน 2 mb)
                </TextImage>
              </Form.Item>
              <Form.Item
                style={{ marginTop: '-50px', height: '100px' }}
                name="cover_url"
                validateStatus={reqImg.status}
                help={reqImg.text}
                initialValue={props.match.params.id ? fileList : ''}
              >
                <Upload
                  beforeUpload={(e) => uploadAll('upload', e)}
                  className="user-management-upload"
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onRemove={onRemoveImg}
                  onChange={(e) => uploadAll('change', e)}
                  type="picture"
                  accept="image/png,image/jpeg"
                  customRequest={props.addImageData}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[20, 15]}>
            <Col span={12}>
              <Form.Item
                style={{ marginTop: '-25px' }}
                name="first_name"
                label="ชื่อ"
                rules={[
                  {
                    required: true,
                    validator: blockSpecialCharecter
                  },
                ]}
                initialValue={props.match.params.id && dataUser.first_name ? dataUser.first_name : ''}
              >
                <Input
                  autoComplete="off"
                  maxLength="255"
                  onKeyPress={(e) => formatToName(e)}
                  onPaste={(e) => formatToName(e)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ marginTop: '-25px' }}
                name="last_name"
                label="นามสกุล"
                rules={[
                  {
                    required: true,
                    validator: blockSpecialCharecter
                  },
                ]}
                initialValue={props.match.params.id && dataUser.last_name ? dataUser.last_name : ''}
              >
                <Input
                  autoComplete="off"
                  maxLength="255"
                  onKeyPress={(e) => formatToName(e)}
                  onPaste={(e) => formatToName(e)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[20, 15]}>
            <Col span={12}>
              <Form.Item
                className={messageErrorEmail && 'item-required'}
                style={{ marginTop: '-15px' }}
                name="email"
                label="อีเมล"
                validateStatus={messageError || messageErrorEmail ? 'error' : ''}
                help={messageErrorEmail}
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                    type: 'email'
                  },
                ]}
                initialValue={props.match.params.id && dataUser.email ? dataUser.email : ''}
              >
                <Input
                  autoComplete="off"
                  name="email"
                  disabled={props.match.params.id && isVerified === true ? true : false}
                  onChange={(e) => onFieldsChange(e, 'email')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ marginTop: '-15px' }}
                onChange={mobileFormat}
                name="mobile"
                label="หมายเลขโทรศัพท์"
                validateStatus={reqTel.status}
                help={reqTel.text}
                initialValue={props.match.params.id && dataUser.mobile ? dataUser.mobile : ''}
              >
                <Input maxLength={12} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[20, 15]}>
            <Col span={12}>
              <Form.Item
                style={{ marginTop: '-40px' }}
                name="user_group"
                label="กลุ่มผู้ใช้งาน"
                rules={[
                  {
                    required: true,
                    message: 'User group is required !!',
                  },
                ]}
                initialValue={props.match.params.id && dataUser.user_group}
              >
                <Select
                  placeholder="เลือก"
                  onChange={onSelected}
                  virtual={false}
                >
                  {userGroup && userGroup.map((item, index) => {
                    return (<Option key={index} value={item.key_slug}>{item.name}</Option>)
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ marginTop: '-40px' }}
                name="role"
                label="สิทธิ์เข้าใช้งาน"
                rules={[
                  {
                    required: true,
                    message: 'Role is required !!',
                  },
                ]}
                initialValue={props.match.params.id ? idRole : undefined}
              >
                <Select
                  virtual={false}
                  placeholder="เลือก"
                  disabled={
                    !props.match.params.id && isSelected === false ?
                      props.match.params.id && isSelected === false ?
                        false
                        : true
                      : false
                  }
                >
                  {role && role.map((item, index) => {
                    return (<Option key={index} value={item.id}>{item.name}</Option>)
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[20, 15]}>
            <Col span={12}>
              <Form.Item
                style={{ marginTop: '-5px' }}
                label="สถานะการใช้งาน"
                name="status"
                rules={[
                  {
                    required: true,
                    message: 'User status is required !!',
                  },
                ]}
                initialValue={props.match.params.id ? dataUser.status : 'active'}
              >
                <Radio.Group>
                  <Radio value={'active'}>ใช้งาน</Radio>
                  <Radio value={'inactive'}>ไม่ใช้งาน</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={props.match.params.id ? labelVerified : 'สถานะการยืนยันตัวตน'}
                name="verified"
                rules={[
                  {
                    required: props.match.params.id ? false : true,
                    message: 'User verified is required !!',
                  },
                ]}
                initialValue={'false'}
              >
                {props.match.params.id ?
                  <div style={{ color: isVerified === true ? '#00C428' : '#ff0000', }}>
                    {isVerified === true ? 'Verified' : 'Unverified'}
                  </div>
                  :
                  <Radio.Group disabled={true}>
                    <Radio value={'false'}>Unverified</Radio>
                    <Radio value={'true'}>Verified</Radio>
                  </Radio.Group>
                }
              </Form.Item>
            </Col>
          </Row>

          <Form.Item shouldUpdate={true}>
            <Footeractionlayout
              onCancle={clickCancle}
              isFill={isClick === false ? false : true}
            ></Footeractionlayout>
          </Form.Item>

          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={handleCancel}
            width={800}
          >
            <img
              alt="Preview profile"
              style={{ width: '100%', marginTop: '20px' }}
              src={previewImage}
            />
          </Modal>

          <Modal
            visible={openModal.openSuccess}
            footer={false}
            closable={false}
            centered={true}
            width={'528px'}
          >
            <BoxModel height="309px">
              <Row>
                <Col span={24} style={{ textAlign: 'center', marginTop: '14px' }}>
                  <img alt="Icon Success" src={iconSucc} width={58} height={58} />
                </Col>
                <Col span={24}>
                  <HeaderModel>
                    {props.match.params.id ?
                      'แก้ไขข้อมูลสำเร็จ'
                      :
                      'บันทึกข้อมูลสำเร็จแล้ว'
                    }
                  </HeaderModel>
                </Col>
                <Col span={24}>
                  <TextModel marginTop="11px">
                    {props.match.params.id ?
                      'กด ‘ตกลง’ เพื่อออกจากหน้านี' :
                      <>
                        ระบบได้ส่ง activation link ไปทางอีเมลของผู้ใช้งานเรียบร้อยแล้ว<br />
                        กด ‘ตกลง’ เพื่อออกจากหน้านี้
                      </>
                    }
                  </TextModel>
                </Col>
                <Col span={24}>
                  <TextModel marginTop="52px">
                    <Space>
                      <Button
                        onClick={() => { setIsClick(false); setOpenModal({ ...openModal, openErr: false }); history.goBack() }}
                        type="primary"
                        style={{ width: '168px' }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </TextModel>
                </Col>
              </Row>
            </BoxModel>
          </Modal>

          <Modal
            visible={openModal.openErr}
            footer={false}
            closable={false}
            centered={true}
            width={'528px'}
          >
            <BoxModel height="309px">
              <Row>
                <Col span={24} style={{ textAlign: 'center', marginTop: '14px' }}>
                  <img alt="Icon Error" src={iconErr} width={58} height={58} />
                </Col>
                <Col span={24}>
                  <HeaderModel>
                    {props.match.params.id ?
                      'แก้ไขข้อมูลไม่สำเร็จ'
                      :
                      'บันทึกข้อมูลไม่สำเร็จ'
                    }
                  </HeaderModel>
                </Col>
                <Col span={24}>
                  <TextModel marginTop="11px">
                    {msgErr.length !== 0 ? msgErr.map((item, index) => {
                      return <div key={index}>{item}</div>
                    }) :
                      <div>กรุณากรอกข้อมูลใหม่อีกครั้ง</div>
                    }
                  </TextModel>
                </Col>
                <Col span={24}>
                  <TextModel marginTop="52px">
                    <Space>
                      <Button
                        onClick={() => { setIsClick(false); setMsgErr([]); setOpenModal({ ...openModal, openErr: false }) }}
                        type="primary"
                        style={{ width: '168px' }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </TextModel>
                </Col>
              </Row>
            </BoxModel>
          </Modal>

          <Modal
            visible={isCancel}
            footer={false}
            closable={false}
            centered={true}
            width={'420px'}
          >
            <div
              style={{
                height: '180px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Row>
                <Col
                  span={24}
                  style={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  ยืนยันการยกเลิก
                </Col>
                <Col
                  span={24}
                  style={{ fontSize: '18px', textAlign: 'center' }}
                >
                  หากคุณต้องการยกเลิก
                </Col>
                <Col
                  span={24}
                  style={{ fontSize: '18px', textAlign: 'center' }}
                >
                  กรุณากด ยืนยัน
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: '18px',
                    textAlign: 'center',
                    marginTop: '30px',
                  }}
                >
                  <Space>
                    <Button
                      style={{ width: '170px' }}
                      onClick={() => handleCancel()}
                    >
                      ยกเลิก
                    </Button>
                    <Button
                      type="primary"
                      style={{ width: '170px' }}
                      onClick={() => {
                        props.history.goBack()
                      }}
                    >
                      ยืนยัน
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Modal>

          <Modal
            visible={openModalLink}
            footer={false}
            closable={false}
            centered={true}
            width={'528px'}
          >
            <BoxModel height="309px">
              <Row>
                <Col span={24} style={{ textAlign: 'center', marginTop: '14px' }}>
                  <img alt="Icon Success" src={iconWarn} width={60} height={60} />
                </Col>
                <Col span={24}>
                  <HeaderModel>
                    ไม่สามารถส่งลิงก์ได้
                  </HeaderModel>
                </Col>
                <Col span={24}>
                  <TextModel marginTop="11px">
                    {msgErr.length !== 0 ? msgErr.map((item, index) => {
                      return <div key={index}>{item}</div>
                    }) :
                      <div>เนื่องจากลิงก์เดิมยังสามารถใช้งานได้ <br />
                        ถึงวันที่ {activateExp.date} เวลา {activateExp.time} น.</div>
                    }
                  </TextModel>
                </Col>
                <Col span={24}>
                  <TextModel marginTop="52px">
                    <Space>
                      <Button
                        onClick={() => { setIsClick(false); setOpenModalLink(false) }}
                        type="primary"
                        style={{ width: '168px' }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </TextModel>
                </Col>
              </Row>
            </BoxModel>
          </Modal>
        </Form>
      )}
    </>
  )
}

const TitleImage = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`

const TextImage = styled.p`
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 21px;
  color: #2F323A;
  margin-top: -40px;
`

const BoxModel = styled.div`
  height: ${(props) => (props.height)};
  justify-content: center;
  align-items: center;
  display: flex;
`

const TextModel = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: ${(props) => (props.marginTop)};
`

const HeaderModel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 52px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
`

const ResendLink = styled.div`
  text-decoration-line: underline;
  color: #C4C4C4;
  cursor: pointer;
`

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(users, state),
})

const mapDispatchToProps = (dispatch) => ({
  createData: (data) => dispatch(createData(users, data, process.env.REACT_APP_AUTHEN)),
  readData: (id) => dispatch(readData(users, id, process.env.REACT_APP_AUTHEN)),
  updateData: (data, id) => dispatch(updateData(users, data, id, process.env.REACT_APP_AUTHEN)),
  getMasData: (schema, url) => dispatch(getMasterList(schema, url)),
  addImageData: (params) => dispatch(resizeUpload(params)),
  getData: (params) => dispatch(getList(`all/${roles}`, params, process.env.REACT_APP_AUTHOR)),
  activateResend: (data) => dispatch(createData(activate, data, process.env.REACT_APP_AUTHEN)),
})

const UserManagementFormWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementForm)


export default UserManagementFormWithConnect
