import { Button } from "antd";
import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";

const Page404 = () => {
  const history = useHistory();
  return (
    <Container>
      <Box width="300px" top="-70px">
        <TextBg>404</TextBg>
      </Box>
      <Box width="500px" top="-120px">
        <TextContent fontSize="30px" fontWeight="500">
          OOPS!
        </TextContent>
        <TextContent fontSize="26px" fontWeight="400">
          That page doesn’t exist or is unavailable.
        </TextContent>
        <BoxButton>
          <Button
            type="primary"
            style={{ width: "200px", height: "46px", fontSize: "18px" }}
            onClick={() => history.push("/")}
          >
            Go Home
          </Button>
        </BoxButton>
      </Box>
    </Container>
  );
};

export default Page404;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
`;

const Box = styled.div`
  text-align: center;
  margin-top: ${(props) => props.top};
`;

const TextBg = styled.div`
  font-size: 200px;
  font-weight: 500;
  color: #e2e7ee;
`;
const TextContent = styled.div`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  display: flex;
  justify-content: center;
  color: #2f323a;
`;
const BoxButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
`;
