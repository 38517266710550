import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Radio,
  Checkbox,
  message
} from 'antd'
import {
  CloudOutlined,
  DatabaseOutlined,
  InsertRowBelowOutlined,
  ToolOutlined,
  UserOutlined,
  DashboardOutlined
} from '@ant-design/icons'

import { createData, readData, updateData, getMasterList } from 'actions/collection'
import { connect } from "react-redux";
import { getResourceLoading } from 'selectors'
import { roles } from 'schemas'

import { useHistory } from 'react-router';
import styled from 'styled-components'
import iconErr from 'images/icon-error@3x.png'
import iconCorrect from 'images/correct@3x.png'
import '../../../App.less'
import { mapErrMsg } from '../../../utils/common'
const { Option } = Select
const { TextArea } = Input;

let validate = true

const RoleAndPermissionForm = (props) => {
  const history = useHistory();
  const formRef = useRef();
  const pathName = history.location.pathname.split("/")[2];
  const roleId = history.location.pathname.substring(history.location.pathname.lastIndexOf("/") + 1)
  const [openModal, setOpenModal] = useState({ openCancel: false, openErr: false, openConfirm: false, openSuccess: false })
  const [permission, setPermission] = useState();
  const [userGroup, setUserGroup] = useState();
  const [showReq, setShowReq] = useState(false)
  const [msgErr, setMsgErr] = useState([])
  const [isDepa, setIsDepa] = useState(false)
  const textReq = <><p>สิทธิ์ในการใช้งานระบบ</p><Req>&nbsp;*</Req></>

  const icon = {
    'dashboard': <DashboardOutlined />,
    'cms': <InsertRowBelowOutlined />,
    'opt': <ToolOutlined />,
    'user-management': <UserOutlined />,
    'master-data': <DatabaseOutlined />,
    "ftp-management": <CloudOutlined />
  }

  // ** connect API ** //
  const getUserGroup = () => {
    props.getMasData('all/user-groups', process.env.REACT_APP_AUTHEN).then((res) => {
      if (res.code === 200) {
        setUserGroup(res.data)
      }
    })
  }

  const getAllMenu = (params) => {
    const userGroup = params ? params : ''
    props.getMasData(`all/menus?user_group=${userGroup}`, process.env.REACT_APP_AUTHOR).then((res) => {
      if (res.code === 200) {
        setPermission(res.data)
      }
    })
  }

  const getRoles = () => {
    props.readData(roleId).then((res) => {
      if (res.code === 200) {
        const newPermission = res.data.roles_menus.map((item) => {
          return { ...item, isReqMain: checkValidate(item.sub_menus) }
        })
        validate = newPermission.every(e => e.isReqMain === true || e.isReqMain === undefined)
        setPermission(newPermission)
        formRef.current.setFieldsValue(res.data);
      }
    })
  }

  const createRoles = (value, permission) => {
    const data = JSON.stringify({
      "name": value.name.trim(),
      "user_groups": value.user_groups,
      "description": value.description,
      "permissions": permission,
      "status": value.status
    });

    props.createData(data).then((res) => {
      message.destroy()
      if (res?.code === 201) {
        if (res?.data) {
          setOpenModal({ ...openModal, openSuccess: true, openConfirm: false })
        }
      } else {
        const arrError = mapErrMsg(res?.errors, 'สิทธิ์เข้าใช้งาน')
        setMsgErr(arrError)
        setOpenModal({ ...openModal, openErr: true })
      }
    })
  }

  const updateRoles = (value, permission) => {
    const data = JSON.stringify({
      "name": value.name.trim(),
      "user_groups": value.user_groups,
      "description": value.description,
      "permissions": permission,
      "status": value.status
    });

    props
      .updateData(data, roleId)
      .then((response) => {
        message.destroy()
        if (response?.code === 200) {
          setOpenModal({ ...openModal, openSuccess: true, openConfirm: false })
        } else {
          message.destroy()
          const arrError = mapErrMsg(response?.errors, 'สิทธิ์เข้าใช้งาน', 'edit')
          setMsgErr(arrError)
          setOpenModal({ ...openModal, openErr: true })
        }
      })
  }
  // ** connect API ** //

  const onFinishFailed = (errorInfo) => {
    setShowReq(validate)
  };

  const onFinish = (value) => {
    setShowReq(validate)
    const filterPermiss = permission.map((item) => {
      const newSub = item.sub_menus.filter((sub) => {
        return sub.roles_permission && (sub.roles_permission.can_read === true || sub.roles_permission.can_create === true || sub.roles_permission.can_update === true || sub.roles_permission.can_delete === true)
      })
      return newSub
    })

    let arrPermiss = [];
    for (let i = 0; i < filterPermiss.length; i++) {
      arrPermiss = arrPermiss.concat(filterPermiss[i]);
    }

    const newPermission = arrPermiss.map((item) => {
      const can_read = item.roles_permission.can_read ? item.roles_permission.can_read : false
      const can_create = item.roles_permission.can_create ? item.roles_permission.can_create : false
      const can_update = item.roles_permission.can_update ? item.roles_permission.can_update : false
      const can_delete = item.roles_permission.can_delete ? item.roles_permission.can_delete : false
      return { key_sub_menu: item.key_slug, permission: { can_read, can_create, can_update, can_delete } }
    })

    if (validate === false) {
      message.loading('Save in progress..', 0)
      pathName === 'create' ? createRoles(value, newPermission) : updateRoles(value, newPermission)
    }
  };

  const onChangePermission = (e, role, mainMenu) => {
    const newPermission = renderCheckbox(e, role, mainMenu)
    validate = newPermission.every(e => e.isReqMain === true || e.isReqMain === undefined)
    setShowReq(validate)
    setPermission(newPermission)
  }

  const checkValidate = (newSub) => {
    const reqSub = newSub.map((item) => {
      if (item.roles_permission) {
        const req = Object.values(item.roles_permission).every(e => e === false)
        return { ...item, isReqSub: req }
      } else {
        return { ...item, isReqSub: true }
      }
    })
    const reqMain = reqSub.every(e => e.isReqSub === true)
    return reqMain
  }

  const renderCheckbox = (e, role, mainMenu) => {

    const newPermission = permission.map((main) => {
      if (main.name === mainMenu) {
        const newSub = main.sub_menus.map((sub) => {
          if (sub.name === e.target.name) {
            if (sub.name === 'Contacts & Feedbacks' || sub.name === 'Approve CCTV' || (sub.name === 'CCTV' && isDepa)) {
              if (e.target.checked === true) {
                if (role === 'update') {
                  return { ...sub, roles_permission: { ...sub.roles_permission, can_create: false, can_read: true, can_update: true } }
                } else {
                  return { ...sub, roles_permission: { ...sub.roles_permission, can_read: true } }
                }
              } else {
                if (role === 'update') {
                  return { ...sub, roles_permission: { ...sub.roles_permission, can_update: false } }
                } else {
                  return { ...sub, roles_permission: { ...sub.roles_permission, can_read: false, can_update: false } }
                }
              }
            } else {
              if (e.target.checked === true) {
                if (role === 'delete') {
                  return { ...sub, roles_permission: { ...sub.roles_permission, can_create: true, can_read: true, can_update: true, can_delete: true } }
                } else if (role === 'update') {
                  return { ...sub, roles_permission: { ...sub.roles_permission, can_create: true, can_read: true, can_update: true } }
                } else if (role === 'create') {
                  return { ...sub, roles_permission: { ...sub.roles_permission, can_create: true, can_read: true } }
                } else {
                  return { ...sub, roles_permission: { ...sub.roles_permission, can_read: true } }
                }
              } else {
                if (role === 'delete') {
                  return { ...sub, roles_permission: { ...sub.roles_permission, can_delete: false } }
                } else if (role === 'update') {
                  return { ...sub, roles_permission: { ...sub.roles_permission, can_update: false, can_delete: false } }
                } else if (role === 'create') {
                  return { ...sub, roles_permission: { ...sub.roles_permission, can_create: false, can_update: false, can_delete: false } }
                } else {
                  return { ...sub, roles_permission: { ...sub.roles_permission, can_create: false, can_read: false, can_update: false, can_delete: false } }
                }
              }
            }
          } else {
            return { ...sub }
          }
        })
        return { ...main, sub_menus: newSub, isReqMain: checkValidate(newSub) }
      }
      else {
        return { ...main }
      }
    })
    return newPermission
  }

  useEffect(() => {
    getUserGroup();
    if (pathName === 'edit') {
      getRoles()
    } else {
      getAllMenu()
      validate = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeUserGroup = (e) => {
    if (e.includes('depa')) {
      setIsDepa(true)
      getAllMenu(e)
    } else {
      setIsDepa(false)
      getAllMenu()
    }
  }

  const blockSpecialCharecter = (rule, value) => {
    if (value) {
      const newValue = value.replace(/[^a-zA-Zก-๙ ]/g, "");
      formRef.current.setFieldsValue({ [rule.field]: newValue });
    }
    if (!value || !value.replace(/[^a-zA-Zก-๙ ]/g, "")) {
      return Promise.reject(`${rule.message}`);
    } else {
      return Promise.resolve();
    }
  };

  return (
    <div>
      <Skeleton className="App-form" loading={permission === undefined} active>
        <Form layout="vertical" className="App-form" onFinish={onFinish} ref={formRef} onFinishFailed={onFinishFailed}>
          <Form.Item>
            <Topic>
              <h1 className="topic">
                {pathName === 'create' ? 'เพิ่มข้อมูลสิทธิ์การเข้าใช้งาน' : 'แก้ไขข้อมูลสิทธิ์การเข้าใช้งาน'}
              </h1>
            </Topic>
          </Form.Item>

          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="ชื่อสิทธิ์เข้าใช้งาน"
                rules={[{
                  required: true,
                  message: 'Role name is required !!',
                  validator: blockSpecialCharecter,
                }]}>
                <Input autoComplete="off" maxLength="255" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[20, 20]}>
            <Col span={12}>
              <TextLabel>
                <Form.Item
                  name="user_groups"
                  label="กลุ่มผู้ใช้งาน"
                  rules={[{ required: true, message: 'Group is required !!' }]}>
                  <Select onChange={onChangeUserGroup} placeholder="เลือก" disabled={roleId === 'create' ? false : true}>
                    {userGroup && userGroup.map((item, index) => {
                      return (<Option key={index} value={item.key_slug}>{item.name}</Option>)
                    })}
                  </Select>
                </Form.Item>
              </TextLabel>
            </Col>
          </Row>

          <Row gutter={[20, 5]}>
            <Col span={12}>
              <TextLabel>
                <Form.Item
                  name="description"
                  label="รายละเอียด">
                  <TextArea autoComplete="off" autoSize={{ minRows: 8, maxRows: 8 }} />
                </Form.Item>
              </TextLabel>
            </Col>
          </Row>

          <Row gutter={[20, 5]}>
            <Col span={12}>
              <Form.Item
                name="status"
                label="สถานะสิทธิ์เข้าใช้งาน"
                initialValue={'active'}
                rules={[{ required: true, message: 'Status is required !!' }]}>
                <Radio.Group  >
                  <Radio value='active'>ใช้งาน</Radio>
                  <Radio value='inactive'>ไม่ใช้งาน</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[20, 5]}>
            <Col span={24}>
              <Form.Item label={textReq}>

                {showReq === true &&
                  <Col style={{ marginTop: '-22px' }}>
                    <p style={{ color: '#ff4d4f', marginBottom: '0px' }}>Permission is required !!</p>
                  </Col>}

                <TableHeader>
                  <Row justify="space-between" >
                    <Col span={12}>เมนูหลัก / เมนูย่อย</Col>
                    <Col span={12} style={{ textAlign: 'center' }}>การอนุญาตให้เข้าถึง</Col>
                  </Row>
                </TableHeader>
                {
                  permission && permission.map((item, index) => {
                    return (
                      <TableBody key={index}>
                        <Row justify="space-between" >
                          <Col span={12}>
                            <TextHeader1>
                              <Icon>
                                {icon[item.icon]}
                              </Icon>
                              {item.name}
                            </TextHeader1>
                          </Col>
                          <Col span={12}>
                            <TextHeader2>
                              <Roles>ดูข้อมูล</Roles>
                              <Roles>เพิ่มข้อมูล</Roles>
                              <Roles>แก้ไขข้อมูล</Roles>
                              <Roles>ลบข้อมูล</Roles>
                            </TextHeader2>
                          </Col>
                          {item.sub_menus.map((sub, indexSub) => {
                            const lastItem = item.sub_menus[item.sub_menus.length - 1].key_slug
                            return (
                              <React.Fragment key={indexSub}>
                                <Col span={12}>
                                  <TextSubMenu borderBottom={sub.key_slug !== lastItem && '1px solid 	#E8E8E8'}>{sub.name}</TextSubMenu>
                                </Col>
                                <Col span={12}>
                                  <BoxPermission className="permiss-checkbox" borderBottom={sub.key_slug !== lastItem && '1px solid 	#E8E8E8'}>
                                    <Checkbox
                                      name={sub.name}
                                      onChange={(e) => onChangePermission(e, 'read', item.name)}
                                      disabled={sub.can_set_permission.can_read === false}
                                      checked={sub.roles_permission ? sub.roles_permission.can_read : false}
                                    />
                                    <Checkbox
                                      name={sub.name}
                                      onChange={(e) => onChangePermission(e, 'create', item.name)}
                                      disabled={sub.can_set_permission.can_create === false}
                                      checked={sub.roles_permission ? sub.roles_permission.can_create : false}
                                    />
                                    <Checkbox
                                      name={sub.name}
                                      onChange={(e) => onChangePermission(e, 'update', item.name)}
                                      disabled={sub.can_set_permission.can_update === false}
                                      checked={sub.roles_permission ? sub.roles_permission.can_update : false}
                                    />
                                    <Checkbox
                                      name={sub.name}
                                      onChange={(e) => onChangePermission(e, 'delete', item.name)}
                                      disabled={sub.can_set_permission.can_delete === false}
                                      checked={sub.roles_permission ? sub.roles_permission.can_delete : false}
                                    />
                                  </BoxPermission>
                                </Col>
                              </React.Fragment>
                            )
                          })}
                        </Row>
                      </TableBody>
                    )
                  })
                }
              </Form.Item>
            </Col>
          </Row>

          <Form.Item shouldUpdate={true} >
            <Space>
              <Button style={{ width: '12vmax' }} htmlType="button" onClick={() => setOpenModal({ ...openModal, openCancel: true })}>
                ยกเลิก
              </Button>
              <Button style={{ width: '12vmax' }} type='primary' htmlType='submit'>
                บันทึก
              </Button>
            </Space >
          </Form.Item>
        </Form>
      </Skeleton>


      <Modal visible={openModal.openCancel} footer={false} closable={false} centered={true} width={'420px'}>
        <BoxModel height="180px">
          <Row>
            <Col span={24}>
              <HeaderCancel>
                ยืนยันการยกเลิก
              </HeaderCancel>
            </Col>
            <Col span={24}>
              <TextModel >
                หากคุณต้องการยกเลิก
              </TextModel>
            </Col>
            <Col span={24}>
              <TextModel >
                กรุณากด ยืนยัน
              </TextModel>
            </Col>
            <Col span={24}>
              <BoxButton>
                <Space>
                  <Button style={{ width: '170px' }} onClick={() => setOpenModal({ ...openModal, openCancel: false })}>
                    ยกเลิก
                  </Button>
                  <Button type="primary" htmlType="submit" style={{ width: '170px' }} onClick={() => history.goBack()}>
                    ยืนยัน
                  </Button>
                </Space>
              </BoxButton>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal
        visible={openModal.openErr}
        footer={false}
        closable={false}
        centered={true}
        width={'500px'}
      >
        <BoxModel minHeight="220px">
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img alt="example" src={iconErr} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>
                {`${pathName === 'create' ? 'เพิ่ม' : 'แก้ไข'}ข้อมูลไม่สำเร็จ`}
              </HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="15px">
                {msgErr.map((item, index) => {
                  return <p key={index}>{item}</p>
                })}
              </TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button onClick={() => setOpenModal({ ...openModal, openErr: false })} type="primary" style={{ width: '170px' }} >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal
        visible={openModal.openSuccess}
        footer={false}
        closable={false}
        centered={true}
        width={'420px'}
      >
        <BoxModel height="220px">
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img
                alt="example"
                src={iconCorrect}
                width={48}
                height={48}
              />
            </Col>
            <Col span={24}>
              <HeaderModel>
                {`${pathName === 'create' ? 'เพิ่ม' : 'แก้ไข'}ข้อมูลสำเร็จ`}
              </HeaderModel>
            </Col>
            <Col span={24} >
              <TextModel>
                กดตกลงเพื่อออกจากหน้านี้
              </TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="30px">
                <Space>
                  <Button onClick={() => { setOpenModal({ ...openModal, openSuccess: false }); history.goBack() }}
                    type="primary" style={{ width: '170px' }}>
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

    </div >
  )
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(roles, state),
})

const mapDispatchToProps = (dispatch) => ({
  createData: (data) => dispatch(createData(roles, data, process.env.REACT_APP_AUTHOR)),
  readData: (id) => dispatch(readData(roles, id, process.env.REACT_APP_AUTHOR)),
  updateData: (data, id) => dispatch(updateData(roles, data, id, process.env.REACT_APP_AUTHOR)),
  getMasData: (schema, url) => dispatch(getMasterList(schema, url)),
})

const RoleFormWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleAndPermissionForm)

export default RoleFormWithConnect

const Topic = styled.div`
 margin-top: -20px;
`
const TableHeader = styled.div`
border-bottom: 1px solid #DCD6D6;
 background-color: #FAFAFA;
 padding: 18px 50px;
`
const TableBody = styled.div`
border: 1px solid #DCD6D6;
border-top: 0px;
padding: 16px;
padding-bottom: 5px;
`
const TextLabel = styled.div`
margin-top: -15px;
`

const TextHeader1 = styled.div`
color: black;
 padding-right: 8px;
  display: flex;
`
const TextHeader2 = styled.div`
display: flex;
 justify-content: space-around;
`
const TextSubMenu = styled.div`
color: black;
font-weight: 200;
margin-left: 40px;
padding-left: 10px;
display: flex;
height: 30px;
align-items: center;
border-bottom: ${(props) => (props.borderBottom)};
`
const BoxPermission = styled.div`
display: flex ;
justify-content: space-around;
height: 30px;
align-items: center;
border-bottom: ${(props) => (props.borderBottom)};
`
const Icon = styled.div`
padding-right: 8px;
`
const HeaderCancel = styled.div`
font-size: 24px;
font-weight: bold;
text-align: center;
`
const BoxButton = styled.div`
 font-size: 18px;
text-align: center;
margin-top: 30px;
`

const BoxModel = styled.div`
min-height: ${(props) => (props.minHeight)};
height: ${(props) => (props.height)};
justify-content: center;
align-items: center;
display: flex;
`
const HeaderModel = styled.div`
 font-size: 24px;
font-weight: bold;
text-align: center;
 margin-top: 15px;
`
const TextModel = styled.div`
font-size: 18px;
text-align: center ;
margin-top: ${(props) => (props.marginTop)};
`
const Req = styled.p`
color: #ff4d4f;
margin-right: 4px;
font-family: SimSun, sans-serif;
padding: 0px 0px 4px;
`

const Roles = styled.div`
width: 62px;
display: flex;
justify-content: center;
`
