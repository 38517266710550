import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Tag,
  TimePicker,
  Tooltip,
  Upload,
  message,
} from 'antd'
import {
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UploadOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import { createData, readData, updateData } from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'

import DataTableLayout from 'components/layouts/DataTableLayout'
import Footeractionlayout from 'components/layouts/Footeractionlayout'
import Googlemap from 'components/layouts/Maps/googleMap'
import React from 'react'
import { cctv, revise } from 'schemas'
import { connect } from 'react-redux'
import { httpClient } from 'HttpClient'
import iconCorrect from 'images/correct@3x.png'
import iconErr from 'images/icon-error@3x.png'
import moment from 'moment'
import { resizeUpload } from 'actions/file'

const { Option } = Select
const { CheckableTag } = Tag

const text_keywords = (
  <div>
    <div>
      - ใช้เครื่องหมายคอมม่า (,) คั่นระหว่างคีย์เวิร์ดแต่ละคำ โดยไม่ต้องเว้นวรรค
    </div>
    <div>- ใส่คีย์เวิร์ดที่เกี่ยวข้องประมาณ 10 คีย์เวิร์ด</div>
  </div>
)

const text = <span>ระบุเฉพาะ 0-9, a-z และ - เท่านั้น</span>

const textValidName = (
  <span>ระบุเฉพาะ a-z, A-Z, 0-9, -, _ และ เว้นวรรค เท่านั้น</span>
)

function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str
  return str.substring(0, index) + chr + str.substring(index + 1)
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const { TextArea } = Input
const formRef = React.createRef()
const formLatLngRef = React.createRef()

class Formlayout extends React.Component {
  state = {
    //tag variable
    tagsData: [],
    tourismTypesData: [],
    selectedTags: [],
    selectedTourismTypes: [],
    tagsNametoSlug: [],
    TourismTypesNametoSlug: [],
    tagsList: [],
    tagsNameTemp: [],
    tourismTypeNamesTemp: [],
    tourismTypesList: [],

    serverID: [],
    serverIDOption: [],

    errorMsg: '',
    isCreated: false,
    isCreateError: false,
    isCancel: false,
    isDirty: true,
    search: '',
    searchList: [],
    //photo upload variable
    fileList: [],
    fileListVideo: [],
    fileList_mor: [],
    fileList_aft: [],
    fileList_eve: [],
    isRemove: false,
    //CCTV
    cctvList: [],
    cctvChecked: '',
    cctvSelectd: [],
    cameraCode: '',
    cameraName: '',
    cctvType: 'cctv',
    cctvTypeTemp: '',

    //groups
    OptionType: [],
    groupsType: [],
    OptionUserGroup: [],
    userGroupType: [],
    isGroupChange: false,
    isNationalPark: false,

    timelapseType: [],
    timelapseOption: [],

    //Map
    previewVisibleMap: false,
    lat: '',
    lng: '',
    lat_lng: '',
    addressth: '',
    options: '',

    // tempData: {},
    tempServerID: '',
    cameraCodeTemp: '',
    cctvCheckedTemp: '',
    cameraNameTemp: '',
    checkOwnLocations: false,
    setOwnLocations: false,
    ownLocationChange: false,

    //checkFieldfill
    isNameFill: true,
    isDetailFill: true,
    isAreaFill: true,

    //Button
    isPending: false,
    isStatus: '',
    isStatusApproval: 'draft',
    isRevised: false,
    approvalId: '',
  }
  columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (Index) => {
        const index = this.state.cctvList.find((id) => id.id === Index)
        return (
          <Checkbox
            checked={this.state.cctvChecked === Index ? true : false}
            onChange={() => this.onCheck(index.id)}
          ></Checkbox>
        )
      },
    },
    {
      title: 'ไอดี',
      dataIndex: 'id',
      key: 'id',
      width: '8%',
    },
    {
      title: 'ชื่อของกล้อง',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'notes',
      key: 'notes',
      width: '25%',
    },
    {
      title: 'CCTV ลิงค์',
      dataIndex: 'url',
      key: 'url',
      width: '37%',
    },
  ]

  onCheck = (value) => {
    this.setState({
      cctvChecked: value === this.state.cctvChecked ? '' : value,
      cctvSelectd:
        value === this.state.cctvChecked
          ? []
          : this.state.cctvList.find((data) => data.id === value),
    })
  }

  componentDidMount() {
    this.getServerID()
    // if (this.props.match.params.id) {
    //   this.props.readData(this.props.match.params.id)
    // }

    if (this.props.match.params.id) {
      this.props.readData(this.props.match.params.id).then((res) => {
        if (res?.data?.status_approval === 'reject' || res?.data?.status_approval === 'pending_publish') {
          this.props.history.push('/404')
        } else {
          this.getData()
        }
      })
    } else {
      this.getData()
    }
  }

  getData = () => {
    this.getCCTV()
    this.getGroupsList()
    this.getUserGroupList()
    this.getTimelapseList()
    this.getTourismTypes()
    this.getTags().then(() => {
      if (this.props.match.params.id) {
        this.props.readData(this.props.match.params.id).then((res) => {
          this.state.fileList.push(this.setImageToState(res.data.cover_url))
          if (res.data.content_type === 'timelapse') {
            this.state.fileListVideo.push(
              this.setVideoToState(res.data.camera_url)
            )
            this.state.fileList_mor.push(
              this.setImageToState(res.data.snapshot_images.mor_link)
            )
            this.state.fileList_aft.push(
              this.setImageToState(res.data.snapshot_images.aft_link)
            )
            this.state.fileList_eve.push(
              this.setImageToState(res.data.snapshot_images.eve_link)
            )
          }
          res.data.tourism_type_names.map((type) =>
            this.state.selectedTourismTypes.push(type.th)
          )
          res.data.tag_names.map((names) =>
            this.state.selectedTags.push(names.th)
          )
          this.setState({
            cameraName: res.data.camera_name,
            cameraCode: res.data.camera_code,
            cctvChecked: res.data.camera_code,
            isNationalPark: res.data.groups.length > 1 ? true : false,
          })

          res.data.tag_names.map((name) =>
            this.state.tagsNameTemp.push(name.th)
          )
          res.data.tourism_type_names.map((name) =>
            this.state.tourismTypeNamesTemp.push(name.th)
          )
          this.setState({
            // tempData,
            cctvTypeTemp: res.data.content_type
              ? res.data.content_type
              : 'cctv',
            cctvType: res.data.content_type ? res.data.content_type : 'cctv',
            cameraCodeTemp: res.data.camera_code,
            cctvCheckedTemp: res.data.camera_code,
            cameraNameTemp: res.data.camera_name,
            tempServerID: res.data.server_ftp.username,
            isStatus: res?.data?.status,
            isStatusApproval: res?.data?.status_approval,
            isRevised: res?.data?.revised_edition,
            approvalId: res?.data?.approval_id,
          })
        })
      }
    })
  }

  getTourismTypes = () => {
    return httpClient
      .get(`${process.env.REACT_APP_BASEURL}/master/tourism-types`)
      .then((response) => {
        this.setState({ tourismTypesList: response?.data?.data || [] })
        for (let i = 0; i < response?.data?.data.length; i++) {
          this.state.tourismTypesData.push(
            response?.data?.data ? response?.data?.data[i].name.th : []
          )
        }
      })
      .catch((error) => {
        throw error
      })
  }

  getTags = () => {
    return httpClient
      .get(`${process.env.REACT_APP_BASEURL}/master/tags`)
      .then((response) => {
        this.setState({ tagsList: response?.data?.data || [] })
        for (let i = 0; i < response?.data?.data.length; i++) {
          this.state.tagsData.push(
            response?.data?.data ? response?.data?.data[i].name.th : []
          )
        }
      })
      .catch((error) => {
        throw error
      })
  }

  getTimelapseList = () => {
    return httpClient
      .get(`${process.env.REACT_APP_BASEURL}/master/timelapse-configs`)
      .then((response) => {
        this.setState({ timelapseType: response?.data?.data || [] })
        for (let i = 0; i < this.state.timelapseType.length; i++) {
          this.state.timelapseOption.push(
            <Option
              key={i.toString(36) + 1}
              value={this.state.timelapseType[i].key_slug}
            >
              {this.state.timelapseType[i].name}
            </Option>
          )
        }

        if (!this.props.match.params.id) {
          formRef.current.setFieldsValue({
            timelapse: 'default',
          })
        }
      })
      .catch((error) => {
        throw error
      })
  }

  getGroupsList = () => {
    return httpClient
      .get(`${process.env.REACT_APP_BASEURL}/master/cctv-groups`)
      .then((response) => {
        this.setState({ groupsType: response?.data?.data || [] })

        for (let i = 0; i < this.state.groupsType.length; i++) {
          if (!this.state.groupsType[i].special_group) {
            this.state.OptionType.push(
              <Option
                key={i.toString(36) + 1}
                value={this.state.groupsType[i].key_slug}
              >
                {this.state.groupsType[i].name.th}
              </Option>
            )
          }
        }
        if (!this.props.match.params.id) {
          formRef.current.setFieldsValue({
            groups: undefined,
          })
        }
      })
      .catch((error) => {
        throw error
      })
  }

  getUserGroupList = () => {
    return httpClient
      .get(`${process.env.REACT_APP_AUTHEN}/all/user-groups`)
      .then((response) => {
        this.setState({ userGroupType: response?.data?.data || [] })
        for (let i = 0; i < this.state.userGroupType.length; i++) {
          this.state.OptionUserGroup.push(
            <Option
              key={i.toString(36) + 1}
              value={this.state.userGroupType[i].key_slug}
            >
              {this.state.userGroupType[i].name}
            </Option>
          )
        }
        if (!this.props.match.params.id) {
          formRef.current.setFieldsValue({
            user_group: undefined,
          })
        }
      })
      .catch((error) => {
        throw error
      })
  }

  getCCTV = () => {
    return httpClient
      .get(`/admin/monitor`)
      .then((response) => {
        this.setState({ cctvList: response?.data?.data })
      })
      .catch((error) => {
        message.error('Fail to load CCTV lists', 2.5)
      })
  }

  setKeyword = (e) => {
    const value = e.target.value
    this.setState({ search: value })
    this.onSearch(e.target.value)
  }

  onSearch = (e) => {
    this.setState({ searchList: [] })

    return this.setState({
      searchList:
        e.length && !this.state.cctvList
          ? []
          : this.state.cctvList.filter((filters) =>
            filters.name.toLowerCase().includes(e.toLowerCase())
          ),
    })
  }

  setImageToState(value) {
    return {
      uid: -1,
      name: 'Slideimage.png',
      status: 'done',
      url: value,
    }
  }

  setVideoToState(value) {
    return {
      uid: -1,
      name: 'TimeLapse.mp4',
      status: 'done',
      url: value,
    }
  }

  handleChange = ({ fileList }) => this.setState({ fileList })
  handleChangeMor = ({ fileList }) => this.setState({ fileList_mor: fileList })
  handleChangeAft = ({ fileList }) => this.setState({ fileList_aft: fileList })
  handleChangeEve = ({ fileList }) => this.setState({ fileList_eve: fileList })
  handleChangeVideo = ({ fileList }) =>
    this.setState({ fileListVideo: fileList })

  getMapLatLng = (lat, lng, addressth, OwnLocations = false) => {
    this.setState({
      lat: lat,
      lng: lng,
      addressth: addressth,
      setOwnLocations: OwnLocations,
      ownLocationChange: false,
    })
    formLatLngRef.current.setFieldsValue({
      ownLat: lat,
      ownLng: lng,
    })
  }

  handleMapCancel = () => {
    if (this.state.lat_lng.length > 0) {
      formLatLngRef.current.setFieldsValue(
        {
          ownLat: this.state.lat,
          ownLng: this.state.lng,
        },
        this.setState({ ownLocationChange: false })
      )
    } else {
      formLatLngRef.current.setFieldsValue(
        {
          ownLat: '',
          ownLng: '',
        },
        this.setState({ lat: '', lng: '' })
      )
    }

    this.state.lat && this.state.lng === ''
      ? this.setState({
        lat: '',
        lng: '',
        address: '',
        previewVisibleMap: false,
      })
      : this.setState({ previewVisibleMap: false })
  }

  handleMapOk = () => {
    if (this.state.lng && this.state.lat) {
      this.setState({
        lat_lng: [this.state.lng, this.state.lat],
        previewVisibleMap: false,
      })
    } else {
      message.warning('กรุณาเลือกตำแหน่งที่ตั้ง.', 2.5)
    }
    formRef.current.setFieldsValue({
      location: this.state.lat + ', ' + this.state.lng,
      addressth: this.state.addressth,
    })
  }

  handleMapPreview = async (file) => {
    this.setState({ previewVisibleMap: true })
    if (this.state.lat_lng) {
      await this.setState({
        lat: this.state.lat_lng[1],
        lng: this.state.lat_lng[0],
      })
      formLatLngRef.current.setFieldsValue({
        ownLat: this.state.lat,
        ownLng: this.state.lng,
        ownLocationChange: false,
      })
    }
  }

  handleCancel = () => this.setState({ previewVisible: false, isCancel: false })

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  onOwnChange = (value, type) => {
    if (
      formLatLngRef.current.getFieldsValue().ownLat === '' &&
      formLatLngRef.current.getFieldsValue().ownLng === ''
    ) {
      this.setState({ ownLocationChange: false })
    } else {
      if (type === 'lat') {
        if (parseFloat(value) !== this.state.lat) {
          this.setState({ ownLocationChange: true })
        } else {
          this.setState({ ownLocationChange: false })
        }
      } else {
        if (parseFloat(value) !== this.state.lng) {
          this.setState({ ownLocationChange: true })
        } else {
          this.setState({ ownLocationChange: false })
        }
      }
    }
  }

  handleCamCancel = () => {
    this.setState({
      search: '',
      searchList: [],
      cctvSelectd: this.state.cameraCode ? this.state.cctvSelectd : [],
      cctvChecked: this.state.cameraCode ? this.state.cctvChecked : '',
      previewCamLinkVisible: false,
    })
  }

  handleCamOk = () => {
    this.setState({
      previewCamLinkVisible: false,
      cameraCode: this.state.cctvSelectd.id,
      cameraName: this.state.cctvSelectd.name,
      search: '',
      searchList: [],
    })
    formRef.current.setFieldsValue({
      camera_url: this.state.cctvSelectd.url,
      camera_code: this.state.cctvSelectd.id,
    })
  }

  handleCamPreview = async (file) => {
    this.setState({
      cctvChecked: this.state.cameraCode,
      previewCamLinkVisible: true,
    })
  }

  checkValidLng = (rule, value) => {
    if (!/^([0-9.]{1,})(\s{1})?$/.test(value)) {
      if (value === '') {
        return Promise.reject('กรุณาระบุ ลองจิจูด !!')
      } else {
        return Promise.reject('กรุณากรอกตัวเลข 0-9 และ . เท่านั้น !!')
      }
    } else {
      return Promise.resolve()
    }
  }

  checkValidLat = (rule, value) => {
    if (!/^([0-9.]{1,})(\s{1})?$/.test(value)) {
      if (value === '') {
        return Promise.reject('กรุณาระบุ ละติจูด !!')
      } else {
        return Promise.reject('กรุณากรอกตัวเลข 0-9 และ . เท่านั้น !!')
      }
    } else {
      return Promise.resolve()
    }
  }

  onSelected = (e) => {
    this.setState({ cctvType: e })
    if (this.state.cctvTypeTemp === 'timelapse') {
      formRef.current.setFieldsValue({
        camera_url: '',
        snapshotMorning: undefined,
        snapshotAfternoon: undefined,
        snapshotEvening: undefined,
      })
    }
  }

  handleSubmit = (value) => {
    const { isDirty } = this.state
    const ftp_id = this.state.serverID.find(
      (id) => id.name === value.server_ftp_id.split(' | ')[0]
    )

    if (isDirty) {
      this.setState({ isDirty: false })
      const {
        selectedTags,
        TourismTypesNametoSlug,
        selectedTourismTypes,
        tagsNametoSlug,
        isNationalPark,
        timelapseType,
        cameraCode,
        cameraName,
      } = this.state

      const timelapsConfig = timelapseType.find(
        (key) => key.key_slug === value.timelapse
      )

      for (let i = 0; i < selectedTags.length; i++) {
        const index = this.state.tagsList.find(
          (slug) => slug.name.th === selectedTags[i]
        )
        tagsNametoSlug.push(index.key_slug)
      }

      for (let i = 0; i < selectedTourismTypes.length; i++) {
        const index = this.state.tourismTypesList.find(
          (slug) => slug.name.th === selectedTourismTypes[i]
        )
        TourismTypesNametoSlug.push(index.key_slug)
      }

      const requestFrom = {
        name: { th: value.nameth, en: value.nameen },
        key_slug: value.key,
        description: { th: value.descriptionth, en: value.descriptionen },
        address: { th: value.addressth, en: value.addressen },
        location: {
          type: 'Point',
          coordinates: this.state.lat_lng,
        },
        camera_code: cameraCode,
        camera_url:
          this.state.cctvType === 'cctv'
            ? value.camera_url
            : value.video_url.file.originFileObj.url,
        cover_url: value.cover_url.file.originFileObj.url,
        groups:
          isNationalPark === true
            ? [value.groups, 'national-park']
            : [value.groups],
        tags: tagsNametoSlug,
        content_type: value.cctv_type,
        tourism_types: TourismTypesNametoSlug,
        camera_name: cameraName,
        server_ftp_id: ftp_id.id,
        snapshot_images:
          this.state.cctvType === 'timelapse'
            ? {
              mor_link: value.image_mor.file.originFileObj.url,
              aft_link: value.image_aft.file.originFileObj.url,
              eve_link: value.image_eve.file.originFileObj.url,
            }
            : {},
        snapshot_config:
          this.state.cctvType === 'cctv'
            ? {
              mor: moment(value.snapshotMorning).format('HH:mm'),
              aft: moment(value.snapshotAfternoon).format('HH:mm'),
              eve: moment(value.snapshotEvening).format('HH:mm'),
            }
            : {},
        timelapse_config: timelapsConfig,
        area: { th: value.areath, en: value.areaen },
        views: value.views || 0,
        keywords: value.keywords,
        status: 'inactive',
        user_groups: value.user_group,
        status_approval: this.state.isPending === true ? 'pending_publish' : 'draft',
        revised_edition: false,
      }
      message.loading('Save in progress..', 0)
      this.props.createData(requestFrom).then((res) => {
        message.destroy()
        if (res?.code === 200) {
          this.setState({ isPending: false })
          this.setState({ isDirty: true, isCreated: true }, () => {
            localStorage.setItem('page', 1)
            localStorage.setItem('search', '')
          })
        } else {
          this.setState({
            isDirty: true,
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    }
  }

  checkValidEng = (rule, value) => {
    if (value === ' ') {
      formRef.current.setFieldsValue({
        nameen: '',
      })
    } else {
      formRef.current.setFieldsValue({
        nameen: value,
      })
    }
    if (value === null || value === '') {
      return Promise.reject('CCTV name is required !!')
    } else {
      if (!/^([A-Za-z0-9-_ ]{1,})?$/.test(value)) {
        return Promise.reject('Invalid data format !!')
      } else {
        return Promise.resolve()
      }
    }
  }

  handleTagChange(tag, checked) {
    const { selectedTags } = this.state
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag)
    this.setState({ selectedTags: nextSelectedTags })
  }

  handleTourismTypesChange(tag, checked) {
    const { selectedTourismTypes } = this.state
    const nextSelectedTags = checked
      ? [...selectedTourismTypes, tag]
      : selectedTourismTypes.filter((t) => t !== tag)
    this.setState({ selectedTourismTypes: nextSelectedTags })
  }

  handleUpdate = (value) => {
    const { isDirty } = this.state
    const ftp_id = this.state.serverID.find(
      (id) => id.name === value.server_ftp_id.split(' | ')[0]
    )
    if (isDirty) {
      this.setState({ isDirty: false })
      const {
        selectedTags,
        selectedTourismTypes,
        tagsNametoSlug,
        TourismTypesNametoSlug,
        cameraCode,
        timelapseType,
        cameraName,
      } = this.state

      const timelapsConfig = timelapseType.find(
        (key) => key.key_slug === value.timelapse
      )

      for (let i = 0; i < selectedTags.length; i++) {
        const index = this.state.tagsList.find(
          (slug) => slug.name.th === selectedTags[i]
        )
        tagsNametoSlug.push(index.key_slug)
      }

      for (let i = 0; i < selectedTourismTypes.length; i++) {
        const index = this.state.tourismTypesList.find(
          (slug) => slug.name.th === selectedTourismTypes[i]
        )
        TourismTypesNametoSlug.push(index.key_slug)
      }

      const requestFrom = {
        name: { th: value.nameth, en: value.nameen },
        key_slug: value.key,
        description: { th: value.descriptionth, en: value.descriptionen },
        address: { th: value.addressth, en: value.addressen },
        location: {
          type: 'Point',
          coordinates: this.state.lat_lng.length
            ? this.state.lat_lng
            : [
              this.props.dataList.location.coordinates[0],
              this.props.dataList.location.coordinates[1],
            ],
        },
        camera_code: cameraCode,
        camera_url:
          this.state.cctvType === 'cctv'
            ? value.camera_url
            : value.video_url.file
              ? value.video_url.file.originFileObj.url
              : value.video_url[0].url,
        cover_url: value.cover_url.file
          ? value.cover_url.file.originFileObj.url
          : value.cover_url[0].url,
        groups:
          this.state.isGroupChange === false
            ? this.state.isNationalPark === true
              ? [value.groups[0], 'national-park']
              : [value.groups[0]]
            : this.state.isNationalPark === true
              ? [value.groups, 'national-park']
              : [value.groups],
        tags: tagsNametoSlug,
        content_type: value.cctv_type,
        tourism_types: TourismTypesNametoSlug,
        snapshot_images:
          this.state.cctvType === 'timelapse'
            ? {
              mor_link: value.image_mor.file
                ? value.image_mor.file.originFileObj.url
                : value.image_mor[0].url,
              aft_link: value.image_aft.file
                ? value.image_aft.file.originFileObj.url
                : value.image_aft[0].url,
              eve_link: value.image_eve.file
                ? value.image_eve.file.originFileObj.url
                : value.image_eve[0].url,
            }
            : {},
        snapshot_config:
          this.state.cctvType === 'cctv'
            ? {
              mor: moment(value.snapshotMorning).format('HH:mm'),
              aft: moment(value.snapshotAfternoon).format('HH:mm'),
              eve: moment(value.snapshotEvening).format('HH:mm'),
            }
            : {},
        timelapse_config: timelapsConfig,
        camera_name: cameraName,
        server_ftp_id: ftp_id.id,
        views: value.views || 0,
        keywords: value.keywords,
        area: { th: value.areath, en: value.areaen },
        status: this.state.isStatus,
        user_groups: value.user_group,
        status_approval: this.state.isPending === true ? 'pending_publish' : this.state.isStatusApproval,
        revised_edition: this.state.isRevised,
        approval_id: this.state.approvalId,
      }
      message.loading('Save in progress..', 0)
      this.props
        .updateData(requestFrom, this.props.match.params.id)
        .then((response) => {
          message.destroy()
          if (response?.code === 200) {
            this.setState({ isPending: false })
            this.setState({ isDirty: true, isCreated: true })
          } else {
            this.setState({
              isDirty: true,
              isCreateError: true,
              errorMsg: response?.message,
            })
          }
        })
    }
  }

  clickCancle = () => { this.setState({ isCancel: true }) }

  clickPending = () => { this.setState({ isPending: true }) }

  handleGroupChange = () => { this.setState({ isGroupChange: true }) }

  onMapFinish = (value) => {
    if (
      value.ownLat <= 90 &&
      value.ownLat >= -90 &&
      value.ownLng <= 180 &&
      value.ownLng >= -180
    ) {
      this.setState({
        lat: parseFloat(value.ownLat),
        lng: parseFloat(value.ownLng),
        setOwnLocations: true,
        ownLocationChange: false,
      })
    } else {
      if (value.ownLat > 90 || value.ownLat < -90) {
        message.error('ละติจูดไม่ถูกต้อง', 1.5)
      }
      if (value.ownLng > 180 || value.ownLng < -180) {
        message.error('ลองจิจูดไม่ถูกต้อง', 1.5)
      }
    }
  }

  checkImg = (rule, value) => {
    if (this.props.match.params.id) {
      if (value[0]) {
        return Promise.resolve()
      }
    }
    if (
      (value && value.file.type === 'image/png') ||
      (value && value.file.type === 'image/jpeg')
    ) {
      if (this.props.match.params.id) {
        if (value && value.length) {
          return Promise.resolve()
        } else if (value.fileList && value.fileList.length !== 0) {
          return Promise.resolve()
        }
      } else {
        if (value.fileList && value.fileList.length !== 0) {
          return Promise.resolve()
        }
      }
      if (this.state.isRemove === true) {
        return this.setState({ isRemove: false })
      } else {
        return Promise.reject(
          <div style={{ marginTop: '-15px' }}>Please insert photo !!</div>
        )
      }
    } else {
      if (value && value.fileList.length !== 0) {
        value.file = []
        value.fileList = []
        this.setState({ fileList: [] })
        if (value.fileList && value.fileList.length === 0) {
          return Promise.reject(
            <div style={{ marginTop: '-15px' }}>
              Please insert file type .png, .jpeg only !
            </div>
          )
        }
      } else {
        if (this.state.isRemove === true) {
          return this.setState({ isRemove: false })
        } else {
          return Promise.reject(
            <div style={{ marginTop: '-15px' }}>Please insert photo !!</div>
          )
        }
      }
    }
  }

  checkVideo = (rule, value) => {
    if (this.props.match.params.id) {
      if (value[0]) {
        return Promise.resolve()
      }
    }
    if (value && value.file.type === 'video/mp4') {
      if (this.props.match.params.id) {
        if (value && value.length) {
          return Promise.resolve()
        } else if (value.fileList && value.fileList.length !== 0) {
          return Promise.resolve()
        }
      } else {
        if (value.fileList && value.fileList.length !== 0) {
          return Promise.resolve()
        }
      }
      if (this.state.isRemove === true) {
        return this.setState({ isRemove: false })
      } else {
        return Promise.reject(
          <div style={{ marginTop: '0' }}>Please insert video !!</div>
        )
      }
    } else {
      if (value && value.fileList.length !== 0) {
        value.file = []
        value.fileList = []
        this.setState({ fileList: [] })
        if (value.fileList && value.fileList.length === 0) {
          return Promise.reject(
            <div style={{ marginTop: '0' }}>
              Please insert file type .mp4 only !
            </div>
          )
        }
      } else {
        if (this.state.isRemove === true) {
          return this.setState({ isRemove: false })
        } else {
          return Promise.reject(
            <div style={{ marginTop: '0' }}>Please insert video !!</div>
          )
        }
      }
    }
  }

  onRemoveImg = (value) => { this.setState({ isRemove: true }) }

  isCheckPark = () => { this.setState({ isNationalPark: this.state.isNationalPark === true ? false : true, }) }

  validatorKey = (rule, value, callback) => {
    formRef.current.setFieldsValue({
      key: value,
    })

    if (!value && !this.props.match.params.id)
      return callback('Key is required !!')

    const pattern = /^[A-Za-z0-9-]*$/
    if (!pattern.test(value)) return callback('Invalid data format !!')
    return callback()
  }

  onChangeNumber = (e) => {
    const number = parseInt(e || 0, 10)
    if (isNaN(number)) {
      return
    }
    return number
  }

  validatorKeyWords = (rule, value, callback) => {
    let textValue = value

    if (textValue[0] === ' ') {
      textValue = setCharAt(textValue, 0, '')
    }

    if (
      textValue[value.length - 1] === ',' &&
      textValue[value.length - 2] === ' '
    ) {
      textValue = setCharAt(textValue, value.length - 2, ',')
      textValue = setCharAt(textValue, value.length - 1, '')
    }

    if (
      textValue[value.length - 1] === ' ' &&
      textValue[value.length - 2] === ','
    ) {
      textValue = setCharAt(textValue, value.length - 2, ',')
      textValue = setCharAt(textValue, value.length - 1, '')
    }

    if (
      textValue[value.length - 1] === ',' &&
      textValue[value.length - 2] === ','
    ) {
      textValue = setCharAt(textValue, value.length - 2, ',')
      textValue = setCharAt(textValue, value.length - 1, '')
    }

    formRef.current.setFieldsValue({
      keywords: textValue,
    })

    if (!textValue) return callback('Keywords is required !!')

    const pattern = /^[a-z0-9, ก-๏]*$/
    if (!pattern.test(textValue)) return callback('Invalid data format !!')
    return callback()
  }

  getServerID = () => {
    return httpClient
      .get(`${process.env.REACT_APP_BASEURL}/admin/ftp-server`)
      .then((response) => {
        this.setState({ serverID: response?.data?.data?.data_list ? response?.data.data.data_list : [] })
        for (let i = 0; i < this.state.serverID.length; i++) {
          this.state.serverIDOption.push(
            <Option
              key={this.state.serverID[i].username + i}
              value={`${this.state.serverID[i].name} | ${this.state.serverID[i].host}:${this.state.serverID[i].ftp_port} | ${this.state.serverID[i].username}`}
            >
              {`${this.state.serverID[i].name} | ${this.state.serverID[i].host}:${this.state.serverID[i].ftp_port} | ${this.state.serverID[i].username}`}
            </Option>
          )
        }

        if (!this.props.match.params.id) {
          formRef.current.setFieldsValue({
            server_ftp_id: undefined,
          })
        }
      })
      .catch((error) => {
        throw error
      })
  }

  render() {
    const { dataList, isLoading } = this.props
    const {
      previewVisible,
      previewImage,
      fileList,
      fileListVideo,
      fileList_mor,
      fileList_aft,
      fileList_eve,
      lat,
      lng,
      isNationalPark,
      setOwnLocations,
      ownLocationChange,
    } = this.state
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    )

    return (
      <>
        {isLoading === true ? (
          <Skeleton className="App-form" loading={isLoading} active></Skeleton>
        ) : (
          <Form
            ref={formRef}
            layout="vertical"
            className="App-form"
            onFinish={
              this.props.match.params.id ? this.handleUpdate : this.handleSubmit
            }
          >
            <Form.Item style={{ marginTop: '-20px' }}>
              <h1 className="topic">
                {this.props.match.params.id
                  ? 'แก้ไขข้อมูลกล้อง CCTV'
                  : 'เพิ่มข้อมูลกล้อง CCTV'}
              </h1>
            </Form.Item>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  className="form-slide-menu-size"
                  label={
                    <Row>
                      <Col>อัพโหลดรูปภาพ</Col>
                      <Col style={{ color: 'red' }}>&nbsp;*</Col>
                    </Row>
                  }
                >
                  <p
                    style={{
                      fontSize: '12px',
                      marginTop: '-20px',
                    }}
                  >
                    (ขนาดภาพแนะนำ 900x500 px)
                  </p>
                </Form.Item>
                <Form.Item
                  style={{ marginTop: '-35px' }}
                  name="cover_url"
                  rules={[
                    {
                      required: true,
                      validator: this.checkImg,
                    },
                  ]}
                  initialValue={this.props.match.params.id ? fileList : ''}
                >
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onRemove={this.onRemoveImg}
                    onChange={this.handleChange}
                    customRequest={this.props.addImageData}
                    type="picture"
                    accept="image/png,image/jpeg"
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  style={{ marginTop: '-15px' }}
                  name="nameth"
                  label="ชื่อ"
                  rules={[
                    { required: true, message: 'CCTV name is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.name
                      ? dataList.name.th
                      : ''
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  style={{ marginTop: '-15px' }}
                  name="nameen"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      validator: this.checkValidEng,
                      transform: (value) => {
                        let text = ''
                        if (!/^([A-Za-z0-9-_ ]{1,})?$/.test(value)) {
                          text = value.substring(0, value.length - 1)
                        } else {
                          text = value
                        }
                        return text
                      },
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.name
                      ? dataList.name.en
                      : ''
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={1}>
                <Tooltip
                  className="sil-toottip-custom-width"
                  placement="topRight"
                  title={textValidName}
                >
                  <ExclamationCircleOutlined
                    style={{ fontSize: '18px', marginTop: '23px' }}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={11}>
                <Form.Item
                  name="key"
                  label="คีย์"
                  rules={[
                    {
                      validator: this.validatorKey,
                      required: this.props.match.params.id ? false : true,
                      transform: (value) => {
                        let text = ''
                        if (!/^([A-Za-z0-9-]{1,})?$/.test(value)) {
                          text = value.substring(0, value.length - 1)
                        } else {
                          text = value
                        }
                        return text.toLowerCase()
                      },
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id ? dataList.key_slug : ''
                  }
                >
                  <Input
                    maxLength="50"
                    style={{ width: '103%' }}
                    autoComplete="off"
                    disabled={this.props.match.params.id ? true : false}
                  />
                </Form.Item>
              </Col>
              {!this.props.match.params.id && (
                <Col span={1}>
                  <Tooltip placement="top" title={text}>
                    <ExclamationCircleOutlined
                      style={{
                        fontSize: '18px',
                        marginTop: '38px',
                        marginLeft: '15px',
                      }}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  name="descriptionth"
                  label="รายละเอียด"
                  rules={[
                    {
                      required: true,
                      message: 'CCTV description is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.description
                      ? dataList.description.th
                      : '-'
                  }
                >
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    autoComplete="off"
                    maxLength="2000"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="descriptionen"
                  label="Detail"
                  rules={[
                    {
                      required: true,
                      message: 'CCTV description is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.description
                      ? dataList.description.en
                      : '-'
                  }
                >
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    autoComplete="off"
                    maxLength="2000"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  name="location"
                  label="พิกัด"
                  rules={[
                    { required: true, message: 'CCTV address is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.location
                      ? dataList.location.coordinates[1] +
                      ', ' +
                      dataList.location.coordinates[0]
                      : ''
                  }
                >
                  <Input autoComplete="off" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={' '} style={{ display: 'inline-block' }}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EnvironmentOutlined />}
                    size="medium"
                    onClick={this.handleMapPreview}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  name="addressth"
                  label="ที่อยู่"
                  rules={[
                    { required: true, message: 'CCTV address is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.address
                      ? dataList.address.th
                      : ''
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="addressen"
                  label="Address"
                  rules={[
                    { required: true, message: 'CCTV address is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.address
                      ? dataList.address.en
                      : ''
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  name="cctv_type"
                  label="ประเภท CCTV"
                  rules={[
                    { required: true, message: 'CCTV Link is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id
                      ? dataList.content_type
                        ? dataList.content_type
                        : 'cctv'
                      : 'cctv'
                  }
                >
                  <Select
                    placeholder="เลือก"
                    onChange={(e) => this.onSelected(e)}
                  >
                    <Option value="cctv">CCTV</Option>
                    <Option value="timelapse">Timelapse</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {this.state.cctvType === 'cctv' ? (
              <Row gutter={[20, 15]}>
                <Col span={12}>
                  <Form.Item
                    name="camera_url"
                    label="ลิงค์"
                    rules={[
                      { required: true, message: 'CCTV Link is required !!' },
                    ]}
                    initialValue={
                      this.props.match.params.id ? dataList.camera_url : ''
                    }
                  >
                    <Input autoComplete="off" disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label=" " style={{ display: 'inline-block' }}>
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<VideoCameraOutlined />}
                      size="medium"
                      onClick={this.handleCamPreview}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : this.state.cctvType === 'timelapse' ? (
              <Row gutter={[20, 15]}>
                <Col span={12}>
                  <Form.Item
                    className="form-slide-menu-size"
                    label={
                      <Row>
                        <Col style={{ color: 'red' }}>*&nbsp;</Col>
                        <Col>อัพโหลดวิดีโอ Timelapse</Col>
                      </Row>
                    }
                  >
                    <p
                      style={{
                        fontSize: '12px',
                        marginTop: '-20px',
                        marginLeft: '10px',
                      }}
                    >
                      (ขนาดวิดีโอแนะนำ 800x450 px)
                    </p>
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: '-35px' }}
                    name="video_url"
                    rules={[
                      {
                        required: true,
                        validator: this.checkVideo,
                      },
                    ]}
                    initialValue={
                      this.props.match.params.id ? fileListVideo : ''
                    }
                  >
                    <Upload
                      fileList={fileListVideo}
                      onRemove={this.onRemoveImg}
                      onChange={this.handleChangeVideo}
                      customRequest={this.props.addImageData}
                      type="video"
                      accept="video/mp4"
                    >
                      {fileListVideo.length >= 1 ? null : (
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      )}
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            ) : null}
            {fileListVideo[0]?.status === 'done' &&
              this.state.cctvType === 'timelapse' ? (
              <Row gutter={[20, 15]} style={{ marginTop: '-30px' }}>
                <Col span={12}>
                  <video
                    style={{ width: '500px' }}
                    src={
                      fileListVideo[0]?.originFileObj?.url
                        ? fileListVideo[0]?.originFileObj?.url
                        : fileListVideo[0]?.url
                    }
                    type="video/mp4"
                    controls
                  />
                </Col>
              </Row>
            ) : null}
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  name="groups"
                  label="กลุ่ม (จังหวัด)"
                  rules={[
                    { required: true, message: 'CCTV Group is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id ? dataList.groups : null
                  }
                >
                  <Select placeholder="เลือก" onChange={this.handleGroupChange}>
                    {this.state.OptionType}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 0]}>
              <Col span={12}>
                <Form.Item rules={[{ required: false }]}>
                  <Checkbox
                    checked={isNationalPark}
                    onChange={this.isCheckPark}
                  >
                    เป็นอุทยานฯ
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  name="areath"
                  label="เขต"
                  rules={[
                    { required: true, message: 'CCTV area is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.area
                      ? dataList.area.th
                      : ''
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="areaen"
                  label="Area"
                  rules={[
                    { required: true, message: 'CCTV area is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.area
                      ? dataList.area.en
                      : ''
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 0]}>
              <Col span={24}>
                <Form.Item
                  name="tags"
                  label="แท็ก"
                  rules={[
                    { required: false, message: 'CCTV Tag is required !!' },
                  ]}
                >
                  {this.state.tagsData.map((tag) => (
                    <CheckableTag
                      key={tag}
                      checked={this.state.selectedTags.indexOf(tag) > -1}
                      onChange={(checked) => this.handleTagChange(tag, checked)}
                    >
                      {tag}
                    </CheckableTag>
                  ))}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 0]}>
              <Col span={24}>
                <Form.Item
                  name="tourism_types"
                  label="ประเภทการท่องเที่ยว"
                  rules={[
                    {
                      required: false,
                      message: 'CCTV tourism types is required !!',
                    },
                  ]}
                >
                  {this.state.tourismTypesData.map((tag) => (
                    <CheckableTag
                      key={tag}
                      checked={
                        this.state.selectedTourismTypes.indexOf(tag) > -1
                      }
                      onChange={(checked) =>
                        this.handleTourismTypesChange(tag, checked)
                      }
                    >
                      {tag}
                    </CheckableTag>
                  ))}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={11}>
                <Form.Item
                  name="keywords"
                  label="คีย์เวิร์ด"
                  rules={[
                    {
                      validator: this.validatorKeyWords,
                      required: true,
                      transform: (value) => {
                        let text = ''
                        if (!/^([a-z0-9, ก-๏]{1,})?$/.test(value)) {
                          text = value.substring(0, value.length - 1)
                        } else {
                          text = value
                        }
                        return text.toLowerCase()
                      },
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id ? dataList.keywords : ''
                  }
                >
                  <Input style={{ width: '103%' }} autoComplete="off" />
                </Form.Item>
              </Col>
              {!this.props.match.params.id && (
                <Col span={1}>
                  <Tooltip placement="top" title={text_keywords}>
                    <ExclamationCircleOutlined
                      style={{
                        fontSize: '18px',
                        marginTop: '38px',
                        marginLeft: '15px',
                      }}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <Form.Item
                  style={{ marginTop: '0px' }}
                  name="server_ftp_id"
                  label="เซิร์ฟเวอร์ FTP"
                  rules={[
                    {
                      required: true,
                      message: 'Server is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.server_ftp?.name
                      ? `${dataList.server_ftp.name} | ${dataList.server_ftp.host}:${dataList.server_ftp.ftp_port} | ${dataList.server_ftp.username}`
                      : null
                  }
                >
                  <Select showSearch placeholder="เลือก">
                    {this.state.serverIDOption}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {this.state.cctvType === 'cctv' ? (
              <>
                <Row style={{ marginBottom: '-50px' }}>
                  <Col span={24}>
                    <Form.Item label="ตั้งค่า Snapshot" />
                  </Col>
                </Row>
                <Row gutter={[20, 0]}>
                  <Col span={4}>
                    <Form.Item
                      name="snapshotMorning"
                      label="ช่วงเช้า"
                      rules={[
                        {
                          required: true,
                          message: 'Snapshot time is required !!',
                        },
                      ]}
                      initialValue={
                        this.props.match.params.id &&
                          dataList.snapshot_config &&
                          dataList.snapshot_config.mor
                          ? moment(dataList.snapshot_config.mor, 'HH:mm')
                          : undefined
                      }
                    >
                      <TimePicker format={'HH:mm'} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="snapshotAfternoon"
                      label="ช่วงกลางวัน"
                      rules={[
                        {
                          required: true,
                          message: 'Snapshot time is required !!',
                        },
                      ]}
                      initialValue={
                        this.props.match.params.id &&
                          dataList.snapshot_config &&
                          dataList.snapshot_config.aft
                          ? moment(dataList.snapshot_config.aft, 'HH:mm')
                          : undefined
                      }
                    >
                      <TimePicker format={'HH:mm'} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="snapshotEvening"
                      label="ช่วงเย็น"
                      rules={[
                        {
                          required: true,
                          message: 'Snapshot time is required !!',
                        },
                      ]}
                      initialValue={
                        this.props.match.params.id &&
                          dataList.snapshot_config &&
                          dataList.snapshot_config.eve
                          ? moment(dataList.snapshot_config.eve, 'HH:mm')
                          : undefined
                      }
                    >
                      <TimePicker format={'HH:mm'} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : this.state.cctvType === 'timelapse' ? (
              <>
                <Row style={{ marginBottom: '-40px' }}>
                  <Col span={24}>
                    <Form.Item
                      className="form-slide-menu-size"
                      label={
                        <Row>
                          <Col>อัพโหลด Snapshot</Col>
                        </Row>
                      }
                    >
                      <p
                        style={{
                          fontSize: '12px',
                          marginTop: '-20px',
                          marginLeft: '0px',
                        }}
                      >
                        (ขนาด Ratio ที่แนะนำ 16:9)
                      </p>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[20, 0]}>
                  <Col span={4}>
                    <Form.Item
                      name="image_mor"
                      label="ช่วงเช้า"
                      rules={[
                        {
                          required: true,
                          validator: this.checkImg,
                        },
                      ]}
                      initialValue={
                        this.props.match.params.id ? fileList_mor : ''
                      }
                    >
                      <Upload
                        listType="picture-card"
                        fileList={fileList_mor}
                        onPreview={this.handlePreview}
                        onRemove={this.onRemoveImg}
                        onChange={this.handleChangeMor}
                        customRequest={this.props.addImageData}
                        type="picture"
                        accept="image/png,image/jpeg"
                      >
                        {fileList_mor.length >= 1 ? null : uploadButton}
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="image_aft"
                      label="ช่วงกลางวัน"
                      rules={[
                        {
                          required: true,
                          validator: this.checkImg,
                        },
                      ]}
                      initialValue={
                        this.props.match.params.id ? fileList_aft : ''
                      }
                    >
                      <Upload
                        listType="picture-card"
                        fileList={fileList_aft}
                        onPreview={this.handlePreview}
                        onRemove={this.onRemoveImg}
                        onChange={this.handleChangeAft}
                        customRequest={this.props.addImageData}
                        type="picture"
                        accept="image/png,image/jpeg"
                      >
                        {fileList_aft.length >= 1 ? null : uploadButton}
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="image_eve"
                      label="ช่วงเย็น"
                      rules={[
                        {
                          required: true,
                          validator: this.checkImg,
                        },
                      ]}
                      initialValue={
                        this.props.match.params.id ? fileList_eve : ''
                      }
                    >
                      <Upload
                        listType="picture-card"
                        fileList={fileList_eve}
                        onPreview={this.handlePreview}
                        onRemove={this.onRemoveImg}
                        onChange={this.handleChangeEve}
                        customRequest={this.props.addImageData}
                        type="picture"
                        accept="image/png,image/jpeg"
                      >
                        {fileList_eve.length >= 1 ? null : uploadButton}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : null}
            <Row gutter={[20, 0]}>
              <Col span={12}>
                <Form.Item
                  name="timelapse"
                  label="ตั้งค่า Timelapse"
                  rules={[
                    {
                      required: true,
                      message: 'Timelapse config is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.timelapse_config
                      ? dataList.timelapse_config.key_slug
                      : ''
                  }
                >
                  <Select placeholder="Please select">
                    {this.state.timelapseOption}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {!this.props.match.params.id && (
              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <Form.Item
                    name="views"
                    label="จำนวนผู้เข้าชม (เริ่มต้น)"
                    initialValue={0}
                  >
                    <InputNumber
                      className="inp-count-view"
                      min={0}
                      initialValue={0}
                      formatter={this.onChangeNumber}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {/* <Row gutter={[20, 0]}>
              <Col span={24}>
                <Form.Item
                  label="สถานะ"
                  name="status"
                  initialValue={
                    this.props.match.params.id
                      ? dataList.status === 'active'
                        ? 'active'
                        : 'inactive'
                      : 'active'
                  }
                >
                  <Radio.Group>
                    <Radio value={'active'}>ใช้งาน</Radio>
                    <Radio value={'inactive'}>ไม่ใช้งาน</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row> */}

            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  name="user_group"
                  label="กลุ่มผู้ใช้งาน"
                  rules={[
                    { required: true, message: 'User Group is required !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id ? dataList.user_groups : null
                  }
                >
                  <Select placeholder="เลือก">
                    {this.state.OptionUserGroup}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Footeractionlayout
                isShowButtonApprove={
                  this.props.match.params.id ?
                    dataList.status_approval === 'draft' || dataList.status_approval === 'revise' ? true : false
                    : true
                }
                onPending={this.clickPending}
                onCancle={this.clickCancle}
              ></Footeractionlayout>
            </Form.Item>

            <Modal
              visible={this.state.previewVisibleMap}
              onCancel={this.handleMapCancel}
              width={1000}
              onOk={this.handleMapOk}
            >
              <div style={{ marginTop: '20px' }}>
                <Row style={{ marginTop: '20px' }}>
                  <Googlemap
                    setOwnlocataions={setOwnLocations}
                    getMap={this.getMapLatLng}
                    latitude={
                      lat
                        ? lat
                        : this.props.match.params.id && dataList.location
                          ? dataList.location.coordinates[1]
                          : ''
                    }
                    longitude={
                      lng
                        ? lng
                        : this.props.match.params.id && dataList.location
                          ? dataList.location.coordinates[0]
                          : ''
                    }
                  />
                  <Space>
                    <Col style={{ marginTop: '25px' }}>ระบุพิกัด</Col>
                    <Form
                      ref={formLatLngRef}
                      style={{ marginBottom: '-30px', marginTop: '18px' }}
                      layout="Horizontal"
                      onFinish={this.onMapFinish}
                    >
                      <Space>
                        <Form.Item
                          name="ownLat"
                          label="ละติจูด"
                          rules={[
                            { required: true, validator: this.checkValidLat },
                          ]}
                          initialValue={
                            this.props.match.params.id && dataList.location
                              ? dataList.location.coordinates[1]
                              : lat
                                ? lat
                                : ''
                          }
                        >
                          <Input
                            autoComplete="off"
                            onChange={(e) =>
                              this.onOwnChange(e.target.value, 'lat')
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name="ownLng"
                          label="ลองจิจูด"
                          rules={[
                            { required: true, validator: this.checkValidLng },
                          ]}
                          initialValue={
                            this.props.match.params.id && dataList.location
                              ? dataList.location.coordinates[0]
                              : lng
                                ? lng
                                : ''
                          }
                        >
                          <Input
                            autoComplete="off"
                            onChange={(e) =>
                              this.onOwnChange(e.target.value, 'lng')
                            }
                          />
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            {ownLocationChange ? 'อัพเดทหมุด' : 'ปักหมุด'}
                          </Button>
                        </Form.Item>
                      </Space>
                    </Form>
                  </Space>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.previewCamLinkVisible}
              onCancel={this.handleCamCancel}
              onOk={this.handleCamOk}
              width={1000}
            >
              <div style={{ marginTop: '20px' }}>
                <Input
                  style={{ marginBottom: '10px', width: '20vmax' }}
                  onChange={this.setKeyword}
                  placeholder="ค้นหาชื่อกล้อง CCTV"
                  onPressEnter={this.onSearch}
                  suffix={<SearchOutlined />}
                  value={this.state.search}
                  autoComplete="on"
                />
                <DataTableLayout
                  showPagination={false}
                  dataSource={
                    this.state.cctvList
                      ? this.state.searchList.length
                        ? this.state.searchList
                        : this.state.search.length
                          ? this.state.searchList
                          : this.state.cctvList
                      : []
                  }
                  columns={this.columns}
                  scroll={true}
                />
              </div>
            </Modal>
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={this.handleCancel}
              width={800}
            >
              <img
                alt="example"
                style={{ width: '100%', marginTop: '20px' }}
                src={previewImage}
              />
            </Modal>
            <Modal
              visible={this.state.isCreated}
              footer={false}
              closable={false}
              centered={true}
              width={'420px'}
            >
              <div
                style={{
                  height: '220px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <img
                      alt="example"
                      src={iconCorrect}
                      width={48}
                      height={48}
                    />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    บันทึกข้อมูลสำเร็จแล้ว
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กดตกลงเพื่อออกจากหน้านี้
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.push('/cctv')
                        }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.isCreateError}
              footer={false}
              closable={false}
              centered={true}
              width={'500px'}
            >
              <div
                style={{
                  height: '220px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <img src={iconErr} width={48} height={48} alt="Error" />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    บันทึกข้อมูลไม่สำเร็จ
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    {this.state.errorMsg}
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.setState({ isCreateError: false })
                        }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.isCancel}
              footer={false}
              closable={false}
              centered={true}
              width={'420px'}
            >
              <div
                style={{
                  height: '180px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    ยืนยันการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    หากคุณต้องการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กรุณากด ยืนยัน
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        style={{ width: '170px' }}
                        onClick={() => this.handleCancel()}
                      >
                        ยกเลิก
                      </Button>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.goBack()
                        }}
                      >
                        ยืนยัน
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
          </Form>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(cctv, state),
  dataList: getResource(cctv, state),
})

const mapDispatchToProps = (dispatch) => ({
  createData: (data) => dispatch(createData(cctv, data)),
  readData: (id) => dispatch(readData(cctv, id)),
  updateData: (data, id) => dispatch(updateData(cctv, data, id)),
  addImageData: (params) => dispatch(resizeUpload(params)),
  reviseData: (id) => dispatch(updateData(revise, null, id, process.env.REACT_APP_CCTV_APPROVAL)),
})

const SlideListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Formlayout)

export default SlideListWithConnect
