import "index.css";

import * as serviceWorker from "serviceWorker";

import { applyMiddleware, compose, createStore } from "redux";

import AuthProvider from "contexts/AuthContext";
import UserGroupProvider from "contexts/UserGroupContext";
import React from "react";
import ReactDOM from "react-dom";
import Root from "Root";
import rootReducer from "reducers";
import thunk from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <AuthProvider>
    <UserGroupProvider>
      <Root store={store} />
    </UserGroupProvider>
  </AuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
