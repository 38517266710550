import './AccountStyle.less'

import { Button, Col, Form, Input, Row, message } from 'antd'
import React, { useState } from 'react'

import SvgEmail from 'images/icon-email.svg'
import axios from 'axios'
import moment from 'moment'
import styled from 'styled-components'

export default function Verify(props) {

  const [messageError, setMessageError] = useState('')
  const [messageErrorEmail, setMessageErrorEmail] = useState('')

  const onFinish = (values) => {
    const requireVerify = {
      contact: values.email,
      service: "seeitlive",
      channel: "email",
      otp_code_length: 6,
      ref_code_length: 6,
      verify_max: 3,
      expire_time: 60
    }

    message.loading({ content: 'Loading...' })
    axios.post(`${process.env.REACT_APP_MSG}/otp/request`, requireVerify)
      .then(async (response) => {
        message.destroy()
        if (response?.data?.code === 200) {
          localStorage.setItem('time_count', moment().add(1, 'minutes'))
          localStorage.setItem('contact', response?.data?.data?.contact)
          localStorage.setItem('ref_code', response?.data?.data?.ref_code)
          localStorage.setItem('otp_type', 'verify')

          props.history.push('/otp')
        } else {
          setMessageError(response?.data?.message)
        }
      })
      .catch((error) => {
        message.destroy()
        console.log('error', error?.response?.data?.errors)
        throw error
      })
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo?.errorFields.length) {
      if (!messageErrorEmail)
        setMessageErrorEmail(errorInfo?.values.email ? '' : 'Please input your email !')
    }
  }

  const onFieldsChange = (e) => {
    if (e.target.name === 'email') {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const checkMail = re.test(String(e.target.value).toLowerCase());
      if (!checkMail && e.target.value) {
        setMessageErrorEmail('Email is not a valid email !')
      } else {
        setMessageErrorEmail(e.target.value ? '' : 'Please input your email !')
      }
    }
  }

  return (
    <div>
      <LayoutVerify>
        <Row align="center" justify="center">
          <Col span="24" className="text-align-center">
            <Title>ยืนยันตัวตน</Title>
            <LabelVerify>กรุณาตรวจสอบอีเมลเพื่อขอรหัส OTP สำหรับยืนยันตัวตน</LabelVerify>
          </Col>
          <Col span={24} className="flex-center">
            <Form
              {...layout}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{ email: localStorage.getItem('contact') }}
              className="w-85-per"
            >
              <Form.Item
                className={`inp-color-black inp-account account-mb-10 ${messageError && ' msg-err'}`}
                name="email"
                validateStatus={messageError || messageErrorEmail ? 'error' : ''}
                help={messageErrorEmail}
                rules={[
                  { required: true, message: 'Please input your email!', type: 'email' },
                ]}
              >
                <Input
                  prefix={<img src={SvgEmail} width="16" height="16" alt="Email" />}
                  placeholder="Email"
                  autoComplete="off"
                  name="email"
                  onChange={(e) => onFieldsChange(e)}
                  disabled
                />
              </Form.Item>
              <Form.Item {...tailLayout} >
                <Button
                  type="primary"
                  htmlType="submit"
                  className="account-form-button"
                  disabled={localStorage.getItem('contact') ? false : true}
                >
                  ขอรหัส OTP
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </LayoutVerify >
    </div>
  )
}

const Title = styled.h1`
   font-size: 35px;
  margin-bottom:0px;
`

const LabelVerify = styled.h4`
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin-bottom:52px;
`

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
}

const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
}

const LayoutVerify = styled.div`
  width: 415px;
  height: 100px;
  position: absolute;
  top: -200px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  display: flex;
  justify-content: center;
`