import 'App.less'
import 'Custom.less'

import { BrowserRouter as Router, Switch } from 'react-router-dom'

import App from 'App.js'
import { ConfigProvider } from 'antd'
import ForgetPassword from 'containers/Account/ForgotPassword'
import Login from 'containers/Account/Login'
import Otp from 'containers/Account/Otp'
import SetPassword from 'containers/Account/SetPassword'
import PrivateRoute from 'components/routes/PrivateRoute'
import { Provider } from 'react-redux'
import PublicRoute from 'components/routes/PublicRoute'
import React from 'react'
import Verify from 'containers/Account/Verify'
import { createBrowserHistory } from 'history'
import thTH from 'antd/es/locale/th_TH'

const customHistory = createBrowserHistory()

const Root = ({ store }) => (
  <Provider store={store}>
    <ConfigProvider locale={thTH}>
      <Router history={customHistory}>
        <Switch>
          <PublicRoute component={Login} path="/login" />
          <PublicRoute component={Verify} path="/verify" />
          <PublicRoute component={Otp} path="/otp" />
          <PublicRoute component={ForgetPassword} path="/forgot-password" />
          <PublicRoute component={SetPassword} path="/activation" />
          <PrivateRoute component={App} />
        </Switch>
      </Router>
    </ConfigProvider>
  </Provider>
)

export default Root
