import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  Radio,
  message
} from 'antd'
import { useHistory } from 'react-router';
import styled from 'styled-components'
import iconErr from 'images/icon-error@3x.png'
import iconCorrect from 'images/correct@3x.png'
import '../../../App.less'
import { createData, readData, updateData, getMasterList } from 'actions/collection'
import { connect } from "react-redux";
import { getResourceLoading } from 'selectors'
import { usergroup } from 'schemas'
import { mapErrMsg } from '../../../utils/common'

const { TextArea } = Input;
const url = `${process.env.REACT_APP_AUTHEN}`

const UserGroupForm = (props) => {
  const history = useHistory()
  const formRef = useRef()
  const pathName = history.location.pathname.split("/")[2];
  const keySlug = history.location.pathname.substring(history.location.pathname.lastIndexOf("/") + 1)
  const [openModal, setOpenModal] = useState({ openCancel: false, openSuccess: false, openErr: false })
  const [dataGroup, setDataGroup] = useState()
  const [msgErr, setMsgErr] = useState([]);
  const [loading, setLoading] = useState(false)

  // ** connect API ** //

  const getGroup = () => {
    props.readData(keySlug).then((res) => {
      if (res.code === 200) {
        setDataGroup(res.data)
        formRef.current.setFieldsValue(res.data);
      } else {
        setDataGroup('')
      }
    })
  }

  const createGroup = async (value) => {
    await setLoading(true)
    const data = JSON.stringify({
      "key_slug": value.key_slug.trim(),
      "name": value.name.trim(),
      "description": value.description ? value.description.replace(/\s+/g, ' ').trim() : value.description,
      "status": value.status
    });
    await props.createData(data).then((res) => {
      message.destroy()
      if (res?.code === 201) {
        if (res?.data) {
          setOpenModal({ ...openModal, openSuccess: true, openConfirm: false })
        }
      } else {
        const arrError = mapErrMsg(res?.errors, 'กลุ่มผู้ใช้งาน')
        setMsgErr(arrError)
        setOpenModal({ ...openModal, openErr: true })
      }
    })
    await setLoading(false)
  }

  const updateGroup = async (value) => {
    await setLoading(true)
    const data = JSON.stringify({
      "key_slug": value.key_slug.trim(),
      "name": value.name.trim(),
      "description": value.description ? value.description.replace(/\n+/g, '\n').trim() : value.description,
      "status": value.status
    });

    await props
      .updateData(data, keySlug)
      .then((res) => {
        message.destroy()
        if (res?.code === 200) {
          setOpenModal({ ...openModal, openSuccess: true, openConfirm: false })
        } else {
          const arrError = mapErrMsg(res?.errors, 'กลุ่มผู้ใช้งาน')
          setMsgErr(arrError)
          setOpenModal({ ...openModal, openErr: true })
        }
      })
    await setLoading(false)
  }
  // ** connect API ** //

  const blockSpecialCharecter = (rule, value) => {
    if (value) {
      if (rule.field === 'key_slug') {
        const newValue = value.replace(/[^a-zA-Z0-9-]/g, "").toLowerCase();
        formRef.current.setFieldsValue({ [rule.field]: newValue, })

      } else {
        const newValue = value.replace(/[^a-zA-Z0-9ก-๙ ]/g, "");
        formRef.current.setFieldsValue({ [rule.field]: newValue })
      }
    }
    if (!value
      || (rule.field === 'name' && !value.replace(/[^a-zA-Z0-9ก-๙ ]/g, ""))
      || (rule.field === 'key_slug' && !value.replace(/[^a-zA-Z0-9-]/g, "").toLowerCase())) {
      return Promise.reject(`${rule.message}`)
    } else {
      return Promise.resolve()
    }
  }

  const onFinish = (value) => {
    if (!loading) {
      message.loading('Save in progress..', 0)
      pathName === 'create' ? createGroup(value) : updateGroup(value)
    }
  }

  const formatText = (e, name) => {
    const regex = name === 'name' ? new RegExp("^[a-zA-Z0-9ก-๙ ]") : new RegExp("^[a-zA-Z0-9-]")
    const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (e.type === 'paste') {
      const arrString = e.clipboardData.getData('text/plain').split('')
      const isvalid = arrString.every((item) => regex.test(item) === false)
      if (isvalid) {
        e.preventDefault();
        return false;
      }
    } else {
      if (!regex.test(key)) {
        e.preventDefault();
        return false;
      }
    }
  }

  useEffect(() => {
    if (pathName === 'edit') {
      getGroup()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Skeleton className="App-form" loading={!dataGroup && pathName !== 'create'} active>
        <Form layout="vertical" className="App-form" onFinish={onFinish} ref={formRef} >
          <Form.Item>
            <Topic>
              <h1 className="topic">
                {pathName === 'create' ? 'เพิ่มข้อมูลกลุ่มผู้ใช้งาน' : 'แก้ไขข้อมูลกลุ่มผู้ใช้งาน'}
              </h1>
            </Topic>
          </Form.Item>


          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="กลุ่มผู้ใช้งาน"
                rules={[{
                  required: true,
                  message: 'Group name is required !!',
                  validator: blockSpecialCharecter,
                }]}>
                <Input
                  autoComplete="off"
                  maxLength={255}
                  onKeyPress={(e) => formatText(e, 'name')}
                  onPaste={(e) => formatText(e, 'name')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="key_slug"
                label="คีย์"
                rules={[
                  {
                    required: true,
                    message: 'Key is required !!',
                    validator: blockSpecialCharecter,
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  maxLength={50}
                  disabled={pathName === 'edit'}
                  onKeyPress={(e) => formatText(e, 'key_slug')}
                  onPaste={(e) => formatText(e, 'key_slug')} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[20, 5]}>
            <Col span={12}>
              <TextLabel>
                <Form.Item
                  name="description"
                  label="รายละเอียด">
                  <TextArea autoComplete="off" autoSize={{ minRows: 8, maxRows: 8 }} />
                </Form.Item>
              </TextLabel>
            </Col>
          </Row>

          <Row gutter={[20, 5]}>
            <Col span={12}>
              <Form.Item
                name="status"
                label="สถานะการใช้งาน"
                initialValue={'active'}
                rules={[{ required: true, message: 'Status is required !!' }]}>
                <Radio.Group  >
                  <Radio value='active'>ใช้งาน</Radio>
                  <Radio value='inactive'>ไม่ใช้งาน</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item shouldUpdate={true} >
            <Space>
              <Button style={{ width: '12vmax' }} htmlType="button"
                onClick={() => setOpenModal({ ...openModal, openCancel: true })}
              >
                ยกเลิก
              </Button>
              <Button style={{ width: '12vmax' }} type='primary' htmlType='submit'>
                บันทึก
              </Button>
            </Space >
          </Form.Item>
        </Form >
      </Skeleton>

      <Modal visible={openModal.openCancel} footer={false} closable={false} centered={true} width={'420px'}>
        <BoxModel height="180px">
          <Row>
            <Col span={24}>
              <HeaderCancel>
                ยืนยันการยกเลิก
              </HeaderCancel>
            </Col>
            <Col span={24}>
              <TextModel >
                หากคุณต้องการยกเลิก
              </TextModel>
            </Col>
            <Col span={24}>
              <TextModel >
                กรุณากด ยืนยัน
              </TextModel>
            </Col>
            <Col span={24}>
              <BoxButton>
                <Space>
                  <Button style={{ width: '170px' }} onClick={() => setOpenModal({ ...openModal, openCancel: false })}>
                    ยกเลิก
                  </Button>
                  <Button type="primary" htmlType="submit" style={{ width: '170px' }} onClick={() => history.goBack()}>
                    ยืนยัน
                  </Button>
                </Space>
              </BoxButton>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal
        visible={openModal.openErr}
        footer={false}
        closable={false}
        centered={true}
        width={'528px'}
      >
        <BoxModel minHeight="300px">
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img alt="example" src={iconErr} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>
                {`${pathName === 'create' ? 'เพิ่ม' : 'แก้ไข'}ข้อมูลไม่สำเร็จ`}
              </HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="15px">
                {msgErr.map((item, index) => {
                  return <p key={index}>{item}</p>
                })}
              </TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="40px">
                <Space>
                  <Button
                    onClick={() => setOpenModal({ ...openModal, openErr: false })}
                    type="primary" style={{ width: '170px' }} >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal
        visible={openModal.openSuccess}
        footer={false}
        closable={false}
        centered={true}
        width={'528px'}
      >
        <BoxModel height="300px">
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <img
                alt="example"
                src={iconCorrect}
                width={48}
                height={48}
              />
            </Col>
            <Col span={24}>
              <HeaderModel>
                {`${pathName === 'create' ? 'บันทึก' : 'แก้ไข'}ข้อมูลสำเร็จแล้ว`}
              </HeaderModel>
            </Col>
            <Col span={24} >
              <TextModel>
                กด ‘ตกลง’ เพื่อออกจากหน้านี้
              </TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="40px">
                <Space>
                  <Button onClick={() => { setOpenModal({ ...openModal, openSuccess: false }); history.goBack() }}
                    type="primary" style={{ width: '170px' }}>
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(usergroup, state),
})

const mapDispatchToProps = (dispatch) => ({
  createData: (data) => dispatch(createData(usergroup, data, url)),
  readData: (id) => dispatch(readData(usergroup, id, url)),
  updateData: (data, id) => dispatch(updateData(usergroup, data, id, url)),
  getMasData: (schema, url) => dispatch(getMasterList(schema, url)),
})

const userGroupFormWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserGroupForm)
export default userGroupFormWithConnect

const Topic = styled.div`
 margin-top: -20px;
`

const TextLabel = styled.div`
margin-top: -15px;
`

const HeaderCancel = styled.div`
font-size: 24px;
font-weight: bold;
text-align: center;
`
const BoxButton = styled.div`
 font-size: 18px;
text-align: center;
margin-top: 30px;
`

const BoxModel = styled.div`
width: ${(props) => (props.width)};
min-height: ${(props) => (props.minHeight)};
height: ${(props) => (props.height)};
justify-content: center;
align-items: center;
display: flex;
`
const HeaderModel = styled.div`
 font-size: 35px;
font-weight: 500;
text-align: center;
padding-bottom: 8px;
 margin-top: 15px;
`
const TextModel = styled.div`
font-size: 18px;
text-align: center ;
margin-top: ${(props) => (props.marginTop)};
`
