import React from 'react'
import Viewlayout from 'components/layouts/Viewlayout'
import {
  Row,
  Col,
  Form,
  message,
  Modal,
  Skeleton,
  Space,
  Button,
  Input,
  Checkbox,
} from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import {
  readDataFTP,
  deleteDataFTP,
  createDataFTPtoZM,
} from 'actions/collection'
import { ftppath, ftplinkzm } from 'schemas'
import { getResource, getResourceLoading } from 'selectors'
import iconDeleted from 'images/correct@3x.png'
import iconErr from 'images/icon-error@3x.png'
import { EyeOutlined } from '@ant-design/icons'
import CryptoJS from 'crypto-js'
import iconCorrect from 'images/correct@3x.png'

const reqPassFormRef = React.createRef()

class view extends React.Component {
  state = {
    visible: false,
    isCreateError: false,
    visibleDeleted: false,
    errorMsg: '',
    viewPasswordModal: false,
    getPassword: '',
    showingPassword: false,
    getDePassword: '',
    isDirty: false,
    isCreated: false,
    printNoPassword: false,
  }

  componentDidMount() {
    this.props.readDataFTP(this.props.match.params.id).then((res) => {
      const bytes = CryptoJS.AES.decrypt(
        res?.data?.server_ftp?.password,
        'seeitlive-thailand-2020'
      )
      const passwordDe = bytes.toString(CryptoJS.enc.Utf8)
      const passwordConvert = []
      for (let i = 0; i < passwordDe.length; i++) {
        if (i === 0 || i === passwordDe.length - 1) {
          passwordConvert.push(passwordDe[i])
        } else {
          passwordConvert.push('*')
        }
      }
      this.setState({
        passwordDe: passwordConvert,
        getDePassword: res.data.server_ftp.password,
      })
    })
  }

  showModaldelete = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    })
    this.deleteList()
  }

  handleCancel = (e) => {
    this.setState({
      page: 1,
      search: this.state.search,
      per_page: localStorage.getItem('per_page')
        ? parseInt(localStorage.getItem('per_page'))
        : this.state.per_page,
      sort_field: 'sort',
      sort_by: 'asc',
      visible: false,
    })
  }

  deleteList = () => {
    this.setState({ visible: false }, () => {
      this.props
        .deleteData(
          this.props.dataList.id,
          this.props.location.search.split('=')[1]
        )
        .then(() => {
          message.loading('Delete in progress..', 2).then(() => {
            message.success('Delete complete !!', 0.5)
            this.setState({ delete_id: '' })
            this.props.history.push('/ftp-path')
          })
        })
    })
  }

  gotoEdit = () => {
    this.props.history.push(`/ftp-path/edit/${this.props.match.params.id}`)
  }

  getPassword = async (e) => {
    await this.setState({ getPassword: e })
  }

  readingPassword = async () => {
    reqPassFormRef.current.setFieldsValue({
      req_pass: '',
    })
    this.setState({ viewPasswordModal: false })
    const bytes = CryptoJS.AES.decrypt(
      localStorage.getItem('tempData'),
      'seeitlive-thailand-2020'
    )
    const passwordDe = bytes.toString(CryptoJS.enc.Utf8)

    if (this.state.getPassword === passwordDe) {
      this.setState({ showingPassword: true })
      message.success('Success !!', 1.5)
    } else {
      await this.setState({ showingPassword: false, printNoPassword: false })
      message.error('Wrong password !!', 1.5)
    }

    if (this.state.printNoPassword) {
      setTimeout(() => {
        window.print()
      }, 500)
    }
  }

  onClickPrint = () => {
    if (!this.state.showingPassword) {
      this.setState({ viewPasswordModal: true, printNoPassword: true })
    } else {
      window.print()
    }
  }

  connectFTPtoZM = () => {
    this.setState({ connectFTPModal: false })

    if (this.state.isDirty === false) {
      this.setState({ isDirty: true })
      const requestFrom = {
        ftp_path_id: this.props.match.params.id,
      }

      message.loading('Adding in progress..', 0)
      this.props.createDataFTP(requestFrom).then((res) => {
        message.destroy()
        if (res?.code === 201) {
          this.setState({ isDirty: true, isCreated: true })
        } else {
          this.setState({
            isDirty: false,
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    }
  }

  render() {
    const { dataList, isLoading } = this.props

    const bytes = CryptoJS.AES.decrypt(
      this.state.getDePassword,
      'seeitlive-thailand-2020'
    )

    const passwordDecode = bytes.toString(CryptoJS.enc.Utf8)
    return (
      <Form className="App-form">
        <Viewlayout
          viewData={dataList}
          isLoading={isLoading}
          textTitle="ข้อมูลการตั้งค่าเส้นทาง FTP"
          iconEdit="edit"
          iconDelete="delete"
          iconPrint="print"
          textEdit="แก้ไขข้อมูล"
          textPrint="พิมพ์"
          textDelete="ลบข้อมูล"
          onClickPrint={this.onClickPrint}
          onEdit={this.gotoEdit}
          onDelete={this.showModaldelete}
        >
          {isLoading === true ? (
            <Skeleton
              className="App-form"
              loading={isLoading}
              active
            ></Skeleton>
          ) : (
            <div id="mainDiv">
              <Row>
                <Col className="sil-break-word" span={24}>
                  <h1>Location name</h1>
                  {dataList.location_name ? dataList.location_name : '-'}
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '30px', width: '250px' }} span={12}>
                  <h1>FTP Server</h1>
                  {dataList.server_ftp?.host || '-'}
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '30px', width: '250px' }} span={12}>
                  <h1>FTP Port</h1>
                  {dataList.server_ftp?.ftp_port || '-'}
                </Col>
              </Row>
              <Row gutter={[100, 0]}>
                <Col
                  className="sil-break-word"
                  style={{ marginTop: '30px', width: '250px' }}
                  span={24}
                >
                  <h1>FTP Username</h1>
                  {dataList.server_ftp?.username
                    ? dataList.server_ftp?.username
                    : '-'}
                </Col>
              </Row>
              <Row gutter={[100, 0]}>
                <Col
                  className="sil-break-word"
                  style={{ marginTop: '30px', width: '250px' }}
                  span={24}
                >
                  <h1>FTP Password</h1>
                  <Space size="middle">
                    <div>
                      {dataList.server_ftp?.password &&
                        !this.state.showingPassword
                        ? this.state.passwordDe
                        : passwordDecode}{' '}
                    </div>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.setState({ viewPasswordModal: true })}
                    >
                      {!this.state.showingPassword ? <EyeOutlined /> : null}
                    </div>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col
                  className="sil-break-word"
                  style={{ marginTop: '30px' }}
                  span={24}
                >
                  <h1>Path (UID)</h1>
                  {dataList.path ? dataList.path : '-'}
                </Col>
              </Row>
              <Row>
                <Col
                  className="sil-break-word"
                  style={{ marginTop: '30px' }}
                  span={24}
                >
                  <h1>Ratio</h1>
                  {dataList.ratio ? dataList.ratio : '-'}
                </Col>
              </Row>
              <Row>
                <Col
                  className="sil-break-word"
                  style={{ marginTop: '30px' }}
                  span={24}
                >
                  <h1>Generate dir</h1>
                  <Checkbox
                    checked={true}
                    className="custom-checkbox"
                    style={{ cursor: 'default' }}
                  >
                    Auto generate dir
                  </Checkbox>
                </Col>
              </Row>
              {!dataList?.connected && (
                <Row className="add-to-zm-btn">
                  <Col
                    className="sil-break-word"
                    style={{ marginTop: '30px', width: '250px' }}
                    span={12}
                  >
                    <Button
                      type="primary"
                      onClick={() => this.setState({ connectFTPModal: true })}
                    >
                      <PlusOutlined />
                      Add to ZM
                    </Button>
                  </Col>
                </Row>
              )}
              <Modal
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
              >
                <Col className="modal-delete">
                  <DeleteOutlined style={{ fontSize: '40px' }} />
                </Col>
                <Col className="modal-delete">ต้องการลบข้อมูลใช่หรือไม่</Col>
              </Modal>
              <Modal
                visible={this.state.visibleDeleted}
                footer={false}
                closable={false}
                centered={true}
                width={'500px'}
              >
                <div
                  style={{
                    height: '180px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <img
                        alt="example"
                        src={iconDeleted}
                        width={48}
                        height={48}
                      />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      ลบข้อมูลเสร็จสิ้น
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          type="primary"
                          style={{ width: '170px' }}
                          onClick={() => this.props.history.push('/ftp-path')}
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
              <Modal
                visible={this.state.isCreateError}
                footer={false}
                closable={false}
                centered={true}
                width={'500px'}
              >
                <div
                  style={{
                    height: '220px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <img alt="example" src={iconErr} width={48} height={48} />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      เพิ่มข้อมูลไม่สำเร็จ
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      {this.state.errorMsg}
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          type="primary"
                          style={{ width: '170px' }}
                          onClick={() => {
                            this.setState({ isCreateError: false })
                          }}
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
              <Modal
                visible={this.state.viewPasswordModal}
                footer={false}
                closable={false}
                centered={true}
                width={'700px'}
              >
                <div
                  style={{
                    height: '180px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row justify="center">
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      ยืนยันการขอดู Password
                    </Col>
                    <Col
                      span={24}
                      style={{ fontSize: '18px', textAlign: 'center' }}
                    >
                      กรุณากรอก Password ของคุณ
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      <Space size="large" align="center">
                        Password :
                        <Form ref={reqPassFormRef}>
                          <Form.Item
                            name="req_pass"
                            style={{ marginBottom: 0 }}
                          >
                            <Input.Password
                              style={{ width: '300px' }}
                              onChange={(e) => this.getPassword(e.target.value)}
                            />
                          </Form.Item>
                        </Form>
                      </Space>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          style={{ width: '170px' }}
                          onClick={() =>
                            this.setState({
                              viewPasswordModal: false,
                              printNoPassword: false,
                            })
                          }
                        >
                          ยกเลิก
                        </Button>
                        <Button
                          type="primary"
                          style={{ width: '170px' }}
                          onClick={() => this.readingPassword()}
                        >
                          ยืนยัน
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
              <Modal
                visible={this.state.connectFTPModal}
                footer={false}
                closable={false}
                centered={true}
                width={'700px'}
              >
                <div
                  style={{
                    height: '180px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row justify="center">
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      ยืนยันการเพิ่มข้อมูลไปที่ ZM
                    </Col>
                    <Col
                      span={24}
                      style={{ fontSize: '18px', textAlign: 'center' }}
                    >
                      คุณต้องการเพิ่มข้อมูลไปที่ ZM ใช่หรือไม่
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    ></Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                      }}
                    >
                      <Space>
                        <Button
                          style={{ width: '170px' }}
                          onClick={() =>
                            this.setState({ connectFTPModal: false })
                          }
                        >
                          ยกเลิก
                        </Button>
                        <Button
                          type="primary"
                          style={{ width: '170px' }}
                          onClick={() => this.connectFTPtoZM()}
                        >
                          ยืนยัน
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
              <Modal
                visible={this.state.isCreated}
                footer={false}
                closable={false}
                centered={true}
                width={'420px'}
              >
                <div
                  style={{
                    height: '220px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <img
                        alt="example"
                        src={iconCorrect}
                        width={48}
                        height={48}
                      />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      เพิ่มข้อมูล FTP สำเร็จแล้ว
                    </Col>
                    <Col
                      span={24}
                      style={{ fontSize: '18px', textAlign: 'center' }}
                    >
                      กดตกลงเพื่อออกจากหน้านี้
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          type="primary"
                          style={{ width: '170px' }}
                          onClick={() => {
                            this.props.history.goBack()
                          }}
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </div>
          )}
        </Viewlayout>
      </Form>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(ftppath, state),
  dataList: getResource(ftppath, state),
})

const mapDispatchToProps = (dispatch) => ({
  createDataFTP: (data) => dispatch(createDataFTPtoZM(ftplinkzm, data)),
  readDataFTP: (id) => dispatch(readDataFTP(ftppath, id)),
  deleteData: (id) => dispatch(deleteDataFTP(ftppath, id)),
})

const PartherGroupWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(view)

export default PartherGroupWithConnect
