import Logo from "images/logo-sil.png";
import React from "react";
import styled from "styled-components";

const Page503 = () => {
  return (
    <Container>
      <Box top="-120px">
        <TextBg>500</TextBg>
      </Box>
      <Box top="-120px" left="-25px">
        <TextContent fontSize="30px" fontWeight="500">
          OOPS!
        </TextContent>
        <TextContent fontSize="26px" fontWeight="400">
          Internal Server Error.
        </TextContent>
        <TextContent fontSize="26px" fontWeight="400">
          Try to refresh this page.
        </TextContent>
      </Box>
      <LogoSil src={Logo} />
    </Container>
  );
};

export default Page503;

const LogoSil = styled.img`
  position: absolute;
  width: 135px;
  margin: 35px;
`;

const Container = styled.div`
  min-width: 768px;
  width: 100%;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: center;
`;

const Box = styled.div`
  text-align: center;
  margin-top: ${(props) => props.top};
  margin-left: ${(props) => props.left};
`;

const TextBg = styled.div`
  font-size: 200px;
  font-weight: 500;
  color: #e2e7ee;
  cursor: default;
`;

const TextContent = styled.div`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  display: flex;
  justify-content: center;
  color: #2f323a;
  cursor: default;
`;
