import React from 'react'
import { connect } from 'react-redux'
import {
  Form,
  Modal,
  Skeleton,
  message,
  Radio,
  Row,
  Col,
  Space,
  Button,
  Select,
  Input,
} from 'antd'
import { httpClient } from 'HttpClient'
import Footeractionlayout from 'components/layouts/Footeractionlayout'
import { createData, readData, updateData } from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'
import { cctvgroups } from 'schemas'
import iconErr from 'images/icon-error@3x.png'
import iconCorrect from 'images/correct@3x.png'

const { TextArea } = Input
const { Option } = Select
const formRef = React.createRef()

class Formslidemenulayout extends React.Component {
  state = {
    isDirty: true,
    errorMsg: '',
    isCreated: false,
    isCreateError: false,
    isCancel: false,
    cctvGroups: [],
    keySlug: '',
    nameTh: '',
    nameEn: '',
    descriptionTh: '',
    descriptionEn: '',
    cctvData: [],
    dataID: '',
  }

  componentDidMount() {
    this.CCTVGroups()
    if (this.props.match.params.id) {
      this.props.readData(this.props.match.params.id).then((response) => {
        this.setState({
          dataID: response?.data?.id,
          keySlug: response?.data?.key_slug,
          nameTh: response?.data?.name.th,
          nameEn: response?.data?.name.en,
          descriptionTh: response?.data?.description?.th,
          descriptionEn: response?.data?.description?.en,
        })
      })
    }
  }

  clickCancle = () => {
    this.setState({ isCancel: true })
  }

  handleCancel = () => this.setState({ previewVisible: false, isCancel: false })

  CCTVGroups = () => {
    return httpClient
      .get(`${process.env.REACT_APP_BASEURL}/admin/cctv-groups/unused`)
      .then((response) => {
        this.setState({ cctvData: response?.data?.data })
        // eslint-disable-next-line array-callback-return
        this.state.cctvData.map((value, index) => {
          this.state.cctvGroups.push(
            <Option key={index} value={value.name.th} data={value}>
              {value.name.th}
            </Option>
          )
        })
        if (!this.props.match.params.id) {
          formRef.current.setFieldsValue({
            group: undefined,
          })
        }
      })
      .catch((error) => {
        message.error('Fail to load CCTV lists', 2.5)
      })
  }

  handleSubmit = (value) => {
    const { isDirty, keySlug, nameTh, nameEn } = this.state
    if (isDirty) {
      this.setState({ isDirty: false })
      const requestFrom = {
        key_slug: keySlug,
        name: { th: nameTh, en: nameEn },
        description: { th: value.descriptionTh, en: value.descriptionEn },
        special_group: false,
        status: value.status,
      }

      message.loading('Save in progress..', 0)
      this.props.createData(requestFrom).then((res) => {
        message.destroy()
        if (res?.code === 200) {
          this.setState({ isDirty: true, isCreated: true }, () => {
            localStorage.setItem('page', 1)
            localStorage.setItem('search', '')
          })
        } else {
          this.setState({
            isDirty: true,
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    }
  }

  handleUpdate = (value) => {
    const { dataList } = this.props
    const { isDirty, keySlug, nameTh, nameEn, dataID } = this.state
    if (isDirty) {
      this.setState({ isDirty: false })
      const requestFrom = {
        key_slug: keySlug,
        name: { th: nameTh, en: nameEn },
        description: { th: value.descriptionTh, en: value.descriptionEn },
        special_group: dataList.special_group,
        status: value.status,
      }
      console.log('requestFrom :>> ', requestFrom)
      message.loading('Save in progress..', 0)
      this.props.updateData(requestFrom, dataID).then((response) => {
        message.destroy()
        if (response?.code === 200) {
          this.setState({ isDirty: true, isCreated: true })
        } else {
          this.setState({
            isDirty: true,
            isCreateError: true,
            errorMsg: response?.message,
          })
        }
      })
    }
  }

  onSelect = async (e) => {
    await this.setState({
      keySlug: e.data.key_slug,
      nameTh: e.data.name.th,
      nameEn: e.data.name.en,
    })

    formRef.current.setFieldsValue({
      key_slug: e.data.key_slug,
    })
  }

  render() {
    const { dataList, isLoading } = this.props
    const { previewVisible, previewImage } = this.state
    return (
      <>
        {isLoading === true ? (
          <Skeleton className="App-form" loading={isLoading} active></Skeleton>
        ) : (
          <Form
            ref={formRef}
            layout="vertical"
            className="App-form"
            onFinish={
              this.props.match.params.id ? this.handleUpdate : this.handleSubmit
            }
          >
            <Form.Item style={{ marginTop: '-20px' }}>
              <h1 className="topic">
                {this.props.match.params.id
                  ? 'แก้ไขข้อมูลกลุ่มจังหวัด'
                  : 'เพิ่มข้อมูลกลุ่มจังหวัด'}
              </h1>
            </Form.Item>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  label="กลุ่ม (จังหวัด)"
                  name="group"
                  rules={[
                    {
                      required: this.props.match.params.id ? false : true,
                      message: 'CCTV groups is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.name
                      ? dataList.name.th
                      : null
                  }
                >
                  <Select
                    placeholder="เลือก"
                    onSelect={(value, option) => this.onSelect(option)}
                    disabled={this.props.match.params.id ? true : false}
                  >
                    {this.state.cctvGroups}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  label="คีย์"
                  name="key_slug"
                  rules={[{ required: false }]}
                  initialValue={
                    this.props.match.params.id && dataList
                      ? dataList.key_slug
                      : ''
                  }
                >
                  <Input autoComplete="off" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  name="descriptionTh"
                  label="รายละเอียด"
                  rules={[
                    {
                      required: true,
                      message: 'CCTV group description is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.description
                      ? dataList.description.th
                      : ''
                  }
                >
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="descriptionEn"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: 'CCTV group description is required !!',
                    },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.description
                      ? dataList.description.en
                      : ''
                  }
                >
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  label="สถานะ"
                  name="status"
                  rules={[{ required: false }]}
                  initialValue={
                    this.props.match.params.id
                      ? dataList.status === 'active'
                        ? 'active'
                        : 'inactive'
                      : 'active'
                  }
                >
                  <Radio.Group>
                    <Radio value={'active'}>ใช้งาน</Radio>
                    <Radio value={'inactive'}>ไม่ใช้งาน</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item shouldUpdate={true}>
              <Footeractionlayout
                onCancle={this.clickCancle}
              ></Footeractionlayout>
            </Form.Item>
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={this.handleCancel}
              width={800}
            >
              <img
                alt="example"
                style={{ width: '100%', marginTop: '20px' }}
                src={previewImage}
              />
            </Modal>
            <Modal
              visible={this.state.isCreated}
              footer={false}
              closable={false}
              centered={true}
              width={'420px'}
            >
              <div
                style={{
                  height: '220px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <img
                      alt="example"
                      src={iconCorrect}
                      width={48}
                      height={48}
                    />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    บันทึกข้อมูลสำเร็จแล้ว
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กดตกลงเพื่อออกจากหน้านี้
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.goBack()
                        }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.isCreateError}
              footer={false}
              closable={false}
              centered={true}
              width={'500px'}
            >
              <div
                style={{
                  height: '220px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <img alt="example" src={iconErr} width={48} height={48} />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    บันทึกข้อมูลไม่สำเร็จ
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    {this.state.errorMsg}
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.setState({ isCreateError: false })
                        }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.isCancel}
              footer={false}
              closable={false}
              centered={true}
              width={'500px'}
            >
              <div
                style={{
                  height: '180px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    ยืนยันการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    หากคุณต้องการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กรุณากด ยืนยัน
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        style={{ width: '170px' }}
                        onClick={() => this.handleCancel()}
                      >
                        ยกเลิก
                      </Button>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.goBack()
                        }}
                      >
                        ยืนยัน
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
          </Form>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(cctvgroups, state),
  dataList: getResource(cctvgroups, state),
})

const mapDispatchToProps = (dispatch) => ({
  createData: (data) => dispatch(createData(cctvgroups, data)),
  readData: (id) => dispatch(readData(cctvgroups, id)),
  updateData: (data, id) => dispatch(updateData(cctvgroups, data, id)),
})

const SlideListWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Formslidemenulayout)

export default SlideListWithConnect
