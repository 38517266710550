// api path
export const logout = '/logout'
export const dashboard = 'admin/dashboard'
export const slides = 'admin/slides'
export const cctv = 'admin/cctv'
export const contacts = 'admin/contacts'
export const partners = 'admin/partners'
export const zm = 'admin/cctv-zm'
export const tourismtypes = 'admin/tourism-types'
export const tags = 'admin/tags'
export const cctvgroups = 'admin/cctv-groups'
export const partnergroups = 'admin/partner-groups'
export const popupevent = 'admin/popup-event'
export const blogs = 'admin/blogs'
export const blogcategories = 'admin/blog-categories'
export const ftpserver = 'admin/ftp-server'
export const ftppath = 'admin/ftp-path'
export const ftplinkzm = 'admin/ftp/zm/connect'

export const roles = 'roles'
export const users = 'users'
export const userprofile = 'me'
export const usergroup = 'user-groups'
export const activate = 'activate/resend'
export const revise = 'cctv/approval/revise'
export const approval = 'cctv/approval'
