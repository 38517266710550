import moment from 'moment'
import "moment/locale/th"

export const DateConvert = (value) => {
  return moment.unix(value || moment()).format('DD/MM/YYYY')
}

export const FullDateConvert = (value) => {
  return moment.unix(value || moment()).format('DD MMMM YYYY')
}

export const TimeConvert = (value) => {
  return moment.unix(value || moment()).format('HH:mm')
}

export const DateTimeConvert = (value) => {
  return moment.unix(value || moment()).format('DD MMMM YYYY เวลา HH:mm น.')
}
