import React, { useState } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  message,
} from 'antd'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import '../../../App.less'
import { getResourceLoading } from "selectors"
import { userprofile } from "schemas"
import { connect } from "react-redux"
import { updateData } from "actions/collection"
import iconCorrect from "images/correct@3x.png"
import iconErr from "images/icon-error@3x.png"

const PasswordForm = (props) => {
  const history = useHistory()
  const [openModal, setOpenModal] = useState({ openCancel: false, openErr: false, openConfirm: false, openSuccess: false })
  const [reqPassword, setReqPassword] = useState({ status: "success", text: '' })

  const onFinish = (value) => {
    const data = {
      new_password: value.new_password,
      old_password: value.current_password
    }
    setReqPassword({ status: "success", text: '' })
    props.updateData(data).then((res) => {
      message.destroy()
      if (res?.code === 200) {
        setOpenModal({ ...openModal, openSuccess: true, openConfirm: false })
      } else if (res?.code === 404) {
        setReqPassword({ status: "error", text: "Password is invalid !!" })
      } else {
        message.destroy()
        setOpenModal({ ...openModal, openErr: true })
      }
    })
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo?.errorFields.length) {
      errorInfo.errorFields.map((err) => {
        if (err.name.toString() === 'current_password') {
          setReqPassword({ status: "error", text: err.errors })
        }
        return err
      })
    }
  }

  return (
    <>
      <Form
        layout="vertical"
        className="App-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item>
          <Topic>
            <h1 className="topic">
              เปลี่ยนรหัสผ่าน
            </h1>
          </Topic>
        </Form.Item>

        <Row gutter={[20, 0]}>
          <Col className='password' span={12} >
            <Form.Item
              name="current_password"
              label="รหัสผ่านปัจจุบัน"
              rules={[
                {
                  required: true,
                  message: 'Current password is required !!',
                },
                {
                  pattern: /^(?!.* )(?=.*?)(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                  message: 'Current password is invalid !!',
                },
              ]}
              validateStatus={reqPassword.status}
              help={reqPassword.text && reqPassword.text}
            >
              <Input autoComplete="off" maxLength={255} />
            </Form.Item>
          </Col>
          <Col span={12} />

          <Col span={12} >
            <Form.Item
              name="new_password"
              label="รหัสผ่านใหม่"
              rules={[
                {
                  required: true,
                  message: 'New password is required !!',
                },
                {
                  pattern: /^(?!.* )(?=.*?)(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                  message: 'New password is invalid !!',
                },
              ]}
            >
              <Input autoComplete="off" maxLength={255} />
            </Form.Item>
          </Col>
          <Col span={12} />

          <Col span={12} >
            <Form.Item
              dependencies={['new_password']}
              name="confirm_password"
              label="ยืนยันรหัสผ่านใหม่"
              rules={[
                {
                  required: true,
                  message: 'Confirm new password is required !!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Password don’t match. Please try again.'))
                  },
                }),
              ]}
            >
              <Input autoComplete="off" maxLength={255} />
            </Form.Item>
          </Col>
          <Col span={12} />

          <Col span={12} style={{ paddingTop: '16px' }}>
            <Form.Item shouldUpdate={true} >
              <Button style={{ width: '12vmax' }} type='primary' htmlType='submit'>
                ยืนยัน
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Modal
        visible={openModal.openErr}
        footer={false}
        closable={false}
        centered={true}
        width={"528px"}
      >
        <BoxModel minHeight="300px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="example" src={iconErr} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>
                เปลี่ยนรหัสผ่านไม่สำเร็จ
              </HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel>กด 'ตกลง' เพื่อออกจากหน้านี้</TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="40px">
                <Space>
                  <Button
                    onClick={() =>
                      setOpenModal({ ...openModal, openErr: false })
                    }
                    type="primary"
                    style={{ width: "170px" }}
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal
        visible={openModal.openSuccess}
        footer={false}
        closable={false}
        centered={true}
        width={"528px"}
      >
        <BoxModel height="300px">
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <img alt="example" src={iconCorrect} width={48} height={48} />
            </Col>
            <Col span={24}>
              <HeaderModel>เปลี่ยนรหัสผ่านสำเร็จ</HeaderModel>
            </Col>
            <Col span={24}>
              <TextModel>กด 'ตกลง' เพื่อออกจากหน้านี้</TextModel>
            </Col>
            <Col span={24}>
              <TextModel marginTop="40px">
                <Space>
                  <Button
                    onClick={() => {
                      setOpenModal({ ...openModal, openSuccess: false });
                      history.push('/profile')
                    }}
                    type="primary"
                    style={{ width: "170px" }}
                  >
                    ตกลง
                  </Button>
                </Space>
              </TextModel>
            </Col>
          </Row>
        </BoxModel>
      </Modal>

      <Modal visible={openModal.openCancel} footer={false} closable={false} centered={true} width={'420px'}>
        <BoxModel height="180px">
          <Row>
            <Col span={24}>
              <HeaderCancel>
                ยืนยันการยกเลิก
              </HeaderCancel>
            </Col>
            <Col span={24}>
              <TextModel >
                หากคุณต้องการยกเลิก
              </TextModel>
            </Col>
            <Col span={24}>
              <TextModel >
                กรุณากด ยืนยัน
              </TextModel>
            </Col>
            <Col span={24}>
              <BoxButton>
                <Space>
                  <Button style={{ width: '170px' }} onClick={() => setOpenModal({ ...openModal, openCancel: false })}>
                    ยกเลิก
                  </Button>
                  <Button type="primary" htmlType="submit" style={{ width: '170px' }} onClick={() => history.goBack()}>
                    ยืนยัน
                  </Button>
                </Space>
              </BoxButton>
            </Col>
          </Row>
        </BoxModel>
      </Modal>
    </>
  )
}

const Topic = styled.div`
  margin-top: -20px;
`

const HeaderCancel = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`

const BoxButton = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: 30px;
`

const BoxModel = styled.div`
  min-height: ${(props) => (props.minHeight)};
  height: ${(props) => (props.height)};
  justify-content: center;
  align-items: center;
  display: flex;
`

const TextModel = styled.div`
  font-size: 18px;
  text-align: center ;
  margin-top: ${(props) => (props.marginTop)};
`

const HeaderModel = styled.div`
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 8px;
  margin-top: 15px;
`;

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(userprofile, state),
})

const mapDispatchToProps = (dispatch) => ({
  updateData: (data, id) => dispatch(updateData(`${userprofile}/password`, data, id, process.env.REACT_APP_AUTHEN)),
})

const PasswordFormWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordForm)


export default PasswordFormWithConnect
