import { combineReducers } from 'redux'
import resourceReducer from './resource'
import responseStatus from './responseStatus'
import collectionReducer from './collection'
import entitiesReducer from './entities'

const appReducer = combineReducers({
  resource: resourceReducer,
  entities: entitiesReducer,
  collection: collectionReducer,
  responseStatus,
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
