import React from 'react'
import {
  Form,
  Select,
  Modal,
  Input,
  Skeleton,
  message,
  Button,
  Row,
  Col,
  Space,
} from 'antd'
import Footeractionlayout from 'components/layouts/Footeractionlayout'
import { connect } from 'react-redux'
import { zm } from 'schemas'
import { createData, readData, updateData } from 'actions/collection'
import { getResource, getResourceLoading } from 'selectors'
import iconErr from 'images/icon-error@3x.png'
import iconCorrect from 'images/correct@3x.png'

const formRef = React.createRef()
const { Option } = Select

class form extends React.Component {
  state = {
    selectWidth: 0,
    selectHeight: 0,
    isDirty: true,

    errorMsg: '',
    isCreated: false,
    isCreateError: false,
    isCancel: false,
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.readData(this.props.match.params.id)
    }
  }

  clickCancle = () => {
    this.setState({ isCancel: true })
  }

  handleCancel = () => this.setState({ isCancel: false })

  optionLists() {
    return (
      <>
        <Option value={'352x240'}>{'352x240'}</Option>
        <Option value={'640x480'}>{'640x480'}</Option>
        <Option value={'800x450'}>{'800x450'}</Option>
        <Option value={'1280x720'}>{'1280x720'}</Option>
        <Option value={'1920x1080'}>{'1920x1080'}</Option>
      </>
    )
  }

  handleSubmit = async (value) => {
    const { isDirty } = this.state
    if (isDirty) {
      this.setState({ isDirty: false })
      const requestFrom = {
        Monitor: {
          Name: value.name,
          Notes: value.note,
          Type: 'Ffmpeg',
          Path: value.path,
          width: this.state.selectWidth,
          height: this.state.selectHeight,
        },
      }

      message.loading('Save in progress..', 0)
      this.props.createData(requestFrom).then((res) => {
        message.destroy()
        if (res?.code === 200) {
          this.setState({ isDirty: true, isCreated: true }, () => {
            localStorage.setItem('page', 1)
            localStorage.setItem('search', '')
          })
        } else {
          this.setState({
            isDirty: true,
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    }
  }

  handleUpdate = async (value) => {
    const { isDirty } = this.state
    const width = value.resolution.split('x')[0]
    const height = value.resolution.split('x')[1]
    await this.setState({ selectWidth: width, selectHeight: height })
    if (isDirty) {
      this.setState({ isDirty: false })
      const requestFrom = {
        Monitor: {
          Name: value.name,
          Notes: value.note,
          Type: 'Ffmpeg',
          Path: value.path,
          width: this.state.selectWidth,
          height: this.state.selectHeight,
        },
      }

      message.loading('Save in progress..', 0)
      this.props
        .updateData(requestFrom, this.props.match.params.id)
        .then((res) => {
          message.destroy()
          if (res?.code === 200) {
            this.setState({ isDirty: true, isCreated: true })
          } else {
            this.setState({
              isDirty: true,
              isCreateError: true,
              errorMsg: res?.message,
            })
          }
        })
    }
  }

  onChangeResolution = (res) => {
    const width = res.split('x')[0]
    const height = res.split('x')[1]
    this.setState({ selectWidth: width, selectHeight: height })
  }

  render() {
    const { isLoading, dataList } = this.props
    return (
      <>
        {isLoading === true ? (
          <Skeleton className="App-form" loading={isLoading} active></Skeleton>
        ) : (
          <Form
            ref={formRef}
            layout="vertical"
            className="App-form"
            onFinish={
              this.props.match.params.id ? this.handleUpdate : this.handleSubmit
            }
          >
            <Form.Item style={{ marginTop: '-20px' }}>
              <h1 className="topic">
                {this.props.match.params.id
                  ? 'แก้ไขข้อมูลกล้อง ZM'
                  : 'เพิ่มข้อมูลกล้อง ZM'}
              </h1>
            </Form.Item>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  style={{ marginTop: '-15px' }}
                  label="ชื่อ"
                  name="name"
                  rules={[{ required: true, message: 'Please insert name !!' }]}
                  initialValue={
                    this.props.match.params.id && dataList.Monitor
                      ? dataList.Monitor.Monitor.Name
                      : undefined
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  style={{ marginTop: '-5px' }}
                  label="รายละเอียด"
                  name="note"
                  rules={[{ required: true, message: 'Please insert note !!' }]}
                  initialValue={
                    this.props.match.params.id && dataList.Monitor
                      ? dataList.Monitor.Monitor.Notes
                      : undefined
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  style={{ marginTop: !!'-5px' }}
                  label="ลิงค์"
                  name="path"
                  rules={[
                    { type: 'url', message: 'Website format is incorrect. !!' },
                    { required: true, message: 'Please insert url !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.Monitor
                      ? dataList.Monitor.Monitor.Path
                      : undefined
                  }
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[20, 15]}>
              <Col span={12}>
                <Form.Item
                  style={{ marginTop: '-5px' }}
                  label="ขนาดวีดีโอ (Pixel)"
                  name="resolution"
                  rules={[
                    { required: true, message: 'Please select resolution !!' },
                  ]}
                  initialValue={
                    this.props.match.params.id && dataList.Monitor
                      ? `${dataList.Monitor.Monitor.Width}x${dataList.Monitor.Monitor.Height}`
                      : undefined
                  }
                >
                  <Select
                    placeholder="เลือก"
                    onChange={(e) => this.onChangeResolution(e)}
                  >
                    {this.optionLists()}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item shouldUpdate={true} style={{ marginTop: '15px' }}>
              <Footeractionlayout
                onCancle={this.clickCancle}
              ></Footeractionlayout>
            </Form.Item>
            <Modal
              visible={this.state.isCreated}
              footer={false}
              closable={false}
              centered={true}
              width={'420px'}
            >
              <div
                style={{
                  height: '220px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <img
                      alt="example"
                      src={iconCorrect}
                      width={48}
                      height={48}
                    />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    บันทึกข้อมูลสำเร็จแล้ว
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กดตกลงเพื่อออกจากหน้านี้
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.goBack()
                        }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.isCreateError}
              footer={false}
              closable={false}
              centered={true}
              width={'500px'}
            >
              <div
                style={{
                  height: '220px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <img alt="example" src={iconErr} width={48} height={48} />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    บันทึกข้อมูลไม่สำเร็จ
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}
                  >
                    {this.state.errorMsg}
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.setState({ isCreateError: false })
                        }}
                      >
                        ตกลง
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              visible={this.state.isCancel}
              footer={false}
              closable={false}
              centered={true}
              width={'420px'}
            >
              <div
                style={{
                  height: '180px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Row>
                  <Col
                    span={24}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    ยืนยันการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    หากคุณต้องการยกเลิก
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: '18px', textAlign: 'center' }}
                  >
                    กรุณากด ยืนยัน
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      marginTop: '30px',
                    }}
                  >
                    <Space>
                      <Button
                        style={{ width: '170px' }}
                        onClick={() => this.handleCancel()}
                      >
                        ยกเลิก
                      </Button>
                      <Button
                        type="primary"
                        style={{ width: '170px' }}
                        onClick={() => {
                          this.props.history.goBack()
                        }}
                      >
                        ยืนยัน
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </Modal>
          </Form>
        )}
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(zm, state),
  dataList: getResource(zm, state),
})

const mapDispatchToProps = (dispatch) => ({
  createData: (data) => dispatch(createData(zm, data)),
  readData: (id) => dispatch(readData(zm, id)),
  updateData: (data, id) => dispatch(updateData(zm, data, id)),
})

const ZMWithConnect = connect(mapStateToProps, mapDispatchToProps)(form)

export default ZMWithConnect
