import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  message,
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { deleteList, getList } from "actions/collection";
import { getCollectionLoading, getPagination, getStateResult } from "selectors";

import Actions from "components/action";
import DataTableLayout from "components/layouts/DataTableLayout";
import { Link } from "react-router-dom";
import { MenuAuth } from "contexts/menuAuth";
import React from "react";
import { connect } from "react-redux";
import { getPageShowing } from "utils/common.js";
import iconDeleted from "images/correct@3x.png";
import iconErr from "images/icon-error@3x.png";
import { zm } from "schemas";

let list = [];
class index extends React.Component {
  static contextType = MenuAuth;

  state = {
    page: 1,
    per_page: 15,
    search: "",
    image_link: "",
    visibleImg: false,
    listsData: [],
    isDeleteError: false,
    isDeleteSuccess: false,
    errorMsg: "",
    actionPermission: undefined,
  };

  componentDidMount() {
    getPageShowing();
    if (
      localStorage.getItem("search") &&
      localStorage.getItem("currSearch") === window.location.pathname
    ) {
      this.setState({ search: localStorage.getItem("search") });
    }
    let params = {
      page: localStorage.getItem("page")
        ? localStorage.getItem("page")
        : this.state.page,
      per_page: localStorage.getItem("per_page")
        ? parseInt(localStorage.getItem("per_page"))
        : this.state.per_page,
      search:
        localStorage.getItem("search") &&
        localStorage.getItem("currSearch") === window.location.pathname
          ? String(localStorage.getItem("search"))
          : this.state.search,
    };
    this.state.search !== "" ? this.onSearch() : this.readData(params);
  }

  onSearch = (e) => {
    localStorage.setItem("page", 1);
    let params = {
      page: 1,
      per_page: localStorage.getItem("per_page")
        ? parseInt(localStorage.getItem("per_page"))
        : this.state.per_page,
      search: localStorage.getItem("search")
        ? localStorage.getItem("search")
        : this.state.search,
      sort_field: this.state.sort_field,
      sort_by: this.state.sort_by,
    };
    localStorage.setItem("search", this.state.search);
    localStorage.setItem("currSearch", window.location.pathname);
    this.props.getData(params).then((res) => {
      this.setState({ listsData: res.data.data_list });
    });
  };

  setKeyword = (e) => {
    const value = e.target.value;
    localStorage.setItem("search", value);
    this.setState({ search: value });
  };

  readData = (params) => {
    this.props.getData(params).then((resopnse) => {
      list = [];
      // eslint-disable-next-line array-callback-return
      resopnse.data.data_list.map((value, index) => {
        list.push(value);
      });
      this.setState({ listsData: list });
    });
  };

  columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: "8%",
      render: (item) => {
        const { pagination } = this.props;
        return (
          pagination.per_page * (pagination.current_page - 1) + parseInt(item)
        );
      },
    },
    {
      title: "ไอดี",
      dataIndex: "Id",
      key: "Id",
      width: "10%",
    },
    {
      title: "รหัสกล้อง",
      dataIndex: "Name",
      key: "Name",
      width: "25%",
    },
    {
      title: "รายละเอียด",
      dataIndex: "Notes",
      key: "Notes",
      width: "30%",
      ellipsis: true,
    },
    {
      title: "ลิงค์",
      dataIndex: "Path",
      key: "Path",
      ellipsis: true,
      width: "30%",
      render: (url) => {
        return (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={url} target="_blank">
            {url}
          </a>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: "7%",
      render: (record) => {
        const author = this.context?.menuAuth?.find(
          (menu) => menu.key_slug === "cms"
        );
        const menu = author?.sub_menus?.find((sub) => sub.key_slug === "zm");

        !this.state.actionPermission &&
          this.setState({ actionPermission: menu.role_permission });

        return (
          <Actions
            record={{ id: record.Id }}
            path="/zm"
            onDelete={this.deleteList}
            history={this.props.history}
            permission={this.state.actionPermission}
          />
        );
      },
    },
  ];

  getActionPermission = () => {
    const author = this.context?.menuAuth?.find(
      (menu) => menu.key_slug === "cms"
    );
    const menu = author?.sub_menus?.find((sub) => sub.key_slug === "zm");

    !this.state.actionPermission &&
      this.setState({ actionPermission: menu.role_permission });
  };

  deleteList = (delete_id) => {
    const { pagination, dataList } = this.props;
    localStorage.setItem(
      "page",
      dataList.length === 1 && parseInt(localStorage.getItem("page")) > 1
        ? parseInt(localStorage.getItem("page")) - 1
        : this.state.page
    );
    const params = {
      page:
        dataList.length === 1 && pagination.current_page > 1
          ? pagination.current_page - 1
          : this.state.page,
      search: this.state.search,
      per_page: this.state.per_page,
    };

    message.loading("Delete in progress..", 0);
    this.props.deleteData(delete_id, params).then((res) => {
      if (res?.code === 200) {
        message.destroy();
        this.setState({
          search: "",
          isDeleteSuccess: true,
        });
        let params = {
          page: localStorage.getItem("page")
            ? localStorage.getItem("page")
            : this.state.page,
          per_page: localStorage.getItem("per_page")
            ? parseInt(localStorage.getItem("per_page"))
            : this.state.per_page,
          search: localStorage.getItem("search")
            ? String(localStorage.getItem("search"))
            : this.state.search,
        };
        this.readData(params);
      } else {
        message.destroy();
        this.setState({
          isDeleteError: true,
          errorMsg: res?.message,
        });
      }
    });
  };

  handleTableChange = (dataRevice) => {
    list = [];
    // eslint-disable-next-line array-callback-return
    dataRevice.map((value, index) => {
      list.push(value);
    });
    this.setState({ listsData: list });
  };

  render() {
    const { isLoading, pagination } = this.props;
    const { actionPermission } = this.state;
    return (
      <Form>
        <Row justify="space-between">
          <Col>
            <Input
              style={{ marginBottom: "30px", width: "20vmax" }}
              onChange={this.setKeyword}
              placeholder="ค้นหารหัสกล้อง CCTV"
              onPressEnter={this.onSearch}
              suffix={
                <SearchOutlined
                  onClick={() => this.onSearch()}
                  style={{ cursor: "pointer" }}
                />
              }
              value={this.state.search}
              autoComplete="on"
            ></Input>
          </Col>
          {actionPermission && actionPermission?.can_create && (
            <Col>
              <Button type="primary">
                <Link to="/zm/create">
                  <PlusOutlined /> เพิ่มข้อมูล
                </Link>
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            {isLoading === true ? (
              <Skeleton
                className="App-form"
                loading={isLoading}
                active
              ></Skeleton>
            ) : (
              <DataTableLayout
                dataSource={this.state.listsData}
                columns={this.columns}
                isloading={isLoading}
                pagePagination={pagination}
                getData={this.props.getData}
                onChange={this.handleTableChange}
                getPermission={this.getActionPermission}
              />
            )}
          </Col>
          <Modal
            visible={this.state.isDeleteSuccess}
            footer={false}
            closable={false}
            centered={true}
            width={"500px"}
          >
            <div
              style={{
                height: "180px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img alt="example" src={iconDeleted} width={48} height={48} />
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  ลบข้อมูลเสร็จสิ้น
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "30px",
                  }}
                >
                  <Space>
                    <Button
                      type="primary"
                      style={{ width: "170px" }}
                      onClick={() => {
                        this.setState({ isDeleteSuccess: false });
                      }}
                    >
                      ตกลง
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Modal>
          <Modal
            visible={this.state.isDeleteError}
            footer={false}
            closable={false}
            centered={true}
            width={"500px"}
          >
            <div
              style={{
                height: "220px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img alt="example" src={iconErr} width={48} height={48} />
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  ลบข้อมูลไม่สำเร็จ
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  {this.state.errorMsg}
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "30px",
                  }}
                >
                  <Space>
                    <Button
                      type="primary"
                      style={{ width: "170px" }}
                      onClick={() => {
                        this.setState({ isDeleteError: false, errorMsg: "" });
                      }}
                    >
                      ตกลง
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Modal>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  pagination: getPagination(zm, state),
  isLoading: getCollectionLoading(zm, state),
  dataList: getStateResult(zm, state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: (params) => dispatch(getList(zm, params)),
  deleteData: (id) => dispatch(deleteList(zm, id)),
});

const ZmListWithConnect = connect(mapStateToProps, mapDispatchToProps)(index);

export default ZmListWithConnect;
