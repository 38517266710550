import React from 'react'
import Viewlayout from 'components/layouts/Viewlayout'
import { Row, Col, Form, message, Modal, Skeleton, Button, Space } from 'antd'
import { DeleteOutlined, PictureFilled } from '@ant-design/icons'
import { connect } from 'react-redux'
import { readData, deleteData } from 'actions/collection'
import { partners } from 'schemas'
import { getResource, getResourceLoading } from 'selectors'
import iconDeleted from 'images/correct@3x.png'
import iconErr from 'images/icon-error@3x.png'
import { MenuAuth } from "contexts/menuAuth";
import { imageError } from '../../../utils/common'

class view extends React.Component {
  static contextType = MenuAuth;
  state = {
    visible: false,
    isCreateError: false,
    visibleDeleted: false,
    errorMsg: '',
    actionPermission: undefined,
  }

  componentDidMount() {
    this.props.readData(this.props.match.params.id)
  }

  componentDidUpdate() {
    if (this.context?.menuAuth !== this.state.actionPermission) {
      this.getPermission()
    }
  }

  getPermission() {
    const author = this.context?.menuAuth?.find((menu) => menu?.key_slug === "opt");
    const menu = author?.sub_menus?.find((sub) => sub?.key_slug === "partners-management");
    !this.state.actionPermission &&
      this.setState({ actionPermission: menu?.role_permission });
  }

  showModaldelete = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    })
    this.deleteList()
  }

  handleCancel = (e) => {
    this.setState({
      page: 1,
      search: this.state.search,
      per_page: localStorage.getItem('per_page')
        ? parseInt(localStorage.getItem('per_page'))
        : this.state.per_page,
      sort_field: 'sort',
      sort_by: 'asc',
      visible: false,
    })
  }

  deleteList = () => {
    this.setState({ visible: false }, () => {
      message.loading('Delete in progress..', 0)
      this.props.deleteData(this.props.match.params.id).then((res) => {
        if (res?.code === 200) {
          message.destroy()
          this.setState({
            visibleDeleted: true,
          })
        } else {
          message.destroy()
          this.setState({
            isCreateError: true,
            errorMsg: res?.message,
          })
        }
      })
    })
  }

  gotoEdit = () => {
    this.props.history.push(
      `/partners-management/edit/${this.props.match.params.id}`
    )
  }

  render() {
    const { dataList, isLoading } = this.props

    return (
      <Form className="App-form">
        <Viewlayout
          permission={this.state.actionPermission}
          viewData={dataList}
          isLoading={isLoading}
          textTitle="ข้อมูลพาร์ทเนอร์"
          iconEdit="edit"
          iconDelete="delete"
          textEdit="แก้ไขข้อมูล"
          textDelete="ลบข้อมูล"
          onEdit={this.gotoEdit}
          onDelete={this.showModaldelete}
        >
          {isLoading === true ? (
            <Skeleton
              className="App-form"
              loading={isLoading}
              active
            ></Skeleton>
          ) : (
            <div>
              <Row>
                <Col>
                  <h1>รูปภาพ</h1>
                  {dataList.image_url ? (
                    <span>
                      <img
                        onError={imageError}
                        alt="รูปภาพ"
                        src={dataList.image_url}
                        style={{ height: '200px' }}
                      />
                    </span>
                  ) : (
                    <PictureFilled style={{ fontSize: '80px' }} />
                  )}
                </Col>
              </Row>
              <Row gutter={[100, 0]}>
                <Col className="sil-break-word" style={{ marginTop: '30px' }} span={12}>
                  <h1>ชื่อ</h1>
                  {dataList.name ? dataList.name.th : ''}
                </Col>
                <Col className="sil-break-word" style={{ marginTop: '30px' }} span={12}>
                  <h1>Name</h1>
                  <div style={{ whiteSpace: 'nowrap' }}>{dataList.name ? dataList.name.en : ''}</div>
                </Col>
              </Row>
              <Row gutter={[100, 0]}>
                <Col className="sil-break-word" style={{ marginTop: '30px', }} span={12}>
                  <h1 style={{ whiteSpace: 'nowrap' }}>รายละเอียด</h1>
                  {dataList.detail && dataList.detail.th
                    ? dataList.detail.th
                    : '-'}
                </Col>
                <Col className="sil-break-word" style={{ marginTop: '30px' }} span={12}>
                  <h1>Detail</h1>
                  {dataList.detail && dataList.detail.en
                    ? dataList.detail.en
                    : '-'}
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>ลิงค์</h1>
                  <Button
                    style={{ marginLeft: '-15px' }}
                    type="link"
                    target="_blank"
                    href={dataList.link_url}
                  >
                    {dataList.link_url}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '30px' }}>
                  <h1>สถานะ</h1>
                  {dataList.status === 'active' ? 'ใช้งาน' : 'ไม่ใช้งาน'}
                </Col>
              </Row>
              <Modal
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
              >
                <Col className="modal-delete">
                  <DeleteOutlined style={{ fontSize: '40px' }} />
                </Col>
                <Col className="modal-delete">ต้องการลบข้อมูลใช่หรือไม่</Col>
              </Modal>
              <Modal
                visible={this.state.visibleDeleted}
                footer={false}
                closable={false}
                centered={true}
                width={'500px'}
              >
                <div
                  style={{
                    height: '180px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <img
                        alt="example"
                        src={iconDeleted}
                        width={48}
                        height={48}
                      />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      ลบข้อมูลเสร็จสิ้น
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          type="primary"
                          style={{ width: '170px' }}
                          onClick={() =>
                            this.props.history.push('/contact-partners')
                          }
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
              <Modal
                visible={this.state.isCreateError}
                footer={false}
                closable={false}
                centered={true}
                width={'500px'}
              >
                <div
                  style={{
                    height: '220px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <img alt="example" src={iconErr} width={48} height={48} />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      ลบข้อมูลไม่สำเร็จ
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      {this.state.errorMsg}
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: '18px',
                        textAlign: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Space>
                        <Button
                          type="primary"
                          style={{ width: '170px' }}
                          onClick={() => {
                            this.setState({ isCreateError: false })
                          }}
                        >
                          ตกลง
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </div>
          )}
        </Viewlayout>
      </Form >
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: getResourceLoading(partners, state),
  dataList: getResource(partners, state),
})

const mapDispatchToProps = (dispatch) => ({
  readData: (id) => dispatch(readData(partners, id)),
  deleteData: (id) => dispatch(deleteData(partners, id)),
})

const SlideListWithConnect = connect(mapStateToProps, mapDispatchToProps)(view)

export default SlideListWithConnect
